import { Endpoints } from 'constant';
import { IPayload } from 'interface';
import { request } from 'utils';

import { SET_INSTAGRAM_DATA } from './landingPageActionTypes';

export const getInstagramData = payload => {
	return request({
		payload,
		endpoint: Endpoints.instagram,
		onSuccess: ({ response, dispatch }) => {
			dispatch({
				type: SET_INSTAGRAM_DATA,
				payload: response.data
			});
		},
		onError: ({ message }) => console.log(message),
		loadingMessage: 'GET_DATA'
	});
};
