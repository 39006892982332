import { Colors } from "constant";
import styled from "styled-components";

const ButtonTheme = (theme) => {
  if (theme === "primary") {
    return `
      color: ${Colors.white};
      background-color: ${Colors.darkSecurity};
    `;
  } else if (theme === "primary-outline") {
    return `
      border: 1px solid ${Colors.darkSecurity};
      background-color: transparent;
      color: ${Colors.darkSecurity};
    `;
  } else if (theme === "white") {
    return `
      // border: 1px solid ${Colors.darkSecurity};
      background-color: #FBF6F3;
      color: ${Colors.darkSecurity};
    `;
  } else if (theme === "growth") {
    return `
      background-color: ${Colors.okuGrowth};
      color: #FFFFFF;
    `;
  }

  return `
    color: ${Colors.white};
    background-color: ${Colors.security};
  `;
};

const ButtonStyle = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0px 30px;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
  transform: translateY(0px);
  border: none;

  transition: all 300ms ease;

  font-family: AcuminPro;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.01em;

  :hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 4px #00000030;
  }

  ${(props) => ButtonTheme(props.theme)}

  :disabled {
    border: 1px solid #999999;
    background-color: #d4d4d4;
    color: #666666;
    cursor: not-allowed;

    :hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

export default ButtonStyle;
