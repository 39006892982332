import React from 'react';
import { useState, useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import {twofactorAuth, sendtwofacode, getuser, disabletwofactorAuth} from '../../../../services/auth';
import {
  Text,
  Button,
  Input
} from 'components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthenticationFormStyle from './style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';

const MailChimpForm = ({
  subscribe,
  status,
}: any) => {
  const [success, setSuccess] = useState(false);
  const [payload, setPayload] = useState({ code: '' });
  const current_user = useSelector(selectors.userProfile);

  useEffect(() => {
    if (status === 'success') {
      setSuccess(true);
      setPayload({ code: '' });

      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    }
  }, [status]);
  
  const [User, setUser] = useState('');

  const handlegetuser = async() =>{
    const res = await getuser(current_user.user.id);
    if(res.data.status){
      setUser(res.data.result);
    }
  }

  useEffect(() =>{
      handlegetuser()
  },[])
  const [tfacode ,setTfacode] = useState('');
  const handletwofactorAuth = async() =>{
    const values = {
      code: tfacode
    }
      if(User.TwofactorAuth == 'enabled'){
        const res = await  disabletwofactorAuth(current_user.user.id, values);
        if(res.data.status){
          handlegetuser();
          window.location.reload();
          toast("Two factor authentication has been disabled");
        }else{
          toast("Something went wrong")
        }
      }else{
        const res = await twofactorAuth(current_user.user.id,values)
        if(res.data.status){
          toast('Setting Two factor authentication success')
          window.location.reload();
        }else{
          toast("Something went wrong")
        }
      }
  }
  const submit = (subscribe: any) => {
    subscribe({ MERGE0: payload.code });
  };

  const handleSendCode = async() =>{
    const res =  await sendtwofacode(current_user.user.id);

    if(res.data.status){
      toast('Verification code resent to your email')
    }
  }
  const onChangeText = ({ name, value }: any) => {
    setPayload({
      ...payload,
      [name]: value
    });
  };

  return (
    <AuthenticationFormStyle
      success={ success }
    >
      <div className='join-our-team'>
        <div className=''>
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text='Two Factor Authentication'
            color='var(--blue)'
            className='mb-5 join-modal-title'
          />

          <Text
            text='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla malesuada adipiscing vel neque volutpat pharetra.'
            color='var(--black200)'
            className='mb-5'
          />
          <Input
            name='code'
            value={ tfacode  }
            className='mb-5'
            onChange={ (e) => setTfacode(e.value) }
            placeholder='Enter the code'
            textColor="black"
          />
        </div>
        <div className='btn-submit'>
          <Button
            className='btn-submit-list'
            label='Resend Code'
            textColor={ 'black' }
            backgroundColor={ '#E3E0E0' }
            onClick={() => handleSendCode()}
          />
          <Button
            className='btn-submit-list'
            label='Submit'
            textColor={ 'white' }
            onClick={() => handletwofactorAuth()}
            backgroundColor={ 'black' }
          />
        </div>

      </div>
    </AuthenticationFormStyle>
  );
};

const AuthenticationForm: React.FC = () => {
  const postUrl = `${process.env.REACT_APP_MAILCHIMP_URL}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
  return (
    <MailchimpSubscribe
      url={ postUrl }
      render={ ({
        subscribe, status, message
      }) => (
        <MailChimpForm
          subscribe={ subscribe }
          message={ message }
          status={ status }
        />
      ) }
    />
  );
};

export default AuthenticationForm;