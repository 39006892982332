import React from 'react';
import { Images } from 'constant';
import ScrollAnimation from 'react-animate-on-scroll';

const Hero = () => {
	return (
		<div className='bg-newBeginning'>
			<div className='md:container px-5 md:mx-auto'>
				<ScrollAnimation
					animateOnce
					animateIn='fadeIn'
					animatePreScroll={ false }
					className='flex items-center justify-center'
				>
					<img alt='Hero' src={ Images.HomeHero }/>
				</ScrollAnimation>
				<div className='flex flex-col items-center justify-center mt-[48px] pb-14'>
					<ScrollAnimation
						animateOnce
						animateIn='fadeInUp'
						animatePreScroll={ false }
					>
						<h1 className='text-base md:text-xl text-brandBlack text-center font-ExpressWay leading-[23px] tracking-widest uppercase'>Celebrate, create, inspire, & Connect</h1>
						<h2 className='text-[32px] md:text-5xl text-brandBlack font-semibold text-center my-4 font-ExpressWay leading-[35px] md:leading-[56px] tracking-[0.01em]'>Welcome to Oku House</h2>
						<p className='text-base md:text-lg text-brandBlack text-center font-AcuminPro leading-5 '>A home to find community, discover interests, share<br className='hidden lg:block' />stories, and celebrate culture</p>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
};

export default Hero;