import styled from "styled-components";

const PutonsaleModal = styled.div`
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;

    .inputbox {
        border-radius: 6px;
        outline: none;
        width:50%;
        margin-right:3%;
        margin-left:3%;
    }
    .inbutblock {
        margin-left:16%;
    }
    .table-body{
        color: gray;
        justify-content: center;
        text-align:center;
        // border-bottom: 1px solid white;
    }
    .table-head{
        color: gray;
        height: 60px;
        border-bottom: 1px solid gray;
    }
    .table-row {
        // padding: 20px 0px;
        height: 60px;
        border-bottom: 1px solid gray;
    }
    .price{
        display:flex;
        align-items:center;
        justify-content: center;
        height:60px;
    }
    .cusprofile {
        display:flex;
        align-items:center;
        justify-content: flex-start;
        margin-left:30px;
        height:60px;
    }

    .pricebox {
        outline: none;
        width:100%;
        height:40px;
        margin-right:3%;
    }
    
`;

export default PutonsaleModal;
