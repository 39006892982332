import styled from 'styled-components';

const FilterStyle = styled.div`
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  select {
    height: 60px;
    width: 100%;
    padding: 0px 20px;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--grey100);
    background-color: transparent;
    color: black;
    font-size: 16px;

    :focus {
      border: 1px solid var(--color-input-focus);
    }

    ::placeholder {
      color: var(--grey200);
    }
  }

  option {
    color: black;
    margin: 10px 0 10px 0;
  }

  
  @media screen and (max-width: 720px) {
    select {
      height: 45px;
    }

    .label p {
      font-size: 12px;
    }
  }

`;

export default FilterStyle;