import React from "react";
import { useNavigate } from "react-router";

import { Text } from "components";
import { Icons, Images, Colors } from "constant";

const MarketplaceCard = ({
  collectionId = "1",
  title = "Restaurant Name",
  wrapperClass = "w-full",
  onClick,
  imgClassName = "lg:h-[191px] md:h-[171px] h-[150px]",
  srcImages = Images.Odette,
  srcImagesLogo = Images.OdetteLogo,
  imgLogoClassName = "h-[60px] w-[109px] my-[5px]",
  country = "Singapore, Singapore",
  token = "1,000",
  address,
  count,
}) => {
  // navigate will only use until integration
  const navigate = useNavigate();

  return (
    <div
      onClick={
        onClick
          ? () => onClick()
          : () => navigate(`/collections/${collectionId}`)
      }
      className={`bg-layoutBg hover:bg-layoutBg/95 transition ease-in-out duration-500 rounded flex flex-col justify-between cursor-pointer ${wrapperClass}`}
    >
      {/* <img className={ `self-center ${imgLogoClassName}` } src={ srcImagesLogo } alt='Sunset in the mountains' /> */}
      <img
        className={`w-full ${imgClassName}`}
        src={srcImages}
        alt="Sunset in the mountains"
        style={{ objectFit: "cover" }}
      />
      <div className="p-[15px] flex-1 flex flex-col justify-between">
        <div className="flex items-center space-x-2">
          <Text
            text={title}
            fontFamily="ExpressWay"
            fontWeight="700"
            fontSize="20px"
            className="font-bold"
            color={Colors.darkSecurity}
          />
          <img
            className={"w-fit self-center h-fit"}
            src={Images.BadgesLogo}
            alt="Sunset in the mountains"
          />
        </div>
        <div className="flex items-center space-x-2 mt-[5px] mb-[5px]">
          <Icons.LocationIcon className="" />
          <Text
            text={address?.slice(0, 20)}
            fontFamily="AcuminPro"
            fontWeight="300"
            color={Colors.darkSecurity}
          />
        </div>
        <div className="flex items-center space-x-1">
          <Text
            text={count}
            fontFamily="AcuminPro"
            fontWeight="700"
            fontSize="16px"
            color={Colors.abundance}
          />
          <Text
            text="NFTs"
            fontFamily="AcuminPro"
            fontWeight="400"
            fontSize="16px"
            color={Colors.darkGrey}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketplaceCard;
