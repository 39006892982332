import React, { useState, useEffect } from 'react';

import {
  Dropzone,
  CustomSelect,
  Text
} from 'components';
import { Icons } from 'constant';
import UploadDocumentsStyle from './style';
import { addDocument } from '../../../../../../services/kyc';
import selectors from '../../../../../../selectors';
import {BiCloudUpload} from "react-icons/bi"

import S3 from "react-aws-s3";
import { useDispatch, useSelector } from 'react-redux';

const UploadDocuments = ({setFilestate, filestate, state, setState, setSelected, selected, DocumentUrl }) => {
  const user = useSelector(selectors.userProfile);

  const DocumentType = ['Passport', 'Driving License'];
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName:process.env.REACT_APP_DIRECTORYNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  };
  const handleimageChange = (e) => {
    let files = e.target.files;
    let file = files[0];
    setFilestate(e.target);
    const newImage = URL.createObjectURL(files[0]);
    setState(newImage);
    const ReactS3Client = new S3(config);
    const newFileName = file.name;
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      setState(data.location)
    }
    );
  }
  const [newusers, setNewUser] = useState('');
  const getCurrentuser = async () => {
    const res = await getuser(user.user.id)
    if (res.data.status) {
      setNewUser(res.data.result);
    }
  }
  useEffect(()=>{
    getCurrentuser();
  },[])

  return (
    <UploadDocumentsStyle>
      <Text
        text={'Choose Document Type'}
        color={'gray'}
        fontWeight={'700'}
        className='mb-2'
      />
      <CustomSelect
        value={newusers.DocumentType ? newusers.DocumentType : selected}
        onChange={(e) => setSelected(e.target.files)}
        options={DocumentType}
      />
      <div className='mt-5'>
        <Text
          text={'Upload Document'}
          color={'gray'}
          fontWeight={'700'}
          className='mb-2'
        />
        {/* <Dropzone /> */}
        <div className="p-4 createitem_uploadbox text-white  h-100 rounded-t border-solid border-[1px] border-white/10 ">
          
          {state || DocumentUrl? 
            <>
              <label for="profile-upload" className="custom-file-upload">

              <img className="w-full mx-auto h-auto cursor-pointer" src={ DocumentUrl ? DocumentUrl : state } style={{width:"100px",height:"100px"}}/>

              </label>
              <input id="profile-upload" type="file" className='hidden' onChange={(e) => handleimageChange(e)}/>
              </>
              :   
              <label class="createitem_upload-button">
            <div className='flex justify-center'>
              <BiCloudUpload size={18} color="black"/>
            </div>
            <small style={{color:"gray"}}>
              + Upload your document
            </small>
            <br />
            <small style={{color:"gray"}}>JPG, PNG, 2mb</small>
            <input
              type={"file"}
              accept={"image/png, image/gif, image/jpeg"}
              style={{ display: "none" }}
              className="createitem_input"
              onChange={(e) => handleimageChange(e)}
            />
          </label>
            } 
          <br />
        </div>
      </div>
    </UploadDocumentsStyle>
  );
};

export default UploadDocuments;