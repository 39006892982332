import {get,put,post} from '../http';

export const getRestaurants = async() => {
    try{
        const result = await get('restaurant/getRestaurants')
        return result;
    }catch(error){
        throw error;
    }
}



export const getcollectionnfts = async(id) => {
    try{
        const result = await get(`restaurant/getcollectionnfts/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}


export const NFTgroupcollection = async() => {
    try{
        const result = await get('restaurant/NFTgroupcollection')
        return result;
    }catch(error){
        throw error;
    }
}


export const getNewCollections = async() => {
    try{
        const result = await get('restaurant/getNewCollections')
        return result;
    }catch(error){
        throw error;
    }
}

export const getEventwithCollections = async() => {
    try{
        const result = await get('restaurant/getEventwithCollections')
        return result;
    }catch(error){
        throw error;
    }
}

export const getLatestRestaurants = async() => {
    try{
        const result = await get('restaurant/getLatestRestaurants')
        return result;
    }catch(error){
        throw error;
    }
}


export const getRestaurantsNftCount = async(values) => {
    try{
        const result = await post('restaurant/getRestaurantsNftCount', values)
        return result;
    }catch(error){
        throw error;
    }
}

