import React,{useState} from 'react';

import { Layout } from 'components';
// import { Header } from 'components';
import { Header } from 'pages/LandingPageNew/Components';

import { Tab } from './Components';
import SubHeader from './Sections/SubHeader';

import MyProfileStyle from './style';


const MyProfile = () => {
  const [showSignIn, setShowSignIn] = useState('INIT');
  const [particular, setParticular] = useState(false);
  return (
    <Layout>
       <Header showSignIn={showSignIn} setShowSignIn={setShowSignIn} particular={particular} />
      <MyProfileStyle>
        <div className='wrapper'>
          <SubHeader />
          <Tab />
        </div>
      </MyProfileStyle>
    </Layout>
  );
};

export default MyProfile;