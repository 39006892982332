export const email = email => {
	const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regExp.test(email);
};

export const phoneNumber = phone => {
	// format phone : 0... => min. 10 digit
	const re = /^\d+$/;

	return !!(phone.length > 9 && phone.length < 20 && re.test(phone));
};

export const passwordLength = password => {
	return !(password.length < 8);
};

export const reuired = value => {
	if (value) return true
	return false
};

export const minLength = (value,length) => {
	if (value.length >= length) return true
	return false
};

export const passwordMatch =  (password, confirmPassword) => {
	return ( password === confirmPassword);
};