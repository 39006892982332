
import { SET_LOADING } from 'stores/Misc/miscActionTypes';

const request = ({
	payload,
	endpoint,
	loadingMessage = '',
	onSuccess,
	onError,
	optimisticUpdate,
}) => {
	return async(dispatch, getState) => {
		try {
			if (loadingMessage.length) {
				await dispatch({
					type: SET_LOADING,
					isLoading: true,
					loadingMessage
				});
			}

			optimisticUpdate && optimisticUpdate(dispatch);

			const token = await getState().user.token;

			const makeRequest = await fetch(endpoint.path, {
				method: endpoint.method,
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: token || '',
				},
				body: (endpoint.method !== 'GET' && JSON.stringify(payload)) || null,
				mode: 'cors',
			});

			const response = await makeRequest.json();

			if (makeRequest.status >= 500) {
				throw (makeRequest.statusText);
			} else if (makeRequest.status === 401) {
				localStorage.clear();
			} else if (makeRequest.status >= 400) {
				onError && onError({
					message: response.stat_msg,
					dispatch
				});
			} else {
				onSuccess && onSuccess({
					dispatch,
					response
				});
			}
		} catch (error) {
			
			console.log('CATCHERROR', error);
		}

		if (loadingMessage.length) {
			await dispatch({
				type: SET_LOADING,
				isLoading: false,
				loadingMessage: ''
			});
		}
	};
};

export default request;