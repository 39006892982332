import styled from "styled-components";

const ModalStyles = styled.div`
	
	// padding-top: 80px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	
    
    .transactiontype {
        width:100px !important;
    }
    
    .card {
        
        background: white;
        align-items:center;
        border-radius: 10px;
        backdrop-filter: blur(100px);
        display: flex;
        box-sizing: border-box;
        max-width: 100%;
        color:black;
        padding:1em 1.5em;
        margin-top: 15px;
        overflow: hidden;
        cursor: pointer;
        background-color: lightgray;
        animation: slideToBottom 1s;
    }

    .title{
        color:blue;
    }

    .card:hover {
        border: none;
        background-color: gray;
        color:white !important;
        // opacity:0.5;
    }

    .logo {
        padding: 10px;
        max-width: 20%;
    }
    .settle:hover {
        font-size: 14px;
        backgroundColor: #C2FBD7;
        color:white;
        marginRight: 5px;
    }
    .reject{
        font-size: 14px;
        background-color:red;
        color:white;
        margin-right: 5px;
    }

    .menu {
        padding: 10px;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .arrow {
        padding-right: 30px;
        margin: auto;
        background-color: red;
    }
    .connect-wallet {
        background-color: #003051;
        margin-right: auto;
        margin-left: auto;
        top: 5em;
        display: block;
        position: relative;
        border: 1px solid #FFFBD1;
        color:white;
    }
    .connect-wallet:hover{
        background-color: #007aff;
    }
`;

export default ModalStyles;