import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import selectors from 'selectors';
import {  useSelector } from 'react-redux';
import loading from '../../assets/images/loading.gif';
import {updatePayment} from '../../services/Nft';
export default function CheckoutForm({options, setPaymentstatus,paymentStatus, nft}) {
  const stripe = useStripe();
  const elements = useElements();
  const current_user = useSelector(selectors.userProfile);


  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = options.clientSecret;

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clientSecret = options.clientSecret;
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      redirect:"if_required"
      // confirmParams: {
      //   // Make sure to change this to your payment completion page
      //   return_url: "http://localhost:3000",
      // },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   setMessage(error.message);
    // } else {
    //   setMessage("An unexpected error occurred.");
    // }

    setIsLoading(false);
    stripe.retrievePaymentIntent(clientSecret).then(async({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          const value={
            paymentId: paymentIntent.id,
            PaymentStatus : paymentIntent.status,
            paidAt: paymentIntent.created,
            paidBy: current_user.user.id
          }
          setMessage("Payment succeeded!");
          setPaymentstatus(true);
          const res= await updatePayment(nft._id, value)
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <div className="flex  mt-5 justify-center">
      <button disabled={isLoading || !stripe || !elements} id="submit" style={{backgroundColor:"#003153", color:"white", width:'200px', height:'50px'}}>
        <span id="button-text">
          {isLoading ? <img src={loading} alt="loader" style={{width:"40px", height:"40px", marginLeft:"50%"}}></img> : "Pay now"}
        </span>
      </button>
      </div>
      
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
}