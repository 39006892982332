import React, { useCallback } from "react";
import { Images } from "constant";

const Share = ({
  url,
  instaUrl,
  twitterUrl,
  discordUrl,
  facebookUrl,
  isDark = false,
}) => {
  const handleClick = useCallback(
    (itemUrl) => {
      const socmed = {
        twitter: twitterUrl,
        ig: instaUrl,
        facebook: facebookUrl,
        copy: url,
        discord: discordUrl,
      };
      window.open(socmed[itemUrl], "anotherWindow", "width=800,height=400");
    },
    [window]
  );

  return (
    <div className="flex justify-between w-[160px] items-center py-2 px-4 rounded-xl bg-white z-[999]">
      <div
        onClick={() => handleClick("copy")}
        className={isDark ? "dark-content" : "content"}
      >
        <img src={Images.ILCopyDark} alt="Copy URL" />
      </div>
      <div
        onClick={() => handleClick("ig")}
        className={isDark ? "dark-content" : "content"}
      >
        <img src={Images.ILInstagramDark} alt="Instagram" />
      </div>
      <div
        onClick={() => handleClick("facebook")}
        className={isDark ? "dark-content" : "content"}
      >
        <img src={Images.ILFacebookDark} alt="" className="Facebook" />
      </div>
      <div
        onClick={() => handleClick("twitter")}
        className={isDark ? "dark-content" : "content"}
      >
        <img src={Images.ILTwitterDark} alt="Twitter" />
      </div>
    </div>
  );
};

export default React.memo(Share);
