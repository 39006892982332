export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL = 'SET_MODAL';
export const SET_MY_PROFILE_TAB = 'SET_MY_PROFILE_TAB';

// export interface SetLoading {
//   type: typeof SET_LOADING
//   isLoading: boolean
//   loadingMessage: string
// }

// export type MiscActionTypes = SetLoading