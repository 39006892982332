import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          //infuraId: process.env.REACT_APP_INFURA_ID
          rpc: {
            56: "https://bsc-dataseed.binance.org",
            97: "https://data-seed-prebsc-1-s1.binance.org:8545/"
          }
        }
      }
    }

    return providerOptions;
}

const web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions: getProviderOptions() // required
});


const initialState = {
    loading: false,
    address: "",
    connected: false,
    web3: null,
    provider: null,
    token: null,
    errorMsg: null,
    marketplace: null,
    // NFT: null,
    web3Modal,
    totaldata:[],
    totalamount:null,
    presaleNFT:[],
		walletname:"",
}

const walletConnectReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CONNECTION_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CONNECTION_SUCCESS":
            return {
              ...state,
              loading: false,
              address: action.payload.address,
              token: action.payload.token,
              marketplace: action.payload.marketplace,
              // NFT: action.payload.NFT,
              web3: action.payload.web3,
              provider: action.payload.provider,
              connected: action.payload.connected,
              walletname: action.payload.walletname,
            };
        case "CONNECTION_FAILED":
            return {
                ...initialState,
                loading: false,
                errorMsg: action.payload,
            };
        case "UPDATE_ADDRESS":
            return {
                ...state,
                address: action.payload.address,
            };
        case "UPDATE":
            return {
                ...state,
                totaldata: action.payload,
            };
        case "UPDATE1":
            return {
                ...state,
                totalamount: action.payload
            };
        case "UPDATEpresale":
            return {
                ...state,
                presaleNFT: action.payload
            };
           
        default:
            return state;
    }
};

export default walletConnectReducer;
  