import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Button, Text, Faq } from 'components';
import { Colors, Images } from 'constant';

import Layout from '../../Components/Layout';

const House = () => {
	const benefits = [
		{
			icon: Images.BenefitPeople,
			label: 'Personalized Experiences'
		},
		{
			icon: Images.BenefitBlockChain,
			label: 'Innovative Tech on Blockchain'
		},
		{
			icon: Images.BenefitAccess,
			label: 'Access to House and Space'
		},
		{
			icon: Images.BenefitPeoples,
			label: 'Connect with Oku Member'
		},
	];

	const list = [
		{
			id: 1,
			title: 'For Restaurants',
			contents: [
				{
					id: 1,
					title: 'Where will these tokens be bought and sold?',
					p: ['The initial offering of tokens will occur on Oku House’s marketplace. Tokens can be resold or leased in the marketplace as well.'],
				},
				{
					id: 2,
					title: 'How are token values calculated? Are they based on some aspect of the operation’s business?',
					p: ['Oku House and the restaurant will decide on the initial price of each token. After a token is purchased, the owner of that token has the ability to resell that token at whatever price they feel captures the value of that token’s utility. Generally, restaurant operational and financial performance (typically indicated by demand) may influence the price that token owners value their tokens at.'],
				},
				{
					id: 3,
					title: 'What benefits are there for token owners? Are tokens connected to reservations?',
					p: [
						'Token owners will have benefits to the restaurant that the token represents. In addition, token owners will be entitled to benefits to other Oku House restaurant partners around the world. These benefits include advanced and preferred reservation bookings, discounts, and access to exclusive events / menu items.',
						'There are different tiers of tokens for each restaurant and these tiers will represent different sets of benefits. Generally, the higher the tier, the lower the number of tokens in circulation.',
						'Token owners will also be able to lease their tokens to interested parties in exchange for a fee. In this way, token owners are able to receive monetary benefits if they aren’t able to experience the cuisine themselves.'
					],
				},
				{
					id: 4,
					title: 'What happens if I own a token in a restaurant and they decide to close?',
					p: ['In the situation where a restaurant decides to close, benefits to that specific operation will become nullified. However, benefits to other Oku House restaurant partners will continue to exist. Token owners may have the option of either converting their token ownership to another restaurant or liquidating their token into Solana cryptocurrency by selling the tokens back to Oku House.',],
				}
			]
		},
		{
			id: 2,
			title: 'For Members',
			contents: [
				{
					id: 1,
					title: 'Will restaurants be vetted by Oku House?',
					p: ['The initial offering of tokens will occur on Oku House’s marketplace. Tokens can be resold or leased in the marketplace as well.'],
				},
				{
					id: 2,
					title: 'What level of information will be shared to token owners on an ongoing basis? How is sensitive information and data protected?',
					p: ['a. OG works with a data privacy team to sanitize and protect any sensitive information. We work very closely with local and regional regulation to ensure that data is properly protected.', 'b. OG and the restaurant will report high-level financial and operational information to token owners to allow them to make better-informed purchase and sale decisions.'],
				},
				{
					id: 3,
					title: 'What happens if the restaurant is unprofitable? What happens if a restaurant decides to close?',
					p: [
						'Oku House will work closely with the restaurant as a financing partner to determine how we can collectively improve profitability, both from an operational and sales perspective. ',
						'If the restaurant decides to close, there are several scenarios that will affect token ownership in different ways. Depending on the restaurant’s solvency, Oku House may facilitate a token buyback program for token owners who wish to liquidate their membership. If a restaurant is insolvent, certain reserve funds may be used to facilitate this transaction.',
					],
				},
			]
		},
	];

	return (
		<Layout>
			<div className='max-w-[1276px] w-full px-5 mx-auto pt-[140px] md:pt-[160px]'>
				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={ false }
				>
					<div className='max-w-[200px] xs:max-w-[unset] mx-auto'>
						<Text
							text='Welcome to Oku House'
							textAlign='center'
							fontWeight='700'
							fontType='h1'
							fontFamily='Expressway'
							className='mb-6'
							color={ Colors.darkSecurity }
						/>
					</div>
				</ScrollAnimation>

				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={ false }
					delay={ 200 }
				>
					<div className='max-w-[600px] md:max-w-[1000px] mx-auto'>
						<Text
							text='Oku House, a place for you to find a new community, discover new interests, or share your own stories. Our creative partners offer our community of members a set of experiences and products that are intended to reshape how we engage, interact, and live.'
							textAlign='center'
							className='mb-10'
							fontFamily='AcuminPro'
							fontSize='20px'
							lineHeight='30px'
							color={ Colors.darkSecurity }
						/>
					</div>
				</ScrollAnimation>

				<ScrollAnimation
					animateOnce
					animateIn='zoomIn'
					animatePreScroll={ false }
					delay={ 300 }
				>
					<img alt='' src={ Images.OkuHouse } className='mx-auto' />
				</ScrollAnimation>
			</div>

			<div className='bg-[#FBF6F3] mt-[-20px]'>
				<div className='bg-[#FFFBF9] h-[70px] sm:h-[100px] rounded-bl-[100px]' />
				<div className='max-w-[1276px] w-full px-5 mx-auto'>
					<div className='relative py-[40px] max-w-[1000px] mx-auto md:py-[60px] md:flex'>
						<img alt='' src={ Images.FlowerOrange } className='absolute md:relative top-[-40px] md:top-0 left-[-50px] md:left-0 md:mr-5 lg:mr-[60px] w-[150px] z-0' />
						<Text
							text='Our House is built on the foundation that people thrive when they’re connected, and we connect through shared experiences and stories. It’s this fundamental social behavior that has allowed us to define and morph local, regional, and global culture through history, art, food, and craft.'
							fontFamily='AcuminPro'
							className='relative z-1'
							fontSize='20px'
							lineHeight='30px'
							color={ Colors.darkSecurity }
						/>
					</div>

				</div>

				<div className='max-w-[1276px] w-full mb-[60px] lg:mb-[130px] md:px-5 mx-auto'>
					<div className='flex gap-[10px] lg:gap-[30px] w-full'>
						<ScrollAnimation
							animateOnce
							animateIn='fadeInUp'
							delay={ 200 }
							duration={ 1 }
							className='w-[50%]'
						>
							<img alt='' src={ Images.OkuHouse1 } className='w-full' />
						</ScrollAnimation>
						<ScrollAnimation
							animateOnce
							animateIn='fadeInUp'
							delay={ 0 }
							duration={ 1 }
							className='w-[calc(25%-10px)] lg:w-[calc(25%-30px)]'
						>
							<img alt='' src={ Images.OkuHouse2 } className='w-full h-full translate-y-[20px]' />
						</ScrollAnimation>
						<ScrollAnimation
							animateOnce
							animateIn='fadeInUp'
							delay={ 400 }
							duration={ 1 }
							className='w-[calc(25%-10px)] lg:w-[calc(25%-30px)]'
						>
							<img alt='' src={ Images.OkuHouse3 } className='w-full h-full' />
						</ScrollAnimation>
					</div>
				</div>

				<div className='bg-abundance'>
					<div className='relative max-w-[1276px] w-full px-5 mx-auto py-5 lg:py-[80px] z-1'>
						<div className='relative z-[1] max-w-[812px]'>
							<Text
								text='Each of our business partners represents an added room in our House. And we invite our guests into these rooms to explore and enjoy all that our partners have to offer. If you’re particularly keen on supporting a specific partner.'
								className='max-w-[650px] lg:max-w-[820px]'
								fontFamily='AcuminPro'
								fontSize='20px'
								lineHeight='30px'
								contentClassName='!text-center md:!text-left'
							/>

							<Button
								theme='secondary'
								label='Learn About Our Membership'
								className='mt-[20px] lg:mt-[45px] mx-auto md:mx-0'
							/>
						</div>
						<img alt='' src={ Images.BoxCircle } className='absolute right-0 top-0 h-full z-0 opacity-40 lg:opacity-100' />
					</div>
				</div>

				<div className='relative max-w-[1147px] mx-auto px-[20px] py-[40px] md:py-[60px] lg:py-20'>
					<Text
						text={ 'What You\'ll Get' }
						textAlign='center'
						fontWeight='700'
						fontType='h2'
						fontFamily='Expressway'
						className='mb-12'
						color={ Colors.security }
					/>

					<div className='grid grid-cols-2 md:grid-cols-4 gap-[30px]'>
						{
							benefits?.map((benefit, index) => (
								<ScrollAnimation
									animateOnce
									animateIn={ index % 2 === 0 ? 'fadeInLeft' : 'fadeInRight' }
									duration={ 1 }
									key={ index } className='md:max-w-[200px]'
								>
									<img alt='' src={ benefit.icon } className='mx-auto mb-6' />
									<Text
										text={ benefit.label }
										textAlign='center'
										fontWeight='700'
										fontFamily='Expressway'
										className='px-5'
										color={ Colors.security }
									/>
								</ScrollAnimation>
							))
						}
					</div>

				</div>
			</div>

			<div className='max-w-[1147px] mx-auto px-[20px] py-[40px] md:py-[60px] lg:py-20'>
				<Faq isRender={ true } list={ list } />
			</div>
		</Layout>
	);
};

export default House;