import React, { useEffect, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useNavigate } from 'react-router-dom';

import {
	Text,
	Input,
	Button,
	Modal
} from 'components';
import { Colors, Endpoints, Languages } from 'constant';

const MailChimpForm = ({ subscribe, status }) => {
	const navigate = useNavigate();

	const [payload, setPayload] = useState({
		restaurantName: '',
		email: '',
		name: '',
		role: ''
	});

	useEffect(() => {
		if (status === 'success') {
			navigate('/thank-you');
		}
	}, [status]);

	const submit = subscribe => {
		subscribe({
			MERGE0: payload.email,
			MERGE1: payload.name,
			MERGE2: payload.restaurantName,
			MERGE3: payload.role
		});
	};

	const onChangeText = ({ name, value }) => {
		setPayload({
			...payload,
			[name]: value
		});
	};

	return (
		<div className='w-full md:max-w-[600px]'>
			<Text
				fontWeight={ '700' }
				text={ Languages.restaurantParter.form.title }
				color={ Colors.darkSecurity }
				fontType='h1'
				className='mb-4'
				fontFamily='ExpressWay'
				contentClassName='!text-[38px] !leading-[42px] lg:!text-[48px] md:!leading-[60px]'
			/>
			<Text
				text={ Languages.restaurantParter.form.desc }
				color={ Colors.darkSecurity }
				className='mb-[30px]'
				fontWeight='400'
				fontSize='16px'
				lineHeight={ '25px' }
				fontFamily='ExpressWay'
			/>
			<Input
				className='mb-4'
				name='restaurantName'
				value={ payload.restaurantName }
				onChange={ onChangeText }
				placeholder='Restaurant Name'
				textColor='black'
				borderFocus='solid 1.5px orange'
			/>
			<Input
				className='mb-4'
				name='email'
				value={ payload.email }
				onChange={ onChangeText }
				placeholder='Email'
				textColor='black'
				borderFocus='solid 1.5px orange'
			/>
			<Input
				className='mb-4'
				name='name'
				value={ payload.name }
				onChange={ onChangeText }
				placeholder='Contact Person Name'
				textColor='black'
				borderFocus='solid 1.5px orange'
			/>
			<Input
				name='role'
				value={ payload.role }
				onChange={ onChangeText }
				placeholder='Role in Your Restaurant (Optional)'
				textColor='black'
				className='mb-[30px]'
				borderFocus='solid 1.5px orange'
			/>

			<Button
				label={ Languages.restaurantParter.form.labelCTA }
				disabled={ (!payload.email && !payload.restaurantName && !payload.name) || status === 'sending' }
				onClick={ () => submit(subscribe) }
			/>
		</div>
	);
};

const Form = () => {
	return (
		<MailchimpSubscribe
			url={ Endpoints.mailchimp.path }
			render={ ({ subscribe, status }) => <MailChimpForm subscribe={ subscribe } status={ status } /> }
		/>
	);
};

export default Form;