import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  connectWallet,
  connectFailed,
} from "../../../../../stores/User/WalletAction";

import { Button } from "components";
import { Images } from "constant";

const Wallet = () => {
  const dispatch = useDispatch();

  const wallet = useSelector((state) => state.WalletConnect);
  const [balan, setBalance] = useState();

  useEffect(() => {
    if (wallet.address) {
      getbalance();
    }
  }, [wallet]);
  const getbalance = async () => {
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;

    const tokenInst = await web3.eth.getBalance(address);
    const balanceInEth = await web3.utils.fromWei(tokenInst, "ether");
    setBalance(balanceInEth);
  };

  const walletconnectaction = async (value) => {
    try {
      dispatch(connectWallet(value));
    } catch (err) {
      console.log("error:", err);
    }
  };

  const handleWalletDisConnect = async () => {
    const disconnect_ = await dispatch(connectFailed());
    const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
  };

  const listWallet = [
    {
      name: "metamask",
      logo: Images.Wallets.MetaMaskLogo,
      logoText: Images.Wallets.MetaMaskText,
      classTextLogo: "",
    },
    {
      name: "coinbasewallet",
      logo: Images.Wallets.CoinBaseLogo,
      logoText: Images.Wallets.CoinBaseText,
      classTextLogo: "h-5",
    },
    {
      name: "fortmatic",
      logo: Images.Wallets.FortmaticLogo,
      logoText: Images.Wallets.FortmaticText,
      classTextLogo: "h-5",
    },
    {
      name: "walletconnect",
      logo: Images.Wallets.WalletConnectLogo,
      logoText: Images.Wallets.WalletConnectText,
      classTextLogo: "h-5",
    },
  ];

  const hidenWalletAddress =
    wallet.address.toString().slice(0, 6) +
    "..." +
    wallet.address.toString().slice(-4);

  // <Text text={balance && Number(balance).toFixed(2) +" Matic"} fontSize='24px' fontWeight='700' color="black"/>
  return (
    <div className="md:w-full lg:w-[80%] w-full mt-4 flex flex-col gap-y-5">
      {listWallet.map((item, idx) => {
        return (
          <div
            index={idx}
            onClick={() => {
              wallet.address === ""
                ? walletconnectaction(item?.name)
                : wallet.address !== "" && wallet.walletname === item?.name
                ? wallet.walletname === item?.name
                  ? null
                  : walletconnectaction(item?.name)
                : null;
            }}
            className={`flex flex-col items-center md:items-start lg:items-start gap-x-5 justify-between w-full py-8 border lg:flex-row md:flex-row rounded-xl px-6 
					${
            wallet.address === ""
              ? ` bg-white border-okuGrowth cursor-pointer`
              : wallet.address !== "" && wallet.walletname === item?.name
              ? ` bg-white border-okuGrowth cursor-pointer`
              : ` bg-slate-200 border-slate-300 cursor-not-allowed`
          }`}
          >
            <div className="flex items-start gap-5">
              <img src={item?.logo} className="w-16" />
              <div>
                <img src={item?.logoText} className={item?.classTextLogo} />
                <p className="mt-3 tracking-wider break-all text-darkSecurity">
                  {wallet.address && wallet.walletname === item?.name
                    ? hidenWalletAddress
                    : "Connect Wallet"}
                </p>
              </div>
            </div>
            <div className="flex flex-col items-center gap-2 mt-10 text-right md:items-start lg:items-start text-darkSecurity lg:mt-0 md:mt-0">
              <span className="uppercase tracking-[0.1em]">Total Balance</span>
              <span className="text-2xl font-bold tracking-[0.1em]">
                {balan && wallet.walletname === item?.name
                  ? Number(balan).toFixed(2) + " Matic"
                  : "Connect Wallet"}
              </span>
              {wallet.address === "" ? (
                <></>
              ) : wallet.walletname === item?.name ? (
                <Button
                  theme="primary"
                  className="mt-3"
                  label="Disconnect"
                  onClick={() => handleWalletDisConnect()}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Wallet;
