import React from 'react';
import { useNavigate } from 'react-router';
import { Images } from 'constant';
import { PageContruction, Header, Footer } from 'pages/LandingPageNew/Components';

const Events = () => {
	const list = [
		'Discounts and access at the events',
		'Specific table seating and guests',
		'Discounts to our Club House Collections',
		'Access to limited-time products or items'
	];
	const navigate = useNavigate();
	return (
		<div className='bg-white'>
			<Header />
			<div className='h-[100px]' />
			<div className='w-full m-auto md:px-20 container pb-5 md:py-16'>
				<PageContruction
					Image={Images.Events}
					Title='Oops.. Our events are still being planned!'
					Subtitle={[
						'Oku House hosts events and pop-ups in partnership with chefs, restaurants, mixologists, bars, artists, musicians, and other creators.',
						'Each of our event collections represents a collectible entry ticket that provides certain benefits including:'
					]}
					listString={list}
					// TopBtn='Join Access List'
					BottomBtn='Become a Partner!'
					urlBottom={() => navigate('/partnership')} />
			</div>
			<Footer />
		</div>
	);
};

export default Events;