import styled from 'styled-components';

const RegisterStyle = styled.div`
 
  input {
    padding: 10px 20px;
  }

  .checkbox1 {
    width: 20px;
    height: 20px;
    padding: 2px;
    cursor: pointer;
    border: 1px solid black;
    
    .checked1 {
      width: 100%;
      height: 100%;
      background-color: #6BB692;
    }
  }

  .checkbox2 {
    width: 20px;
    height: 20px;
    padding: 2px;
    cursor: pointer;
    border: 1px solid black;
    
    .checked2 {
      width: 100%;
      height: 100%;
      background-color: #6BB692;
    }
  }

  .link {
    color: #6BB692;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default RegisterStyle;
