import React from "react";
import { useNavigate } from "react-router";

import { Text } from "components";
import { Icons, Images, Colors } from "constant";

const CollectionCard = ({
  collectionId,
  title = "Event Name",
  wrapperClass = "w-full",
  count,
  srcImages,
  nftPrice,
  description,
  benefits,
  collectionName,
  OwnedBy,
}) => {
  // navigate will only use until integration
  const navigate = useNavigate();
  return (
    // <div onClick={  () => navigate(`/collectionsgroup/${collectionId}/${collectionName}/tokens`,{state:{collectionName:collectionName,collectionId:collectionId,collectionImg:srcImages,OwnedBy:OwnedBy,NFTCount:count}}) }
    <div
      onClick={() =>
        navigate(`/collectionsgroup/${collectionId}`, {
          state: {
            collectionName: collectionName,
            collectionId: collectionId,
            collectionImg: srcImages,
            OwnedBy: OwnedBy,
            NFTCount: count,
          },
        })
      }
      className={`p-[20px] bg-layoutBg hover:bg-layoutBg/95 transition ease-in-out duration-500 rounded flex flex-col cursor-pointer overflow-hidden ${wrapperClass}`}
    >
      <div className="flex space-x-[20px] mb-5">
        <img
          className={"w-fit self-center h-fit"}
          src={`${process.env.REACT_APP_S3_URL}${srcImages}`}
          alt="Sunset in the mountains"
          style={{ width: "50px", height: "50px", borderRadius: "100px" }}
        />
        <div className="flex flex-col justify-center">
          <div className="flex items-center space-x-2">
            <Text
              text={title}
              fontFamily="ExpressWay"
              fontWeight="700"
              fontSize="20px"
              className="font-bold"
              color={Colors.darkSecurity}
            />
            {/* <img className={ 'w-fit self-center h-fit' } src={`${process.env.REACT_APP_S3_URL}${srcImages}`} alt='Sunset in the mountains' style={{width:"50px",height:"50px"}}/> */}
          </div>
          <span
            className="mt-2.5"
            fontFamily="AcuminPro"
            style={{ color: Colors.abundance, fontWeight: "bold" }}
          >
            {count}% Royalty
          </span>
        </div>
      </div>
      <div className="pl-[10px] mb-5">
        <Text
          text="Description"
          color={Colors.darkGrey}
          fontWeight="600"
          fontSize="18px"
        />
        <span className="mb-1.5 text-darkSecurity font-light font-AcuminPro">
          {description?.slice(0, 30)}
        </span>
      </div>
      <div className="pl-[10px] mb-5">
        <Text
          text="Collection Tier"
          color={Colors.darkGrey}
          fontWeight="600"
          fontSize="18px"
        />
        <span className="mb-1.5 text-darkSecurity font-light font-AcuminPro">
          {benefits?.slice(0, 30)}
        </span>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col justify-between space-y-1">
          {/* <Text text='Average Token Price' color={ Colors.darkGrey } fontWeight='300' fontSize='14px' />
					<div className='flex space-x-2'>
						<img src={ Images.Polygon } className='w-[20px] h-[20px]' alt='currency' />
						<Text text={ nftPrice } color={ Colors.darkSecurity } fontWeight='700' fontSize='20px' />
					</div> */}
        </div>
        <div
          className="flex items-center space-x-1 cursor-pointer"
          onClick={() =>
            navigate(`/collections/${collectionId}/${collectionName}/tokens`, {
              state: {
                collectionName: collectionName,
                collectionImg: srcImages,
                OwnedBy: OwnedBy,
                NFTCount: count,
              },
            })
          }
        >
          <Text
            text="View Details"
            color={Colors.abundance}
            fontFamily="AcuminPro"
            fontWeight="700"
          />
          <Icons.ArrowDropdown className="h-1.5 w-5 [&>path]:fill-abundance -rotate-90" />
        </div>
      </div>
    </div>
  );
};

export default CollectionCard;
