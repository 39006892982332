const DateTimeDisplay = ({value, type, isDanger}) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p className="text-black mb-0" style={{fontWeight:"bold"}}>{value}</p>
      {/* <span>{type}</span> */}
    </div>
  );
};

export default DateTimeDisplay;
