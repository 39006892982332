import React, { useState , useEffect} from 'react';

import { TransactionCard, TransactionDetailCard } from 'components/Card';

import TransactionHistoryStyle from './style';
import {gettransactionHistory} from '../../../../services/Nft';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../selectors';
import axios from 'axios';
import Loader from "react-js-loader";

const TransactionHistory = () => {
  const walletaddress = useSelector(selectors.WalletConnect);
  const [open, setOpen] = useState(false)

  const [selectedCard, setSelectedCard] = useState("")
  const [data, setData] = useState([]);
  const get_transaction = async() => {
    const res = await gettransactionHistory(walletaddress.address);
    const getdatas = res.data.result;
    const activities = res.data.activity;
    const result1  = activities.filter((item) => item.walletAddress == walletaddress.address || item.from == walletaddress.address)
    const result2 = result1.filter((item) => item.type == 'buy' || item.type == 'transfer');
    let arr=[];
    getdatas.map((item) => {
      result2.map((i) => {
        if(item._id == i.NFTId){
          i.OwnedBy = item.OwnedBy;
          arr.push(i);
        }
      })
    })
    setData(arr);
    setOpen(false);
  }
  const [maticToUsd, setMatictousd] = useState('');
  const getmatictoUsd = async() => {
    const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd", {headers: {"Access-Control-Allow-Origin": "*"}})
    setMatictousd(matictousd.data['matic-network'].usd)
  }
  useEffect(() => {
    setOpen(true);
    getmatictoUsd()
    get_transaction()
  },[walletaddress])

  return (
    <TransactionHistoryStyle>
      <div className={ selectedCard !== 0 ? 'section' : '' }>
        <div className={ selectedCard !== 0 ? 'selected' : '' }>
          {data && data.map((item, i) => (
            <div key={ item?.id }>
              <div
                onClick={ e => {
                  e.preventDefault();
                  setSelectedCard(item);
                } }
              >
                <TransactionCard
                  image = {item?.NFTImage}
                  title={ item?.NFTName }
                  restoName={ item?.OwnedBy }
                  time={ ( new Date(item?.updatedAt).toLocaleString())}
                  purchase={ "$ " +item?.NFTPrice}
                  matic={(Number(item?.NFTPrice)/maticToUsd).toFixed(2)}
                  status={ item?.type }
                />
              </div>
            </div>
          )) }
        </div>
        {selectedCard &&
        <div className={ selectedCard !== 0 ? 'block' : 'hidden' } id={ `link${selectedCard}` }>
          <TransactionDetailCard
            image ={selectedCard?.NFTImage}
            title={ selectedCard?.NFTName }
            restoName={selectedCard?.OwnedBy }
            time={ ( new Date(selectedCard?.updatedAt).toLocaleString())}
            purchase={ "$ " +(selectedCard?.NFTPrice) }
            matic={(Number(selectedCard?.NFTPrice)/maticToUsd).toFixed(2)}
            status={ selectedCard?.type }
          />
        </div>
        }
      </div>
      {open ? 
        <Loader type="spinner-cub" bgColor={"black"}  color={'black'} size={80} />
        : null
      }
    </TransactionHistoryStyle>
  );
};

export default TransactionHistory;