import styled from 'styled-components';

const SettingStyle = styled.div`
  .wrapper1 {
    padding-top: 20vh;
    width: 80%;
    margin: auto;
    display: flex;
    animation: slideToBottom 1s;
  }
  .section1 {
    width: 255px;
  }
  .section2 {
    width: 100%;
  }
  .tabmenu {
    margin-top: 30px;
    cursor: pointer;
  }
  .tabmenu-list:hover {
    border: 2px solid #EF8440;
    color: #EF8440;
    background-color:rgba(239, 132, 64, 0.1);
  }

  @media screen and (max-width: 650px) {
    .wrapper1 {
      flex-direction: column;
      width: 100%;
      padding-top: 15vh;
    }
    .section1 {
      width: 100%;
      padding: 20px;
    }
    .tabmenu {
      display: flex;
      flex-wrap: wrap;
    }
    .tabmenu-list {
      width: 180px;
    }
  }

  @media screen and (max-width: 430px) {
    .tabmenu-list {
      padding: 10px;
      width: 150px;
    }
  }
`;

export default SettingStyle;