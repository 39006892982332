const baseUrl = process.env.REACT_APP_BASE_URL;
const accessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

export const endpoints = {
	login: {
		path: baseUrl + '/login',
		method: 'POST'
	},
	instagram: {
		path: `https://graph.instagram.com/me/media?fields=id,permalink,media_type,media_url,caption,timestamp&limit=50&access_token=${accessToken}`,
		method: 'GET'
	}
};

export default endpoints;