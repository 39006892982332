import axios from 'axios';
import { Fetch } from 'services/local-storage';


const getHeaders = async (tokens) => {
  let headers = {};
  const token = Fetch('token');
  headers.Authorization = token;
  headers['Content-Type'] = 'application/json';
  headers['x-auth-token'] =tokens;
  headers['api_key'] = process.env.REACT_APP_API_KEY
  headers = { ...headers };
  return headers;
}

const baseUrl =  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "https://okunftbackend.click";

//"https://okunftbackend.click";
//"http://localhost:4000"

export const get = async (url, tokens) => {
  const headers = await getHeaders(tokens)
  return axios.get(`${baseUrl}/${url}`, {
    headers,
  });
}


export const post = async (url, data, options) => {
  const headers = await getHeaders();
  return axios.post(`${baseUrl}/${url}`, data, {
    headers
  });
}

export const put = async (url, data,tokens, queryParams, options) => {
  const headers = await getHeaders(tokens);
  return axios.put(`${baseUrl}/${url}`, data, {
    params: queryParams,

    headers,
  });
}

export const patch = async (url, data, options) => {
  const headers = await getHeaders();
  return axios.patch(`${baseUrl}/${url}`, data, { headers });
}



export const createSignature = async ( data) => {
  try{
    const result = await axios.post(`${baseUrl}/restaurant/create_signature`, data);
    return result;
    
  }catch(error){
    throw error;
  }
}
