import styled from 'styled-components';

const SecurityStyle = styled.div`
    padding: 0 0px 0 30px;

    .context {
        display: flex;
    }
    .content {
        width: 100%;
        border-top: 1px solid #424949;
        margin: 30px 0 0px 0;
    }

    @media screen and (max-width: 650px) {
        padding: 20px;
      }

`;

export default SecurityStyle;