import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'components';
import { Icons } from 'constant';

const PageContruction = ({
	className,
	Image,
	Title,
	Subtitle,
	Heading,
	listString,
	TopBtn = '',
	BottomBtn = '',
	urlTop,
	urlBottom
}) => {

	return (
		<div className={`${className} bg-newBeginning flex flex-col lg:flex-row items-center xl:space-x-20 lg:p-[30px]`}>
			<img
				alt=''
				src={Image}
				className='relative lg:w-[425px] w-full' />
			<div className='py-5 lg:py-0 xl:pr-20  lg:px-0 px-5'>
				<p className='mb-4 font-semibold md:text-[32px] text-[28px] leading-[38px] font-ExpressWay text-black whitespace-pre-line'>{Title}</p>
				{
					Subtitle?.map((list, index) => (
						<p key={index} className='mb-4 text-darkSecurity tracking-[0.01em] text-base md:text-lg font-AcuminPro leading-[19px] md:leading-6'>{list}</p>
					))}
				{
					Heading !== '' &&
					<p className='mb-4 mt-8 text-darkSecurity tracking-[0.03em] md:tracking-widest text-base md:text-lg font-ExpressWay leading-[22px] uppercase'>{Heading}</p>
				}
				{
					listString?.map((list, index) => (
						<div key={index} className='flex flex-row space-x-3 mb-2'>
							<div className='py-1'>
								<Icons.Asterisk className=' [&>path]:fill-darkSecurity cursor-pointer w-4 h-4' />
							</div>
							<p className='text-darkSecurity tracking-[0.01em] text-base md:text-lg font-AcuminPro leading-[22px]'>{list}</p>
						</div>
					))}
				{
					(BottomBtn !== '' || TopBtn !== '') &&
					<div className='flex flex-col md:flex-row mt-8 md:space-x-4 space-y-4 md:space-y-0'>
						{
							TopBtn !== '' &&
							<Button theme='primary' label={TopBtn} className='w-full lg:w-fit' onClick={urlTop} />
						}

						{
							BottomBtn !== '' &&
							<Button theme='primary-outline' label={BottomBtn} className='w-full lg:w-fit' onClick={urlBottom} />
						}
					</div>
				}
			</div>
		</div>
	);
};

export default PageContruction;