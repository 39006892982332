import styled from 'styled-components';
import { Colors } from 'constant';

const NFTMembershipStyle = styled.div`
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #1C1E19;
  z-index: 99;

  .logo {
    width: 70px;
    margin-bottom: 20px;
  }

  .content-container {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0 0px 0 0px;
    margin: auto;
  }

  .coming-soon {
    width: fit-content;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: ${Colors.orange};
  }

  .restaurant {
    min-height: 100vh;
  
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 80px 20px 80px 160px;
    margin-right: 30px;
  }

  .box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 160px;
    max-width: 160px;
    height: 100%;
    margin-left: 20px;
    padding: 30px;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    transition: all 700ms ease;
    overflow: hidden;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  .box::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
  }

  .tier-bg {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;

    img {
      object-fit: cover;
    }
  }

  .web-tier-list {
    width: fit-content;
    min-width: 740px;
    height: 100vh;
    max-height: 720px;
    padding: 0px 0px;
    float: right;
  }

  .tier {
    font-size: 40px;
    color: #fff;
    margin-top: auto;
    transform-origin: right;
    transition: all 700ms ease;
    letter-spacing: 8px;
  }

  .ENTHUSIAST {
    transform: rotate(-90deg) translate(-80px, -120%);
  }

  .EPICURE {
    transform: rotate(-90deg) translate(-140px, -120%);
  }
  
  .CONNOISSEUR {
    transform: rotate(-90deg) translate(-160px, -120%);
  }

  .benefit {
    opacity: 0;
    transform: translate(50px, 0px);
    transition: all 500ms ease;
    transition-delay: 300ms;
    min-width: 290px;
  }

  ul {
    margin-left: 30px;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 36px;
      color: #FAFAFA;
      font-family: AcuminPro;
    }
  }

  .box-hover {
    width: 100%;
    max-width: 350px;
    filter: none;
    -webkit-filter: none;
  }

  .box-hover .benefit {
    display: block;
  }

  .box-hover .benefit-hover {
    opacity: 1;
    transform: translate(0px, 0px);
  }

  .box-hover .tier {
    transform: rotate(0deg) translate(0px, 0px);
  }

  .mobile-list-container {
    display: none;
  }

  @media screen and (max-width: 1150px) {
    .content-container {
      flex-direction: column;
      align-items: center;
      padding: 0 0px;
    }

    .web-tier-list {
      margin-top: 20px;
      padding-top: 0px;
      padding-bottom: 20px;
      padding-left: 10px;
    }

    .description {
      margin-right: 0px;
      max-width: unset;
    }

    .box {
      height: 500px;
    }
  }

  @media screen and (max-height: 690px) {
    .logo {
      display: none;
    }
  }

  @media screen and (max-width: 750px) {
    .content-container {
      padding: 0 0px;
    }

    .web-tier-list {
      display: none;
    }

    .mobile-list-container {
      display: grid;
      grid-template-columns: auto auto auto;
      width: 100vw;
      padding-left: 40px;
      padding-right: 0px;
      margin: 40px 0px;
      min-height: 500px;

      overflow: scroll;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      ::-webkit-scrollbar {
        display: none;
      }
    }

    .mobile-box {
      position: relative;
      display: flex;
      width: 285px;
      height: 500px;
      margin-right: 15px;
      overflow: hidden;
      z-index: 2;

      ::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 1));
        z-index: 1;
      }

      .mobile-benefit {
        padding: 30px;
        margin-top: auto;
        width: 100%;
        z-index: 2;

        ul {
          margin-left: 10px;

          li {
            font-size: 16px;
          }
        }
      }

      .mobile-tier-bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 0;
     
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    .content-container {
      padding: 0 0px;
      flex-direction: column;
      width: 100%;
      height: 120vh;
    }

    .description {
      width: 100%;
      padding: 0 30px 0 30px;
      margin-top: 20px;
    }

    .web-tier-list {
      display: none;
      visibility: hidden;
    }

    .mobile-list-container {
      padding-left: 20px;
    }
  }
`;

export default NFTMembershipStyle;