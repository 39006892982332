import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";

import {
  Text,
  Button,
  Input,
  Balance,
  PopoverH,
  Sidebar,
  Tabs,
  Modal,
  SelectMenu,
} from "components";
import Web3 from "web3";
import { Menu } from "@headlessui/react";
import { Images, Colors, Icons } from "constant";
import selectors from "../../selectors";
import HeaderStyle from "./style";
import ProfilePopup from "components/ProfilePopup";
import Notifications from "components/Notifications";
import { useLocation, useNavigate } from "react-router";
import { LoginIcon } from "@heroicons/react/solid";
import { LoginPage } from "pages";
import InputEmailForgotPw from "./InputEmailForgotPw";
import { useDispatch, useSelector } from "react-redux";
import { setModalTopUpAction, setMyProfileTabAction } from "stores/actions";
import { useTypedSelector } from "hooks";
import { getuser } from "services/auth";
import { connectWallet } from "redux/WalletAction";

const tabsData = [
  {
    title: "Marketplace",
    endpoint: "/marketplace",
  },
  {
    title: "Help",
    endpoint: "/help",
  },
];

const SignIn = ({ showSignIn, setShowSignIn, onClose, particular }) => {
  const visible =
    showSignIn === "LOGIN" ||
    showSignIn === "FORGOT-PASSWORD" ||
    showSignIn === "FORGOT-PASSWORD-MODAL" ||
    showSignIn === "SIGNUP";
  const isCustom = showSignIn === "LOGIN" || showSignIn === "INIT";
  const isNoPadding =
    showSignIn === "LOGIN" ||
    showSignIn === "INIT" ||
    showSignIn === "FORGOT-PASSWORD-MODAL" ||
    showSignIn === "FORGOT-PASSWORD-MODAL-CLOSE";

  const [cur_user, setCurUser] = useState("");
  const [cur_userdata, setCurUserdata] = useState("");

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      isCustom={isCustom}
      noPadding={isNoPadding}
      hideCloseBtn
    >
      {showSignIn === "LOGIN" || showSignIn === "INIT" ? (
        <LoginPage
          onClose={onClose}
          showSignIn={showSignIn}
          setShowSignIn={setShowSignIn}
          setCurUser={setCurUser}
          cur_user={cur_user}
          cur_userdata={cur_userdata}
          setCurUserdata={setCurUserdata}
          particular={particular}
        />
      ) : showSignIn === "FORGOT-PASSWORD-MODAL" ||
        showSignIn === "FORGOT-PASSWORD-MODAL-CLOSE" ? (
        <InputEmailForgotPw setShowSignIn={setShowSignIn} />
      ) : (
        <ModalVerification showSignIn={showSignIn} />
      )}
    </Modal>
  );
};

const ModalVerification = ({ showSignIn }) => {
  const title =
    showSignIn === "SIGNUP"
      ? "Email Verification Link Sent to your Email"
      : "Forgot Password Link Sent to your Email";
  return (
    <div className="flex flex-col items-center">
      <div className="h-[120px] w-[120px] mb-10 flex justify-center items-center bg-abundance/5 rounded-full">
        <Icons.PaperPlane className="h-[50px] w-[60px] [&>path]:fill-abundance" />
      </div>
      <Text
        text={title}
        className="mb-2.5"
        color={Colors.darkSecurity}
        fontSize="24px"
        fontFamily="ExpressWay"
        fontWeight="700"
      />
    </div>
  );
};

const Header = ({ showSignIn, setShowSignIn, particular }) => {
  const dispatch = useDispatch();
  const { modalTopUpVisible } = useTypedSelector("misc");
  const navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [showModalTransaction, setShowModalTransaction] = useState(false);
  const [newuser, setNewUser] = useState("");
  const [balance, setBalance] = useState("");
  const user = useSelector(selectors.userProfile);
  const walletaddress = useSelector((state) => state.WalletConnect);
  const [isSuccessResetPw, setIsSuccessResetPw] = useState(false);
  const token = user.token;

  const location = useLocation();

  const currentUrl = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location.pathname]);

  useEffect(() => {
    const { web3Modal, address } = walletaddress;
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }
    try {
      web3.eth.getAccounts().then(async (res) => {
        if (res[0].toLowerCase() == user.user.walletAddress.toLowerCase()) {
          if (web3Modal && user.user) {
            dispatch(connectWallet());
          }
        }
      });
    } catch (e) {
      console.log("error", e);
    }
  }, []);

  useEffect(() => {
    getCurrentuser();
    if (currentUrl == "login" && user.user) {
      navigate("/marketplace");
    }
  }, [token]);

  useEffect(() => {
    if (currentUrl === "marketplace" || currentUrl === "wallet") {
      const openSignIn = location.state?.successResetPw;

      setActiveTabIndex(0);

      if (openSignIn) {
        setShowSignIn("LOGIN");
        window.history.replaceState({}, document.title);
      }
    } else if (currentUrl === "help") {
      setActiveTabIndex(1);
    } else {
      setActiveTabIndex(-1);
    }

    return () => {
      setShowSignIn("INIT");
    };
  }, [currentUrl]);

  const getCurrentuser = async () => {
    try {
      if (user.user.id) {
        const res = await getuser(user.user.id);
        if (res.data.status) {
          setNewUser(res.data.result);
        }
      } else {
        console.log("user not found");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const classSidebar = useMemo(() => {
    if (showSidebar) return "translate-x-0 opacity-1";
    return "-translate-x-full opacity-0";
  }, [showSidebar]);

  const renderModalTransactionDetail = useMemo(() => {
    return (
      <Modal
        visible={showModalTransaction}
        onClose={() => setShowModalTransaction(false)}
        contentClassName="w-full md:w-[540px]"
      >
        <div className="">
          <Text
            text="Transaction Detail"
            className="mb-[30px]"
            color={Colors.prussianBlue}
            fontFamily="lora"
            fontWeight="700"
            fontSize="32px"
          />

          <div className="flex justify-between items-center mb-3">
            <div className=" ">
              <div className="flex items-center space-x-2 mb-1">
                <Icons.ClockIcon className={"[&>path]:fill-prussianBlue "} />
                <Text
                  text="20/07/2022 | 10.00 AM"
                  fontWeight="300"
                  color="black"
                  className=""
                  fontSize="14px"
                />
              </div>
              <Text
                text="Top Up Polygon Crypto with Moonpay"
                className=""
                fontWeight="300"
                color="black"
              />
            </div>
            <Button label="Top Up Again" width="fit-content" />
          </div>

          <Text
            text="+1,235"
            fontWeight="700"
            fontSize="20px"
            className="mb-[30px]"
            fontFamily="lora"
            color={Colors.prussianBlue}
          />

          <Text
            text="Summary"
            fontWeight="700"
            fontSize="24px"
            fontFamily="lora"
            color={Colors.darkBlue}
          />

          <div className="flex items-center justify-between mt-5">
            <Text text="NFT Name Goes Here" color="black" fontWeight="300" />
            <Text text="$1,237" color="black" fontWeight="800" />
          </div>
          <div className="flex items-center justify-between mt-5">
            <Text text="NFT Name Goes Here" color="black" fontWeight="300" />
            <Text text="$1,237" color="black" fontWeight="800" />
          </div>
        </div>
      </Modal>
    );
  }, [showModalTransaction]);

  const renderSelectCrypto = () => {
    return (
      <SelectMenu
        wrapperClassName=""
        menuBtnClassName="w-full"
        menuClassName="w-full"
        menuItemsClassName="w-full z-10"
        MenuButton={
          <div className="flex items-center justify-between p-5 border-solid border-[1px] border-black rounded">
            <div className="flex space-x-2">
              <img src={Icons.polygon} className="" alt="currency" />
              <Text text="Polygon" color="black" className="font-extrabold" />
            </div>
            <img src={Images.ArrowDown} className="filter-black" alt="" />
          </div>
        }
      >
        <div className="px-px z-10">
          <Menu.Item>
            {({ active }) => (
              <React.Fragment>
                <button
                  className={
                    "h-[62px] pl-[20px] text-white group flex w-full items-center space-x-3 rounded-md px-1 text-sm bg-white border-solid border-[1px] border-white/10"
                  }
                  onClick={() => null}
                >
                  <img src={Icons.solana} className="" alt="currency" />
                  <Text
                    text="Solana"
                    color="black"
                    className="font-extrabold"
                  />
                </button>
                <button
                  className={
                    "h-[62px] pl-[20px] text-white group flex w-full items-center space-x-3 rounded-md px-1 text-sm bg-white border-solid border-[1px] border-white/10"
                  }
                  onClick={() => null}
                >
                  <img src={Icons.polygon} className="" alt="currency" />
                  <Text
                    text="Polygon"
                    color="black"
                    className="font-extrabold"
                  />
                </button>
              </React.Fragment>
            )}
          </Menu.Item>
        </div>
      </SelectMenu>
    );
  };

  const renderUserLogin = () => {
    const walletaddress = useSelector(selectors.WalletConnect);

    const wallet = walletaddress && walletaddress.address;

    useEffect(() => {
      if (walletaddress && walletaddress.address) {
        getBalance();
      }
    }, []);
    const getBalance = async () => {
      setBalance("0.1");
    };

    return (
      <>
        {user.user ? (
          <div className="flex items-center  mr-5 space-x-2 bg-black/5 px-5 py-2 rounded-[100px] cursor-pointer">
            <Text text="Kyc : " color="black" />
            <Text
              text={
                newuser && newuser?.kyc_status == "passed"
                  ? "Verified"
                  : newuser?.kyc_status
              }
              fontWeight="400"
              fontSize="16px"
              color={newuser?.kyc_status == "passed" ? "green" : "red"}
              className="ml-2"
            />
          </div>
        ) : null}

        {token && wallet && (
          <>
            <div>
              <PopoverH
                button={
                  <div className="flex space-x-2 items-center bg-darkSecurity/5 hover:bg-darkSecurity/10 px-5 py-2 rounded-[100px] cursor-pointer">
                    <Icons.WalletIcon
                      className="[&>path]:fill-darkSecurity"
                      alt="wallet"
                    />
                    {/* <Text text='1,2345' fontFamily='AcuminPro' color={ Colors.darkSecurity } className='font-extrabold' /> */}
                    <Text
                      text={
                        wallet && ` ${wallet.slice(0, 3)}...${wallet.slice(-3)}`
                      }
                      className="font-extrabold"
                      color="black"
                    />
                  </div>
                }
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative rounded-lg bg-white">
                    <div className="p-[30px]">
                      <Balance
                        onClickTopUp={() => dispatch(setModalTopUpAction())}
                      />
                    </div>
                    <hr className="border-t-darkSecurity/10 absolute top-0 w-full h-[1px] mt-[95px]" />
                  </div>
                </div>
              </PopoverH>
            </div>
            <Modal
              visible={modalTopUpVisible}
              onClose={() => dispatch(setModalTopUpAction())}
              contentClassName="w-full md:w-[540px]"
            >
              <div className="">
                <Text
                  text="Top Up with Crypto"
                  className="mb-3"
                  color={Colors.prussianBlue}
                  fontFamily="lora"
                  fontWeight="700"
                  fontSize="32px"
                />

                <Text
                  color="black"
                  fontWeight="300"
                  className="mb-[30px]"
                  text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia perspiciatis similique voluptatibus officia et culpa iure, labore nobis veritatis error."
                />

                {renderSelectCrypto()}

                <Input
                  label="Input Amount"
                  name="input-amount"
                  className="mb-10 mt-5"
                  textInputClassName="input-amount"
                  textColor={Colors.prussianBlue}
                  autofocus
                  labelClassName="mb-[5px] text-red-600"
                  placeholder="Input Amount"
                />

                <Button
                  label="Top Up Balance"
                  className="w-full"
                  width="100%"
                  onClick={() => {
                    dispatch(setModalTopUpAction());
                    setShowModalTransaction(true);
                  }}
                />
              </div>
            </Modal>
          </>
        )}
        {!wallet && (
          <>
            <Button
              className="ml-2"
              borderRadius="100px"
              label={token ? "Connect Wallet" : "Sign in / Sign up"}
              height="48px"
              textColor={Colors.white}
              backgroundColor={Colors.darkSecurity}
              onClick={() => {
                if (token) {
                  navigate("/wallet");
                } else {
                  navigate("/sign-in");
                  //** */ setShowSignIn('LOGIN');
                }
              }}
              bgHover={Colors.prussianBlue}
            />
            <div
              className="py-[19px] px-4 bg-white/10 mr-16 rounded cursor-pointer hidden"
              onClick={() => setShowSignIn("LOGIN")}
            >
              <Icons.UserIcon className="" />
            </div>
            <LoginIcon
              onClick={() => navigate("/marketplace")}
              width={30}
              height={30}
              color="white"
              className="mr-[80px] cursor-pointer hidden"
            />
          </>
        )}
        {token && (
          <div className={wallet}>
            <ProfilePopup
              onClickMenu={(e) => dispatch(setMyProfileTabAction(e))}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderStyle
        showSignIn={showSignIn}
        className={`${
          currentUrl === "" ||
          currentUrl === "forgot-password" ||
          currentUrl === "help"
            ? "hidden"
            : ""
        }`}
      >
        <div className="header flex items-center justify-between xs:px-[80px]">
          <div className="flex items-center">
            <div
              className="space-y-1 cursor-pointer ml-5 xs:ml-0 mr-2 border-solid border-[1px] border-darkSecurity/25 py-1.5 px-1 xl:hidden sm:mr-10"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <div className="w-4 h-0.5 rounded-full bg-darkSecurity" />
              <div className="w-4 h-0.5 rounded-full bg-darkSecurity" />
              <div className="w-4 h-0.5 rounded-full bg-darkSecurity" />
            </div>
            <img
              alt="logo"
              src={Images.OkuHouseLogo}
              className="w-[154px] h-[35px] cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>

          <div
            className={`flex items-center ${
              showSignIn === "LOGIN" ? "hidden" : ""
            }`}
          >
            <Tabs
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              tabUnderlineWidth={tabUnderlineWidth}
              setTabUnderlineWidth={setTabUnderlineWidth}
              tabUnderlineLeft={tabUnderlineLeft}
              setTabUnderlineLeft={setTabUnderlineLeft}
              tabsData={tabsData}
              wrapperClassName="hidden xl:block"
              btnClassName="font-bold pt-2 pb-[5px] mr-10"
              underlineStyle="h-[1px] bg-darkSecurity"
              hoverBtnClassName="hover:text-darkSecurity"
            />
            <div className="hidden lg:flex items-center">
              {renderUserLogin()}
              {renderModalTransactionDetail}
            </div>
            <img
              alt="search"
              src={Images.IconSearch}
              className="search-icon mr-5 cursor-pointer md:hidden "
            />
          </div>
          <div
            onClick={() => setShowSignIn("INIT")}
            className={`cursor-pointer h-12 w-12 flex items-center justify-center rounded ${
              showSignIn === "LOGIN" ? "" : "hidden"
            }`}
          >
            <Icons.XCloseIcon className="[&>path]:fill-darkGrey" />
          </div>
        </div>
      </HeaderStyle>

      <SignIn
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
        particular={particular}
        onClose={() => {
          if (showSignIn === "LOGIN") {
            setShowSignIn("INIT");
          } else if (showSignIn === "FORGOT-PASSWORD-MODAL") {
            setShowSignIn("FORGOT-PASSWORD-MODAL-CLOSE");
          } else {
            setShowSignIn("");
          }
        }}
      />
      <Sidebar
        wrapperClassName={classSidebar + ` ${currentUrl === "" && "hidden"}`}
        setShowSidebar={setShowSidebar}
      />
    </>
  );
};

export default React.memo(Header);
