import React, { useState } from "react";

import { Images, Colors } from "constant";
import { Text, Button, Modal, Input } from "components";

import SubHeaderStyle from "./style";
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../selectors';
import { getuser, edit_user_profile } from '../../../../services/auth';
import { useEffect } from "react";
import S3 from "react-aws-s3";
import { ToastContainer, toast } from 'react-toastify';
import { connectFailed } from '../../../../stores/User/WalletAction';
import 'react-toastify/dist/ReactToastify.css';
const SubHeader = ({ }) => {
  const [openTab, setOpenTab] = useState(1);
  const dispatch = useDispatch();
  const wallet = useSelector(state => state.WalletConnect);
  const user = useSelector(selectors.userProfile);
  const [showProfilemodal, setShowProfilemodal] = useState(false);
  const [image, setImage] = useState('');
  const [fileName, setFileName] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [newuser, setNewUser] = useState('');
  const handleProfile = async (e) => {
    const files = e.target.files;
    let file = files[0];
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIRECTORYNAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    };

    const ReactS3Client = new S3(config);
    const newFileName = file.name + "_" + Date.now();
    ReactS3Client.uploadFile(file, newFileName).then((data) =>
      console.log(data)
    );
    const newImage = URL.createObjectURL(files[0]);
    setImage(newImage)
    setFileName(newFileName);
  }
  const getCurrentuser = async () => {
    const res = await getuser(user.user.id)
    if (res.data.status) {
      setNewUser(res.data.result);
    }
  }
  useEffect(() => {
    getCurrentuser();
  }, [])
  const handleSubmit = async () => {
    const tokens = user.token
    const values = {
      userName: name ? name : user.user.userName,
      phone: phone ? phone : newuser.Phone,
      email: email ? email : newuser.email,
      profilePicture: fileName ? fileName : newuser.profilePicture

    };
    const res = await edit_user_profile(user.user.id, values, tokens);
    if (res.data.status) {
      getCurrentuser();
      toast("Profile updated");
      setShowProfilemodal(false);
      window.location.reload(false);
    } else {
      dispatch({
        type: 'SET_PROFILE',
        payload: ''
      });
      dispatch(connectFailed());
      toast("session expired");
    }
  }
  return (
    <SubHeaderStyle>
      <div className='header flex items-center'>
        <div className='flex items-center'>
          <div className="profil">
            <img className="rounded-full max-w-full h-auto" src={newuser.profilePicture ? `${process.env.REACT_APP_S3_URL}${newuser.profilePicture}` : Images.Profile} width={120} height={120} alt='profile' />
          </div>
          <ul className=''>
            <li><Text text={newuser && newuser.userName} fontWeight='700' fontType='h4' fontSize='24px' color="black"/></li>
            <li><Text text={wallet && `${wallet.address.toString().slice(0, 5)}...${wallet.address.toString().slice(-5)}`} fontWeight='300' color="black"/></li>
            {/* <div className="flex items-center">
              <Text text="Kyc Status : " color="black"/>
              <Text text={newuser && newuser.kyc_status == 'passed' ? "Verified" : newuser.kyc_status} fontWeight='400' fontType='h4' fontSize='20px' color={newuser.kyc_status == 'passed' ? Colors.okuGreen : "red"} className='ml-2' />
            </div> */}
          </ul>



        </div>
        {/* <div className='flex flex-start ml-10'>
          <Button label="Edit profile" width="100%" onClick={() => setShowProfilemodal(!showProfilemodal)} />
        </div> */}

        {/* <div className='settingmenu flex items-center'>
          <ul className='flex'>
            <li className='menu mr-10 bg-slate-800 flex items-center'><img className='icon' src={ Images.Share } /></li>
            <li className='menu bg-slate-800 flex items-center'><img className='icon' src={ Images.Setting } /></li>
          </ul>
        </div> */}
      </div>
      <Modal visible={showProfilemodal}
        onClose={() => setShowProfilemodal(false)}
        contentClassName='w-full xs:w-[540px]'
        bgColor=""
        hideCloseBtn={true}
      >
        <div className="flex justify-center mt-2">
          {!image && !newuser.profilePicture ?
            <>
              <label for="file-upload" className="custom-file-upload">

                <img className="rounded-full max-w-full h-auto cursor-pointer" src={Images.Profile} width={100} height={100} />

              </label>
              <input id="file-upload" type="file" onChange={(e) => handleProfile(e)} />
            </>
            : null
          }
          {image || newuser.profilePicture ?
            <>
              <label for="profile-upload" className="custom-file-upload">

                <img className="rounded-full max-w-full h-auto cursor-pointer" src={image ? image : `${process.env.REACT_APP_S3_URL}${newuser.profilePicture}`} width={100} height={100} />

              </label>
              <input id="profile-upload" type="file" onChange={(e) => handleProfile(e)} />
            </>
            : null
          }
        </div>
        <div className="flex  mt-2 inputblock" >
          <label className="label">Name</label>
          <input type="text" className="inputbox rounded" defaultValue={newuser.userName} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="flex  mt-2 inputblock">
          <label className="label">Email</label>
          <input type="email" className="inputbox rounded" style={{ cursor: "no-drop" }} disabled={true} defaultValue={newuser && newuser.email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="flex  mt-2 inputblock">
          <label className="label">Phone</label>
          <input type="text" className="inputbox rounded " defaultValue={newuser.Phone} onChange={(e) => setPhone(e.target.value)} />
        </div>

        <div className="flex justify-center mt-5">
          <Button label="Submit" onClick={() => handleSubmit()} />
        </div>

      </Modal>

    </SubHeaderStyle>
  );
}
export default SubHeader