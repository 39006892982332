import { Button } from 'components';
import { Images } from 'constant';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useNavigate } from 'react-router';
const howItsWork = [
	{
		img: Images.HomeHit1,
		tag: 'one',
		title: 'Browse our creator collections',
		desc: 'Browse our different collections, each curated to celebrate the art, design, and craft of our partners.',
		button: 'Explore gallery',
		url: '/marketplace'
	},
	{
		img: Images.HomeHit2,
		tag: 'two',
		title: 'Own the experience',
		desc: 'Purchase a piece of our collections to gain access to our events and spaces and other unique benefits.',
		button: 'Explore benefits',
		url: '/our-house'
	},
	{
		img: Images.HomeHit3,
		tag: 'three',
		title: 'Share your stories',
		desc: 'Own these tokens forever and share these experiences with our community or your friends and family.',
		button: 'Learn how to trade!',
		url: '/our-house'
	},
];
const HowItsWork = () => {
	const navigate = useNavigate();
	return (
		<div className='bg-white'>
			<div className='md:container md:mx-auto pb-[70px] pt-28 md:pt-24 lg:pt-[120px] relative'>
				<ScrollAnimation
					animateOnce
					animateIn='fadeIn'
					duration={0.5}
					className='absolute top-10 md:top-16 left-0 right-0 px-5'
				>
					<h2 className='text-[32px] md:text-6xl lg:text-[100px] text-brandBlack font-bold text-center font-ExpressWay leading-[144px] tracking-[0.01em]'>HERE’S HOW IT WORKS</h2>
				</ScrollAnimation>
				<div className='flex lg:items-center md:justify-between gap-5 md:gap-[88px] relative z-10 overflow-x-auto items-start px-5'>
					{
						howItsWork.map((item, index) => (
							<ScrollAnimation
								animateOnce
								animateIn='fadeIn'
								className='w-full'
								duration={0.5}
								key={index}
								delay={index * 100}
							>
								<div className='flex flex-col space-y-2 w-[70vw] md:w-fit'>
									<img alt={item.tag} src={item.img} />
									<h3 className='text-base md:text-xl leading-[19px] md:leading-6 tracking-widest uppercase pt-2 font-ExpressWay'>{item.tag}</h3>
									<h2 className='text-base md:text-xl leading-[19px] md:leading-6 font-bold font-AcuminPro'>{item.title}</h2>
									<p className='text-brandBlack text-sm md:text-lg font-AcuminPro leading-[19px] md:leading-6 pb-5'>{item.desc}</p>
									<Button onClick={() => navigate(item.url)} theme='primary' label={item.button} className='w-fit' />
								</div>
							</ScrollAnimation>
						))
					}
				</div>
			</div>
		</div>
	);
};

export default HowItsWork;
