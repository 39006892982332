import style from 'styled-components';

const WalletConnectionStyle = style.div`
  height: fit-content;
  overflow-x: hidden;

  .wrapper {
    display: flex;
    width: 89%;
    margin: auto
  }

  .left-content {
    width: 55%;
    margin-top: 60px;
    margin-left: 40px;
  }

  .right-content {
    width: 50%;
    margin-top: 40px;
  }

  @media screen and (max-width: 900px) {
    .wrapper {
      flex-direction: column;
    }
    .left-content {
      width: 100%;
      margin-top: 60px;
      margin-left: 0px;
    }
  
    .right-content {
      width: 100%;
      margin-top: 0px;
    }
  }
  

`;

export default WalletConnectionStyle;