import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Colors, Images, Languages } from 'constant';
import { Text } from 'components';

import Layout from '../../Components/Layout';
import Form from './Form';

const Restaurant = () => {
	const howItWorks = [
		{
			image: Images.Partnership,
			title: 'Restaurant Partnership',
			desc: 'We facilitates the launch of a membership collection for each of our restaurant partners.'
		},
		{
			image: Images.Offering,
			title: 'Membership Offering',
			desc: 'The collections are offered to our global community of diners. By owning a membership, diners will receive special benefits at any of Oku House\'s partner restaurants.',
		},
		{
			image: Images.Journey,
			title: 'Growth Journey',
			desc: 'We work hand-in-hand with our restaurant partners to strategically maintain and grow their food concepts.',
		},
	];

	const restaurants = [
		{
			image: Images.SushiAirways,
			logo: Images.SushiDiningLogo,
			desc: 'Polynetik spesade. Losåsat geoning. Devarade trade. Pegogt krolang.',
		},
		{
			image: Images.Odette,
			logo: Images.OdetteLogo,
			desc: 'Polynetik spesade. Losåsat geoning. Devarade trade. Pegogt krolang.',
		},
		{
			image: Images.RonnyGuillermo,
			logo: Images.RonnyLogo,
			desc: 'Polynetik spesade. Losåsat geoning. Devarade trade. Pegogt krolang.',
		},
		{
			image: Images.FineDining,
			logo: Images.FineDiningLogo,
			desc: 'Polynetik spesade. Losåsat geoning. Devarade trade. Pegogt krolang.',
		}
	];

	return (
		<Layout>
			<div>
				<div className='relative pt-[140px] md:pt-[160px] pb-[60px] md:pb-[120px] mb-[60px] md:mb-20 overflow-hidden'>
					<ScrollAnimation
						animateOnce
						animateIn='fadeInUp'
						animatePreScroll={ false }
					>
						<Text
							fontType='h1'
							fontWeight='700'
							text='How It Works'
							fontFamily='Expressway'
							textAlign='center'
							className='mb-8'
							color={ Colors.security }
						/>
					</ScrollAnimation>
					<div className='px-5 md:flex md:gap-[52px] w-fit mx-auto'>
						{
							howItWorks.map((datum, index) => (
								<ScrollAnimation
									animateOnce
									animateIn='fadeInUp'
									animatePreScroll={ false }
									key={ index }
									className='mb-10 w-full max-w-[390px]'
									delay={ index * 100 }
								>
									<img alt='' src={ datum.image } className='w-fit mx-auto mb-5 md:mb-8' />
									<Text
										fontType='h3'
										fontWeight='600'
										text={ datum.title }
										fontFamily='Expressway'
										textAlign='center'
										className='mb-4'
										color={ Colors.security }
									/>
									<Text
										text={ datum.desc }
										fontWeight='300'
										fontFamily='AcuminPro'
										textAlign='center'
										color={ Colors.security }
									/>
								</ScrollAnimation>
							))
						}
					</div>

					<div className='absolute bg-newBeginning w-[150%] h-full top-0 z-[-1] rotate-[-3deg] origin-bottom-left' />
				</div>

				<div className='max-w-[1276px] w-full px-5 mx-auto mb-[60px] md:mb-20'>
					<Text
						fontType='h1'
						fontWeight='700'
						text='Restaurant Partners List'
						fontFamily='Expressway'
						textAlign='center'
						className='mb-10'
						color={ Colors.security }
					/>

					<div className='grid grid-cols-2 lg:grid-cols-4 gap-[20px] lg:gap-[30px]'>
						{
							restaurants.map((datum, index) => (
								<ScrollAnimation
									animateOnce
									animateIn='fadeInUp'
									animatePreScroll={ false }
									key={ index } className='lg:max-w-[286px]'
									delay={ index * 100 }
								>
									<img alt='' src={ datum.image } className='rounded-[10px] mb-[15px] md:mb-5 w-full' />
									<img alt='' src={ datum.logo } className='mb-[15px] md:mb-5' />
									<Text
										text={ datum.desc }
										fontWeight='300'
										fontFamily='AcuminPro'
										color={ Colors.security }
									/>
								</ScrollAnimation>
							))
						}
					</div>
				</div>

				<div className='max-w-[1276px] w-full px-5 mx-auto mb-[60px] md:mb-20 md:flex md:items-center md:justify-between'>
					<div className='mb-10 md:mb-0 w-full md:max-w-[495px] mr-[30px]'>
						<Text
							fontFamily='ExpressWay'
							fontWeight='700'
							fontType='h1'
							text={ Languages.restaurantParter.title }
							color={ Colors.security }
							lineHeight='85px'
							className='mb-5 md:mb-[30]'
							contentClassName='md:!text-[48px] md:!leading-[60px] lg:!text-[72px] lg:!leading-[85px]'
						/>
						<Text
							text={ Languages.restaurantParter.desc1 }
							color={ Colors.security }
							fontFamily='AcuminPro'
							lineHeight={ '25px' }
							className='mb-5 md:mb-[30]'
						/>
						<Text
							text={ Languages.restaurantParter.desc2 }
							color={ Colors.security }
							fontFamily='AcuminPro'
							lineHeight={ '25px' }
						/>
					</div>

					<Form />
				</div>

			</div>
		</Layout>
	);
};

export default Restaurant;