import style from 'styled-components';

const LandingPageStyle = style.div`
  height: fit-content;
  overflow-x: hidden;

  .fixed-content-container {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    min-width: 100vw;
    height: 100vh;
    transform: translate(${props => props.showFixedContent === 'restaurant' ? '-100vw' : '0px'}, 0px);
    transition: all 500ms ease;
    background-color: var(--color-bg);
    overflow: hidden;
  }

	.blank-screen {
		height: 100vh;
		background: #141E30;
		opacity: 0;
	}

  .show-content {
    display: flex;
  }

  .fadeIn-restaurant {
    transform: translate(-100vw);
    animation: fadeIn 500ms ease forwards;
  }
  
  .fadeOut-restaurant {
    transform: translate(-100vw);
    animation: fadeOut 500ms ease forwards;
  }

  .toggle-nft {
    animation: fadeIn 500ms ease forwards;
  }

  .fadeOut-nft {
    animation: fadeOut 500ms ease forwards;
  }

  .animated-logo {
    position: fixed;
    right: ${props => props.animateTransitionNftMembership ? '50vw' : '2vw'};
    bottom: ${props => props.animateTransitionNftMembership ? '50vh' : '80px'};
    cursor: pointer;
    animation: rotate 15000ms ease-in-out infinite;
    transition: all 700ms ease;
    z-index: 50;
    
    img {
      width: 140px;
    }
  }

  .transition-page {
    position: fixed;
    right: ${props => props.animateTransitionNftMembership ? '0px' : '0px'};
    bottom: ${props => props.animateTransitionNftMembership ? '0px' : '0px'};
    width: ${props => props.animateTransitionNftMembership ? '100vw' : '0px'};
    height: ${props => props.animateTransitionNftMembership ? '100vw' : '0px'};
    border-radius: ${props => props.animateTransitionNftMembership ? '0px' : '100%'};
    background-color: var(--color-bg);
    opacity: ${props => props.animateTransitionNftMembership ? '1' : '0'};
    transform-origin: left-top;
    z-index: 49;
    transition: all 900ms ease;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    
    100% {
      opacity: 0;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    
    50% {
      transform: rotate(180deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

	@keyframes slideToLeft {
		from {
			transform: translateX(500px);
			opacity: 0;
		}
		to {
			transform: translateX(0);
			opacity: 1;
		}
	}

  @media screen and (max-width: 900px) {
    .animated-logo,
    .transition-page {
      display: none;
    }
  }

  .flex {
    display: flex;
  }

  .flex-end {
    display: flex;
    align-items: flex-end;
  }

  .flex-1 {
    flex: 1;
  }

  .block {
    display: block;
  }

  .modal-open {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-35 {
    margin-bottom: 35px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .mb-45 {
    margin-bottom: 45px;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-25 {
    margin-top: 25px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mt-35 {
    margin-top: 35px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-45 {
    margin-top: 45px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mr-25 {
    margin-right: 25px;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .mr-35 {
    margin-right: 35px;
  }

  .mr-40 {
    margin-right: 40px;
  }

  .mr-45 {
    margin-right: 45px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .ml-25 {
    margin-left: 25px;
  }

  .ml-30 {
    margin-left: 30px;
  }

  .ml-35 {
    margin-left: 35px;
  }

  .ml-40 {
    margin-left: 40px;
  }

  .ml-45 {
    margin-left: 45px;
  }

  .ml-50 {
    margin-left: 50px;
  }

  .ml-55 {
    margin-left: 55px;
  }

  .header-backdrop {
    height: 125px;
  }
`;

export default LandingPageStyle;