import React, { useState } from 'react';

import { Layout, Text } from 'components';

import {
  Security,
  KYC,
  Notification,
  PrivacyPolicy,
  TermsCondition
} from './Sections';
import SettingStyle from './style';
import { MyProfile } from 'pages';
import EditProfile from './Sections/EditProfile';

const Setting = () => {
  const [openTab, setOpenTab] = useState(1);
  const menu = [
    {
      title: 'Edit Profile',
      id: 1
    },
    {
      title: 'Security',
      id: 2
    },
    {
      title: 'KYC',
      id: 3
    },
    {
      title: 'Terms & Conditions',
      id: 4
    },
    {
      title: 'Privacy & Policy',
      id: 5
    },
  ];

  return (
    <Layout>
      <SettingStyle>
        <div className='wrapper1'>
          <div className='section1'>
            <Text
              text={ 'Settings' }
              fontWeight={ '700' }
              color={ 'black' }
              fontSize={ '48px' }
              fontType={ 'h2' }
            />
            <div
              className='tabmenu list-none'
              role='tablist'
              
            >
              { menu.map((item, i) => (
                <div
                  key={ item.id }
                  onClick={ e => {
                    e.preventDefault();
                    setOpenTab(item.id);
                  } }
                  style={{backgroundColor: openTab == item.id ? "rgba(239, 132, 64, 0.1)" : "rgba(28, 30, 25, 0.05)"}}
                  className={ openTab === item.id ? 'tabmenu-list bg-slate-800 rounded p-5 mb-5 flex w-full' : 'tabmenu-list p-5 mb-5 mr-2 last:mr-0 flex bg-slate-800 rounded w-full' }
                >
                  <a
                    data-toggle='tab'
                    href={ `link${item.id}` }
                    role='tablist'
                  >
                    <Text
                      text={ item.title }
                      fontWeight={ '700' }
                      color={ openTab == item.id ? '#EF8440' : 'black' }
                      fontSize={ '20px' }
                    />
                  </a>
                </div>
              )) }
            </div>
          </div>
          <div className='section2'>
            <div className=''>
              <div className={ openTab === 1 ? 'block' : 'hidden' } id='link1'>
                <EditProfile />
              </div>
              <div className={ openTab === 2 ? 'block' : 'hidden' } id='link1'>
                <Security />
              </div>
              <div className={ openTab === 3 ? 'block' : 'hidden' } id='link2'>
                <div className=''>
                  <KYC />
                </div>
              </div>
            </div>
          </div>
        </div>
      </SettingStyle>
    </Layout>
  );
};

export default Setting;