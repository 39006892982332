import styled from 'styled-components';

const DropzoneStyle = styled.div`
    border: 2px dotted gray;
    height: 225px;
    margin: auto;
    color: white;

    .uploadbtn {
        margin-top: 60px;
    }
`;

export default DropzoneStyle;