import React, { useEffect } from 'react';
import { ChangeEvent, useState } from 'react';

import { validation } from 'helpers';
import { Images } from 'constant';
import { Text } from 'components';


import InputStyle from './style';
import colors from 'constant/colors';
const Input = ({
	type = 'text',
	name,
	value,
	label = '',
	placeholder = '',
	className = '',
	autofocus = false,
	onChange,
	labelClassName = 'mb-5',
	textColor = '',
	textInputClassName = '',
	textRightInput = '',
	bgColor,
	border,
	focusLabelColor,
	borderFocus,
	iconRight,
	bgContainer,
	

}) => {
	const [invalidMessage, setInvalidMessage] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [isFocus, setIsFocus] = useState(false);
	const [password, setpassword] = useState('');

	const handleOnBlur = () => {
		if (name === 'email' || name === 'emailSignIn' || name === 'emailSignUp' || name === 'emailForgotten') {
			if (!validation.email(value)) {
				setInvalidMessage('Invalid email format');
			}  else {
				setInvalidMessage('');
			}
		} else if (name === 'fullName' || name === 'passwordSignIn' || name === 'createPassword' || name === 'confirmPasswords') {
			if (value === '') {
				setInvalidMessage(`${name === 'fullName' ? 'Name' : 'Password'} can not be empty`);
			} else if ((name === 'passwordSignIn' || name === 'createPassword' || name === 'confirmPasswords') && value.length < 5) {
				setInvalidMessage('Minimum length for password is 5 characters');
			} else {
				setInvalidMessage('');
			}
		}
		 else if (name === 'phoneNumber') {
			if (!validation.phoneNumber(value)) {
				setInvalidMessage('Invalid phone number');
			} else {
				setInvalidMessage('');
			}
		}

		else if(name === 'password'){
            setpassword(value)
			if (!validation.passwordLength(value)) {
				setInvalidMessage('Password must be at least 8 characters');
			} else {
				setInvalidMessage('');
			}
		}
		// else if(name === 'confirmPassword'){
        //     console.log(validation.passwordMatch(value),password)
		// 	if (validation.passwordMatch(password,value)) {
		// 		setInvalidMessage('');
		// 	} else {
		// 		setInvalidMessage('Confirm Password must be same as Password');	
		// 	}
		// }

		setIsFocus(false);
	};

	const handleOnFocus = () => {
		setIsFocus(true);
	};

	const handleOnChange = e => onChange && onChange({
		value: e.target.value,
		name: e.target.name
	});

	const renderLabel = () => {
		if (!label) return null;

		return (
			<Text
				text={ label }
				fontWeight='700'
				color={ isFocus ? `${focusLabelColor ? focusLabelColor : 'var(--color-text-blue)'}` : 'var(--grey200)' }
				className={ `${labelClassName} label` }
			/>
		);
	};

	const renderInputType = () => {
		if (type === 'area') {
			return renderTextAreaInput();
		} else if (type === 'search') {
			return renderSearchInput();
		}

		return renderTextInput();
	};

	const renderSearchInput = () => {
		return (
			<form autoComplete='off' className='input-container input-search'>
				<img alt='search' src={ Images.IconSearch } className='search-icon' />
				<input
					name={ name }
					defaultValue={ value }
					placeholder='Search'
					type='text'
					onChange={ handleOnChange }
					autoFocus={ autofocus }
					onFocus={ handleOnFocus }
					onBlur={ handleOnBlur }
				/>
			</form>
		);
	};

	const renderTextInput = () => (
		<>
			<div className={ `input-container ${textInputClassName}` }>
				<input
					name={ name }
					defaultValue={ value }
					placeholder={ placeholder }
					type={ type === 'password' && showPassword ? 'text' : type }
					onChange={ handleOnChange }
					autoFocus={ autofocus }
					onFocus={ handleOnFocus }
					onBlur={ handleOnBlur }
					autoComplete='off'
				/>
				{ textRightInput &&
				<Text text={ textRightInput } />
				}
				{
					iconRight && iconRight
				}
			</div>
			<Text text={ invalidMessage } className={ `mt-2 ${!invalidMessage && 'hidden'}` } color={ colors.redSalsa } />
		</>
	);

	const renderTextAreaInput = () => (
		<textarea
			value={ value }
			name={ name }
			onChange={ handleOnChange }
			placeholder={ placeholder }
			onFocus={ handleOnFocus }
			onBlur={ handleOnBlur }
		/>
	);

	return (
		<InputStyle
			className={ className }
			textColor={ textColor }
			bgColor={ bgColor }
			border={ border }
			borderFocus={ borderFocus }
			bgContainer={ bgContainer }
			iconRight={ iconRight }
			invalidMessage={ invalidMessage }
		>
			{ renderLabel() }
			{ renderInputType() }
		</InputStyle>
	);
};

export default Input;