import React, { useState, useEffect } from 'react';

import { Input } from 'components';

import GeneralInformationStyle from './style';

const GeneralInformation = ({ status, payload, setPayload,newuser}) => {
	const [success, setSuccess] = useState(false);
	

	useEffect(() => {
		if (status === 'success') {
			setSuccess(true);
			setPayload({
				firstname: '',
				lastname: '',
				birthday: ''
			});

			setTimeout(() => {
				setSuccess(false);
			}, 3000);
		}
	}, [status]);

	const onChangeText = ({ name, value }) => {
		setPayload({
			...payload,
			[name]: value
		});
	};

	return (
		<GeneralInformationStyle>
			<Input
				label='First Name'
				name='firstname'
				value={ newuser && newuser.userFirstname ? newuser.userFirstname :  payload.firstname }
				className='mb-6'
				onChange={ onChangeText }
				autofocus
				placeholder='Your First Name'
			/>
			<Input
				label='Last Name'
				name='lastname'
				value={newuser && newuser.userSecondname ? newuser.userSecondname : payload.lastname }
				className='mb-6'
				onChange={ onChangeText }
				placeholder='Your Last Name'
			/>
			<div className='date-picker'>
				<Input
					label='Birthday'
					name='birthday'
					type='date'
					value={newuser && newuser.userDOB ? newuser.userDOB : payload.birthday }
					className='mb-6'
					onChange={ onChangeText }
					placeholder='Select Date'
				/>
			</div>
		</GeneralInformationStyle>
	);
};

export default GeneralInformation;