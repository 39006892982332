import React from 'react';

import { Verify } from 'components';
import { Images } from 'constant';
import { Header, Footer, Layout } from 'pages/LandingPageNew/Components'
import { useLocation } from 'react-router-dom';

const Verification = () => {

	const { state } = useLocation();
	return (
		<Layout bgColor='bg-white'>
			<div className='w-full m-auto md:px-20 container py-5 md:pt-20 min-h-[calc(100vh-250px)]'>
				<Verify
					Image={Images.Verify1}
					Kicker='You’re almost there!'
					Title='Verify its you!'
					Subtitle=''
					state={state}
					 />
			</div>
		</Layout>
	);
};

export default Verification;