import styled from 'styled-components';

const TextStyle = styled.div`
  /* width: fit-content; */
  ${props => `
    h1, h2, h3, h4, h5, p {
      color: ${props.color || 'var(--dark-security)'};
      letter-spacing: ${props.customLetterSpacing ? props.customLetterSpacing : '0.01em'};
    }

    h1 {
      font-family:  ${props.fontFamily || 'Expressway'};
      font-size: ${props.fontSize || '48px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
      line-height: ${props.lineHeight || 'normal'};
    }

    h2 {
      font-family:  ${props.fontFamily || 'Expressway'};
      font-size: ${props.fontSize || '32px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
      line-height: ${props.lineHeight || '41px'};
      
      @media screen and (max-width: 500px) {
        font-size: ${props.fontSize || '24px'};
        line-height: ${props.lineHeight || '31px'};
      }
    }

    h3 {
      font-family:  ${props.fontFamily || 'Expressway'};
      font-size: ${props.fontSize || '24px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
      font-weight: ${props.fontFamily};

      span {
        font-family: Expressway;
        font-size: ${props.fontSize || '2rem'};
        text-align: ${props.textAlign || 'left'};
        font-style: ${props.fontStyle || 'unset'};
        font-weight: ${props.fontWeight || '700'};
      }
    }
    
    h4 {
      font-family:  ${props.fontFamily || 'Expressway'};
      font-size: ${props.fontSize || '20px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
    }
    
    p,
    pre {
      font-family:  ${props.fontFamily || 'AcuminPro'};
      font-size: ${props.fontSize || '16px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '400'};
			line-height: ${props.lineHeight || 'normal'} ;
    }

    @media screen and (max-width: 768px) {
      h1 {
        font-size: 38px;
        line-height: 48px;
      }

      h4 {
        font-size: 16px;
        line-height: 26px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }

      pre {
        font-size: 14px;
        line-height: 20px;
      }
    }

    pre {
      white-space: normal;
    }
  `}

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 30px;
      line-height: 40px;
    }

    h2 {
      font-size: 28px;
      line-height: 45px;
    }

    h3 {
      font-size: 20px;
      line-height: 38px;
    }

    h4 {
      font-size: 16px;
      line-height: 26px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
    }

    pre {
      font-size: 14px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 28px;
      line-height: 38px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
    }

    pre {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default TextStyle;