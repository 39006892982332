import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { useDispatch, useSelector } from "react-redux";

import { Button, Text, Modal, PopoverH } from "components";
import { Colors, Images, MenuList, Icons } from "constant";

import { HeaderStyle, MenuItemStyle } from "./style";
import { setModalTopUpAction, setMyProfileTabAction } from "stores/actions";
import ProfilePopup from "components/ProfilePopup";
import Web3 from "web3";
import { connectWallet } from "redux/WalletAction";
import { connectFailed } from "../../../../stores/User/WalletAction";
import { getuser } from "../../../../services/auth";
import selectors from "../../../../selectors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;

  const wallet = useSelector((state) => state.WalletConnect);

  const user = useSelector(selectors.userProfile);

  const [showSidebar, setShowSidebar] = useState(false);
  const onClickBurgerMenu = () => setShowSidebar(!showSidebar);
  const [usertokencheck, setusertokencheck] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setusertokencheck(true);
    } else {
      setusertokencheck(false);
    }
  }, [refresh]);

  useEffect(() => {
    const { web3Modal, address } = wallet;
    let web3;
    if (window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }
    try {
      web3.eth.getAccounts().then(async (res) => {
        try {
          if (res[0].toLowerCase() == user.user.walletAddress.toLowerCase()) {
            if (web3Modal && user.user) {
              dispatch(connectWallet());
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
    } catch (e) {
      console.log("error", e);
    }
  }, []);

  const logout = async () => {
    if (wallet.address) {
      const { web3Modal } = wallet;
      web3Modal.clearCachedProvider();
      dispatch(connectFailed());
    }
    dispatch({
      type: "SET_PROFILE",
      payload: "",
    });
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
    setrefresh(true);
    //** */ navigate('/marketplace')
  };

  const checkmarketnav = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res1 = await getuser(user.user.id);
      if (res1.data.status) {
        const User = res1.data.result;
        if (User.membershipstatus == "approved") {
          navigate(`/marketplace`);
        } else {
          navigate(`/membership`);
        }
      } else {
        toast("Something Went wrong");
      }
    } else {
      navigate("/sign-in");
      toast("Login Your Account");
    }
  };

  const checkmembership = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res1 = await getuser(user.user.id);
      if (res1.data.status) {
        const User = res1.data.result;
        if (User.membershipstatus == "approved") {
          navigate("/wallet");
        } else {
          toast("Complete Your Membership");
        }
      } else {
        toast("Something Went wrong");
      }
    } else {
      navigate("/sign-in");
      toast("Login Your Account");
    }
  };

  const renderMenuList = () =>
    MenuList.map((menu, index) => (
      <MenuItemStyle
        key={index}
        className={`menu-item ${
          menu.url === "/marketplace" && !usertokencheck && "hidden"
        }`}
        isPageFocus={pathname === menu.url}
        onClick={() =>
          menu.label === "Access List"
            ? window.open(menu.url, "_blank")
            : menu.label === "Collections"
            ? checkmarketnav()
            : navigate(menu.url)
        }
      >
        {/* //** menu.label === 'Gallery' ? checkmarketnav() : */}
        <Text
          text={menu.label}
          fontWeight="500"
          color={Colors.darkSecurity}
          className="label"
        />
      </MenuItemStyle>
    ));

  const renderBurgerMenu = () => (
    <div className="burger block lg:hidden" onClick={onClickBurgerMenu}>
      <div className="burger-lines" />
    </div>
  );

  const handleWalletDisConnect = async () => {
    dispatch(connectFailed());
    const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
  };

  return (
    <HeaderStyle showSidebar={showSidebar}>
      <div className="navbar-container ">
        <div className="w-full container px-5 mx-auto flex justify-between items-center lg:gap-x-0 md:gap-x-0 gap-x-3">
          <div className="flex items-center justify-between w-full lg:w-fit">
            <img
              onClick={() => navigate("/")}
              src={Images.OkuHouseLogo}
              className="header-logo cursor-pointer"
              alt=""
            />
            {renderBurgerMenu()}
          </div>

          <div
            className="h-fit list-container hidden lg:flex ml-10"
            style={{ marginLeft: "100px" }}
          >
            {renderMenuList()}
          </div>

          {/* {usertokencheck && wallet.address && (
            <>
              <div>
                <PopoverH
                  button={
                    <div className="flex space-x-2 items-center bg-darkSecurity/5 hover:bg-darkSecurity/10 px-5 py-2 rounded-[100px] cursor-pointer ml-10">
                      <Icons.WalletIcon
                        className="[&>path]:fill-darkSecurity"
                        alt="wallet"
                      />
                      <Text
                        text={
                          wallet.address &&
                          ` ${wallet.address.slice(
                            0,
                            3
                          )}...${wallet.address.slice(-3)}`
                        }
                        className="font-extrabold"
                        color="black"
                      />
                    </div>
                  }
                ></PopoverH>
              </div>
            </>
          )} */}

          <div className="lg:flex hidden">
            {!usertokencheck && (
              <Button
                className="d-flex ml-10"
                label={"Sign-In / Sign-Up"}
                onClick={() => {
                  navigate("/sign-in");
                }}
              />
            )}
          </div>

          {usertokencheck && (
            <div className="flex items-center">
              {/* {wallet.address ? (
                <Button
                  label={`Disconnect(${
                    wallet.address.slice(0, 3) +
                    "..." +
                    wallet.address.slice(-3)
                  })`}
                  className="connect-wallet"
                  onClick={() => {
                    handleWalletDisConnect();
                  }}
                />
              ) : (
                <Button
                  label="Connect Wallet"
                  className="connect-wallet"
                  onClick={() => {
                    navigate("/wallet");
                  }}
                />
              )} */}

              <ProfilePopup
                onClickMenu={(e) => dispatch(setMyProfileTabAction(e))}
              />
            </div>
          )}
        </div>
      </div>

      <div className="sidebar">
        {renderMenuList()}
        {!usertokencheck && (
          <Button
            label="Sign-In / Sign-Up"
            className="w-full my-5"
            onClick={() => navigate("/sign-in")}
          />
        )}
      </div>
    </HeaderStyle>
  );
};

export default Header;
