import styled from "styled-components";
import { Colors } from "constant";

const HeaderStyle = styled.div`
  .overlay {
    display: ${(props) => (props.showSidebar ? "block" : "none")};
  }

  .burger {
    height: 20px;
    width: 25px;
    z-index: 9999;

    .burger-lines {
      position: relative;
      width: ${(props) => (props.showSidebar ? "0px" : "25px")};
      height: 3px;
      margin-top: 8px;
      border-radius: 5px;
      background-color: ${Colors.darkSecurity};
      transition: all 300ms ease;

      ::after,
      ::before {
        content: "";
        position: absolute;
        width: 25px;
        height: 3px;
        border-radius: 5px;
        background-color: ${(props) =>
          props.showSidebar ? Colors.darkSecurity : Colors.darkSecurity};
        transform-origin: left;
        transition: all 700ms ease;
      }

      ::after {
        top: -8px;
        transform: ${(props) =>
          props.showSidebar ? "rotate(40deg)" : "rotate(0deg)"};
      }

      ::before {
        top: 8px;
        transform: ${(props) =>
          props.showSidebar ? "rotate(-40deg)" : "rotate(0deg)"};
      }
    }
  }

  .sidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 200px;
    padding-top: 50px;
    padding-bottom: 20px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: #fbf6f3;
    /* background-color: #EF844060; */
    /* backdrop-filter: blur(10px); */
    transform: ${(props) =>
      props.showSidebar ? "translate(0px, 0px)" : "translate(500px, 0px)"};
    transition: all 700ms ease;
    z-index: 899;
    box-shadow: 4px 0px 8px #00000020;
  }

  .list-container {
    gap: 10px;

    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 750px) {
    .burger {
      height: 16px;
      width: 20px;

      .burger-lines {
        position: relative;
        width: ${(props) => (props.showSidebar ? "0px" : "16px")};
        height: 2px;
        margin-top: 5px;
        border-radius: 5px;
        background-color: ${Colors.darkSecurity};
        transition: all 300ms ease;

        ::after,
        ::before {
          content: "";
          position: absolute;
          width: ${(props) => (props.showSidebar ? "19px" : "16px")};
          height: 2px;
          border-radius: 5px;
          background-color: ${(props) =>
            props.showSidebar ? Colors.darkSecurity : Colors.darkSecurity};
          transform-origin: left;
          transition: all 700ms ease;
        }

        ::after {
          top: -7px;
          transform: ${(props) =>
            props.showSidebar ? "rotate(43deg)" : "rotate(0deg)"};
        }

        ::before {
          top: 6px;
          transform: ${(props) =>
            props.showSidebar ? "rotate(-43deg)" : "rotate(0deg)"};
        }
      }
    }
  }

  @media screen and (min-width: 840px) {
    .list-container {
      gap: 20px;
    }
  }

  @media screen and (min-width: 1000px) {
    .list-container {
      gap: 30px;

      p {
        font-size: 16px;
      }
    }
  }
`;

const MenuItemStyle = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 5px;

  @media screen and (min-width: 768px) {
    .label p {
      color: ${(props) =>
        props.isPageFocus ? Colors.abundance : Colors.darkSecurity};
    }

    :hover {
      ::before {
        opacity: 1;
        bottom: -5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px 0px;
    .label {
      position: relative;
      width: fit-content;
    }

    p {
      font-size: 14px;
      margin: auto;
      text-shadow: ${(props) =>
        props.isPageFocus ? "1px 1px 2px #00000020" : "unset"};
      color: ${(props) =>
        props.isPageFocus ? Colors.abundance : Colors.darkSecurity};
    }
  }
`;

export { HeaderStyle, MenuItemStyle };
