import React from 'react';
import Routes from 'routes';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GoogleOAuthProvider } from '@react-oauth/google';


import './App.css';



function App () {
  
  return (
      <>
      <GoogleOAuthProvider clientId="431832797604-tv7c8vfhv6paiv33rsqcjiu6vtlpf8l4.apps.googleusercontent.com">
      <Routes />
      <ToastContainer/>
      </GoogleOAuthProvider>
      </>
  );
}

export default App;
