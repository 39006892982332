import { Tab } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Button, Text } from "components";
import { Layout } from "pages/LandingPageNew/Components";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Finish from "./sections/Finish";
import Profile from "./sections/Profile";
import Rules from "./sections/Rules";
import Security from "./sections/Security";
import Wallet from "./sections/Wallet";
import Reffereal from "./sections/Reffereal";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../selectors";
import { getuser } from "../../../../services/auth";
import { getGreeting } from "helpers/getGreeting";

const tabs = [
  {
    name: "Your Profile",
  },
  {
    name: "Your wallet",
  },
  // {
  // 	name: 'Security',
  // },
  {
    name: "House Rules",
  },
  // {
  // 	name: 'Lets Connect'
  // },
  // {
  // 	name: 'submit',
  // },
];

const index = () => {
  const user = useSelector(selectors.userProfile);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(10);
  const [userinfo, setuserinfo] = useState();

  // useEffect(() => {
  // 	if (user) {
  // 		getCurrentuser();
  // 	}
  // }, [user])

  // useEffect(() => {
  // 	if (localStorage.getItem('selectedIndex')) {
  // 		setSelectedIndex(Number(localStorage.getItem('selectedIndex')))
  // 	}
  // }, [])

  useEffect(() => {
    localStorage.setItem("selectedIndex", selectedIndex);
  }, [selectedIndex]);

  // useEffect(() => {
  // 	if (localStorage.getItem('selectedIndex')) {
  // 		setSelectedIndex(Number(localStorage.getItem('selectedIndex')))
  // 	}
  // }, [])

  useEffect(() => {
    localStorage.setItem("selectedIndex", selectedIndex);
  }, [selectedIndex]);

  const getCurrentuser = async () => {
    const res = await getuser(user.user?.id);
    if (res.data.status) {
      const eve = res.data.result;
      setuserinfo(eve);
    }
  };

  return (
    <Layout>
      <div className="min-h-[calc(100vh-70px)] flex items-start bg-newBeginning py-5 lg:py-20 px-4 xl:container mx-auto overflow-hidden">
        <Tab.Group
          manual={true}
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
          as="div"
          className="flex flex-col items-start w-full h-full gap-5 lg:flex-row lg:gap-16"
        >
          <Tab.List
            as="div"
            className="flex flex-row h-full gap-5 mt-10 overflow-x-auto max-lg:w-full lg:flex-col lg:gap-11"
          >
            {tabs.map((tab, index) => {
              return (
                <Tab
                  key={index}
                  onClick={(e) => e.preventDefault()}
                  className="flex items-center flex-shrink-0 gap-6 cursor-default focus:outline-none"
                >
                  <div
                    className={`w-[35px] h-[35px] rounded-full border flex items-center justify-center ${
                      selectedIndex === index && selectedIndex !== 4
                        ? "bg-black"
                        : (selectedIndex > index && selectedIndex !== 10) ||
                          selectedIndex === 5
                        ? "bg-okuGrowth"
                        : "bg-transparent"
                    }`}
                  >
                    <Text
                      fontType="p"
                      className="mt-[4px]"
                      fontSize="20px"
                      fontWeight={700}
                      color={
                        (selectedIndex === index && selectedIndex !== 4) ||
                        (selectedIndex > index && selectedIndex !== 10) ||
                        selectedIndex === 5
                          ? "white"
                          : "#636363"
                      }
                    >
                      {(selectedIndex > index && selectedIndex !== 10) ||
                      selectedIndex === 5 ? (
                        <CheckIcon width={16} color="white" />
                      ) : (
                        index + 1
                      )}
                    </Text>
                  </div>
                  {selectedIndex === 10 && (
                    <Text
                      fontType="p"
                      className="uppercase tracking-[0.01em] whitespace-nowrap"
                      fontSize="24px"
                      fontWeight={500}
                      color="#636363"
                    >
                      {tab.name}
                    </Text>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels as="div" className="flex-1 h-full max-lg:w-full">
            {selectedIndex === 10 ? (
              userinfo && userinfo.membershipstatus == "updated" ? (
                <>
                  <div className="flex flex-col justify-center h-full">
                    <Text
                      fontType="h3"
                      fontSize="24px"
                      fontWeight={400}
                      color="#000000"
                      className="uppercase"
                    >
                      {getGreeting()} {user.user?.userName}
                    </Text>
                    <Text
                      fontType="h2"
                      fontSize="45px"
                      fontWeight={600}
                      className="mt-8"
                      lineHeight="53px"
                      color="#000000"
                    >
                      You’re Membership Updated <br />
                      Waiting for Approve..
                    </Text>
                    {/* <Text fontType='p' fontSize='18px' fontWeight={400} className='mt-8' color='#1C1E19'>This application process will take you about 10-15 minutes to complete. We suggest to finish this now so you can focus on collecting your favorite pieces and making new memories!</Text> */}
                    <Button
                      onClick={() => navigate("/profile")}
                      theme="primary-outline"
                      className="mt-11 w-fit"
                    >
                      View Profile
                    </Button>
                  </div>
                </>
              ) : userinfo && userinfo.membershipstatus == "approved" ? (
                <>
                  <Text
                    fontType="h3"
                    fontSize="24px"
                    fontWeight={400}
                    color="#000000"
                    className="uppercase"
                  >
                    {getGreeting()} {user.user?.userName}
                  </Text>
                  <Text
                    fontType="h2"
                    fontSize="45px"
                    fontWeight={600}
                    className="mt-8"
                    lineHeight="53px"
                    color="#000000"
                  >
                    You’re Membership Approved <br />
                  </Text>
                  {/* <Text fontType='p' fontSize='18px' fontWeight={400} className='mt-8' color='#1C1E19'>This application process will take you about 10-15 minutes to complete. We suggest to finish this now so you can focus on collecting your favorite pieces and making new memories!</Text> */}
                  <Button
                    onClick={() => navigate("/profile")}
                    theme="primary-outline"
                    className="mt-11 w-fit"
                  >
                    View Profile
                  </Button>
                </>
              ) : userinfo?.membershipstatus == "rejected" ? (
                <>
                  <Text
                    fontType="h3"
                    fontSize="24px"
                    fontWeight={400}
                    color="#000000"
                    className="uppercase"
                  >
                    {getGreeting()} {user.user?.userName}
                  </Text>
                  <Text
                    fontType="h2"
                    fontSize="45px"
                    fontWeight={600}
                    className="mt-8"
                    lineHeight="53px"
                    color="#000000"
                  >
                    You’re Membership Rejected <br />
                  </Text>
                  {/* <Text fontType='p' fontSize='18px' fontWeight={400} className='mt-8' color='#1C1E19'>This application process will take you about 10-15 minutes to complete. We suggest to finish this now so you can focus on collecting your favorite pieces and making new memories!</Text> */}
                  <Button
                    onClick={() => navigate("/profile")}
                    theme="primary-outline"
                    className="mt-11 w-fit"
                  >
                    View Profile
                  </Button>
                </>
              ) : (
                <div className="flex flex-col justify-center h-full">
                  <Text
                    fontType="h3"
                    fontSize="24px"
                    fontWeight={400}
                    color="#000000"
                    className="uppercase"
                  >
                    {getGreeting()} {user.user?.userName}
                  </Text>
                  <Text
                    fontType="h2"
                    fontSize="45px"
                    fontWeight={600}
                    className="mt-8"
                    lineHeight="53px"
                    color="#000000"
                    fontFamily="Expressway"
                  >
                    You’re about to complete your exclusive membership!
                  </Text>
                  <Text
                    fontType="p"
                    fontSize="18px"
                    fontWeight={400}
                    className="mt-8"
                    color="#1C1E19"
                    fontFamily="AcuminPro"
                    lineHeight="22px"
                    customLetterSpacing="0.01em"
                  >
                    This application process will take you about 10-15 minutes
                    to complete.
                    <br />
                    We recommend completing the application now, so you can
                    focus on <br />
                    collecting your favorite pieces and making new memories!
                    <br />
                    <br />
                    Don’t forget to prep your exclusive invite code; if you
                    don’t have a code, <br />
                    join our Discord or ask a friend!
                  </Text>
                  <div className="flex items-center gap-6 mt-11">
                    <Button
                      onClick={() => setSelectedIndex(0)}
                      theme="primary-outline"
                      className="w-fit whitespace-nowrap"
                    >
                      Let’s Begin!
                    </Button>
                    <Button
                      onClick={() =>
                        window.open("https://discord.gg/okuhouse", "_blank")
                      }
                      theme="primary-outline"
                      className="w-fit whitespace-nowrap"
                    >
                      Join Discord
                    </Button>
                  </div>
                </div>
              )
            ) : (
              <>
                {/* <Tab.Panel className='flex-1'><Profile handleNextPage={() => setSelectedIndex(1)} /></Tab.Panel>
									<Tab.Panel className='flex-1'><Wallet handleNextPage={() => setSelectedIndex(2)} /></Tab.Panel>
									<Tab.Panel className='flex-1'><Security handleNextPage={() => setSelectedIndex(3)} /></Tab.Panel>
									<Tab.Panel className='flex-1'><Rules handleNextPage={() => setSelectedIndex(4)} /></Tab.Panel>
									<Tab.Panel className='flex-1'><Reffereal handleNextPage={() => setSelectedIndex(5)} /></Tab.Panel>
									<Tab.Panel className='flex-1'><Finish handleNextPage={() => navigate('/profile')} /></Tab.Panel> */}

                {/* <Tab.Panel className='flex-1'><Reffereal handleNextPage={() => setSelectedIndex(1)} /></Tab.Panel> */}
                <Tab.Panel className="lg:flex-1">
                  <Profile handleNextPage={() => setSelectedIndex(1)} />
                </Tab.Panel>
                <Tab.Panel className="lg:flex-1">
                  <Wallet
                    handleBackPage={() => setSelectedIndex(0)}
                    handleNextPage={() => setSelectedIndex(2)}
                  />
                </Tab.Panel>
                {/* <Tab.Panel className='flex-1'><Security handleNextPage={() => setSelectedIndex(4)} /></Tab.Panel> */}
                <Tab.Panel className="lg:flex-1">
                  <Rules
                    handleBackPage={() => setSelectedIndex(1)}
                    handleNextPage={() => setSelectedIndex(4)}
                  />
                </Tab.Panel>
                <Tab.Panel className="flex-1">
                  <Finish handleNextPage={() => navigate("/profile")} />
                </Tab.Panel>
              </>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};

export default index;
