import { Tab } from "@headlessui/react";
import { Button } from "components";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Input from "../../Membership/components/Input";
import { validation } from "helpers";
import { toast } from "react-toastify";
import {
  resetPasswordprofile,
  getuser,
  updatetwofauth,
  register,
  verify,
} from "../../../../../services/auth";
import selectors from "../../../../../selectors";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ToggleBtn, Text } from "components";
import QRCode from "react-qr-code";
import { AiOutlineCopy } from "react-icons/ai";

const Security = () => {
  const [otp, setOtp] = useState();
  const navigate = useNavigate();

  const user = useSelector(selectors.userProfile);

  const [showPassword, setShowPassword] = useState({
    createPw: false,
    confirmPw: false,
  });
  const [payload, setPayload] = useState({
    createPassword: "",
    confirmPassword: "",
    oldpassword: "",
  });

  useEffect(() => {
    if (user) {
      getCurrentuser();
    }
  }, [user]);

  const [twofaceauth, settwofaceauth] = useState(true);
  const getCurrentuser = async () => {
    const res = await getuser(user.user.id);
    if (res.data.status) {
      const eve = res.data.result;

      settwofaceauth(eve.tfauth && eve.tfauth == true ? true : false);
    }
  };

  const validateResetPw =
    payload.createPassword.length > 7 &&
    payload.confirmPassword.length > 7 &&
    validation.passwordMatch(payload.createPassword, payload.confirmPassword);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const resetpassword = async () => {
    if (validateResetPw) {
      if (
        !validation.passwordMatch(payload.oldpassword, payload.confirmPassword)
      ) {
        const values = {
          password: payload.confirmPassword,
          oldpassword: payload.oldpassword,
        };
        try {
          const verify_ = await resetPasswordprofile(user.user.id, values);
          console.log(verify_);
          if (verify_.data.status) {
            navigate("/profile");
            toast(verify_.data.message);
          } else {
            toast(verify_.data.message);
          }
        } catch (err) {
          console.log(err);
          toast(err.response.data.message);
        }
      } else {
        toast("Current Password and New Password Must be Different");
      }
    } else {
      toast("Password Must be Same and Above 8 letters");
    }
  };

  const handleClickToggle = async () => {
    if (twofaceauth) {
      disableauth();
      settwofaceauth(false);
    } else {
      enableauth();
      settwofaceauth(true);
    }
  };

  const disableauth = async () => {
    const values = {
      tfauth: "false",
    };
    const reqs = await updatetwofauth(user.user.id, values);

    if (reqs.data.status) {
      console.log("reqs=reqs===>", reqs);
      // setrefresh(true)
    }
  };

  const [qrurl, setqrurl] = useState();
  const [baseurl, setbaseurl] = useState();

  const enableauth = async () => {
    const values = {
      id: user.user.id,
    };

    const req = await register(values);

    setqrurl(req.data.secret.otpauth_url);
    setbaseurl(req.data.secret.base32);
    if (req.data) {
    }
  };

  const verifyauth = async () => {
    if (otp != "") {
      const values = {
        userId: user.user.id,
        token: otp,
      };
      const res = await verify(values);

      if (res.data.verified) {
        toast("User verified");

        const values = {
          tfauth: "true",
        };
        const reqs = await updatetwofauth(user.user.id, values);
        if (reqs.data.status) {
          navigate("/marketplace");
        }
      } else {
        toast("Invalid OTP");
      }
    } else {
      toast("Enter the OTP");
    }
  };

  return (
    <div className="w-full md:w-[60%] lg:w-[60%] mt-10">
      <Tab.Group>
        <Tab.List as="div" className="flex items-center gap-24">
          <Tab
            className={({ selected }) =>
              `uppercase border-b pb-1 text-black text-lg ${
                selected
                  ? "border-okuGrowth font-semibold"
                  : "border-transparent"
              }`
            }
          >
            Password
          </Tab>
          <Tab
            className={({ selected }) =>
              `uppercase border-b pb-1 text-black text-lg ${
                selected
                  ? "border-okuGrowth font-semibold"
                  : "border-transparent"
              }`
            }
          >
            google 2fa
          </Tab>
        </Tab.List>
        <Tab.Panels as="div" className="mt-8">
          <Tab.Panel>
            <Input
              onChange={(e) =>
                setPayload({ ...payload, oldpassword: e.target.value })
              }
              placeholder="Current Password"
              type="password"
              label="change your password"
            />
            <div className="mt-4">
              <Input
                onChange={(e) =>
                  setPayload({ ...payload, createPassword: e.target.value })
                }
                className="mb-2"
                placeholder="New Password"
                type="password"
                label="new password"
              />
              <Input
                onChange={(e) =>
                  setPayload({ ...payload, confirmPassword: e.target.value })
                }
                className=""
                placeholder="Confirm Password"
                label="Confirm Password"
                type="password"
              />
            </div>
            <Button
              theme="primary-outline"
              onClick={() => resetpassword()}
              className="w-fit mt-8"
            >
              Save
            </Button>
          </Tab.Panel>
          <Tab.Panel>
            <div>
              <h4 className="text-2xl tracking-[0.01em] text-darkSecurity font-semibold">
                Authenticate your account
              </h4>
              <p className="mt-2 tracking-[0.01em] text-darkSecurity">
                We want to make sure your account is 100% secure.
                <br />
                Let’s secure it with Google 2FA!
              </p>
            </div>
            {twofaceauth ? (
              <>
                <ToggleBtn
                  handleClickToggle={() => {
                    handleClickToggle();
                  }}
                  checked={twofaceauth}
                />

                {qrurl ? (
                  <>
                    <QRCode
                      style={{
                        height: "auto",
                        width: "30%",
                        marginTop: "10px",
                      }}
                      value={qrurl}
                    />
                    <Text
                      fontType="h3"
                      fontSize="20px"
                      className="mt-3 mb-3"
                      fontWeight={600}
                      color="#000000"
                    >
                      (OR)
                    </Text>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Text
                        fontType="h3"
                        fontSize="16px"
                        fontWeight={700}
                        className="uppercase"
                        color="#000000"
                      >
                        {" "}
                        Secret Key : {baseurl}{" "}
                      </Text>{" "}
                      <AiOutlineCopy
                        className="ml-2"
                        style={{ cursor: "pointer" }}
                        color="black"
                        size={22}
                        onClick={() => {
                          navigator.clipboard.writeText(baseurl);
                          toast("Secret Key Copied");
                        }}
                      />{" "}
                    </div>
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      isInputNum
                      hasErrored={true}
                      data-cy="123456"
                      containerStyle="flex items-center gap-4 mt-5"
                      inputStyle={`!w-[47px] h-[60px] border rounded-md font-bold font-AcuminPro text-3xl text-center leading-[0px] ${
                        otp?.length === 6
                          ? "border-2 border-okuGrowth"
                          : "border-[#8D8D8D]"
                      }`}
                      focusStyle={`${
                        otp?.length === 6 ? "outline-none" : "outline-abundance"
                      }`}
                    />

                    <Button
                      onClick={() => verifyauth()}
                      theme="primary-outline"
                      className="w-fit mt-8"
                    >
                      Verify
                    </Button>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <ToggleBtn
                  handleClickToggle={() => {
                    handleClickToggle();
                  }}
                  checked={twofaceauth}
                />
                {/* <OtpInput
					 value={ otp }
					 onChange={ handleChange }
					 numInputs={ 6 }
					 isInputNum
					 hasErrored={ true }
					 data-cy='123456'
					 containerStyle='flex items-center gap-4 mt-8'
					 inputStyle={ `!w-[47px] h-[60px] border rounded-md font-bold font-AcuminPro text-3xl text-center leading-[0px] ${otp?.length === 6 ? 'border-2 border-okuGrowth' : 'border-[#8D8D8D]'}` }
					 focusStyle={ `${otp?.length === 6 ? 'outline-none' : 'outline-abundance'}` }
				 /> */}
              </>
            )}

            {/* <Button theme='primary-outline' className='w-fit mt-8'>Resend Code</Button> */}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Security;
