import React from 'react';
import './styles.scss';
import { Images } from 'constant';

export const handleOpenSocmed = (link) => {
	window.open(link, '_blank');
};

const Socmed = ({ withBackground, isDark }) => {

	return (
		// <div className={ withBackground ? 'socmed-bg-content' : 'socmed-container' }>
		// 	<div onClick={ () => handleOpenSocmed('https://twitter.com/experience_oku') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILTwitterDark : Images.ILTwitter } alt='' />
		// 	</div>
		// 	<div onClick={ () => handleOpenSocmed('https://www.instagram.com/experience.okuhouse/') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILInstagramDark : Images.ILInstagram } alt='' />
		// 	</div>
		// 	<div onClick={ () => handleOpenSocmed('https://www.linkedin.com/company/oku-group/?originalSubdomain=sg') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILLinkedinDark : Images.ILLinkedin } alt='' />
		// 	</div>
		// 	<div onClick={ () => handleOpenSocmed('https://discord.com/invite/okuhouse') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILDiscordDark : Images.ILDiscord } alt='' />
		// 	</div>
		// 	<div onClick={ () => handleOpenSocmed('https://t.me/okugroup') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILTelegramDark : Images.ILTelegram } alt='' className='telegram' />
		// 	</div>
		// 	<div onClick={ () => handleOpenSocmed('https://t.me/okugroup') } className={ isDark ? 'dark-content' : 'content' }>
		// 		<img src={ isDark ? Images.ILTelegramDark : Images.ILTelegram } alt='' className='telegram' />
		// 	</div>
		// </div>
		<div className='socmed-container max-w-[200px] flex items-center gap-[10px] lg:gap-[20px]'>
			<img onClick={() => handleOpenSocmed('https://discord.gg/okuhouse')} src={Images.ILDiscord} alt='' className='cursor-pointer w-6 h-6' />
			<img onClick={() => handleOpenSocmed('https://www.instagram.com/experience.okuhouse/')} src={Images.ILInstagram} alt='' className='cursor-pointer w-6 h-6' />
			<img onClick={() => handleOpenSocmed('https://www.linkedin.com/company/oku-house/')} src={Images.ILLinkedin} alt='' className='cursor-pointer w-6 h-6' />
			<img onClick={() => handleOpenSocmed('https://t.me/okuhouse')} src={Images.ILTelegram} alt='' className='cursor-pointer w-6 h-6' />
			<img onClick={() => handleOpenSocmed('mailto:hi@oku.house')} src={Images.ILEmail} alt='' className='cursor-pointer w-6 h-6' />
		</div>
	);
};

export default Socmed;
