import { combineReducers } from 'redux';

import userReducer from './User/userReducer';
import miscReducer from './Misc/miscReducer';
import RestaurantReducer from './User/RestaurantReducer';
import WalletReducer from './User/walletReducer';
import walletConnectReducer from './User/Reducer';
import landingPageReducer from './LandingPage/landingPageReducer';

const rootReducer = combineReducers({
  user: userReducer,
  misc: miscReducer,
  restaurant: RestaurantReducer,
  nft: RestaurantReducer,
  wallet: walletConnectReducer,
  WalletConnect: walletConnectReducer,
  landingPage: landingPageReducer
});

export default rootReducer;