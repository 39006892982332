import React from 'react';
import { useNavigate } from 'react-router';

import { Button } from 'components';

const Verify = ({
	className,
	Image,
	Kicker,
	state,
	Title,
	Subtitle,
	Btn = '',
	handleSubmit
}) => {
  const navigate = useNavigate()

	const submit = () => {
		if (handleSubmit) {
			handleSubmit()
		}else{
			navigate('/partnership')
		}
	}

	return (
		<div className={`${className} bg-newBeginning flex flex-col lg:flex-row items-center space-x-5 xl:space-x-20`}>
			<img
				alt=''
				src={Image}
				className='relative lg:w-[425px] w-full' />
			<div className='py-5 lg:py-0 xl:pr-20 lg:px-0 px-5'>
				<p className='uppercase md:text-lg text-base leading-[22px] tracking-widest font-ExpressWay text-darkSecurity'>{Kicker}</p>
				<p className='mt-3 uppercase font-semibold md:text-[40px] text-2xl leading-[38px] tracking-widest font-ExpressWay text-black'>{Title}</p>
				{
					Subtitle === '' ?
						<p className='mt-5 text-darkSecurity tracking-[0.01em] text-base md:text-lg font-AcuminPro'>We’ve sent a verification email to <br /><p className='font-bold text-orange italic font-AcuminPro text-base md:text-lg tracking-[0.01em]'>{state?.data}</p><br />Please check your inbox and click on the verification link!</p>
						:
						<p className='mt-8 text-darkSecurity tracking-[0.01em] text-base md:text-lg font-AcuminPro leading-[22px]'>{Subtitle}</p>
				}
				{
					Btn !== '' &&
					<Button theme='primary-outline' label={Btn} className='w-full lg:w-fit font-bold mt-6' onClick={(e) => {e.stopPropagation(); e.preventDefault();submit()}} />
				}
			</div>
		</div>
	);
};

export default Verify;