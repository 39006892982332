import React from 'react';
import TextStyle from './style';

const Text = ({
	fontType = 'p',
	fontFamily = 'AcuminPro',
	children,
	className,
	text,
	fontSize,
	textAlign,
	fontStyle,
	fontWeight,
	color,
	lineHeight,
	onClick,
	contentClassName,
	customLetterSpacing
}) => {
	let content = <p className={contentClassName}>{text || children}</p>;
	if (fontType === 'h1') content = <h1 className={contentClassName}>{text || children}</h1>;
	else if (fontType === 'h2') content = <h2 className={contentClassName}>{text || children}</h2>;
	else if (fontType === 'h3') content = <h3 className={contentClassName}>{text || children}</h3>;
	else if (fontType === 'h4') content = <h4 className={contentClassName}>{text || children}</h4>;
	else if (fontType === 'pre') content = <pre className={contentClassName}>{text || children}</pre>;

	return (
		<TextStyle
			className={className}
			fontFamily={fontFamily}
			fontSize={fontSize}
			textAlign={textAlign}
			fontStyle={fontStyle}
			fontWeight={fontWeight}
			color={color}
			lineHeight={lineHeight}
			onClick={onClick}
			customLetterSpacing={customLetterSpacing}
		>
			{content}
		</TextStyle>
	);
};

export default Text;