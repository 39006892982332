import styled from 'styled-components';

const NotificationStyle = styled.div`
    padding: 0 0px 0 30px;
    width: 100%;
    animation: slideToBottom 1s;

    li {
        border-bottom: 1px solid #424949;
    }

    @media screen and (max-width: 650px) {
        padding: 20px;
      }
`;

export default NotificationStyle;