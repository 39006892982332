import { useState } from "react";
import CountDownTimer from "./CountDownTimer";
import useCountDown from "./hooks/useCountDown";

const CdTimerComp = ({targetdate}) => {
  const [days, hours, minutes, seconds] = useCountDown( new Date(targetdate).toLocaleString());
  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;
  const [targetDate, setTargetDate] = useState(
    new Date(targetdate).toLocaleString()
  );

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.value) {
      setTargetDate(new Date(event.target.value));
    } else {
      setTargetDate(new Date(dateTimeAfterThreeDays));
    }
  };

  return (
    <>
      <div className="play-details">
        <div className="play-details-body">
          <div className="countdown-container">
            <CountDownTimer targetDate={targetDate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CdTimerComp;
