import { Colors } from 'constant';
import styled from 'styled-components';

// interface HeaderStyleProps {

// }

const HeaderStyle = styled.div`
  position: fixed;
  width: 100vw;
  ${props => props.showSignIn === 'LOGIN' ? `
  z-index: 99999;
  ` : `
  z-index: 999;
  `}
  transition: all 500ms;
  background: ${Colors.layoutBg};
  /* background: #141E30; */
  
  li {
    cursor: pointer;
    color:black   
    :hover p {
      color: ${Colors.lemonChiffon};
    }
  }

  .header {
    height: 100px;
    width: 100%;
    max-width: 1440px;
    margin: auto;
  }

  .logo {
    width: 118px;
  }
`;

export default HeaderStyle;
