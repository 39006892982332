import React from 'react';

import FilterStyle from './style';

const CustomSelect = ({ value, onChange, options }) => {
  return (
    <FilterStyle>
      <div className='input-container'>
        <select
          value={ value }
          onChange={ onChange }
          style={{color:"black"}}
        >
          { options?.map(value => (
            <option value={ value } key={ value } >
              { value }
            </option>
          )) }
        </select>
      </div>
    </FilterStyle>
  );
};

export default CustomSelect;