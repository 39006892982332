import React, { useEffect, useState } from 'react';
import { PropsSection } from 'pages/WalletConnection'
import Faq from 'components/FAQ';

const FAQ = ({ isRender }) => {
	const list = [
		{
			id: 1,
			title: '',
			contents: [
				{
					id: 1,
					title: 'How to connect wallet?',
					p: ['The initial offering of tokens will occur on Oku Group’s marketplace. Tokens can be resold or leased in the marketplace as well.'],
				},
				{
					id: 2,
					title: 'How to connect with Metamask?',
					p: ['Oku Group and the restaurant will decide on the initial price of each token. After a token is purchased, the owner of that token has the ability to resell that token at whatever price they feel captures the value of that token’s utility. Generally, restaurant operational and financial performance (typically indicated by demand) may influence the price that token owners value their tokens at.'],
				},
				{
					id: 3,
					title: 'How to connect with Coinbase Wallet?',
					p: [
						'Token owners will have benefits to the restaurant that the token represents. In addition, token owners will be entitled to benefits to other Oku Group restaurant partners around the world. These benefits include advanced and preferred reservation bookings, discounts, and access to exclusive events / menu items.',
						'There are different tiers of tokens for each restaurant and these tiers will represent different sets of benefits. Generally, the higher the tier, the lower the number of tokens in circulation.',
						'Token owners will also be able to lease their tokens to interested parties in exchange for a fee. In this way, token owners are able to receive monetary benefits if they aren’t able to experience the cuisine themselves.'
					],
				},
				{
					id: 4,
					title: 'How to connect with Connect Wallet?',
					p: ['In the situation where a restaurant decides to close, benefits to that specific operation will become nullified. However, benefits to other Oku Group restaurant partners will continue to exist. Token owners may have the option of either converting their token ownership to another restaurant or liquidating their token into Solana cryptocurrency by selling the tokens back to Oku Group.',],
				},
				{
					id: 5,
					title: 'How to connect with Fortmatic?',
					p: ['In the situation where a restaurant decides to close, benefits to that specific operation will become nullified. However, benefits to other Oku Group restaurant partners will continue to exist. Token owners may have the option of either converting their token ownership to another restaurant or liquidating their token into Solana cryptocurrency by selling the tokens back to Oku Group.',],
				}
			]
		},
	];

	return (
		<>
			<Faq
				list={ list }
				isRender={ isRender }
			/>
		</>
	);
};

export default FAQ;
