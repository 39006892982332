import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { persistor, store } from "../index";
import restaurantBuilder from "../../contractsABI/RestaurantBuilder.json";
import governance from "../../contractsABI/Governance.json";
import usdcABI from "../../contractsABI/USDC.json";
import usdtABI from "../../contractsABI/USDT.json";
import wrappedmaticabi from "../../contractsABI/MaticABI.json";
import Fortmatic from "fortmatic";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

export const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const getProviderOptions = () => {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        //infuraId: process.env.REACT_APP_INFURA_ID
        rpc: {
          80001: "https://matic-mumbai.chainstacklabs.com",
          137: "https://polygon-rpc.com/",
        },
      },
    },
    fortmatic: {
      package: Fortmatic,
      options: {
        key: "pk_live_7C1BF3A50D5A76A3",
      },
    },

    coinbasewallet: {
      package: CoinbaseWalletSDK,

      options: {
        key: "pk_live_7C1BF3A50D5A76A3",
      },
    },
  };

  return providerOptions;
};

// const endpoint = "https://rpc-mumbai.maticvigil.com";
// const testAPIKey = "pk_test_68B474AAFC60C9B2";
// const customNodeOptions = {
//   rpcUrl: endpoint,
//   chainId: 80001
// };

const endpoint = "https://rpc-mainnet.maticvigil.com/";
const testAPIKey = "pk_live_9CC81EBA92E2E3FE";
const customNodeOptions = {
  rpcUrl: endpoint,
  chainId: 137
};


export const connectWallet = (walletname) => {
  return async (dispatch) => {
    dispatch(connectRequest());
    try {
      const web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions: getProviderOptions(), // required
      });
      var provider = "";
      if (walletname === "coinbasewallet") {
        var provider = await web3Modal.connectTo("coinbasewallet");
      } else if (walletname === "walletconnect") {
        var provider = await web3Modal.connectTo("walletconnect");
      } else if (walletname === "fortmatic") {

        const fm = new Fortmatic(testAPIKey, customNodeOptions);
        provider = new Web3(fm.getProvider());
      } else if (walletname === "metamask") {
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: getProviderOptions().walletconnect, // required
        });
        var provider = await web3Modal.connect();
      } else {
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: getProviderOptions().walletconnect, // required
        });
        var provider = await web3Modal.connect();
      }

      try{
        if (window.ethereum && window.ethereum.networkVersion !== "137") {
          await addNetwork(137);
        }
      }catch(e){
        console.log("err",e)
      }
     
    
      const marketplaceAddress = process.env.REACT_APP_Marketplace;
      await subscribeProvider(provider);

      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      var marketplace = new web3.eth.Contract(
        governance,
        process.env.REACT_APP_OKU_GOVERNANCE
      );
      var usdcInstance = new web3.eth.Contract(
        usdcABI,
        process.env.REACT_APP_USDC
      );
      var usdtInstance = new web3.eth.Contract(
        usdtABI,
        process.env.REACT_APP_USDT
      );

      var restaurant = new web3.eth.Contract(
        restaurantBuilder,
        process.env.REACT_APP_RESTAURANT_BUILDER
      );
      console.log("restaurant==>restaurant",restaurant)
      var wrappedMatic = new web3.eth.Contract(
        wrappedmaticabi,
        process.env.REACT_APP_WRAPPED_MATIC
      );

      dispatch(
        connectSuccess({
          address,
          web3,
          provider,
          connected: true,
          marketplace,
          wrappedMatic,
          restaurant,
          // NFT,
          web3Modal,
          usdcInstance,
          usdtInstance,
					walletname,
        })
      );
    } catch (e) {
      dispatch(connectFailed(e));
    }
  };
};

const subscribeProvider = async (provider) => {
  if (!provider.on) {
    return;
  }

  provider.on("connect", async (id) => {
    console.log(id);
  });

  provider.on("networkChanged", async (networkId) => {
    if (networkId !== "137") {
      console.log("netwrokid:", networkId);
      // persistor.dispatch(connectFailed('Please switch to Binance mainnet'));
    } else {
      store.dispatch(connectWallet());
    }
  });
};

export async function addNetwork(id) {
  let networkData;
  switch (id) {
    case 137:
      networkData = [
        {
          chainId: "0x89",
          chainName: "Polygon Mainnet",
          rpcUrls: ["https://polygon-rpc.com/"],
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ];

      break;

    case 80001:
      networkData = JSON.stringify(networkData);
      networkData = [
        {
          chainId: "0x13881", //web3.utils.toHex('80001'),
          chainName: "Mumbai Testnet",
          rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ];

      break;
    default:
      break;
  }
  if (Number(id) == 137) {
    return window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: networkData,
    });
  } else {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: networkData,
    });
  }
}
