import moment from 'moment';

export const getGreeting = () =>  {
  const now = moment();
  const morning = moment({hour: 0});
  const afternoon = moment({hour: 12});
  const evening = moment({hour: 17});

  if (now.isBetween(morning, afternoon)) {
    return 'Good Morning';
  } else if (now.isBetween(afternoon, evening)) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
}