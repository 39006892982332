import styled from "styled-components";
import { Colors } from "constant";

const HeaderStyle = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  width: 100vw;
  z-index: 999;
  background: #fbf6f3;

  .header-container {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    top: 0px;
    left: 0px;
    padding: 20px 0px;
    position: relative;
    background: ${(props) =>
      props.showNavBg ||
      props.activeMenu === "restaurant" ||
      props.activeMenu === "nft"
        ? "#FBF6F3"
        : "#FBF6F3"};
    transform: translate(
      0px,
      ${(props) => (props.isScroll ? "-100vh" : "0px")}
    );
    transition: all 500ms ease;
  }

  .nav {
    width: 70%;
    background: #192234;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    animation: slideIn 0.7s;
  }

  .nav-menu {
    p {
      transition: color 300ms ease;
    }

    :hover {
      p {
        color: ${Colors.darkSecurity};
      }
    }
  }

  .side-nav .nav .btn {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .slideout-nav {
    width: 70%;
    height: 100%;
    background: #192234;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    animation: slideOut 0.7s;
    transform: translateX(-100vw);
  }

  .bg {
    width: 30%;
    background: rgba(0, 0, 0, 0.7);
    animation: fadeIn 0.7s;
  }

  .fadeout-bg {
    width: 30%;
    background: rgba(0, 0, 0, 0.7);
    animation: fadeOut 0.7s;
    opacity: 0;
  }

  .border-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .leftNav {
    display: flex;
    align-items: center;
  }

  .header-container .leftNav .btn-menu img {
    height: 50px;
    cursor: pointer;
  }

  .rightNav {
    /* display: ${(props) =>
      props.showFixedContent === "restaurant" ? "none" : "flex"};; */
    display: flex;
    align-items: center;
    gap: 20px;
    /* opacity: ${(props) =>
      props.showFixedContent === "restaurant" ? "0" : "1"}; */
    transition: all 300ms ease;

    ${(props) =>
      props.showFixedContent === "restaurant"
        ? `
			.for-restaurant {
				background-color: var(--color-button-hover);
			}
		`
        : ""}
  }

  .rightNav .navLink {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    background: rgba(255, 255, 255, 0.1);
  }

  .menu {
    position: relative;
    display: flex;
    margin-left: 100px;

    p {
      cursor: pointer;
    }

    ${(props) =>
      (props.activeMenu === "home" || props.activeMenu === "nft") &&
      `
			::before {
				content: '';
				position: absolute;
				width: ${props.activeMenu === "nft" ? "125px" : "44px"};
				height: 2px;
				bottom: -5px;
				transform: translate(${props.activeMenu === "nft" ? "85px" : "0px"}, 0px);
				transition: all 300ms ease;
			}
		`}
  }

  .navLink {
    text-decoration: none;
    color: white;
  }

  .search-input {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    width: 400px;
    height: 44px;
    margin-left: 30px;
    color: black;
  }

  .for-restaurant {
    width: 170px;
    height: 43px;
    border: 1px solid black;
    background: none;
    color: ${Colors.darkSecurity};
    border-radius: 100px;
  }

  .for-signin {
    height: 43px;
    border-radius: 100px;
  }

  .sider-nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .nav-menu {
    position: relative;
    margin-left: auto;
  }

  .mobile-sidebar,
  .burger-menu {
    display: none;
  }

  .join-team {
    height: 48px;
  }

  @media screen and (max-width: 1240px) {
    .header-container {
      padding: 20px;
    }
  }

  @media screen and (max-width: 1000px) {
    .menu {
      margin-left: 50px;
    }
  }

  @media screen and (max-width: 900px) {
    .menu {
      display: none;
    }

    .join-team {
      display: none;
    }

    .header-logo {
      width: 100px;
      cursor: pointer;
    }

    .header-container {
      padding: 20px 0px;
    }

    .close-icon {
      width: fit-content;
      padding: 8px;
      padding-bottom: 6px;
      margin-left: auto;
      border-radius: 5px;
      background: linear-gradient(
        116.29deg,
        rgba(255, 255, 255, 0.07) -29.94%,
        rgba(255, 255, 255, 0.052) -29.93%,
        rgba(255, 255, 255, 0.03) 101.76%
      );
    }

    .sidebar-button-container {
      width: 100%;

      button {
        width: 100%;
      }
    }

    .burger-menu {
      display: block;
      margin-right: 20px;
      background-color: #1c1e19;
      border-radius: 4px;
    }

    .mobile-sidebar {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      top: 0px;
      width: 100vw;
      height: 100vh;
      max-width: 280px;
      padding: 80px 30px 90px 10px;
      padding-top: 40px;
      background-color: #141e30;
      z-index: 99;
      transform: translate(
        ${(props) => (props.showSidebar ? "0px" : "-100vw")},
        0px
      );
      transition: all 500ms ease;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    }

    .join-team-mobile {
      display: block;
    }

    .for-restaurant-mobile {
      width: 200px;
      background-color: var(--blue200);
      border: 1px solid var(--yellow);
      margin-bottom: 20px;
    }

    .nav-menu {
      margin-left: auto;
    }

    .nav-menu p {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .home {
      position: relative;

      ${(props) =>
        props.activeMenu === "home" &&
        `
				::before {
					content: '';
					position: absolute;
					width: 55px;
					height: 2px;
					bottom: -5px;
				}
			`}
    }

    .nft-membership {
      position: relative;

      ${(props) =>
        props.activeMenu === "nft" &&
        `
				::before {
					content: '';
					position: absolute;
					width: 157px;
					height: 2px;
					bottom: -5px;
				}
			`}
    }
  }

  @media screen and (max-width: 500px) {
    .header-logo {
      width: 80px;
    }

    .header-container {
      padding: 20px;
      width: 100%;
    }

    .for-signin {
      width: 150px;
    }

    .for-restaurant {
      display: none;
    }

    .socmed-container {
      gap: 10px;

      .content {
        width: 35px;
        height: 35px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export default HeaderStyle;
