
import { post, get, put } from "../http";
import axios from 'axios';
const baseUrl =  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "http://localhost:4000";
import { Fetch } from 'services/local-storage';

export const createApplicant = async (id, values) => {
    try {
        const data = await post(`kyc/createApplicant/${id}`, values);
        return data;
    } catch (error) {
        throw error;
    }
};

export const addDocument = async (id, values) => {
    try {
        const data = await post(`kyc/addDocument/${id}`, values);
        return data;
    } catch (error) {
        throw error;
    }
};

export const addSelfie = async (id) => {
    try {
        const data = await post(`kyc/addSelfie/${id}`);
        return data;
    } catch (error) {
        throw error;
    }
};

export const creatCheck = async (id, values) => {
    try {
        const data = await post(`kyc/creatCheck/${id}`, values);
        return data;
    } catch (error) {
        throw error;
    }
};

export const checkstatus = async (id) => {
    try {
        const data = await get(`kyc/retriveCheck/${id}`);
        return data;
    } catch (error) {
        throw error;
    }
};

export const EditApplicant = async (id, values) => {
    try {
        const data = await put(`kyc/editapplicant/${id}`, values);
        return data;
    } catch (error) {
        throw error;
    }
};