import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useNavigate } from 'react-router';

import { Button, CardList } from 'components';
import { Images } from 'constant';
import { Layout, PageContruction } from 'pages/LandingPageNew/Components';
import { handleOpenSocmed } from 'components/Socmed';
const Partnership = () => {

	const navigate = useNavigate();
	const tierList = [
		{
			title: 'Concept Partners',
			subtitle: 'Our concept partners help us strategize and determine the look and feel of our Oku Club House spaces from menu design all the way to decor and ambiance.',
			image: Images.PartnerTier1,
		},
		{
			title: 'Event Partners',
			subtitle: 'Our event partners work with us to launch individual events for our community members to attend, from art galleries to dining pop-ups to product launches.',
			image: Images.PartnerTier2,
		},
		{
			title: 'Community sponsors',
			subtitle: 'Our community sponsors help us build and curate our membership, from sponsorship to activities and other promotions.',
			image: Images.PartnerTier3,
		},

	];

	const Productlist = [
		'An exclusive, tailored NFT collection',
		'Ability to engage event attendees before, during and after each event',
		'Awareness across Oku House’s community member'
	];

	return (
		<Layout>
			<div className='relative max-w-screen m-auto overflow-hidden'>
				<div className='relative w-full h-[140px] lg:h-full'>
					<img
						alt='partnership hero'
						src={Images.PartnerHero}
						className='w-full object-cover h-full' />
				</div>
				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={false}
					delay={700}
				>
					<div className='px-6 py-20 md:py-28 flex flex-col space-y-[30px] justify-center items-center'>
						<p className='uppercase lg:text-5xl text-[28px] font-semibold leading-[33px] md:leading-[56px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>Partner with oku</p>
						<p className='mb-10 lg:text-[32px] text-xl font-semibold leading-[28px] md:leading-[38px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>Behind each product, service, and experience is a<br className='hidden md:inline' />story to be told. We partner with creators to<br className='hidden md:inline' />unlock and share these stories.</p>
						<Button theme='primary-outline' label='Our Philosophy' className='w-full md:w-fit' onClick={() => navigate('/philosophy')} />
					</div>
				</ScrollAnimation>

				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={true}>
					<CardList list={tierList} />
				</ScrollAnimation>

				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={false}
					delay={700}
				>
					<div className='w-full m-auto md:px-20 container pb-16 pt-20 md:pt-32'>
						<div className='flex flex-col space-y-[10px] justify-center items-center mb-20'>
							<p className='uppercase lg:text-5xl text-[28px] font-semibold leading-[38px] md:leading-[56px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>Let’s create experiences together</p>
							<p className='lg:text-[32px] text-xl font-semibold leading-[25px] md:leading-[38px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>Every product, service, and experience has an origin</p>
						</div>
						<PageContruction
							Image={Images.PartnerProduct}
							Title='Be Our Partner'
							Subtitle={[
								'Interested in creating a vibrant and creative community? Once in a lifetime experience? Or host your very own event?'
							]}
							Heading='Be apart of the oku house family'
							listString={Productlist}
							className='!bg-white'
							TopBtn='Let’s Talk' 
							urlTop={()=>handleOpenSocmed('mailto:hi@oku.house')}
							/>
					</div>
				</ScrollAnimation>
			</div>
		</Layout >
	);
};

export default Partnership;