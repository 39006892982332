import React from 'react';
import { useNavigate } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

import { Text, Button, Socmed } from 'components';
import { Colors, Images } from 'constant';

import Layout from '../../Components/Layout';

const ThankYou = () => {
	const navigate = useNavigate();

	return (
		<Layout>
			<div className='pt-[80px] md:pt-[100px]'>
				<div className='flex items-center justify-between flex-col hd:flex-row max-w-[1440px] w-full mx-auto'>
					<div className='md:max-w-[300px] lg:max-w-[400px] xl:max-w-[500px] px-5 md:px-0 md:ml-5 py-[60px] xl:ml-[104px]'>
						<ScrollAnimation
							animateOnce
							animateIn='tada'
							animatePreScroll={ false }
						>
							<Text
								fontType='h1'
								fontWeight='700'
								fontFamily='Expressway'
								color={ Colors.security }
								contentClassName='xl:!text-[72px] !text-center md:!text-left'
								className='mb-6'
							>
								Thank You for Becoming <span className='text-abundance'>Our Partner!</span>
							</Text>
						</ScrollAnimation>

						<Text
							color={ Colors.security }
							fontSize='20px'
							className='mb-10'
							contentClassName='!text-center md:!text-left'
						>
							We will connect with you! If you have any questions,
							please contact <span className='font-bold'>hi@oku.house.</span>
						</Text>

						<Button
							theme='secondary'
							label='Back to Homepage'
							className='mx-auto md:mx-0'
							onClick={ () => navigate('/') }
						/>
					</div>

					<div className='relative md:min-w-[400px] xl:min-w-[600px] ml-0 hd:ml-5'>
						<img alt='' src={ Images.ThankYou } />
						<div className='absolute w-full h-full left-0 top-0 p-5'>
							<div className='w-full h-full border-[2px] border-solid border-[#FFFBF9] flex items-end'>
								<div className='flex items-center justify-between flex-col hd:flex-row w-full bg-abundance p-[10px] hd:p-6 mb-10 gap-[10px]'>
									<Text
										text='In the meantime, follow our socials!'
										color={ Colors.offWhite }
										fontSize='20px'
										fontWeight='700'
										fontFamily='Expressway'
									/>

									<Socmed />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ThankYou;