import styled from 'styled-components';

const FormStyle = styled.div`
  .form-container {
    padding: 0px 0px;
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    animation: slideToLeft 1s;
  }

  .role-input {
    margin-bottom: 20px;
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 60px;
    perspective: 1000px;
    border-radius: 5px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotateX(${props => props.success ? '180deg' : '0deg'});
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 5px;
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnsubmit {
    border-radius: 100px;
    float: left;
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateX(180deg);
    border-radius: 5px;
  }

  .success {
    background-color: #27AE60 !important;
  }

  @media screen and (max-height: 850px) {
    .form-container {
      padding: 0 0px 30px 30px;
    }

    .input-container input {
      height: 48px;
      font-size: 14px;
    }

    .label p {
      font-size: 14px;
    }

    .role-input {
      margin-bottom: 60px;
    }

    button, .flip-card {
      height: 48px;
    }
  }

  @media screen and (max-height: 780px) {
    .role-input {
      margin-bottom: 50px;
    }
  }

  @media screen and (max-width: 720px) {
    .form-container {
      padding: 0px 0px 20px 20px;
    }
    
    .role-input {
      margin-bottom: 30px;
    }
  }
`;

export default FormStyle;