import styled from 'styled-components';
import { Colors } from 'constant';

const LoginPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 100px);
  background-color: ${Colors.bgSignIn};

  .input-wrapper {
    padding: 20px;
    width: 100%;
    background-color: ${Colors.bgSignIn};
  }
`;

export default LoginPageStyle;
