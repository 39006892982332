import style from 'styled-components';

const HelpStyle = style.div`
  height: fit-content;
  overflow-x: hidden;

  .wrapper {
    margin-top: 60px;
    width:83%;
    margin:auto;
  }

  .content {
    width: 100%;
    margin: auto;
  }

  .contact-us {
    margin-right: 110px;
    margin-left: auto;
    top: 180px;
    display: block;
    position: relative;
    border: 1px solid #FFFBD1;
  }

  .search-input {
    margin-right: 110px;
    margin-left: auto;
    top: 225px;
    display: block;
    position: relative;
    width: 350px;
    color:black
  }

  @media screen and (min-width: 1530px) {
    .contact-us {
      margin-right: 200px;
    }
    .search-input {
      margin-right: 200px;
    }
  }

  @media screen and (max-width: 600px) {
    .contact-us {
      margin-right: 20px;
      top: 130px;
    }
    .search-input {
      margin-right: 20px;
      top: 160px;
    }
  }

`;

export default HelpStyle;