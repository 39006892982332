import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch } from 'stores';
import { loginUser } from 'stores/actions';
import selectors from 'selectors';

const useLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useSelector(selectors.userProfile);

  const [payload, setPayload] = useState({
    email: '',
    password: '',
    phone: ''
  });

  const onClickSubmit = () => {
    // dispatch(loginUser(payload));
    localStorage.setItem('token', 'true');
    navigate('/marketplace');
  };

  const onChangeText = (value, name) => {
    setPayload({
      ...payload,
      [name]: value
    });
  };

  return {
    onClickSubmit,
    onChangeText
  };
};

export default useLoginPage;
