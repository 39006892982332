import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { Layout } from "pages/LandingPageNew/Components";
import Info from "./sections/Info";
import Security from "./sections/Security";
import Wallet from "./sections/Wallet";
import S3 from "react-aws-s3";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../selectors";
import { getuser, updatemembershipprofile } from "../../../../services/auth";

const tabs = ["Your Profile", "Your wallet", "Security"];

const Profile = () => {
  const user = useSelector(selectors.userProfile);

  const [Image, setImage] = useState("");
  const [FileName, setFileName] = useState("");
  const [Username, setUsername] = useState("");

  useEffect(() => {
    if (user) {
      getCurrentuser();
    }
  }, [user]);

  const getCurrentuser = async () => {
    const res = await getuser(user.user.id);
    if (res.data.status) {
      const eve = res.data.result;
      setImage(eve.profilePicture ? eve.profilePicture : "");
      setFileName(eve.profilePicture ? eve.profilePicture : "");
      setUsername(eve.userName ? eve.userName : "");
    }
  };

  const handleProfile = async (e) => {
    const files = e.target.files;
    let file = files[0];
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIRECTORYNAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    };

    const ReactS3Client = new S3(config);
    const newFileName = file.name + "_" + Date.now();
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      setImage(data.location);
      setFileName(newFileName);
    });
  };

  return (
    <Layout>
      <div className="min-h-[calc(100vh-100px)] flex items-start bg-newBeginning md:py-20 lg:py-20 py-10 px-4 xl:container mx-auto font-AcuminPro">
        <Tab.Group
          as="div"
          className="flex lg:flex-row md:flex-row flex-col lg:space-x-0 space-x-0 md:space-x-5 items-start h-full w-full"
        >
          <Tab.List
            as="div"
            className="flex flex-col w-full md:w-[30%] lg:w-[30%] mb-3"
          >
            {Image && Image ? (
              <>
                <label
                  htmlFor="file-input"
                  className="w-32 h-32 rounded-full bg-[#D9D9D9] mb-10 cursor-pointer hover:bg-opacity-80"
                >
                  <img
                    className="w-32 h-32 rounded-full cursor-pointer"
                    src={`${process.env.REACT_APP_S3_URL}${FileName}`}
                  />
                </label>
                <input
                  id="file-input"
                  onChange={(e) => handleProfile(e)}
                  type="file"
                  className="hidden"
                />{" "}
              </>
            ) : (
              <label
                htmlFor="file-input"
                className="w-32 h-32 rounded-full bg-[#D9D9D9] mb-10 cursor-pointer hover:bg-opacity-80"
              >
                <input
                  id="file-input"
                  onChange={(e) => handleProfile(e)}
                  type="file"
                  className="hidden"
                />
              </label>
            )}

            <div className="flex flex-col gap-5">
              {tabs.map((e) => {
                return (
                  <Tab
                    key={e}
                    className={({ selected }) =>
                      `font-medium uppercase transition-all ease-in-out duration-500 antialiased outline-none border-none tracking-[0.01em] text-left focus:outline-none flex items-center gap-3 ${
                        selected
                          ? "text-4xl text-[#1C1E19]"
                          : "text-2xl text-[#636363]"
                      }`
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span>{e}</span>
                        <div
                          className={`-mt-2 transition-opacity ease-in-out duration-500 ${
                            selected ? "opacity-100" : "opacity-0"
                          }`}
                        >
                          <svg
                            width="10"
                            height="20"
                            viewBox="0 0 10 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.99634 9.91639C9.97807 9.71104 9.8935 9.51672 9.75465 9.36146L1.77235 0.359624C1.60165 0.154789 1.35318 0.0263167 1.08365 0.00357754C0.814123 -0.0189935 0.54667 0.0658621 0.342748 0.239134C0.138647 0.412407 0.0154513 0.658992 0.00135752 0.922526C-0.0127362 1.18606 0.0833098 1.44387 0.267753 1.63681L7.68123 10L0.267754 18.3632C0.0833149 18.5561 -0.0127355 18.8139 0.00135831 19.0775C0.0154521 19.341 0.138643 19.5876 0.342748 19.7609C0.546676 19.9341 0.814115 20.019 1.08365 19.9964C1.35318 19.9737 1.60165 19.8452 1.77235 19.6404L9.75465 10.6385C9.9323 10.4396 10.0195 10.179 9.99634 9.91626L9.99634 9.91639Z"
                              fill="#1C1E19"
                            />
                          </svg>
                        </div>
                      </>
                    )}
                  </Tab>
                );
              })}
            </div>
          </Tab.List>
          <Tab.Panels as="div" className="h-full flex-1">
            <div>
              <h2 className="text-[40px] font-semibold tracking-wider text-black">
                Welcome Back {Username}!
              </h2>
              <p className="text-darkSecurity text-lg mt-3 leading-[22px] tracking-[0.01em]">
                Here you can edit your name and email address.
                <br />
                This information is not visible to others.
              </p>
            </div>
            <Tab.Panel className="flex-1">
              <Info Image={Image} FileName={FileName} />
            </Tab.Panel>
            <Tab.Panel className="flex-1">
              <Wallet />
            </Tab.Panel>
            <Tab.Panel className="flex-1">
              <Security />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </Layout>
  );
};

export default Profile;
