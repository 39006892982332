import React from 'react';
import { Text, Button } from 'components';
import { Images , Icons} from 'constant';

const TransactionDetailCard = ({image,title, restoName, time, purchase, img, status, matic }) => {
  const renderBtnBuy = () => {
    return (
      <div className='flex flex-col justify-end'>
        <Button
          width='100%'
          height='32px'
          bgHover='lemonChiffon'
          textColorHover='darkBlue'
          textColor='lemonChiffon'
          className='h-6 mt-4'
          backgroundColor='rgba(255, 251, 209, 0.05);'
          label='Buy Again'
        />
      </div>
    );
  };

  return (
    <div className="rounded overflow-hidden shadow-lg p-px p-px mb-8">
      <div className="rounded-md">
        <div className="px-6 py-6">
          <Text text='Transaction Detail' className='font-bold mb-2' fontSize='20px' color='black' fontWeight='800' />
          <div className='flex space-x-2 mt-4 mb-4'>
            <img className="w-[15px] h-[15px]" src={ status === "transfer" ? Images.TransactionOut : Images.TransactionIn } />
            <Text text={ time } fontSize='16px' color='gray' fontWeight='300' />
          </div>
          <div className="flex space-x-4">
            <div className="flex-shrink-0">
              <img className="rounded w-[80px] h-[80px]" src={ image } />
            </div>
            <div className="flex-1 min-w-0">
              <Text text={ title } className='font-bold' fontSize='20px' color='black' fontFamily='Lora' />
              <div className='flex mt-2 space-x-2'>
                <Text text='Owned by' className='font-bold' fontSize='16px' color='gray' fontWeight='300' />
                <Text text={ restoName } fontSize='16px' className='font-bold' color='gray' />
              </div>
              <Text text='Restaurant Royalty:' className='font-bold mt-2 mb-2' fontSize='14px' color='gray' fontWeight='300' />
            </div>
          </div>
          <Text text='Summary' className='font-bold mt-4' fontSize='16px' color='black' fontWeight='700' />


          <div className="flex space-x-2 mt-3">
            <div className="flex-1 min-w-0">
              <Text text={ title } className='mb-4' fontSize='14px' color='gray' fontWeight='300' />
              <Text text='Additional Fee' className='font-bold mb-4' fontSize='14px' color='gray' fontWeight='300' />
              {/* <Text text='Total' className='font-bold' fontSize='16px' color='lemonChiffon' fontWeight='800' /> */}
            </div>
            <div className="inline-flex text-base font-semibold text-gray-900 dark:text-white">
              <div className="flex-1 min-w-0">
                <div style={{display:"flex", alignItems:"center"}}>
                  <img className="w-[15px] h-[15px] mr-2" src={Icons.polygon } alt="polygon" />
                  <Text text={ matic } fontSize='16px' className='font-bold' color='black' />
                </div>
                {/* <div style={{display:"flex", alignItems:"center" }} className="mt-5 ml-6">
                <Text text={ "0" } fontSize='14px' className='font-bold mb-4' color='#fff' />
                </div> */}
                {/* <Text text={ purchase } fontSize='16px' className='font-bold' color='lemonChiffon' /> */}
                <div style={{display:"flex", alignItems:"center"}}>
                <img className="w-[15px] h-[15px] mr-2" src={Icons.polygon } alt="polygon" />
                <Text text={ '0' } fontSize='16px' className='font-bold' color='black' />
                </div>

              </div>
            </div>
          </div>
          {/* { renderBtnBuy() } */}
        </div>
      </div>
    </div>
  );
};

export default TransactionDetailCard;