import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import { Text, Button, CardList } from 'components';
import { Images, Icons, Colors } from 'constant';
import { Layout } from 'pages/LandingPageNew/Components';
import { useNavigate } from 'react-router-dom';
const OurHouse = () => {
	const [activeTab, setActiveTab] = useState('Founder');
	const navigate = useNavigate();

	const tierList = [
		{
			title: 'Founder',
			subtitle: 'Our most exclusive tier for our most involved members. Get priority access to our Club Houses, events, and collections!',
			image: Images.Tier1,
		},
		{
			title: 'Innovator',
			subtitle: 'Our most favored tier for members who want preferential access without the upfront commitment!',
			image: Images.Tier2,
		},
		{
			title: 'Ambassador',
			subtitle: 'Our common tier for avid members who simply enjoy having a space to connect, retreat, or just call their own.',
			image: Images.Tier3,
		},
	];

	const tableList =
	{
		heading: [
			'house benefits',
			'Connoisseur',
			'aesthete',
			'enthusiast'
		],
		body: {
			row1: [
				'Discounts at our Club Houses',
				'Preferred Bookings for Club House Spaces',
				'Preferred Access to New Collections',
				'Discounts on New Collections',
				'Free NFTs Rewards',
				'Number of Guests Per Month',
				'Concierge Service For Membership Management',
			],
			row3: [
				'10% off',
				'no booking fee',
				'',
				'',
				'',
				'',
				'',
			]
		}
		// body: [
		// {
		// 	line1: 'Discounts dining at Oku Club House',
		// 	line2: '30% off',
		// 	line3: '20% off',
		// 	line4: '10% off'
		// },
		// {
		// 	line1: 'Access and bookings to Oku Club House',
		// 	line2: true,
		// 	line3: true,
		// 	line4: true
		// },
		// {
		// 	line1: 'First access to purchase event collections',
		// 	line2: true,
		// 	line3: true,
		// 	line4: true
		// },
		// {
		// 	line1: 'Discount on event collections',
		// 	line2: '20% off',
		// 	line3: '10% off',
		// 	line4: false
		// },
		// {
		// 	line1: 'Free NFT events and collections yearly',
		// 	line2: true,
		// 	line3: true,
		// 	line4: false
		// },
		// {
		// 	line1: 'Number of guests member can bring to an Oku Space',
		// 	line2: 'unlimited',
		// 	line3: 'up to 5 guests',
		// 	line4: 'up to 3 guests'
		// },
		// {
		// 	line1: 'Concierge service for all experiences',
		// 	line2: true,
		// 	line3: false,
		// 	line4: false
		// },
		// {
		// 	line1: 'Exclusivity of Tier',
		// 	line2: '10% of collection',
		// 	line3: '30% of collection',
		// 	line4: '60% of collection'
		// }
		// ],
	};

	const [usertokencheck, setusertokencheck] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem("token")
		if (token) {
			setusertokencheck(true)
		} else {
			setusertokencheck(false)
		}
	}, [])

	return (
		
		<Layout>
			<div className='relative h-fit max-w-[1440px] m-auto'>
				<div className='relative w-full h-[150px] lg:h-full'>
					<img
						alt='membership hero'
						src={Images.MembershipHero}
						className='w-full h-full brightness-50 hidden md:block' />
					<img
						alt='membership hero'
						src={Images.MembershipHeroXs}
						className='w-full h-[150px] md:hidden' />
					<div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full mx-auto container lg:px-'>
						<p className='text-white tracking-widest font-semibold text-base lg:text-[40px] lg:leading-[47px] font-ExpressWay text-center uppercase'>Step into our community and help us define<br className='max-md:hidden'/>what your Club House looks and feels like.</p>
					</div>
				</div>

				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={false}
					delay={700}
				>
					<div className='lg:px-96 py-[60px] md:py-20 flex flex-col space-y-4 md:space-y-[30px] px-6'>
						<p className='lg:text-5xl text-[28px] font-semibold leading-8 md:leading-[56px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>We Invite You</p>
						{/* <p className='lg:text-[32px] text-xl font-semibold lg:leading-[38px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>Step into our community and help us define <br className='hidden md:inline' />what our Club Houses look and feel like.</p> */}
						<p className='lg:text-lg text-sm lg:leading-[22px] tracking-[0.01em] font-ExpressWay text-darkSecurity text-center'>By becoming a member of any of our tiers and actively engaging with our Discord channel, you have the power to influence and transform our spaces. This includes the ability to impact the conversations, activities, and atmosphere within the Oku space, working collaboratively with other passionate members to cultivate and evolve our community's culture.</p>
						<div className='flex flex-col lg:flex-row justify-center max-lg:gap-3 gap-5'>
							{
								!usertokencheck && (
									<Button theme='primary' label='Sign up now' className='w-full lg:w-fit' onClick={() => navigate('/sign-up')} />
								)
							}
							<Button theme='primary-outline' label='We’re on Discord!' className='w-full lg:w-fit' onClick={() => window.open('https://discord.gg/okuhouse', '_blank')} />
						</div>
					</div>
				</ScrollAnimation>

				<ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={true}
					className='pb-10'>
					<CardList list={tierList} />
				</ScrollAnimation>

				{/* <ScrollAnimation
					animateOnce
					animateIn='fadeInUp'
					animatePreScroll={true}
				>
					<div className='py-10 mb-10 lg:mx-auto lg:container'>
						<h3 className='text-[28px] font-semibold tracking-[0.01em] md:text-5xl leading-[56px] font-ExpressWay text-center'>What’s the difference?</h3>
						<div className='hidden lg:block'>
							<table className='table-fixed w-full mt-10 px-10'>
								<thead>
									<tr>
										{
											tableList.heading.map((list, index) => (
												<th
													key={index}
													className={`${index !== tableList.heading.length - 1 && 'border-r border-borderLine'}  border-b border-borderLine text-left py-5 uppercase`}>
													<Text
														text={list}
														fontWeight='600'
														fontSize='24px'
														lineHeight='28px'
														textAlign={`${index !== 0 && 'center'}`}
														customLetterSpacing='0.1em'
														fontFamily='Expressway'
													/>
												</th>
											))}
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<Text
												text='Discounts at our Club Houses'
												fontWeight='500'
												fontSize='20px'
												lineHeight='24px'
												fontFamily='AcuminPro'
											/>
										</td>
										<td rowSpan='3' className=' border-borderLine '>
											<div className='w-full flex justify-end'>
												<img
													className='transform -scale-x-100'
													alt='empty table 2'
													src={Images.TableTop} />
											</div>
										</td>
										<td className='border-x  border-b border-borderLine p-3 uppercase'>
											<Text
												textAlign='center'
												text='10% off'
												fontWeight='600'
												fontSize='20px'
												lineHeight='24px'
												customLetterSpacing='0.1em'
												fontFamily='AcuminPro'
											/>
										</td>
										<td rowSpan='3' className=' border-borderLine'>
											<img
												className='flex justify-center items-center'
												alt='empty table 2'
												src={Images.TableTop} />
										</td>
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Preferred Bookings for Club <br />House Spaces</p>
										</td>
										<td className='border-x border-b border-borderLine p-3 uppercase'>
											<Text
												textAlign='center'
												text='no booking fee'
												fontWeight='600'
												fontSize='20px'
												lineHeight='24px'
												customLetterSpacing='0.1em'
												fontFamily='AcuminPro'
											/>
										</td>
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5 whi'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Preferred Access to New Collections</p>
										</td>
										<td className='border-x border-b border-borderLine'>
											<div className='flex justify-center items-center'>
												<div className='rounded-full rounded-orange border-2 border-okuGrowth p-2 w-fit '>
													<Icons.Check className='[&>path]:fill-okuGrowth' />
												</div>
											</div>
										</td>
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Discounts on New Collections</p>
										</td>
										<td rowSpan='2' className=' border-borderLine'>
											<p className='uppercase font-semibold text-[32px] leading-10 tracking-widest text-okuGrowth font-AcuminPro text-center'>Stay<br />tuned</p>
										</td>
										<td className='border-x border-borderLine' />
										<td rowSpan='2' className=' border-borderLine'>
											<p className='uppercase font-semibold text-[32px] leading-10 tracking-widest text-okuGrowth font-AcuminPro text-center'>we’re<br />coming<br />soon</p>
										</td>
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Free NFTs Rewards</p>
										</td>
										<td className='border-x border-borderLine' />
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Number of Guests Per Month</p>
										</td>
										<td rowSpan='2' className='border-b border-borderLine '>
											<div className=''>
												<img
													alt='empty table 2'
													src={Images.TableBottom} />
											</div>
										</td>
										<td className='border-x border-borderLine' />
										<td rowSpan='2' className='border-b border-borderLine '>
											<div className=''>
												<img
													alt='empty table 2'
													src={Images.TableBottom} />
											</div>
										</td>
									</tr>
									<tr>
										<td className='border-r border-b border-borderLine py-5'>
											<p className='font-medium leading-6 text-xl font-AcuminPro text-black'>Concierge Service For<br />Membership Management</p>
										</td>
										<td className='border-x border-b border-borderLine' />
									</tr>
								</tbody>
							</table>
						</div>

						<div className='lg:hidden px-6'>
							<ul className='flex gap-10 md:justify-between mt-8 text-black overflow-x-auto'>
								<li onClick={() => setActiveTab('CONNOISSEUR')} className={`border-b-2 border-transparent hover:border-okuGrowth cursor-pointer text-lg ${activeTab === 'CONNOISSEUR' && 'border-okuGrowth font-bold'}`}>CONNOISSEUR</li>
								<li onClick={() => setActiveTab('AESTHETE')} className={`border-b-2 border-transparent hover:border-okuGrowth cursor-pointer text-lg ${activeTab === 'AESTHETE' && 'border-okuGrowth font-bold'}`}>AESTHETE</li>
								<li onClick={() => setActiveTab('ENTHUSIAST')} className={`border-b-2 border-transparent hover:border-okuGrowth cursor-pointer text-lg ${activeTab === 'ENTHUSIAST' && 'border-okuGrowth font-bold'}`}>ENTHUSIAST</li>
							</ul>

							<div className={`mt-3 ${activeTab === 'CONNOISSEUR' ? 'flex' : 'hidden'} flex flex-col`}>
								<div className='w-full'>
									<img
										alt='empty table 2'
										src={Images.TableTop} />
								</div>
								<p className='uppercase font-semibold text-[32px] leading-10 tracking-widest text-okuGrowth font-AcuminPro text-center'>Stay<br />tuned</p>
								<img
									alt='empty table 2'
									src={Images.TableBottom} />
							</div>
							<div className={`mt-3 ${activeTab === 'AESTHETE' ? 'flex' : 'hidden'} flex flex-col`}>
								<h3 className='text-base font-semibold tracking-widest font-ExpressWay'>HOUSE BENEFIT</h3>
								<div className='divide-y divide-darkGrey'>
									<div className='py-5 grid grid-cols-2 gap-5'>
										<h3 className='text-sm font-medium font-AcuminPro leading-5'>Discounts at our Club Houses</h3>
										<h3 className='text-base font-medium font-AcuminPro leading-5 tracking-widest uppercase text-center'>10% off</h3>
									</div>
									<div className='py-5 grid grid-cols-2 gap-5'>
										<h3 className='text-sm font-medium font-AcuminPro leading-5'>Preferred Bookings for Club House Spaces</h3>
										<h3 className='text-base font-medium font-AcuminPro leading-5 tracking-widest uppercase text-center'>no booking fee</h3>
									</div>
									<div className='py-5 grid grid-cols-2 gap-5'>
										<h3 className='text-sm font-medium font-AcuminPro leading-5'>Preferred Access to New Collections</h3>
										<div className='flex justify-center items-center'>
											<div className='rounded-full rounded-orange border-2 border-okuGrowth p-2 w-fit '>
												<Icons.Check className='[&>path]:fill-okuGrowth' />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className={`mt-3 ${activeTab === 'ENTHUSIAST' ? 'flex' : 'hidden'} flex flex-col`}>
								<div className='w-full flex justify-end'>
									<img
										className='transform -scale-x-100'
										alt='empty table 2'
										src={Images.TableTop} />
								</div>
								<p className='uppercase font-semibold text-[32px] leading-10 tracking-widest text-okuGrowth font-AcuminPro text-center'>we’re<br />coming<br />soon</p>
								<img
									alt='empty table 2'
									src={Images.TableBottom} />
							</div>
						</div>
					</div>

				</ScrollAnimation> */}
			</div>
		</Layout >
	);
};

export default OurHouse;