const en = {
	restaurantParter: {
		form: {
			title: 'Become Our Partner!',
			desc: 'PARTNER WITH US TO SHARE YOUR STORIES ON OUR PLATFORM!',
			labelRestaurantName: 'Restaurant Name',
			labelEmail: 'Email',
			labelName: 'Name',
			labelRole: 'Role in Your Restaurant (Optional)',
			labelCTA: 'Become Our Restaurant Partner!',
			labelSuccess: 'Success',
		},
		title: 'Restaurant Partnerships',
		desc1: 'We partner with restaurants to help them reach a more global audience. Diners from around the world can now participate in supporting food and beverage brands they care about by purchasing restaurant tokens.',
		desc2: 'In exchange, we provide our restaurant partners with the necessary working capital to grow their business and write the next chapter in their gastronomic journey.',
		desc3: 'Stay in touch and keep up to date with our latest activities on our social media platforms!',
	},

	NFTMembership: {
		title1: 'Oku House',
		title2: 'Membership',
		desc1: 'This is a foodie’s access card to some of the world’s most sought after food and beverage experiences.',
		desc2: 'By becoming a member, you can participate in helping restaurants share their stories while receiving benefits whenever you dine.',
		CTAlabel: 'COMING SOON!',
		sosmed: 'Stay up to date on our social media!'
	},

	header: {
		joinOurTeam: 'Sign Up',
		forRestaurants: 'For Restaurants',
	}
};

export default en;