import React, { useState, useEffect, useRef, useCallback } from "react";
import { Menu } from "@headlessui/react";
import { Input, Layout, Text, SelectMenu, Card } from "components";
import MarketplaceStyle from "./style";
// import { Header } from 'components';
import { Footer, Header } from "pages/LandingPageNew/Components";
import Tabs from "components/Tabs";
import { get, put, post } from "../../services/http/index";
import { getuser } from "../../services/auth";
import { NFTCard, RestoCard } from "components/Card";
import { Icons, Colors, Images } from "constant";
import icons from "constant/icons";
import { Countries } from "helpers/Countries";
import NFTCollections from "components/NFTCollections";
import { useNavigate } from "react-router";
import {
  getRestaurants,
  getEventwithCollections,
} from "../../services/restaurant";
import { Fetch, clear } from "../../services/local-storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "./Pagination";
import { useDispatch, useSelector } from "react-redux";
import { IoMdRefresh } from "react-icons/io";
import selectors from "selectors";
import { SET_RESTAURANT } from "../../stores/User/userActionTypes";
import Loader from "react-js-loader";
import { getrestaurants, getTrendingnfts } from "services/favourites";
import { getLatestNFTs } from "services/Nft";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Select from "react-select";
const tabsData = [{ title: "Restaurant" }, { title: "NFTs" }];

const restaurantPerRow = 8;

const Marketplace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [atozformat, setAtoZformat] = useState("A - Z");
  const targetRef = useRef(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [Restaurant, setRestaurant] = useState([]);
  const [newRestaurant, setNewRestaurant] = useState([]);
  const [trendingNfts, setTrendingNfts] = useState([]);
  const [fav_restaurants, setFavouriteRestaurants] = useState([]);
  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(12);
  const [searchText, setSearchText] = useState("");
  const [CountriesJson, setCountriesJson] = useState(Countries);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = Restaurant.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(Restaurant.length / recordsPerPage);
  const [displayContent, setDisplayContent] = useState("All");
  const current_user = useSelector(selectors.userProfile);
  const user = useSelector(selectors.userProfile);
  const token = user.token;
  const [selectedCountry, setSelectedCountry] = useState("");

  const [next, setNext] = useState(restaurantPerRow);
  const handleMoreImage = () => {
    setNext(next + restaurantPerRow);
  };

  const getdata = async () => {
    setOpen(true);
    const results = await getEventwithCollections();
    if (results && results.data.result) {
      setRestaurant(results.data.result);
      setNewRestaurant(results.data.result);
      setOpen(false);
    } else {
      clear();
      navigate("/marketplace");
    }
  };
  const userProfile = useSelector(selectors.userProfile);

  const getFavouriterestaurants = async () => {
    const result = await get("restaurant/getRestaurants");
    const result_ = result.data.result;
    const restaurant_ = result_.sort((a, b) => b.Likes.length - a.Likes.length);
    let arr = [];
    if (restaurant_.length < 9) {
      arr = restaurant_;
    } else {
      arr = restaurant_.slice(0, 12);
    }
    setFavouriteRestaurants(arr);
  };

  useEffect(() => {
    userdata();
    getdata();
    getFavouriterestaurants();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("nft")) {
      setActiveTabIndex(1);
    }
  }, [token]);

  const userdata = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res1 = await getuser(user.user.id);
      if (res1.data.status) {
        const User = res1.data.result;
        if (User.membershipstatus !== "approved") {
          navigate(`/membership`);
        }
      } else {
        toast("Something Went wrong");
      }
    } else {
      navigate("/sign-in");
      toast("Login Your Account");
    }
  };

  const handleClick = (item) => {
    dispatch({
      type: "SET_RESTAURANT",
      payload: item,
    });
    navigate("/restaurant");
  };

  const handleSearch = (value) => {
    setSearchText(value);
    if (value) {
      const filtered_restaurant = newRestaurant.filter((item) =>
        item.restaurantName.toLowerCase().includes(value.toLowerCase())
      );
      setRestaurant(filtered_restaurant);
    } else {
      setRestaurant(newRestaurant);
      setSearchText("");
    }
  };

  const countriesSelect = async (value) => {
    setSelectedCountry(value);
    try {
      const result = await get("restaurant/getRestaurants");
      if (value.toLowerCase() == "All Countries".toLowerCase()) {
        setRestaurant(result.data.result);
      } else if (value !== "All Countries") {
        const filtered_restaurant = result.data.result.filter(
          (item) => item.Country.toLowerCase() == value.toLowerCase()
        );
        if (filtered_restaurant.length == 0) {
          toast("No Result Found");
        } else {
          setRestaurant(filtered_restaurant);
        }
      } else {
        setRestaurant(newRestaurant);
      }
    } catch (e) {
      getdata();
    }
  };

  const handleAtoZ = () => {
    setAtoZformat(atozformat == "A - Z" ? "Z - A" : "A - Z");
    if (atozformat == "Z - A") {
      const nameArr = newRestaurant.map((item) => item.restaurantName);
      const sortedArr = nameArr.sort();
      let arr = [];
      const newArr = sortedArr.map((item) =>
        newRestaurant.map((i) =>
          item.includes(i.restaurantName) ? arr.push(i) : ""
        )
      );
      setRestaurant(arr);
    } else {
      const nameArr = newRestaurant.map((item) => item.restaurantName);
      const sortedArr = nameArr.sort();
      let arr = [];
      const newArr = sortedArr.map((item) =>
        newRestaurant.map((i) =>
          item.includes(i.restaurantName) ? arr.push(i) : ""
        )
      );
      setRestaurant(arr.reverse());
    }
  };
  const refreshData = () => {
    setRestaurant(newRestaurant);
    setSearchText("");
    setAtoZformat("A - Z");
    setSelectedCountry("");
  };

  const renderDropDown = () => {
    return (
      <Select
        options={Countries}
        className="flex items-center justify-between md:w-[250px] lg:w-[250px] w-auto color-white rounded"
        value={selectedCountry}
        placeholder={
          <div>
            {selectedCountry == "" ? "Select Country" : selectedCountry}
          </div>
        }
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        onChange={(selected) => {
          countriesSelect(selected.name);
        }}
      />
    );
  };
  const restoCard = () => {
    return (
      currentRecords &&
      currentRecords.map((item, i) => {
        return (
          <div
            onClick={() => handleClick(item)}
            className=" rounded shadow-md w-full h-[165px] p-px bg-gradient-to-br from-white/40 to-transparent hover:bg-lemonChiffon cursor-pointer"
          >
            <div className="flex p-5 bg-cardBg rounded-md h-[163px]">
              <div className="w-full flex justify-between space-x-">
                <div className="flex flex-col justify-between ">
                  <img
                    className="h-[50px] w-[130px] "
                    src={`${process.env.REACT_APP_S3_URL}${item.restaurantProfile}`}
                    alt=""
                  />
                  <Text
                    text={item.restaurantName}
                    fontType="h4"
                    fontFamily="Lora"
                    fontSize="20px"
                    className="font-bold mt-[-5px]"
                  />
                  <Text
                    text={`${item.totalNfts} NFT Collections`}
                    color="lemonChiffon"
                    className="font-bold mb-[-5px]"
                  />
                </div>
                <div>
                  <img className="" src={Images.ArrowRight} alt="" />
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  const renderContent = () => {
    if (activeTabIndex === 1) {
      return (
        <>
          {open ? (
            <Loader
              type="spinner-cub"
              bgColor={"black"}
              color={"black"}
              size={80}
            />
          ) : (
            ""
          )}
          <NFTCollections />
        </>
      );
    }
    return (
      <div className="px-5 sm:px-0">
        <div className="flex justify-between space-x-2 items-center">
          <input
            type="text"
            value={searchText}
            className="search-input text-black"
            onChange={(e) => handleSearch(e.value)}
          />
          <div
            className="flex items-center  w-fit space-x-2 py-[10px] px-[20px] bg-white/5 rounded"
            onClick={() => handleAtoZ()}
          >
            <img src={icons.AtoZ} alt="a-to-z" className="" />
            <Text fontWeight="700" text={atozformat} className="w-[45px]" />
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-[10px] gap-y-[20px] mt-5">
          {restoCard()}
        </div>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />

        {open ? (
          <Loader
            type="spinner-cub"
            bgColor={"black"}
            color={"black"}
            size={80}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const renderAllCards = () => {
    return (
      Restaurant &&
      Restaurant.slice(0, next)?.map((marketplace, idx) => {
        return (
          <Card.MarketplaceCard
            wrapperClass="w-full hover:-translate-y-4 border border-solid border-platinumSolid hover:border-[2px] hover:border-abundance "
            title={marketplace.restaurantName}
            address={marketplace.Country}
            count={marketplace.totalNfts}
            srcImages={`${process.env.REACT_APP_S3_URL}${marketplace.restaurantProfile}`}
            srcImagesLogo={marketplace.imgLogo}
            key={idx}
            onClick={() => {
              dispatch({
                type: "SET_RESTAURANT",
                payload: marketplace,
              });
              navigate(`/collections/${marketplace._id}`, {
                state: {
                  img: marketplace.img,
                  id: marketplace._id,
                  data: marketplace,
                },
              });
            }}
          />
        );
      })
    );
  };

  const renderPopularCards = () => {
    return fav_restaurants.length > 0 ? (
      fav_restaurants.map((marketplace, idx) => {
        return (
          <Card.MarketplaceCard
            wrapperClass="w-full hover:-translate-y-4 border border-solid border-platinumSolid hover:border-[2px] hover:border-abundance "
            title={marketplace.restaurantName}
            address={marketplace.Country}
            count={marketplace.totalNfts}
            srcImages={`${process.env.REACT_APP_S3_URL}${marketplace.restaurantProfile}`}
            srcImagesLogo={marketplace.imgLogo}
            key={idx}
            onClick={() => {
              dispatch({
                type: "SET_RESTAURANT",
                payload: marketplace,
              });
              navigate(`/collections/${marketplace._id}`, {
                state: {
                  img: marketplace.img,
                  id: marketplace._id,
                  data: marketplace,
                },
              });
            }}
          />
        );
      })
    ) : (
      <p className="text-black text-center mt-5">No Results</p>
    );
  };

  const renderLatestCards = () => {
    return (
      Restaurant &&
      Restaurant.slice(0, 4).map((marketplace, idx) => {
        return (
          <Card.MarketplaceCard
            wrapperClass="w-full hover:-translate-y-4 border border-solid border-platinumSolid hover:border-[2px] hover:border-abundance "
            title={marketplace.restaurantName}
            address={marketplace.Country}
            count={marketplace.totalNfts}
            srcImages={`${process.env.REACT_APP_S3_URL}${marketplace.restaurantProfile}`}
            srcImagesLogo={marketplace.imgLogo}
            key={idx}
            onClick={() => {
              dispatch({
                type: "SET_RESTAURANT",
                payload: marketplace,
              });
              navigate(`/collections/${marketplace._id}`, {
                state: {
                  img: marketplace.img,
                  id: marketplace._id,
                  data: marketplace,
                },
              });
            }}
          />
        );
      })
    );
  };

  const [showSignIn, setShowSignIn] = useState("INIT");
  const [particular, setParticular] = useState(false);
  return (
    <Layout>
      <Header
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
        particular={particular}
      />
      <MarketplaceStyle>
        <div className="h-fit overflow-hidden mb-10">
          <div className="mt-[100px] md:mt-0 lg:mt-[100px] flex">
            <img
              src={Images.BgMainPage}
              alt="background-main-page absolute -z-10"
              className="object-cover"
              style={{ height: "250px", width: "100%" }}
            />
            <div
              ref={targetRef}
              className={
                "absolute lg:mt-[90px] md:mt-[90px] mt-[100px] flex flex-col items-center w-full px-5 xl:px-0"
              }
            >
              <Text
                text="Explore Our Marketplace"
                fontType="h1"
                color="white"
                fontFamily="Expressway"
                fontWeight="700"
                contentClassName={"lg:text-5xl md:text-5xl text-7xl"}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center py-0 md:pt-[34px] lg:pt-[34px] ">
          <div className="lg:w-defaultContent md:w-full">
            <div className="flex lg:flex-row md:flex-col flex-col items-center justify-between gap-y-3 lg:gap-y-0 md:gap-y-3">
              <div className="flex lg:flex-row space-x-3 md:flex-row flex-row items-center lg:space-x-[20px] md:space-x-[20px]">
                <Text
                  text="All"
                  color={
                    displayContent === "All"
                      ? Colors.abundance
                      : Colors.darkGrey
                  }
                  className={`${
                    displayContent === "All"
                      ? "bg-abundance/10 border-abundance"
                      : "bg-lightGrey border-transparent"
                  } border-solid border-[1px] px-5 py-2.5 rounded-[100px] cursor-pointer`}
                  fontWeight="700"
                  onClick={() => setDisplayContent("All")}
                  fontFamily="AcuminPro"
                />
                <Text
                  text="Latest"
                  color={
                    displayContent === "latest"
                      ? Colors.abundance
                      : Colors.darkGrey
                  }
                  fontWeight="700"
                  className={`${
                    displayContent === "latest"
                      ? "bg-abundance/10 border-abundance"
                      : "bg-lightGrey border-transparent"
                  } border-solid border-[1px] px-5 py-2.5 rounded-[100px] cursor-pointer`}
                  onClick={() => setDisplayContent("latest")}
                  fontFamily="AcuminPro"
                />
                <Text
                  text="Popular"
                  color={
                    displayContent === "popular"
                      ? Colors.abundance
                      : Colors.darkGrey
                  }
                  className={`${
                    displayContent === "popular"
                      ? "bg-abundance/10 border-abundance"
                      : "bg-lightGrey border-transparent"
                  } border-solid border-[1px] px-5 py-2.5 rounded-[100px] cursor-pointer`}
                  fontWeight="700"
                  onClick={() => setDisplayContent("popular")}
                  fontFamily="AcuminPro"
                />
              </div>
              {displayContent !== "popular" ? (
                <div className="flex lg:flex-row md:flex-row flex-col gap-y-3 lg:gap-y-0 md:gap-y-0 items-center md:space-x-5 lg:space-x-5">
                  <div className="flex lg:flex-row md:flex-row gap-x-3 lg:gap-x-3 md:gap-x-3 items-center">
                    <div
                      style={{ backgroundColor: "rgb(0 0 0 / 0.02)" }}
                      className="flex items-center  w-fit space-x-2 py-[10px] px-[20px] rounded mt-0 sm:mt-0"
                      onClick={() => handleAtoZ()}
                    >
                      <img src={Icons.AtoZ} alt="a-to-z" className="" />
                      <Text
                        style={{ width: "10%" }}
                        fontWeight="700"
                        text={atozformat}
                        color="black"
                      />
                    </div>
                    <input
                      type="text"
                      value={searchText}
                      style={{
                        border: ".5px solid lightgray",
                        outline: "none",
                        borderRadius: ".3em",
                        backgroundColor: "rgb(0 0 0 / 0.02)",
                      }}
                      placeholder="Search"
                      className="search-input p-2 placeholder-black::placeholder"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                  <div className="flex lg:flex-row md:flex-row items-center gap-x-1">
                    {renderDropDown("All Countries")}
                    <div id="my-element" data-tooltip-content="Refresh">
                      <IoMdRefresh
                        size={25}
                        color="black"
                        onClick={refreshData}
                      />
                    </div>
                    <ReactTooltip
                      anchorId="my-element"
                      place="right"
                      content="Refresh"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="grid mb-8 md:grid-cols-3 grid-cols-2 sm:px-0 sm:grid-cols-3 lg:grid-cols-4 gap-5 lg:px-0 md:px-10 px-5 lg:gap-x-[30px] lg:gap-y-[30px] md:gap-x-[30px] md:gap-y-[30px] mt-[30px] animate-slideDown2">
              {displayContent == "All"
                ? renderAllCards()
                : displayContent == "popular"
                ? renderPopularCards()
                : renderLatestCards()}
            </div>
            <div className="flex justify-center items-center">
              {next < Restaurant?.length && (
                <button
                  className="mt-5 outline_button-rounded"
                  onClick={handleMoreImage}
                >
                  Load more
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <div className='grid animate-slideDown2'>
        <Tabs
						activeTabIndex={ activeTabIndex }
						setActiveTabIndex={ setActiveTabIndex }
						tabUnderlineWidth={ tabUnderlineWidth }
						setTabUnderlineWidth={ setTabUnderlineWidth }
						tabUnderlineLeft={ tabUnderlineLeft }
						setTabUnderlineLeft={ setTabUnderlineLeft }
						tabsData={ tabsData }
					/>
					{ renderContent() }

        </div> */}
      </MarketplaceStyle>

      <Footer />
    </Layout>
  );
};

export default Marketplace;
