import React, { useEffect, useState } from 'react';
import {
  Button, Dropdown, Layout, Modal, Text, Input,Tabs
} from 'components';
import NFTCollections from 'components/NFTCollections';
import {
  Colors, Icons, Images
} from 'constant';
import icons from 'constant/icons';
import { NFTCard } from 'components/Card';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';
import {getRestaurantNFTs, PayNow,delistNft, getnftstatus, PutonSale, declineoffer, getnftbyid,getlastsold, executeNfts,getsaleshistory, Placeabid,Makeoffer,getbidhistory} from '../../services/Nft';
import {getAllusers} from '../../services/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AddFavourites} from '../../services/favourites';
import {createSignature} from'../../services/http';
import { SET_NFT, SET_PROFILE } from '../../stores/User/userActionTypes';
import axios from 'axios';

import Loader from "react-js-loader";
import {useNavigate} from 'react-router-dom';
import ModalStyles from './styles';
import group27 from  '../../assets/images/Group27.svg';
import group28 from  '../../assets/images/Group28.svg';
import group29 from  '../../assets/images/Group29.svg';
import loadiing from  '../../assets/images/loading.gif';
import {addNetwork} from '../../stores/User/WalletAction';
import {connectFailed} from '../../stores/User/WalletAction';
import S3 from "react-aws-s3";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import loading from '../../assets/images/loading.gif';
import Web3 from 'web3';
import Styles from './stylestable';
import {PutonsaleModal} from './putonsalemodal';
import { useParams } from 'react-router-dom';
import templeteabi from '../../contractsABI/TempleteABI.json';
import {

    LineChart,

    Line,

    XAxis,

    YAxis,

    CartesianGrid,

    Tooltip,

  } from "recharts";
import moment from 'moment';
import CdTimerComp from './date-time-counter/CdTimerComp';
import useCountDown from "./date-time-counter/hooks/useCountDown";
// import { CaretUpFilled } from '@ant-design/icons';

const tabsData = [{ title: 'Offer history' }, { title: 'Bid history' }];
const PutonSaleNFT = () => {
    const data = [{date: '10/10', price: 1}, {date: '2/10', price: 12}, {date: '30/09', price: 18}, {date: '25/09', price: 25}, {date: '20/09', price: 28}, {date: '15/09', price: 30}];
//stripe payment
const stripePromise = loadStripe("pk_test_51LlQoyJJB6dbjlRdZsp94l6vWtRUK5L1vlCse22WBT1ncpbMlOtDRw02dwr5nwt6dTALUL9Vw7kOuzNUCSR6sRiY00CPQpdgM8");

const [clientSecret, setClientSecret] = useState("");
const [paymentStatus, setPaymentstatus] = useState(false);
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
 
  const getNFTstatus = async( ) =>{
    const res = await getnftstatus(nft._id, current_user.token);
    if(!res.data.status){
      toast("session expired");
      dispatch({type:SET_PROFILE, payload:''});
      dispatch(connectFailed());
    }else{
      handlePurchaseModal();
    }
  }

  const {id} = useParams();
  const [nft, setNft] = useState("");
  const handlegetnft = async()=>{
    const res = await getnftbyid(id)
    if(res.data.status){
      setNft(res.data.result)
    }
  }



  const wallet = useSelector((state) => state.WalletConnect);
  const navigate= useNavigate();
  const walletaddress = useSelector(selectors.walletAddress);
  const dispatch = useDispatch();
  const current_user = useSelector(selectors.userProfile);
  const createdat = nft && nft.createdAt.slice(0,10);
  const [open, setOpen] = useState(false);
  const [added, setAdded] = useState(false);
  const [putonSalePrice, setPutonsalePrice] = useState('');
  const [openLoader,setOpenLoader] = useState(false);
  const [showBidforsalemodal, setShowBidforsaleModal] = useState(false);
  const [showPlaceBidmodal, setShowPlaceBidModal] = useState(false);
  const [showMakeOfferModal,setShowMakeOfferModal] = useState(false);
  const [metamaskStatus, setMetamaskStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(false);
  const [instantsaleModal,setInstantsaleModal] = useState(false);
  const [showTimedmodal, setShowTimedModal] = useState(false);
  const [instantActive, setInstantActive] = useState(false);
  const [timedActive, setTimedActive] = useState(false);
  const [oneday, setOneday] = useState(false);
  const [twoday, setTwoday] = useState(false);
  const [threeday, setThreeday] = useState(false);
  const [daytime, setDay] = useState('');
  const [maticActive, setMaticActive] = useState(false);
  const [usdActive, setUsdActive] = useState(false);
  const [usdtActive, setUsdtActive] = useState(false);
  const [showModalPurchase,setShowModalPurchase] = useState(false);
  const [Bidprice, setBidprice] = useState('');
  const [salesHistory, setSalesHistory] = useState([]);
  const [salesData,setSalesData] = useState([]);
  const [availableMatic, setAvailablebalance] = useState('');
  const slcyear = createdat
  const createdyear = slcyear.slice(0,4)
  const slcdate = nft && nft.createdAt.toString();
  const createddate = slcdate.split('-').splice(2,1).join().slice(0,2);
  const slcmonth = createdat;
  const createdmonth = slcmonth.split('-').splice(1,1).join();
  const created_date = createdmonth+"/"+createddate +"/"+createdyear
  const [bidhistory,setBidHistory] = useState([]);
  const [offerhistory,setOfferHistory] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [allusers, setAllusers] = useState([]);
  const [days, hours, minutes, seconds] = useCountDown( new Date(nft.PutonsaleTime).toLocaleString());
  const [lastsoldNft, setLastnft] = useState('');
  const [nftowner, setNftowner] = useState('');
  const [offermethod, setOfferMethod] = useState('matic');
  // const isexpired = days+hours+minutes+seconds <= 0 ? false : true;
  // getting days in required format
  var date1 = new Date(created_date);

  var date2 = new Date();
  let currentdate = date2.getMonth()+1+"/"+ date2.getDate()+"/"+date2.getFullYear();
  const date3=new Date(currentdate);
  var Difference_In_Time = date3.getTime() - date1.getTime();
  
  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  
  const [res_nfts, setResNfts] = useState([]);

  const getAllUSers = async() =>{
    const res = await getAllusers();
    if(res.data.status){
      setAllusers(res.data.result)
    }
  }

  const getLastsoldnft= async()=>{
    const res  =  await getlastsold(id);
    if(res.data.status){
      setLastnft(res.data.result);
      setNftowner(res.data.owner);
    }
  }

  const getnfts = async () => {
    const res = await getRestaurantNFTs(nft && nft.OwnedBy)
    setResNfts(res.data.result)
    const new_data  = res.data.result
    const nft_new=  new_data.filter((item) => item.NFTName == nft.NFTName);
    dispatch({
      type: "SET_NFT",
      payload:nft_new[0],
    });
  }

  const DeclineOffer = async(item)=>{
    const res = await declineoffer(item._id)
    getBidHistory();

  }

  const [maticToUsd, setMatictousd] = useState('');

  const getmatictoUsd = async() => {
    const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",{headers: {"Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods':['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
    }})
    setMatictousd(matictousd.data['matic-network'].usd)
  }

  const getavailablebalance = async() =>{
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance }=wallet;
    const tokenInst = await web3.eth.getBalance(address);
    const matic_balance = await web3.utils.fromWei(tokenInst,'ether');
    const bal = matic_balance;
    setAvailablebalance( bal.length > 3 ? bal.slice(0,5) : bal);
  }

  const getBidHistory =async()=>{
    const res = await getbidhistory(id);
    if(res.data.status){
      const data_ = res.data.result;
      const bids = data_.filter((item) => item.type =='bid')
      setBidHistory(bids);
      const offers = data_.filter((item) => item.type =='makeoffer');
      setOfferHistory(offers);
    }else{
      toast("something went wrong")
    }
  }

  const GetSalesHistory = async() =>{
    const res = await getsaleshistory(id);
    const val = res.data.result;
    let arr=[]
    const results = val.map((item) =>{
      const newarr = {date:item.SoldTime.slice(0,5), price:item.NFTPrice}
      arr.push(newarr);
    })
    const users = await getAllusers();
    setSalesHistory(arr);
    if(users.data.status){
      setAllusers(users.data.result);
      const all_users = users.data.result;
      const filteredusers = all_users.filter((item) => item.userType == "user")
      const new_arr = []
      val.map((item, index) => {
        filteredusers.map((i) => {
          if(item.walletAddress == i.walletAddress ){
            val[index].userName = i.userName;
            val[index].profilePicture = i.profilePicture;
            new_arr.push(val[index])
          }
        })
      })
      setSalesData(new_arr);
    }
    
  }
  useEffect(()=>{
    setAdded(false);
    getmatictoUsd();
    getnfts();
    handlegetnft();
    getBidHistory();
    GetSalesHistory();
    getAllUSers();
    getLastsoldnft();
   
  },[])
  useEffect(()=>{
    getavailablebalance();
  },[wallet])

  const today_ = Date.now()
  const b_today = new Date(today_)
  const month = (b_today.getMonth() + 1).toString().padStart(2, "0");
  const day   = b_today.getDate().toString().padStart(2, "0");
  const today_date = day+"/"+month+"/"+b_today.getFullYear()
  const c = new Date(nft.NFTEndDate)

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day,mnth,date.getFullYear()].join("/");
  }
  const converted = convert(c)
  const date_comparison = today_date >= converted;

  const [showModalPutonsale, setShowModalPutOnsale] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
 
  const handleListMyNFT = () =>{
    if(instantActive){
        setInstantsaleModal(true);
    }else{
        setShowTimedModal(true);
    }
  }
  const [price, setPrice] = useState('');

  const BuyNFT = async(id) =>{
    setShowModalPurchase(false);
    setMetamaskStatus(true)
    setOpenLoader(true)
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance }=wallet;
    let coinaddress;
    if(maticActive){
      coinaddress = process.env.REACT_APP_USDT
    }else if(usdtActive){
      coinaddress = process.env.REACT_APP_USDT
    }else{
      coinaddress = process.env.REACT_APP_USDC
    }

    let paymentValue;
    if(maticActive){
      paymentValue = 1;
    }else if(usdActive){
      paymentValue = 2;
    }else{
      paymentValue = 3;
    }

    let price_;
      if(maticActive){
        price_= (Number(nft.NFTPrice) /maticToUsd).toFixed(2);
      }else if(usdActive){
        price_ = nft.NFTPrice;
      }else{
        price_ = nft.NFTPrice;
      }

    const nftPrice=web3.utils.toWei(nft.NFTPrice, "ether") 
    var date = Date.now()
    const tuple= await createSignature({_sellerAddress:nft.walletAddress,_buyerAddress:address,
       _nftAddress: nft &&  nft.contractAddress, _stableCoinAddress:coinaddress, 
       amount: nftPrice, tokenId:nft.TokenId,fee: 10, paymentType: paymentValue, nonce:date });    
    const nftSigner =[nft.walletAddress,address, nft &&  nft.contractAddress,coinaddress,nftPrice,nft.TokenId,10,paymentValue,date,tuple.data.result]
    if(usdActive){
      const usdc_pay = await usdcInstance.methods.allowance(address, process.env.REACT_APP_OKU_GOVERNANCE).call();
      if(usdc_pay < price_ ){
        try{
          const usdc_approve = await usdcInstance.methods.approve( process.env.REACT_APP_OKU_GOVERNANCE, '999999999999999999999999999999').send({from:address});

        }catch(err){
          setOpenLoader(false);
          setShowModalPurchase(false)
          toast("User cancelled buying Nft ")
        }
      }
    }else if(usdtActive){
      const usdt_pay = await usdtInstance.methods.allowance(address, process.env.REACT_APP_OKU_GOVERNANCE).call();
      if(usdt_pay < price_ ){
        try{
          const usdc_approve = await usdtInstance.methods.approve( process.env.REACT_APP_OKU_GOVERNANCE, '999999999999999999999999999999999').send({from:address});
        }catch(err){
          setOpenLoader(false);
          setShowModalPurchase(false)
          toast("User cancelled buying Nft ")
        }
      }
    }
    setContractStatus(true)
    setMetamaskStatus(false)
    try{
      const buy_nft = await marketplace.methods.executeOrder(nftSigner).send({from:address,value:maticActive ? nftPrice : 0})
      const transactionHash_ =  buy_nft.transactionHash;
      if(buy_nft.status){
        paynft(id, transactionHash_)
      }else{
        setOpenLoader(false);
        setShowModalPurchase(false);
      }
    }catch(err){
      toast("failed to buy nft")
      setOpenLoader(false);
    }
  }

  const paynft = async(id, transactionHash_) =>{
    let paymentmethod;
    let amount_;
    if(maticActive){
      paymentmethod = "matic";
      amount_ = (Number(nft.NFTPrice)/maticToUsd).toFixed(2);
    }else if(usdActive){
      paymentmethod = "usdc";
      amount_ = nft.NFTPrice;

    }else if(usdtActive){
      paymentmethod = "usdt";
      amount_ = nft.NFTPrice;
    }else{
      paymentmethod = "card";
      amount_ = nft.NFTPrice;

    }
    const values= {
      from: wallet.address,
      TokenId: nft.TokenId,
      walletAddress: nft.walletAddress,
      contractAddress: nft.contractAddress,
      transactionHash:transactionHash_,
      paymentMethod: paymentmethod, 
      paymentAmount:amount_,
    }
    const tokens = current_user.token;
    const res = await executeNfts(id, values, tokens);
    if(res.data.status){
      setOpenLoader(false);
      handlegetnft();
      toast("Buying nft successfull")
    }else{
      toast("something went wrong")
    }
  }

  const putOnSaleContract = async() =>{
    setInstantsaleModal(false);
    setOpenLoader(true);
    setContractStatus(true)
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance }=wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    const puonsale_ = await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId, true).send({from:address});
    if(puonsale_.status){
      handlePutonSale();
    }else{
      toast("failed")
    }
  
  }

  const handlePutonSale = async() =>{
    setContractStatus(false);
    setCompletionStatus(true);

    let values;
    if(instantActive){
      values={
        type:"instantsale",
        Price:putonSalePrice,
        PutonsaleTime:''
      }
    }else{
      values={
        type:"timedsale",
        Price: putonSalePrice,
        PutonsaleTime: daytime
      }
    }
    const res = await PutonSale(id, values, current_user.token)
    if(res.data.status){
      handlegetnft();
      setOpenLoader(false);
      setShowTimedModal(false);
      setCompletionStatus(false)
      setShowModalPutOnsale(false);
      setInstantsaleModal(false);
      setShowBidforsaleModal(false);
      toast("Put on sale success")
    }else{
      toast("Put on sale failed")
    }
  }
  const handleDayOne = () =>{
    const new_date = moment().add(1, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }
  const handleDayTwo = () =>{
    const new_date = moment().add(2, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }
  const handleDayThree = () =>{
    const new_date = moment().add(3, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }

  const PlaceABid = async(hash_) =>{
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance }=wallet;
    const values = {
      BidBy: current_user.user.id,
      type: "bid",
      NFTId: nft._id,
      NFTName: nft.NFTName,
      BidPrice: Bidprice && Bidprice,
      walletAddress: wallet.address,
      transactionHash:hash_
      
    }
    const res = await Placeabid(values);
    if(res.data.status){
      setShowPlaceBidModal(false);
      toast("You bidded for this nft")
    }else{
      toast("something went wrong")
    }
  }

  const MakeAnOffer = async(hash_) =>{
    setContractStatus(false);
    setCompletionStatus(true);
    const price_1 = (Number(Bidprice) * maticToUsd).toFixed(2)
    let method_;
    let price_;
    if(offermethod == 'matic'){
      method_ ='matic'
      price_= (Number(Bidprice) /maticToUsd).toFixed(2);
    }else if(offermethod == 'usdc'){
      method_ ='usdc'
      price_= (Number(Bidprice)).toFixed(2);
    }else{
      method_ ='usdt'
      price_= (Number(Bidprice)).toFixed(2);
    }
    const values = {
      OfferBy: current_user.user.id,
      type: "makeoffer",
      NFTId: nft._id,
      NFTName: nft.NFTName,
      BidPrice: price_,
      walletAddress: wallet.address,
      transactionHash:hash_,
      OfferMethod: method_
    }
    const res = await Makeoffer(values);
    if(res.data.status){
      setOpenLoader(false);
      setCompletionStatus(false)
      getBidHistory();
      handlegetnft();
      toast("Make Offer succes");
      setShowMakeOfferModal(false);
    }
  }

  const handlePlaceBid =  async() =>{
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;
    
    let price_= (Number(Bidprice) /maticToUsd).toFixed(2);
    const nftPrice=web3.utils.toWei(price_, "ether") 
    const ress =  await wrappedMatic.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nftPrice).send({from:address})
    if(ress.status){
      PlaceABid(ress.transactionHash);
    }else{
      toast("Place bid failed")
    } 
  }

  const handleSettle = async(item) =>{
    setOpenLoader(true);
    setMetamaskStatus(true)
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;
    const nftPrice=web3.utils.toWei(item.BidPrice, "ether") 
    const coinaddress = process.env.REACT_APP_WRAPPED_MATIC;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try{
      const approval_ =  await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId, true).send({from:address})

      var date = Date.now()
      const tuple= await createSignature({_sellerAddress:address,_buyerAddress:item.walletAddress,
        _nftAddress: nft &&  nft.contractAddress, _stableCoinAddress:coinaddress, 
        amount: nftPrice, tokenId:nft.TokenId,fee: 10, paymentType: 4, nonce:date });

      const nftSigner =[address,item.walletAddress, nft &&  nft.contractAddress,coinaddress,nftPrice,nft.TokenId,10,4,date,tuple.data.result]
      setContractStatus(true);
      setMetamaskStatus(false);
      const sell_nft = await marketplace.methods.executeOrder(nftSigner).send({from:address,value:0})
      
      if(sell_nft.status){
        setContractStatus(false);
        setCompletionStatus(true)
        const values= {
          walletAddress : item.walletAddress,
          from: wallet.address,
          TokenId: nft.TokenId,
          contractAddress: nft.contractAddress,
          transactionHash: sell_nft.transactionHash,
          paymentMethod: "matic", 
          paymentAmount: item.BidPrice,
        }
        const tokens = current_user.token;
        const res = await executeNfts(id, values, tokens);
        if(res.data.status){
          handlegetnft();
          setOpenLoader(false);
        }
      }
    }catch(err){
      console.log("error:",err)
      setOpenLoader(false);
      toast("user cancelled approval")
    }
  }

  const handlemakeOffer = async() =>{
    setShowMakeOfferModal(false);
    setOpenLoader(true);
    setMetamaskStatus(true);
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;
    
    let price_= (Number(Bidprice) /maticToUsd).toFixed(2);
    
    let ress;
    if(offermethod == 'matic'){
      const nftPrice=web3.utils.toWei(price_, "ether") 
      setMetamaskStatus(false);
      setContractStatus(true)
       ress =  await wrappedMatic.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nftPrice).send({from:address})
    }else if(offermethod == 'usdc'){
      const nftPrice=web3.utils.toWei(Bidprice, "ether") 
      setMetamaskStatus(false);
      setContractStatus(true)
       ress =  await usdcInstance.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nftPrice).send({from:address})

    }else if(offermethod == 'usdt'){
      const nftPrice=web3.utils.toWei(Bidprice, "ether") 
      setMetamaskStatus(false);
      setContractStatus(true)
       ress =  await usdtInstance.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nftPrice).send({from:address})
    }

    if(ress.status){
      MakeAnOffer(ress.transactionHash);
    }else{
      toast("Make offer failed")
    }
  }

  const handleAccept = async(item) =>{
    setOpenLoader(true);
    setMetamaskStatus(true)
    
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;

    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try{
    const putonsale_ = await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId,true).send({from:address});
      
    const nftPrice=web3.utils.toWei(item.BidPrice, "ether") 
    let sell_nft;
    if(item.OfferMethod == 'matic'){
      const coinaddress = process.env.REACT_APP_WRAPPED_MATIC;
      var date = Date.now()
      const tuple= await createSignature({_sellerAddress:address,_buyerAddress:item.walletAddress,
        _nftAddress: nft &&  nft.contractAddress, _stableCoinAddress:coinaddress, 
        amount: nftPrice, tokenId:nft.TokenId,fee: 10, paymentType: 4, nonce:date });
  
      const nftSigner =[address,item.walletAddress, nft &&  nft.contractAddress,coinaddress,nftPrice,nft.TokenId,10,4,date,tuple.data.result]
      setContractStatus(true)
      setMetamaskStatus(false);
       sell_nft = await marketplace.methods.executeOrder(nftSigner).send({from:address,value:0})
    }else if(item.OfferMethod == 'usdc'){
      const coinaddress = process.env.REACT_APP_USDC;
      var date = Date.now()
      const tuple= await createSignature({_sellerAddress:address,_buyerAddress:item.walletAddress,
        _nftAddress: nft &&  nft.contractAddress, _stableCoinAddress:coinaddress, 
        amount: nftPrice, tokenId:nft.TokenId,fee: 10, paymentType: 2, nonce:date });
  
      const nftSigner =[address,item.walletAddress, nft &&  nft.contractAddress,coinaddress,nftPrice,nft.TokenId,10,2,date,tuple.data.result]
      setContractStatus(true)
      setMetamaskStatus(false);
       sell_nft = await marketplace.methods.executeOrder(nftSigner).send({from:address,value:0})
    }else if(item.OfferMethod == 'usdt'){
      const coinaddress = process.env.REACT_APP_USDT;
      var date = Date.now()
      const tuple= await createSignature({_sellerAddress:address,_buyerAddress:item.walletAddress,
        _nftAddress: nft &&  nft.contractAddress, _stableCoinAddress:coinaddress, 
        amount: nftPrice, tokenId:nft.TokenId,fee: 10, paymentType: 3, nonce:date });
  
      const nftSigner =[address,item.walletAddress, nft &&  nft.contractAddress,coinaddress,nftPrice,nft.TokenId,10,3,date,tuple.data.result]
      setContractStatus(true)
      setMetamaskStatus(false);
       sell_nft = await marketplace.methods.executeOrder(nftSigner).send({from:address,value:0})
    }
   
      if(sell_nft.status){
        setContractStatus(false);
        setCompletionStatus(true)
        const values= {
          walletAddress : item.walletAddress,
          from: wallet.address,
          TokenId: nft.TokenId,
          contractAddress: nft.contractAddress,
          transactionHash: sell_nft.transactionHash,
          paymentMethod: item.OfferMethod, 
          paymentAmount: item.BidPrice,
        }
        const tokens = current_user.token;
        const res = await executeNfts(id, values, tokens);
        if(res.data.status){
          toast("your nft was sold")
          handlegetnft();
          setOpenLoader(false);
        }else{
          toast("Failed");
        }
      }
    }catch(err){
      toast("Approval failed")
      setOpenLoader(false);
    }
  }

  const handleDelist = async() =>{
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try{
      const putonsale_ = await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId,false).send({from:address});
        
      const res = await delistNft(id);
      if(res.data.status){
        handlegetnft();
        toast("delist success")
      }else{
        toast("delist failed")
      }
    }catch(err){
      toast("Failed to connect with contract")
    }
  }
  return (
    <Layout>
      <div className='h-fit overflow-hidden'>
        <div className='flex flex-col items-center '>
          <div className='w-full px-5 xl:w-defaultContent xl:px-0'>
            <div className='xs:flex mt-[100px] xs:space-x-[30px]'>
              <div className='w-full md:w-[350px]'>
                <div className='rounded-md overflow-hidden shadow-lg p-px bg-gradient-to-br from-white/40 to-transparent md:w-[350px]'>
                  <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`} alt='profile' className='h-[350px] md:w-[350px]  rounded-md' />
                </div>
                <div className='flex justify-between items-center mt-5 bg-white/5 p-5 rounded-t border-solid border-b-[1px] border-b-white/10'>
                  <Text text={`Details`} fontWeight='800' fontSize='20px' color={ Colors.white2 } />
                </div>
                <div className='pb-5 pl-5 pr-5 pt-1 bg-white/5 rounded-b'>
                 <div className='flex justify-between mt-5'>
                    <Text  text="Contract Address"/>
                    <a href={`https://mumbai.polygonscan.com/address/${nft.contractAddress}`} target="_blank">
                      <Text  text={nft && nft.contractAddress.slice(0,5)+"..."+nft.contractAddress.slice(-3)} color={ Colors.okuGreen }/>
                    </a>
                 </div>
                 <div className='flex justify-between mt-5'>
                    <Text  text="Token id"/>
                    <Text  text={nft.TokenId} />
                 </div>
                 <div className='flex justify-between mt-5'>
                    <Text  text="Token Standard"/>
                    <Text  text={"ERC 1155"}/>
                 </div>
                 <div className='flex justify-between mt-5'>
                    <Text  text="Meta Data"/>
                    <Text  text={"--"}/>
                 </div>
                 <div className='flex justify-between mt-7'>
                    
                 </div>
                </div>
              </div>
              <div className='w-full'>
                <div className='flex items-center justify-between mt-2 xs:mt-0'>
                  <Text text={nft.NFTName} fontType='h4' fontFamily='lora' fontWeight='700' fontSize='32px' />
                  {nft && nft.Likes.includes( current_user.user && current_user.user.id) ? 
                  <button className='flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px] bg-white/5 rounded' >
                  <BsStarFill size={18} color="#EF8440"/>
                    <span>Added to Favourite</span>
                </button>
                :
                  <button className='flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px] bg-white/5 rounded' onClick={() =>{ NftAddtoFavourite()}}>
                    <BsStar size={18} color="#EF8440"/>
                    <span>Add to Favourite</span>
                  </button>
                }
                </div>
                <div className='flex items-center space-x-2 mb-1'>
                  <Text text='Owned by' fontWeight='400' />
                  <Text text={nft.OwnedBy} fontWeight='700' color='lemonChiffon' />
                </div>
                <div className='flex items-center space-x-3 mb-4'>
                  <Text text={nft.NFTCollections ? nft.NFTCollections  : "nft name"} fontWeight='300' />
                  <span className='w-[5px] h-[5px] rounded-full bg-lightSilver' />
                  <Text text= {Difference_In_Days != 0 ? `Created ${Difference_In_Days} days ago` : "Created Today"} fontWeight='300' className='opacity-50' />
                </div>
                <Text text='Description' fontWeight='800' fontSize='20px' color={ Colors.white2 } className='mb-1' />
                <Text
                  fontWeight='300'
                  className='mb-3'
                  text={nft.NFTDescription} />

                <Text 
                  text={`Sale ends ${nft.NFTEndDate ? nft.NFTEndDate : "--"} at ${nft.NFTEndTime ? nft.NFTEndTime : "--" } GMT+7`}
                  color={ Colors.okuGreen }
                  fontWeight='800'
                  fontSize='20px'
                  className='bg-white/5 py-5 pl-5 rounded-t border-solid border-b-[1px] border-b-white/10' />
                <div className='flex justify-between items-center mb-5 p-5 bg-white/5 rouded-b'>
                  <div>
                    {/* <Text text='Price' fontWeight='300' className='opacity-40' /> */}
                    <div className='mb-2 mt-2'>
                    <div className='flex items-center space-x-2'>
                    <span className='w-[5px] h-[5px] rounded-full bg-lightSilver' />
                      <Text text='Benefits' fontWeight='300' />
                      <Text text={nft.benefits} fontWeight='700' color='lemonChiffon' />
                    </div>
                      {/* <div className='flex space-x-2'>
                        <img src={ Icons.polygon } className='' alt='currency' />
                        <Text text={(Number(nft.NFTPrice)/(maticToUsd)).toFixed(2)} className='font-extrabold' />

                        <img src={ Icons.dollar } className='' style={{width:"7%", height:"7%"}} alt='currency' />
                        <Text text={((Number(nft.NFTPrice)).toFixed(2))}/>
                        <img src={ Images.USDollar } className='' style={{width:"7%", height:"7%"}} alt='currency' />
                        <Text text={Number(nft.NFTPrice).toFixed(2)}/>
                        <img src={ Images.Usdt } className='' style={{width:"7%", height:"7%"}} alt='currency' />
                        <Text text={Number(nft.NFTPrice).toFixed(2)}/>
                      </div> */}

                    </div>
                    <div className='flex items-center space-x-2'>
                    <span className='w-[5px] h-[5px] rounded-full bg-lightSilver' />
                      <Text text='Restaurant Royalty' fontWeight='300' />
                      <Text text='5%' fontWeight='700' color='lemonChiffon' />
                    </div>
                  </div>
                  {nft.PutonsaleTime ? 
                  <CdTimerComp  targetdate={nft.PutonsaleTime}  />
                  : null
                  }
                  
                  {date_comparison && nft.status != 'sold' ? 
                  <Button label='Sale Ended' backgroundColor='lemonChiffon' textColor='red'/>
                  :
                  <>
                  {nft.status && nft.status == "sold" && wallet.address == nft.walletAddress ? <Button label='Put On Sale' backgroundColor='lemonChiffon' textColor='darkBlue'  onClick={()=>setShowModalPutOnsale(true)}/>
                  :
                  <>
                  {!nft.paidBy  && nft.status != "onsale" && wallet.address == nft.walletAddress ? 
                  <Button label='Put On Sale ' backgroundColor='lemonChiffon' textColor='darkBlue' onClick={ () => {
                    if(current_user.user && wallet.address){
                      handlePutonSale()
                    }else if(!current_user.user && !wallet.address){
                      navigate('/marketplace');
                      window.location.reload();
                    }else if(current_user.user && !wallet.address){
                      toast("Connect your wallet")
                    }
                  }} />
                  :
                  null
                  }
                  </>
                  }
                  </>
                  }
                  {wallet.address == nft.walletAddress && nft.status == 'onsale' ? 
                  <Button label='Delist' backgroundColor='lemonChiffon' textColor='darkBlue' onClick={ () => {
                    if(current_user.user && wallet.address){
                      handleDelist();
                    }else if(!current_user.user && !wallet.address){
                      navigate('/marketplace');
                      window.location.reload();
                    }else if(current_user.user && !wallet.address){
                      toast("Connect your wallet")
                    }
                  }} />
                  :
                  <>
                  {nft.status == "onsale" && !nft.PutonsaleTime ? 
                  <Button label='Buy' backgroundColor='lemonChiffon' textColor='darkBlue' onClick={ () => {
                    if(current_user.user && wallet.address){
                      setShowModalPurchase(true);
                    }else if(!current_user.user && !wallet.address){
                      navigate('/marketplace');
                      window.location.reload();
                    }else if(current_user.user && !wallet.address){
                      toast("Connect your wallet");
                    }
                  }} />
                  :
                  <>{nft.status == 'onsale' && nft.walletAddress != wallet.address && days+hours+minutes+seconds > 0  ? 
                  <Button label='Place Bid' backgroundColor='lemonChiffon' textColor='darkBlue' onClick={ () => {
                    if(current_user.user && wallet.address){
                      setShowPlaceBidModal(true);
                    }else if(!current_user.user && !wallet.address){
                      navigate('/marketplace');
                      window.location.reload();
                    }else if(current_user.user && !wallet.address){
                      toast("Connect your wallet")
                    }
                  }} />
                  :
                  <>
                  {nft.walletAddress != wallet.address && nft.status != 'onsale'  ?
                  <Button label='Make offer' backgroundColor='lemonChiffon' textColor='darkBlue' onClick={ () => {
                    if(current_user.user && wallet.address){
                      setShowMakeOfferModal(true);
                    }else if(!current_user.user && !wallet.address){
                      navigate('/marketplace');
                      window.location.reload();
                    }else if(current_user.user && !wallet.address){
                      toast("Connect your wallet")
                    }
                  }} />
                  :
                  null }
                  </>
                }
                  </>
                  }
                  </>
                  }
                  </div>
                <div className='bg-white/5 rounded '>
                    <div className="flex justify-between py-5 pl-5 rounded-t border-solid border-b-[1px] border-b-white/10">
                    <Text text='Sales History' color={ Colors.white2 }  fontWeight='800' fontSize='20px' />
                    {/* <Dropdown className="mr-2 rounded-t border-solid border-[1px] border-white/10"></Dropdown> */}
                    <select style={{color:"white",opacity:"0.8", fontSize:"16px", appearance:"none", fontWeight:"bold"}} className='selectbox flex items-center justify-between w-fit space-x-2 py-[4px] px-[15px] color-white bg-white/5 rounded mr-5'>
                        <option>All items</option>
                    </select>
                    </div>
                  
                   <LineChart  
                    data={salesHistory && salesHistory}
                    width={700}
                    height={230}
                    margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5
                    }}
                    >
                    {/* <CartesianGrid strokeDasharray="5 5" /> */}
                    <XAxis dataKey="date" />
                    <YAxis dataKey="price"/>
                    <Tooltip />
                    <Line
                    type="monotone"
                    dataKey="price"
                    stroke={Colors.okuGreen}
                    fill='#2795e9'
                    />
                    </LineChart>
                    <Tabs
                    activeTabIndex={ activeTabIndex }
                    setActiveTabIndex={ setActiveTabIndex }
                    tabUnderlineWidth={ tabUnderlineWidth }
                    setTabUnderlineWidth={ setTabUnderlineWidth }
                    tabUnderlineLeft={ tabUnderlineLeft }
                    setTabUnderlineLeft={ setTabUnderlineLeft }
                    tabsData={ tabsData }
                    wrapperClassName='mt-[3px] mb-1 w-full'
                    btnClassName='w-full py-[8px] text-[18px] font-bold font-Lora'
                  />
                  {/* {activeTabIndex == 0 ? 
                  <div className='w-full'>
                  </div>
                  :null
                  } */}
                  {activeTabIndex == 1 ? 
                  <>
                  {bidhistory && bidhistory.map((item) => {
                    return(
                      <div className='w-full flex items-center justify-between mr-15'>
                        <div className='ml-5'>
                          <Text text={item.walletAddress.slice(0,3)+"..."+item.walletAddress.slice(-3)} />
                          <Text text={item.BidPrice +" MATIC"} />
                        </div>
                        
                        <Text text={new Date(item.createdAt).toLocaleDateString()} />
                        <a href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`} target='_blank'>
                          <Text text={item.transactionHash ? item.transactionHash.slice(0,3)+"..." + item.transactionHash.slice(-3) : "--"} />
                        </a>
                        {/* && days+hours+minutes+seconds <= 0 */}
                        {nft.walletAddress == wallet.address && nft.status != 'sold'  ?
                        <div className='flex '>
                          <Button label="Settle" height='30px' width='100px' backgroundColor='green' className="mr-2" onClick={() => handleSettle(item)}/>
                          <Button label="Reject" height='30px' width='100px' backgroundColor='red'/>
                        </div>
                        :null
                        }
                      </div>
                    )
                  })
                  
                  }
                  </>
                  :null
                  }
                  {activeTabIndex == 0 ? 
                  <>
                  {/* <p>offer history</p> */}
                  {offerhistory && offerhistory.map((item) => {
                    return(
                      <div className='w-full flex items-center justify-between mr-15'>
                        <div className='ml-5'>
                          <Text text={item.walletAddress.slice(0,3)+"..."+item.walletAddress.slice(-3)} />
                          <Text color={Colors.okuGreen} text={item.BidPrice + " "+ `${item.OfferMethod ? item.OfferMethod : '-'}` } />
                        </div>
                        
                        <Text text={new Date(item.createdAt).toLocaleDateString()} />
                        <a href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`}  target='_blank'>
                        <Text text={item.transactionHash.slice(0,3)+"..."+item.transactionHash.slice(-3)} />
                        </a>
                       
                        {nft.walletAddress == wallet.address && nft.status == 'sold' && item.status != 'inactive' ?
                        <div className='flex '>
                          <Button label="Accept" height='30px' width='100px' backgroundColor='green' className="mr-2" onClick={() => handleAccept(item)}/>
                          <Button label="Decline" height='30px' width='100px' backgroundColor='red' onClick={() =>DeclineOffer(item)}/>
                        </div>
                        :null
                        }
                      </div>
                    )
                  })
                  
                  }
                  </>
                  :null
                  }
                </div>
                
                
              </div>
              
            </div>
            <div className='p-5 w-full bg-white/5 rounded mt-5 mb-5 tabledata'>
            <Text text='Transaction History' color={ Colors.white2 } fontWeight='500' fontSize='20px' />
            <Styles>
               
                    <table className='table-auto mt-5'>
                    <thead className='table-head'>
                        <tr>
                            <th >Activity</th>
                            <th >Hash</th>
                            <th >User</th>
                            <th >Price</th>
                            <th >From</th>
                            <th >To</th>
                            <th >Time</th>

                        </tr>
                    </thead>
                    {salesData && salesData.map((item) =>{
                  return(
                    <tbody className='table-body'>
                       <tr className='table-row'>
                            <td >{item.type ? item.type : "transer"}</td>
                            <td><a href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`} target='_blank'>{item.transactionHash.slice(0,3)+"..." + item.transactionHash.slice(-3)}
                              </a></td>
                            <td >
                                <div className='cusprofile'>
                                    <img src={ item.profilePicture ? `${process.env.REACT_APP_S3_URL}${item.profilePicture}`: Images.Profile }  alt='currency'className='mr-1 w-6 h-6'  />
                                    <span>{item.userName ? item.userName : ""}</span>
                                </div>
                            </td>
                            <td  className='price'>
                              {item.paymentMethod == 'matic' ? 
                            <img src={ Icons.polygon }   alt='currency'className='mr-1 '  />
                            :null
                            }
                            {item.paymentMethod == 'usdc' ? 
                            <img src={Images.USDollar }   alt='currency'className='mr-1 '  />
                            :null
                            }
                            {item.paymentMethod == 'usdt' ? 
                            <img src={ Images.Usdt }   alt='currency'className='mr-1 '  />
                            :null
                            }
                            <span>{item.NFTPrice}</span>
                            </td>
                            <td>{item.from ? item.from.slice(0,3)+"..."+item.from.slice(-3): '0x0...000'}</td>
                            <td>{item.walletAddress ? item.walletAddress.slice(0,3)+"..."+item.walletAddress.slice(-3): '--'}</td>
                            <td>{item.SoldTime}</td>
                            
                       </tr>
                    </tbody>
                  )})}
			            </table>
                 
                
            </Styles>
            </div>
            
          </div>
        </div>
        {open ? 
            <Loader type="spinner-cub" bgColor={"black"}  color={'black'} size={80} />
            : ""
        }
      </div>

      <Modal
        visible={ showModalPutonsale }
        onClose={ () =>{ setShowModalPutOnsale(false);} }
        contentClassName='w-full xs:w-[540px] h-full xs:h-[650px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Put On Sale' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='500' fontSize='26px' />

            <div className='flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md'>
              <div className='rounded-md overflow-hidden to-transparent w-[100px]'>
                <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}alt='profile' className='h-[100px] w-[150px]  rounded-md' />
              </div>
              <div className='w-full'>
                <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='500' fontSize='18px' className="mb-2" />
                <div className='flex'>
                    <img src={ nftowner && nftowner.profilePicture ? `${process.env.REACT_APP_S3_URL}${nftowner.profilePicture}`: Images.Profile }  alt='currency'className='mr-1 w-6 h-6'  />
                    <Text text={"@" + nftowner && nftowner.userName} color='black' fontFamily='lora' fontWeight='500' fontSize='16px' />
                </div>
                <div className='flex items-center justify-between space-x-2 mr-5'>
                  <Text text='Last Sold' fontWeight='300' color='black' />
                  <div className='flex'>
                    {lastsoldNft.paymentMethod == "matic" ?
                    <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdt" ?
                    <img src={ Images.Usdt }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdc" ?
                    <img src={ Images.USDollar }  alt='currency'className='mr-1'  />
                    :null
                    }
                    <Text text={lastsoldNft.NFTPrice+" "+(lastsoldNft && lastsoldNft.paymentMethod && lastsoldNft.paymentMethod).toUpperCase()}  fontWeight='700' color='black' />
                  </div>
                </div>
                <div className='flex items-center justify-between  space-x-2 mr-5'>
                  <Text text='Owned by' fontWeight='300' color='black' />
                  <Text text={nft.OwnedBy}  fontWeight='700' color='black' />
                </div>
              </div>
            </div>

            <div className='pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1'>
              <div className='flex items-center justify-between mt-5'>
                <Text text='Set Reserve Price' color='black' fontWeight='700' />
                {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-start space-between mt-1'>
                  <div className='flex items-center ml-2'>
                    <Text text={`Make sure  you have enough polygon to perform the network fee`} color='black' fontWeight='300' fontSize='15px' />
                  </div>
                </div>
               
              </div>
             
              <div className='inbutblock flex w-full xs:w-[300px]  items-center justify-between mt-2  border-solid border-[1px] border-netralGrey rounded-md h-10 '>

                <input textColor="black" className='inputbox' placeholder='0' onChange={(e) => setPutonsalePrice(e.target.value)}/>
                <div className='flex mr-4 '>
                  <img src={ Icons.dollar }   alt='currency'className='mr-1 w-5 h-5'  />
                  <Text text='USD' color='black' fontWeight='800' fontSize='16px' />
                </div>
              </div>
              
            </div>

            <Text
              color='black'
              fontWeight='800'
              text='Select Listing options' 
              fontSize='16px'
              className='mb-2'
            />
             <Text
              color='black'
              fontWeight='300'
              text='Timed auction allows you to set time range for collectors to bid on action' 
              fontSize='16px'
            />
            <div style={{ minHeight:"80px", display:"flex", padding:"0 10%"}} className="mt-2">
              <div style={{backgroundColor: instantActive ? "#141E30" :"white", minHeight:"100%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{setInstantActive(true); setTimedActive(false)}}
              >
              <img src={instantActive ? Images.instantgreen : Images.instantblack  }  alt='currency'/>
                <h5 style={{color: instantActive? Colors.okuGreen :"black"}}>Instant Sale</h5>
              </div>
              <div style={{backgroundColor:timedActive ? "#141E30" :"white", minHeight:"100%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{setInstantActive(false); setTimedActive(true)}}
              >
              <img src={ timedActive ? Images.timedgreen : Images.timedblack }  alt='currency' />
                <h5 style={{color: timedActive? Colors.okuGreen :"black"}}>Timed Auction</h5>
              </div>
            </div>
            <Text
              color='black'
              fontWeight='800'
              text='Auction Days' 
              fontSize='18px'
            />
            <div style={{ minHeight:"40px", display:"flex", padding:"0 10%"}} className="mt-3">
              <div style={{backgroundColor: oneday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayOne();setOneday(true); setTwoday(false); setThreeday(false)}}
              >
                <h5 style={{color: oneday? Colors.okuGreen :"black"}}>1 Day</h5>
              </div>
              <div style={{backgroundColor:twoday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayTwo();setOneday(false); setTwoday(true); setThreeday(false)}}
              >
                <h5 style={{color: twoday? Colors.okuGreen :"black"}}>2 Days</h5>
              </div>
              <div style={{backgroundColor:threeday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayThree();setOneday(false); setTwoday(false); setThreeday(true)}}
              >
                <h5 style={{color: threeday? Colors.okuGreen :"black"}}>3 Days</h5>
              </div>
            </div>

            <Button label='List my NFT' disabled={!putonSalePrice || putonSalePrice < 0} className='w-full mt-2' width='100%' onClick={()=> handleListMyNFT()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={ instantsaleModal }
        onClose={ () =>{ setInstantsaleModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[400px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Fixed Price' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5 border-solid  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-5'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' disabled={true} placeholder='0' defaultValue={putonSalePrice && (Number(putonSalePrice) /  maticToUsd).toFixed(2)}/>
                <img src={ Icons.polygon }   alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-5'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" defaultValue={putonSalePrice && putonSalePrice} disabled={true} className='pricebox ml-2' placeholder='$0' />
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%' onClick={() => putOnSaleContract()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>
      <Modal
        visible={ showTimedmodal }
        onClose={ () =>{ setShowTimedModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[550px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Timed Auction' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Method`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <select className='pricebox'>
                    <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' defaultValue={putonSalePrice && (Number(putonSalePrice) /  maticToUsd).toFixed(2)} placeholder='0'/>
                <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" defaultValue={putonSalePrice && putonSalePrice} className='pricebox ml-2' placeholder='$0' />
              </div>

              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Date`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input id="meeting-time" name="meeting-time"   type="datetime-local" value={daytime ? daytime : ''} className='pricebox ml-2' onChange={(e) => setDay(e.target.value)}/>
             
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%' onClick={() => putOnSaleContract()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={ showBidforsalemodal }
        onClose={ () =>{ setShowBidforsaleModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[550px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Timed Auction' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Method`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <select className='pricebox'>
                    <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' placeholder='0'/>
                <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' placeholder='$0' />
              </div>

              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Date`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input type={"date"}  textColor="black" className='pricebox ml-2'  />
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%'/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={ showPlaceBidmodal }
        onClose={ () =>{ setShowPlaceBidModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[550px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Place Bid' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5  mb-1'>
              
            <div className='flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md'>
              <div className='rounded-md overflow-hidden to-transparent w-[250px]'>
                <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}alt='profile' className='h-[120px] w-[250px]  rounded-md' />
              </div>
              <div className='w-full'>
                <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='500' fontSize='18px' className="mb-2" />
                <div className='flex'>
                    <img src={ nftowner && nftowner.profilePicture ? `${process.env.REACT_APP_S3_URL}${nftowner.profilePicture}`: Images.Profile }  alt='currency'className='mr-1 w-6 h-6'  />
                    <Text text={"@" + nftowner && nftowner.userName} color='black' fontFamily='lora' fontWeight='500' fontSize='16px' />
                </div>
                <div className='flex items-center justify-between space-x-2 mr-5 mt-2'>
                  <div className='flex'>
                    {lastsoldNft.paymentMethod == "matic" ?
                    <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdt" ?
                    <img src={ Images.Usdt }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdc" ?
                    <img src={ Images.USDollar }  alt='currency'className='mr-1'  />
                    :null
                    }
                    <Text text={lastsoldNft.NFTPrice+" "+(lastsoldNft && lastsoldNft.paymentMethod && lastsoldNft.paymentMethod).toUpperCase()}  fontWeight='700' color='black' />
                  </div>
                </div>
              </div>
            </div>
              <div className='flex items-center ml-12  justify-between mt-3 w-full xs:w-[300px] border-solid border-[1px] border-netralGrey rounded-md h-12'>
                <Text text={`Available Balance`} color='black' fontWeight='300' fontSize='17px' />
                <div  className='flex'>
                  <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                  <Text text={availableMatic ?  availableMatic + "MATIC" : ''} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
                </div>
                
              </div>

              <div className='inbutblock1 flex w-full xs:w-[400px]  items-center justify-between mt-2  border-solid border-[1px] border-netralGrey rounded-md h-10 '>

                <input textColor="black" className='inputbox' placeholder='0' onChange={(e) => setBidprice(e.target.value)}/>
                <div className='flex mr-4 '>
                  <img src={ Icons.polygon }   alt='currency'className='mr-1 w-5 h-5'  />
                  <Text text='MATIC' color='black' fontWeight='800' fontSize='16px' />
                </div>
              </div>
            
              <div className='flex items-center  justify-between mt-3 w-full h-12'>
                <Text text={`Mininmum Bid Amount`} color='black' fontWeight='300' fontSize='17px' />
                <div  className='flex'>
                  <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                  <Text text={`${(nft.NFTPrice / maticToUsd).toFixed(2)} Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
                </div>
                
              </div>

              <div className='flex items-center justify-between mt-3 w-full  h-12'>
                <Text text={`Maximum bidder in the Auction`} color='black' fontWeight='300' fontSize='17px' />
                <div  className='flex'>
                  <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                  <Text text={`${10} Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
                </div>
                
              </div>
              
              
            </div>
            <Button label='Place Bid' className='w-full mt-2' width='100%' onClick={() => handlePlaceBid()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={ showMakeOfferModal }
        onClose={ () =>{ setShowMakeOfferModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[500px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Make an Offer' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-4'>
              
            <div className='flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md'>
              <div className='rounded-md overflow-hidden to-transparent w-[250px]'>
                <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}alt='profile' className='h-[120px] w-[250px]  rounded-md' />
              </div>
              <div className='w-full'>
                <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='500' fontSize='18px' className="mb-2 mt-2" />
                
                <div className='flex items-center justify-between space-x-2 mr-5 mt-5'>
                <Text text="Last Sold"  fontWeight='700' color='black' />
                  <div className='flex'>
                  {lastsoldNft.paymentMethod == "matic" ?
                    <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdt" ?
                    <img src={ Images.Usdt }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {lastsoldNft.paymentMethod == "usdc" ?
                    <img src={ Images.USDollar }  alt='currency'className='mr-1'  />
                    :null
                    }
                    <Text text={lastsoldNft.NFTPrice+" "+(lastsoldNft && lastsoldNft.paymentMethod && lastsoldNft.paymentMethod).toUpperCase()}  fontWeight='700' color='black' />
                  </div>
                </div>
              </div>
            </div>
            <Text className={"mt-4"} text="Once an offer is sent The owner will have 24 hours to accept. if the offer expires, the funds will be available in your marketplace balance."  fontWeight='300' fontSize={'14px'} color='black' />
              <div className='flex items-center justify-start  mt-2 w-full  border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' placeholder='0' onChange={(e) =>setBidprice(e.target.value)}/>
                {offermethod == 'matic' ? 
                <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                : null
                }
                {offermethod == 'usdc' ? 
                <img src={ Images.USDollar }  alt='currency'className='mr-1'  />
                : null
                }
                {offermethod == 'usdt' ? 
                <img src={ Images.Usdt }  alt='currency'className='mr-1'  />
                : null
                }
                {/* <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/> */}
                <select style={{cursor:"pointer"}} onChange={(e) => setOfferMethod(e.target.value)}>
                  <option label="MATIC" value="matic"></option>
                  <option label="USDC" value="usdc"></option>
                  <option label="USDT" value="usdt"></option>
                </select>
              </div>
            
              <div className='flex items-center  justify-between mt-3 w-full h-12'>
                <Text text={`Available Balance`} color='black' fontWeight='300' fontSize='17px' />
                <div  className='flex'>
                  <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                  <Text text={`${availableMatic && availableMatic} Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
                </div>
              </div>
            </div>
            <Button label='Place Bid' className='w-full' width='100%' onClick={() =>{handlemakeOffer()}}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      {openLoader && 
      <Modal visible={true} onClickClose={() =>setOpenLoader(false)}>
        <ModalStyles>
            <Text
                text={ 'Wallet Action' }
                color='black'
                fontType={ 'p' }
                fontWeight={ '700' }
                fontSize={ '28px' }
                fontFamily={ 'Lora' }
                className='title'
            />
            
            {/* { list.map((item, i) => ( */}
                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group29 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Request sent to wallet"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {metamaskStatus &&
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                        </div>
                    
                    
                </div>
                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group28 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Transaction Underway"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {contractStatus && 
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                        </div>
                    
                    
                </div>
                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group27 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Waiting for Confirmation"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'h5' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {completionStatus && 
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                      </div>
                    
                    
                </div>
            {/* )) } */}
            <Button
                label='CHECK IN SCAN'
                className='connect-wallet'
                // onClick={() => {window.open(``, "_blank")}}
                
                // onClick={ () => handleShowModalContactUs && handleShowModalContactUs() }
            />
     
        </ModalStyles>
      </Modal>

      }
      <Modal
        visible={ showModalPurchase }
        onClose={ () =>{ setShowModalPurchase(false);} }
        contentClassName='w-full xs:w-[540px] h-full xs:h-[650px]'
      >
          <div className=''>
         <Text text='Purchase NFT' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='600' fontSize='24px' />

         <div className='flex space-x-4 mb-1 mt-1'>
           <div className='rounded-md overflow-hidden  to-transparent w-[80px]'>
             <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}alt='profile' className='h-[80px] w-[80px]  rounded-md' />
           </div>
           <div>
           <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='700' fontSize='16px' className="mb-1" />
             <Text text={nft.NFTCollections} color='black' fontFamily='lora' fontWeight='700' fontSize='16px' />
             <div className='flex items-center space-x-2 mb-1 mt-1'>
               <Text text='Owned by' fontWeight='300' color='black' />
               <Text text={nft.OwnedBy}  fontWeight='700' color='black' />
             </div>
             <Text text='View item on chain' color={ Colors.prussianBlue } fontWeight='700' />
           </div>
         </div>

         <Text color={ Colors.darkBlue } text='Summary' fontFamily='lora' fontWeight='700' fontSize='20px' />
         <div className='pb-5 '>
           <div className='flex items-center  mt-1 border-[1px] border-b-netralGrey p-4'>
           <div className='flex items-center'>
           <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}  alt='currency'className='rounded mr-1 w-15 h-10'  />
            {!usdActive  && !usdtActive ?
             <Text text={`Purchasing NFT for  ${ (Number(nft.NFTPrice)/maticToUsd).toFixed(2) + " MATIC" }`} fontSize="42" color='black' fontWeight='700' className="ml-5 rounded"/>
             :
             <Text text={`Purchasing NFT for  ${usdActive ?  nft.NFTPrice+" USDC" :  nft.NFTPrice+" USDT" }`} fontSize="42" color='black' fontWeight='700' className="ml-5 rounded"/>
            }
             {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
           </div>
           </div>
     
         </div> 
         <div onClick={() => {setMaticActive(true);setUsdActive(false);setUsdtActive(false)}} className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded 'style={{marginBottom:"4%",backgroundColor: maticActive ? "#141E30" : "white",borderRadius:"1em",  width:"60%", margin:"auto"}}>
            <div className='flex items-center'>
              <img src={Icons.polygon}  alt='currency'className='rounded mr-1 w-7 h-7' />
              <Text text={`${(Number(nft.NFTPrice)/maticToUsd).toFixed(2)}`} fontSize="42"  fontWeight='700' className="ml-5" color={maticActive ? "white" : "black"}/>
            </div>
            
             <Text text="MATIC"  fontWeight='800'  color={maticActive ? "white" : "black"}/>     
         </div> 
         <div onClick={() =>{setMaticActive(false);setUsdActive(true);setUsdtActive(false)}} className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded 'style={{marginBottom:"4%",backgroundColor:usdActive ? "#141E30" : "white",borderRadius:"1em",  width:"60%", margin:"auto"}}>
         <div className='flex items-center'>
           <img src={Images.USDollar}  alt='currency'className='rounded mr-1 w-7 h-7' />
             <Text text={`${nft.NFTPrice}`} fontSize="42" fontWeight='700' className="ml-5" color={usdActive ? "white" : "black"}/>
          </div>    
          <Text text="USDC" fontWeight='800' color={usdActive ? "white" : "black"} /> 
         </div> 
         
         <div onClick={() =>{setMaticActive(false);setUsdActive(false);setUsdtActive(true)}}  className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded 'style={{marginBottom:"4%", backgroundColor: usdtActive ? "#141E30" : "white",borderRadius:"1em",  width:"60%", margin:"auto"}}>
          <div className='flex items-center'>
           <img src={Images.Usdt}  alt='currency'className='rounded mr-1 w-7 h-7'  />
             <Text text={`${nft.NFTPrice}`} fontSize="42"  fontWeight='700' className="ml-5" color={usdtActive ? "white" : "black"}/>
            
          </div> 
          <Text text="USDT" fontWeight='800' color={usdtActive ? "white" : "black"} />   
         </div> 
         
        
          <Button label='Pay Now' disabled={usdtActive || usdActive || maticActive ? false : true} className='w-full mt-1' width='100%'  onClick={ () => {
          BuyNFT(nft._id)
          } } />
       </div>
      </Modal>
      

    </Layout>
  );
};

export default PutonSaleNFT;