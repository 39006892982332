import React, {useState, useEffect} from 'react';
import { Text } from 'components';
import {
  Colors, Icons, Images
} from 'constant';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../selectors';
import {ethers} from 'ethers'; 
import {AiOutlineCopy} from "react-icons/ai";
import {FaShareAlt} from "react-icons/fa";
import metamask from '../../assets/images/logo/MetaMask_Fox.svg.png';

const Balance = ({
  hideHistoryBtn,
  wrapperClassName,
  btnClassName,
  onClickTopUp
}) => {
  const navigate = useNavigate();

  const wallet = useSelector(selectors.WalletConnect);
  const user = useSelector(selectors.userProfile);
  const [balance, setBalance] = useState('');
  useEffect(() => {
      getBalance()
  },[wallet])

  const getBalance = async () => {
    const { web3, marketplace, restaurant, address, usdcInstance, usdtInstance} = wallet;
    const tokenInst = await web3.eth.getBalance(address);
    const balanceInEth = await web3.utils.fromWei(tokenInst, "ether");
    setBalance(balanceInEth)
  }
  const  copyAddress = () =>{
    var copyText =wallet.address;
    navigator.clipboard.writeText(copyText);
  }

  return (
    <div className={ wrapperClassName }>
      <div className='flex items-center justify-between pb-5 mb-6'>
        <div className='flex items-center'>
          <img alt='logo' src={ metamask } className='h-11 w-11 mr-[10px]' />
          <div>
            <Text text='MetaMask' fontType='h3' fontSize='20px' fontWeight='700' fontFamily='lora' color="black" />
            <div className='flex items-center space-x-2'>
              <Text text={wallet && wallet.address.toString().slice(0,5)+"..."+wallet.address.toString().slice(-3)} id='myInput' fontWeight='300' fontSize='14px' color="black" />
              {/* <img alt='logo' src={ Icons.copy }  className='text-white/10 cursor-pointer' onClick={() => copyAddress()}/> */}
              <AiOutlineCopy color="black" size={18} onClick={() => copyAddress()}/>
            </div>
          </div>
        </div>
        {/* <img alt='logo' src={ Icons.share } className='h-5 w-5 cursor-pointer' /> */}
        <FaShareAlt color="black" size={18} className='cursor-pointer'/>
      </div>
      <div className='border-solid border-[1px] rounded border-black/10 bg-black/10'>
        <div className='flex flex-col items-center justify-center py-5 border-solid border-b-[1px] border-white/10'>
          <Text text='Total Balance' fontSize='14px' fontWeight='300' color="black"/>
          <Text text={balance && Number(balance).toFixed(2) +" Matic"} fontSize='24px' fontWeight='700' color="black"/>
        </div>
        <div className='flex items-center'>
          {
            !hideHistoryBtn &&
            <button onClick={ () => navigate('/my-wallet') } className='py-2 w-full border-solid border-[1px] border-black/10 font-extrabold text-black hover:bg-black hover:text-white '>View History</button>
          }
          {/* <button onClick={ onClickTopUp } className={ `py-2 w-full border-white/10 font-extrabold text-white2 hover:bg-lemonChiffon hover:text-darkBlue  ${btnClassName}` }>Top up with Crypto</button> */}
        </div>
      </div>
    </div>
  );
};

export default Balance;