const colors = {
  darkBlue: "#141E30",
  lemonChiffon: "#FFFBD1",
  white2: "#FAFAFA",
  cardBg: "#1C2537",
  inputBg: "rgba(255, 255, 255, 0.05)",
  redSalsa: "#FB3640",
  prussianBlue: "#003051",
  whiteCoffee: "#E6E2D6",
  lightSilver: "#D9D9D9",
  okuGreen: "#71FE7F",
  netralGrey: "#D4D4D4",
  darkGrey: "#424949",
  lightGrey: "#9D9D9D",
  green: {
    dark: "#2ABC39",
    lemon: "#71FE7F",
  },
  white: "#FFFFFF",
  darkSecurity: "#1C1E19",
  layoutBg: "#FFFBF9",
  offWhite: "#FFFBF9",
  darkGrey: "#9D9D9D",
  abundance: "#EF8440",
  platinumSolid: "#E4E4E4",
  okuGrowth: "#6BB692",
  santaGrey: "#A4A4A4",
  orange: "#EF8440",
  newBegining: "#FBF6F3",
  security: "#1C1E19",
  orangeLighter: "rgba(239, 132, 64, 0.1)",
  black: "#000000",
};

export default colors;
