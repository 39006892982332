import { useState } from 'react';
import { useNavigate } from 'react-router';
import { signIn, signUp, ListallUsers, listallWallets, getuser, sendtwofacode,mailchimpapi } from '../../../../services/auth';
import { validation } from 'helpers';
import { toast } from 'react-toastify';

const useLoginPage = () => {
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [check, setCheck] = useState(false);
	const [check2, setCheck2] = useState(false);
	const [check3, setCheck3] = useState(false);
	const [payload, setPayload] = useState({
		fullName: '',
		email: '',
		phone: '',
		dob: '',
		password: '',
		confirmPassword: ''
	});

	const onClickSubmit = async () => {

		console.log("check,check2,check3",check,check2,check3)

		const validatepassword = payload.password.length > 7 && payload.confirmPassword.length > 7 && validation.passwordMatch(payload.password, payload.confirmPassword)

		if (validatepassword) {
			if (payload.email != "" && payload.fullName != "" && validation.email(payload.email) && payload.dob && check3) {
				const values = {
					email: payload.email,
					phone: payload.phone,
					userName: payload.fullName,
					password: payload.confirmPassword,
					userType: process.env.REACT_APP_USERTYPE,
					userDOB: payload.dob,
					walletAddress: "",
				}
				console.log("value", values)
				await signUp(values).then(async(sign) => {
					if (sign.data.status) {
                        
						if(check || check2){
						const mailchimp = await mailchimpapi(values)
						console.log(mailchimp)
						}
					 navigate('/verification', { state: { data: payload.email } });
					}
				}).catch((res) => {
					toast(res.response.data.message);
				})

			} else {
				toast("Fill Mandatory data with correct format");
			}
		} else {
			toast("Confirm Password must be same as Password");
		}

	};

	const onChangeText1 = (value, name) => {

		setPayload({
			...payload,
			[name]: value
		});
	}

	const onChangeTextphone = (value, name) => {

		setPayload({
			...payload,
			[name]: value
		});
	}

	const onChangeText = (value, name) => {

		if (name === 'phone') {
			const reg = /^\d+$/;
			if (reg.test(value) || value === '') {
				setPayload({
					...payload,
					[name]: value
				});
			}
		} else {
			setPayload({
				...payload,
				[name]: value
			});
		}
	};

	return {
		payload,
		showPassword,
		check,
		check2,
		check3,
		setShowPassword,
		setCheck,
		setCheck2,
		setCheck3,
		onClickSubmit,
		onChangeText,
		onChangeText1,
		onChangeTextphone
	};
};

export default useLoginPage;
