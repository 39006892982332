import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import React, { Fragment } from 'react';

const Select = ({
	label = '',
	placeholder = '',
	options = [],
	isMultiple = false,
	selected,
	setSelected
}) => {

	// const [selected, setSelected] = useState(isMultiple ? [] : undefined);

	const generateSelected = () => {
		if (isMultiple) {
			if (selected.length > 0) {
				return selected.join(', ');
			}
		} else {
			if (selected) {
				return selected;
			}
		}
		return placeholder;
	};

	return (
		<div className='font-AcuminPro flex flex-col'>
			{
				label && (
					<span className='text-darkSecurity mb-2 text-sm uppercase'>{ label }</span>
				)
			}
			<Listbox value={ selected } onChange={ setSelected } className='relative' as='div' multiple={ isMultiple }>
				{ ({ open }) => (
					<>
						<Listbox.Button className={ `w-full bg-white py-3 px-5 border-platinumSolid focus:outline-none text-darkSecurity flex items-center justify-between ${open ? 'border-okuGrowth border-x border-t rounded-t-lg' : 'rounded-lg border'}` }>
							<span className={ `${(isMultiple ? selected.length === 0 : !selected) && 'text-[#636363]'}` }>{ generateSelected() }</span>
							<div>
								<ChevronDownIcon className={ `transform transition-transform ${open && 'rotate-180'}` } width={ 24 } color='#EF8440'/>
							</div>
						</Listbox.Button>
						<Transition
							as={ Fragment }
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Listbox.Options className={ `absolute mt-0 max-h-60 z-10 w-full overflow-auto bg-white pb-[8px] flex flex-col text-base shadow-lg border-transparent ${open ? 'border-okuGrowth rounded-b-lg border-x border-b' : 'rounded-lg border'}` }>
								{ options.map((option, index) => (
									<Listbox.Option
										key={ index }
										value={ option }
										as='div'
										className='text-black flex items-center gap-5 px-5 py-[8px] cursor-pointer hover:bg-okuGrowth hover:bg-opacity-20'
									>
										<div className={ `w-4 h-4 rounded-full border flex items-center justify-center ${(isMultiple ? selected.filter(e => e === option).length > 0 : selected === option)  ? 'border-okuGrowth bg-okuGrowth' : 'border-black'}` }>
											{
												(isMultiple ? selected.filter(e => e === option).length > 0 : selected === option) && (
													<CheckIcon width={ 12 } color='white' />
												)
											}
										</div>
										<span>
											{ option }
										</span>
									</Listbox.Option>
								)) }
							</Listbox.Options>
						</Transition>
					</>
				) }
			</Listbox>
		</div>
	);
};

export default Select;