import React, { useState, useEffect } from 'react'

import { Text } from 'components'

import { NFTCollection, TransactionHistory, Favourite } from 'pages/MyProfile/Sections'
import TabsStyle from './style'

const Tab = () => {
  const [openTab, setOpenTab] = useState(1)
  const menuHeader = [
    { title: "NFTs Collection", id: 1 },
    { title: "Favourite", id: 2 },
    { title: "Transaction History", id: 3 },
  ]
  useEffect(() =>{
    if(window.location.href.includes('favourite')){
      setOpenTab(2);
    }else if (window.location.href.includes('Transaction')){
      setOpenTab(3);
    }else{
      setOpenTab(1);
    }
  },[])

  return (
    <TabsStyle>
      <div className='flex flex-wrap'>
        <div className='w-full'>
          <ul
            className='flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row'
            role='tablist'
          >
            { menuHeader.map((item, i) => (
              <li key={ item.id } className='-mb-px last:mr-0 flex'>
                <a
                  onClick={ e => {
                    e.preventDefault();
                    setOpenTab(item.id);
                  } }
                  data-toggle='tab'
                  href={ `link${item.id}` }
                  role='tablist'
                >
                  <Text
                    text={ item.title }
                    fontWeight={ '700' }
                    color={ openTab == item.id ? 'black' : '#B2BABB' }
                    fontSize={ '20px' }
                    fontFamily={ 'Nunito' }
                  />
                </a>
              </li>
            )) }
          </ul>
          <div className='relative flex flex-col min-w-0 break-words w-full mb-6'>
            <div className='mt-5 flex-auto'>
              <div className='tab-content tab-space'>
                <div className={ openTab === 1 ? 'block' : 'hidden' } id='link1'>
                  <NFTCollection />
                </div>
                <div className={ openTab === 2 ? 'block' : 'hidden' } id='link1'>
                  <Favourite />
                </div>
                <div className={ openTab === 3 ? 'block' : 'hidden' } id='link1'>
                  <TransactionHistory />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TabsStyle>
  )
}

export default Tab