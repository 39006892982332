import React, { useState } from 'react';
import { Button, Input, Modal, Text } from 'components';

import ForgotPasswordStyle from '../style';
import { Colors } from 'constant';
import { validation } from 'helpers';
import { userotpverification ,forgotPassword} from '../../../../services/auth';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const InputEmail = ({ next,email,setEmail,nextstep,closemodal }) => {

  const [verificationCode, setverificationCode] = useState('')
  const navigate = useNavigate();

  const handleOnChange = (value) => {
    setEmail(value)
  }

  const resendotp =async()=>{
    
    next()
  }

  const verifyuserotp = async()=>{
    console.log("verificationCode=>",verificationCode)
    if(verificationCode && verificationCode.length === 4){
      const values = {
        email:email,
        otp:verificationCode,
        };
      const verifyotp = await userotpverification(values);
      if(verifyotp.data.status){
        toast(verifyotp.data.message)
        const values = {
          email:email,
          };
        const forgetpass = await forgotPassword(values)
        console.log("forgetpass==>",forgetpass)
        navigate('/verification', { state: { data: email } });
      }else{
        toast(verifyotp.data.message)
      }
    }else{
      toast("Enter Your 4-Digit OTP")
    }
  }
  
  const renderModalInputCode = () => {
    return (
      
      <Modal
        visible={ nextstep }
        onClose={ () => closemodal() }
        contentClassName='w-full md:w-[540px]'

      >
        <div className='modal-input-container'>
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text='Input Verification Code'
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='Verification code has been sent to your Email Address.'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
          <Input
            name='code'
            className='mb-8'
            autofocus
            placeholder='----'
            onChange={({ value }) => setverificationCode(value)}
            value={verificationCode}
            textColor={ Colors.prussianBlue }
          />
        </div>
        <div className='btn-submit mx-auto flex justify-between'>
          <div>
            <Button
              className=''
              label='Resend'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={()=>resendotp()}
            />
          </div>
          <div>
            <Button
              className=''
              label='Submit'
              height={ '40px' }
              width={ '185px' }
              textColor={ 'white' }
              backgroundColor={ '#003051' }
              disabled={!validation.minLength(verificationCode,4)}
              // onClick={ () => { navigate('/verification', { state: { data: 'your@mail.com' } }); } }
              onClick={ ()=>{ verifyuserotp()}}
            />
          </div>
        </div>
      </Modal>
    );
  };
 
  return (
    <ForgotPasswordStyle>
      <div className='forgotpassword-form'>
        <Text
          text={ 'Enter Your Email Address' }
          fontWeight={ '700' }
          fontSize={ '20px' }
          color={ Colors.prussianBlue }
        />
        <Text
          text={ 'Please enter your registered email.' }
          fontWeight={ '300' }
          fontSize={ '16px' }
          className={ 'mb-8' }
          color={ Colors.prussianBlue }
        />
        <Input
          label='Email'
          name='email'
          placeholder='Email'
          className='mb-5'
          value={email}
          onChange={({ value }) => setEmail(value)}
          textColor={ Colors.prussianBlue }
        />
        <Button
          label='Next'
          width='100%'
          onClick={ next }
          disabled={!validation.email(email)}

        />
      </div>
      { renderModalInputCode() }
    </ForgotPasswordStyle>
  );
};

export default React.memo(InputEmail);
