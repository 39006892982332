import styled from "styled-components";

const WalletStyles = styled.div`
	
	padding-bottom: 80px;
	padding-top: 80px;
    width:90%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	background: #fffbf9;
    .card {
        background: white;
        border-radius: 10px;
        backdrop-filter: blur(100px);
        display: flex;
        box-sizing: border-box;
        max-width: 100%;
        align-items:center;
        justify-content:center;
        margin-top: 20px;
        padding:1.5em 2em;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid #FFFBD1;
        animation: slideToBottom 1s;
    }

    .card:hover {
        border: 2px solid #EF8440;
        background: #fffbf9;
    }

    .logo {
        margin: auto;
        padding:0 1em 0 0;
    }

    .menu {
        position: relative;
        width: 100%;
        margin: auto;
    }

    .menu1 {
        position: relative;
        width: 60%;
        margin: auto;
    }

    .arrow {
        position: relative;
        margin: auto;
    }
    .connect-wallet {
        margin-right: auto;
        margin-left: auto;
        top: 2em;
        display: block;
        position: relative;
        border: 1px solid #FFFBD1;
    }
    input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
        border: 3px solid gray;
    }

    input[type='radio']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
    }

    input[type="radio"]:checked:before {
        background: #007aff;
        
    }
    
    input[type="radio"]:checked {
      border-color:#007aff;
    }
`;

export default WalletStyles;
