import React, { useEffect, useState } from 'react';
import { Text } from 'components';
import { Images } from 'constant';
import { PropsSection } from 'pages/LandingPage';
import './styles.scss';

const FAQ = ({ isRender, list, changed }) => {
    const [selectedList, setSelectedList] = useState(list[0]);
    const [selectedDropDown, setSelectedDropDown] = useState({ id: 1 });
    const [loadList, setLoadList] = useState(false);

    useEffect(() => {
        if (!selectedList.contents?.length ) {
            setSelectedList(list[0]);
        }

        if (selectedList?.contents?.length) {
            for (let i = 1; i <= selectedList.contents.length; i++) {
                if (document.getElementById(`dropdown${selectedList.id}${i}`)) {
                    document.getElementById(`dropdown${selectedList.id}${i}`).style.height = '0px';
                }
            }
        }

        setSelectedDropDown({ id: 1 });

        if (isRender && document?.getElementById(`dropdown${selectedList.id}0`)) {
            document.getElementById(`dropdown${selectedList.id}0`).style.height = 'unset';
        }

        if (isRender) {
            setTimeout(() => {
                setLoadList(true);
            }, 3000);
        }
    }, [isRender, selectedList,list]);
    useEffect(() =>{
        
        if(changed){
            
            if (selectedList.contents?.length ) {
                setSelectedList(list[0]);
            }
    
            if (changed) {
                for (let i = 0; i <= selectedList.contents.length; i++) {
                    if (document.getElementById(`dropdown${selectedList.id}${i}`)) {
                        document.getElementById(`dropdown${selectedList.id}${i}`).style.height = '0px';
                    }
                }
            }
    
            setSelectedDropDown({ id: 1 });
    
            if (changed) {
                document.getElementById(`dropdown${selectedList.id}0`).style.height = 'unset';
            }
    
            if (changed) {
                setTimeout(() => {
                    setLoadList(true);
                }, 3000);
            }
        }
    },[changed, isRender, selectedList,list])
   

    return (
        <>
            <div className={ isRender ? 'section-4' : 'blank-screen' }>
                <>
                    { list.length <= 1 ?
                        <div className='col-right'>
                            <Text
                                text={ 'FAQ' }
                                fontType={ 'h3' }
                                fontWeight={ '700' }
                                color={ 'black' }
                                fontFamily={ 'Lora' }
                                fontSize={ '32px' }
                                className='title'
                            />
                            { selectedList?.contents?.map((item, i) => {
                                return (
                                    <div
                                        key={ item?.id }
                                        className={ `list ${!loadList ? `list-${i}` : ''}` }
                                        onClick={ () => {
                                            if (item.id === selectedDropDown.id) {
                                                setSelectedDropDown({ id: -1 });
                                                setTimeout(() => {
                                                    document.getElementById(`dropdown${selectedList.id}${i}`).style.height = document.getElementById(`dropdown${selectedList.id}${i}`).style.height === '0px' ? 'unset' : '0px';
                                                }, 10);
                                            }

                                            if (item.id !== selectedDropDown.id) {
                                                document.getElementById(`dropdown${selectedList.id}${i}`).style.height = 'unset';
                                                const height = document.getElementById(`dropdown${selectedList.id}${i}`).getBoundingClientRect().height + 'px';
                                                document.getElementById(`dropdown${selectedList.id}${i}`).style.height = '0px';

                                                setSelectedDropDown(item);

                                                setTimeout(() => {
                                                    if (selectedDropDown.id >= 0) {
                                                        document.getElementById(`dropdown${selectedList.id}${(selectedDropDown.id - 1)}`).style.height = '0px';
                                                    }
                                                    document.getElementById(`dropdown${selectedList.id}${i}`).style.height = height;
                                                }, 10);
                                            }
                                        } }
                                    >
                                        <div className='flex'>
                                            <Text
                                                text={ item.title }
                                                fontType={ 'h4' }
                                                fontWeight={ '700' }
                                                fontSize={ '20px' }
                                                color={ 'black' }
                                                fontFamily={ 'Lora' }
                                            />
                                            <img className={ selectedDropDown.id == item.id ? 'arrow-up' : 'arrow-down' } src={ Images.ArrowDown } />
                                        </div>
                                        <div id={ 'dropdown' + selectedList.id + i } className={ `dropdown-content ${selectedDropDown.id === item.id ? 'show-content' : 'close-content'}` }>
                                            {
                                                item.p.map((p, index) => (
                                                    <Text
                                                        key={ index }
                                                        text={ p }
                                                        fontType={ 'p' }
                                                        fontWeight={ '300' }
                                                        color={ 'black' }
                                                        fontSize={ '16px' }
                                                        lineHeight={ '26px' }
                                                        className='mt-5'
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>
                                );
                            }) }
                        </div>
                        :
                        <>
                            <div className='col-left'>
                                <Text
                                    text={ 'FAQ' }
                                    fontType={ 'h1' }
                                    fontWeight={ '700' }
                                    color={ '#FAFAFA' }
                                    fontFamily={ 'Lora' }
                                    className='title'
                                />
                                <div className='nav'>
                                    { list.map((item, i) => (
                                        <div
                                            key={ item.id }
                                            className={ selectedList.id == item.id ? 'list-active' : `list list-${i}` }
                                            onClick={ () => {
                                                alert(item.id)
                                                setSelectedList(item);
                                                setSelectedDropDown(item.contents[0]);
                                            } }
                                        >
                                            <Text
                                                text={ item.title }
                                                fontWeight={ '700' }
                                                color={ selectedList.id == item.id ? '#FFFBD1' : '#FAFAFA' }
                                                fontType={ 'p' }
                                                fontSize={ '20px' }
                                                fontFamily={ 'Lora' }
                                            />
                                        </div>
                                    )) }
                                </div>
                            </div>
                            <div className='col-right'>
                                { selectedList?.contents?.map((item, i) => {
                                    return (
                                        <div
                                            key={ item?.id }
                                            className={ `list ${!loadList ? `list-${i}` : ''}` }
                                            onClick={ () => {
                                                if (item.id === selectedDropDown.id) {
                                                    setSelectedDropDown({ id: -1 });
                                                    setTimeout(() => {
                                                        document.getElementById(`dropdown${selectedList.id}${i}`).style.height = document.getElementById(`dropdown${selectedList.id}${i}`).style.height === '0px' ? 'unset' : '0px';
                                                    }, 10);
                                                }

                                                if (item.id !== selectedDropDown.id) {
                                                    document.getElementById(`dropdown${selectedList.id}${i}`).style.height = 'unset';
                                                    const height = document.getElementById(`dropdown${selectedList.id}${i}`).getBoundingClientRect().height + 'px';
                                                    document.getElementById(`dropdown${selectedList.id}${i}`).style.height = '0px';

                                                    setSelectedDropDown(item);

                                                    setTimeout(() => {
                                                        if (selectedDropDown.id >= 0) {
                                                            document.getElementById(`dropdown${selectedList.id}${(selectedDropDown.id - 1)}`).style.height = '0px';
                                                        }
                                                        document.getElementById(`dropdown${selectedList.id}${i}`).style.height = height;
                                                    }, 10);
                                                }
                                            } }
                                        >
                                            <div className='flex'>
                                                <Text
                                                    text={ item.title }
                                                    fontType={ 'h4' }
                                                    fontWeight={ '700' }
                                                    fontSize={ '20px' }
                                                    fontFamily={ 'Lora' }
                                                />
                                                <img className={ selectedDropDown.id == item.id ? 'arrow-up' : 'arrow-down' } src={ Images.ArrowDown } />
                                            </div>
                                            <div id={ 'dropdown' + selectedList.id + i } className={ `dropdown-content ${selectedDropDown.id === item.id ? 'show-content' : 'close-content'}` }>
                                                {
                                                    item.p.map((p, index) => (
                                                        <Text
                                                            key={ index }
                                                            text={ p }
                                                            fontType={ 'p' }
                                                            fontWeight={ '300' }
                                                            color={ '#FAFAFA' }
                                                            fontSize={ '16px' }
                                                            lineHeight={ '26px' }
                                                            className='mt-20'
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    );
                                }) }
                            </div>
                        </>
                    }
                </>
            </div>
        </>
    );
};

export default FAQ;