import { Button } from 'components';
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../Membership/components/Input';
import Select from '../../Membership/components/Select';
import selectors from '../../../../../selectors';
import { getuser,updatemembershipprofile,edit_user_profile } from '../../../../../services/auth';
import { ToastContainer, toast } from 'react-toastify';
import { connectFailed } from '../../../../../stores/User/WalletAction';
import 'react-toastify/dist/ReactToastify.css';
import {AiOutlineCopy} from "react-icons/ai";

const locations = [
	'Oku House Singapore',
	'Oku House Jakarta',
	'Oku House Bali'
];

const Info = ({FileName,Image}) => {

	const dispatch = useDispatch();

	const user = useSelector(selectors.userProfile);

	const [isEdit, setIsEdit] = useState(true);
	const [selectedLocation, setSelectedLocation] = useState();

	const [username,setusername]=useState("");
	const [useremail,setuseremail]=useState("");
	const [userdob,setuserdob]=useState("");


	useEffect(()=>{
		if(user){
			getCurrentuser();
		}
	},[user])

	const [newuser,setnewuser]=useState()

	const getCurrentuser=async()=>{
		const res = await getuser(user.user.id)
		if (res.data.status) {
		  const eve = res.data.result
		  console.log("eve==>eve==>",eve)
		  setnewuser(eve)
		  setusername(eve.userName ? eve.userName : "")
		  setuseremail(eve.email ?eve.email:"")
		  setuserdob(eve.userDOB ? eve.userDOB :"")
		  setSelectedLocation(eve.membershipclubhouselocation ? eve.membershipclubhouselocation : "")
		}
	}

	const updateprofile=async()=>{
     
		if(username && userdob && useremail && selectedLocation){
		const tokens = user.token
		const values = {
			userName: username ? username : user.user.userName,
			userDOB: userdob ? userdob : newuser.Phone,
			email: useremail ? useremail : newuser.email,
			profilePicture: FileName ? FileName : newuser.profilePicture,
			membershipclubhouselocation: selectedLocation ? selectedLocation:newuser.membershipclubhouselocation
		}

		const res = await edit_user_profile(user.user.id, values, tokens);
		if (res.data.status) {
		toast("Profile updated");
		setIsEdit(prev => !prev)
		} else {
		dispatch({
			type: 'SET_PROFILE',
			payload: ''
		});
		dispatch(connectFailed());
		toast("session expired");
		}
	}else{
		toast("Fill all the above fields");
	}

	}

const disablePastDate = () => {
	const today = new Date();
	const dd = String(today.getDate() + 0).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0! 
	const yyyys = today.getFullYear();
	const yyyy = yyyys-10;
	return yyyy + "-" + mm + "-" + dd;
		};

	return (
		<div className='flex-1'>
			<div className='mt-8 flex flex-col gap-8'>
				{
					isEdit ? (
						<>
							<div className='flex flex-col'>
								<span className='uppercase text-darkSecurity text-sm tracking-wider'>name</span>
								<span className='text-black text-lg tracking-wider'>{username}</span>
							</div>
							<div className='flex flex-col'>
								<span className='uppercase text-darkSecurity text-sm tracking-wider'>date of birth</span>
								<span className='text-black text-lg tracking-wider'>{userdob}</span>
							</div>
							<div className='flex flex-col'>
								<span className='uppercase text-darkSecurity text-sm tracking-wider'>email address</span>
								<div className='flex items-center gap-2'>
									<span className='text-black text-lg tracking-wider'>{useremail}</span>
									<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<circle cx='7.5' cy='7.5' r='7.5' fill='#6BB692' />
										<path d='M7.2448 9.38022C7.44999 9.13237 7.64714 8.89462 7.84374 8.6563C8.72506 7.5878 9.60602 6.51911 10.4875 5.45081C10.5724 5.34806 10.6772 5.2758 10.8054 5.2556C11.1106 5.20743 11.3235 5.47703 11.2262 5.795C11.1988 5.88454 11.1501 5.9733 11.0916 6.04439C10.149 7.18903 9.20331 8.33095 8.25798 9.47325C8.01255 9.76985 7.76657 10.0657 7.52077 10.3617C7.37666 10.5353 7.20164 10.5466 7.04399 10.3891C6.5716 9.9167 6.1003 9.44276 5.62846 8.9696C5.29836 8.63862 4.96825 8.30764 4.63778 7.97705C4.47812 7.81739 4.4527 7.63791 4.58529 7.45183C4.64363 7.37006 4.72684 7.29839 4.81298 7.25119C4.94228 7.18049 5.06884 7.20341 5.18113 7.31645C5.84225 7.98191 6.50576 8.64503 7.16853 9.30874C7.1903 9.33049 7.21334 9.35089 7.2448 9.38022Z' fill='white' />
									</svg>
								</div>
							</div>
							<div className='flex flex-col'>
								<span className='uppercase text-darkSecurity text-sm tracking-wider'>oku club house</span>
								<span className='text-black text-lg tracking-wider'>{selectedLocation}</span>
							</div>
							{newuser && newuser?.membershipstatus == "approved" ?

							<div className='flex flex-col'>
								<span className='uppercase text-darkSecurity text-sm tracking-wider'>Refferal Code  - Maximum Reffer Limit(3)- Used({newuser?.refferalusedcount ? newuser?.refferalusedcount : 0})</span>
								<div className='flex flex-row align-items-center'>
								<span className='text-black text-lg tracking-wider' >{newuser?.refferalcode} </span>
								<AiOutlineCopy className="ml-2 mt-1" style={{cursor:"pointer"}} color="black" size={22} onClick={() =>  {navigator.clipboard.writeText(newuser?.refferalcode);toast("Refferal Code Copied !")}}/>
								</div>
							</div>
							:""
}
						</>
					) : (
						<div className='w-[60%] flex flex-col gap-5'>
							<Input value={username} onChange={(e)=>setusername(e.target.value)} placeholder='Michael Cheung' label='Full Name' />
							<Input value={useremail}
							disable={true}
							//  onChange={(e)=>setuseremail(e.target.value)} 
							 type='email' label='Email Address' />
							<Input value={userdob} placeholder='dd/mm/yyy' max={disablePastDate()} onChange={(e)=>setuserdob(e.target.value)} type='date' label='Date of birth' />
							<Select selected={selectedLocation} setSelected={setSelectedLocation} options={locations} placeholder='Select your Oku Club House Location' label='choose your clubhouse location' />

						</div>
					)
				}
				<Button onClick={() => {!isEdit ? updateprofile() : setIsEdit(prev => !prev)}} theme='primary-outline' className='w-fit'>{!isEdit ? 'Save Information' : 'Edit Information'}</Button>
			</div>
		</div>
	);
};

export default Info;


