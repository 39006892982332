import styled from 'styled-components';

const SubHeaderStyle = styled.div`
   .inputbox{
      width:80%;
      height:50px;
      border:1px solid black;
   }
   input[type="file"] {
      display: none;
   }
   .inputblock{
      flex-direction:column;
      align-items: center;
      justify-content: center;
   }
   .label{
      margin:0 10%;
      align-self: flex-start;
   }
   .profil {
      margin-right: 30px;
   }
   @media (max-width: 715px) { 
      .profil {
         width: 90px;
         height: 90px;
         margin-right: 15px;
      }
    }

    @media (max-width: 480px) { 
      .settingmenu {
         position: relative;
         margin-top: -30px;
      }
    }
`;

export default SubHeaderStyle;