/* eslint-disable no-undef */
import { Button } from 'components';
import { Images } from 'constant';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router';
import ScrollAnimation from 'react-animate-on-scroll';
import { Layout } from 'pages/LandingPageNew/Components';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';

// import required modules
import { Autoplay, EffectFade } from 'swiper';

const Philosophy = () => {

	const navigate = useNavigate();
	const swiperRef = useRef();

	// useEffect(() => {
	// 	const updatePosition = () => {
	// 		if (window.scrollY === swiperRef.current.offsetTop) {
	// 			swiperRef.current.swiper.mousewheel.enable();
	// 		} else {
	// 			swiperRef.current.swiper.mousewheel.disable();
	// 		}
	// 	};

	// 	window.addEventListener('scroll', updatePosition);
	// 	return () => window.removeEventListener('scroll', updatePosition);
	// }, []);
	return (
		// <Layout showAccessList textAccessList='Be a member of our family, and create these memories with us! To be the first to be updated, please join our access list!' btnMembership>
		<Layout>
			<ScrollAnimation
				animateOnce
				animateIn='fadeInUp'
				animatePreScroll={false}
			>
				<div className='min-h-screen -mt-10 md:-mt-[100px] flex flex-col text-center container mx-auto md:px-5 items-center justify-center'>
					<h1 className='text-[24px] md:text-[40px] font-semibold text-brandBlack max-w-screen-md mx-auto leading-[30px] md:leading-[47px] font-ExpressWay tracking-widest uppercase'>We believe that every <br className='hidden md:inline' />region, country, and city <br className='hidden md:inline' />has its own flavor, taste, <br className='hidden md:inline' />and energy. And behind this <br className='hidden md:inline' />culture are numerous <br className='hidden md:inline' />stories to be told.</h1>
				</div>
			</ScrollAnimation>
			<ScrollAnimation
				animateOnce
				animateIn='fadeInUp'
				animatePreScroll={true}
			>
				<div>
					<div className='container mx-auto md:px-5'>
						<h2 className='py-10 leading-7 md:leading-[48px] font-semibold text-center text-darkSecurity text-xl md:text-[40px] font-ExpressWay uppercase'>Our community is central to why we do, and what we do. </h2>
					</div>
					<Swiper
						direction={'vertical'}
						slidesPerView={1}
						ref={swiperRef}
						// mousewheel={ {
						// 	forceToAxis: true,
						// 	sensitivity: 1,
						// 	releaseOnEdges: true,
						// 	thresholdDelta: 50
						// } }
						// simulateTouch={ false }
						allowTouchMove={false}
						modules={[EffectFade, Autoplay]}
						loop={true}
						autoplay={{
							delay: 3000,
							disableOnInteraction: false,
						}}
						effect='fade'
						className='h-screen flex items-center justify-center relative'
					>
						<SwiperSlide>
							{({ isActive }) => (
								<div className='md:h-screen md:flex items-center justify-center relative' >
									<img src={Images.Philosophy1} alt='philosophy 1' className='w-screen' />
									<div className={`${isActive ? 'block mt-5' : 'hidden'} h-full w-full flex items-center justify-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2`}>
										<div className='flex flex-col text-center text-darkSecurity md:text-white max-w-xl'>
											<p className='text-sm md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>All of our creative partners</p>
											<h2 className='text-[32px] md:text-[96px] font-semibold leading-[80px] md:leading-[115px] font-ExpressWay uppercase'>Artists</h2>
											<p className='text-xs md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>play a part in organically curating products and experiences for others to enjoy, own, and share</p>
										</div>
									</div>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{({ isActive }) => (
								<div className='md:h-screen md:flex items-center justify-center relative' >
									<img src={Images.Philosophy2} alt='philosophy 2' className='w-screen' />
									<div className={`${isActive ? 'block mt-5' : 'hidden'} h-full w-full flex items-center justify-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2`}>
										<div className='flex flex-col text-center text-darkSecurity md:text-white max-w-xl'>
											<p className='text-sm md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>All of our creative partners</p>
											<h2 className='text-[32px] md:text-[96px] font-semibold leading-[80px] md:leading-[115px] font-ExpressWay uppercase'>chefs</h2>
											<p className='text-xs md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>play a part in organically curating products and experiences for others to enjoy, own, and share</p>
										</div>
									</div>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{({ isActive }) => (
								<div className='md:h-screen md:flex items-center justify-center relative' >
									<img src={Images.Philosophy3} alt='philosophy 3' className='w-screen' />
									<div className={`${isActive ? 'block mt-5' : 'hidden'} h-full w-full flex items-center justify-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2`}>
										<div className='flex flex-col text-center text-darkSecurity md:text-white max-w-xl'>
											<p className='text-sm md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>All of our creative partners</p>
											<h2 className='text-[32px] md:text-[96px] font-semibold leading-[80px] md:leading-[115px] font-ExpressWay uppercase'>Designers</h2>
											<p className='text-xs md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>play a part in organically curating products and experiences for others to enjoy, own, and share</p>
										</div>
									</div>
								</div>
							)}
						</SwiperSlide>
						<SwiperSlide>
							{({ isActive }) => (
								<div className='md:h-screen md:flex items-center justify-center relative' >
									<img src={Images.Philosophy4} alt='philosophy 4' className='w-screen' />
									<div className={`${isActive ? 'block mt-5' : 'hidden'} h-full w-full flex items-center justify-center md:absolute md:top-1/2 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2`}>
										<div className='flex flex-col text-center text-darkSecurity md:text-white max-w-xl'>
											<p className='text-sm md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>All of our creative partners</p>
											<h2 className='text-[32px] md:text-[96px] font-semibold leading-[80px] md:leading-[115px] font-ExpressWay uppercase'>craftsmen</h2>
											<p className='text-xs md:text-lg font-ExpressWay uppercase leading-5 tracking-widest'>play a part in organically curating products and experiences for others to enjoy, own, and share</p>
										</div>
									</div>
								</div>
							)}
						</SwiperSlide>
					</Swiper>
				</div>
				<div className='container mx-auto md:px-5 py-10 lg:py-20 grid grid-cols-1 lg:grid-cols-2 h-fit'>
					<div className='w-full h-fit flex justify-center lg:justify-end order-last lg:order-first lg:mt-0 mt-20'>
						<img src={Images.Philosophy5} alt='Philosophy 5' className='relative z-10 ' />
					</div>
					<div className='w-full relative h-full '>
						<img src={Images.PhilosophyVector} alt='Philosophy Vectro' className='absolute -top-[400px] lg:-top-72 lg:-left-0' />
						<div className='flex flex-col justify-center h-full mt-20 md:mt-36 lg:ml-20 lg:max-w-md'>
							<h2 className='font-semibold text-darkSecurity text-[28px] md:text-[40px] font-ExpressWay uppercase leading-[38px] md:leading-[48px]'>take a step into our house and explore our community</h2>
							<p className='mb-5 lg:mb-10 mt-5 text-sm md:text-lg text-black font-AcuminPro'>Whether you want to work with us to share your own craft or you are seeking new and exciting activities, Oku House is a place for you to connect.</p>
							<div className='flex flex-col lg:flex-row lg:space-x-3 space-y-3 lg:space-y-0 justify-start'>
								<Button theme='primary' label='Explore Membership' className='w-full lg:w-fit' onClick={() => navigate('/our-house')} />
								<Button theme='primary-outline' label='Explore Discord' className='w-full lg:w-fit' onClick={() => window.open('https://discord.gg/okuhouse', '_blank')} />
							</div>
						</div>
					</div>
				</div>
			</ScrollAnimation>

		</Layout>
	);
};

export default Philosophy;