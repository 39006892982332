import styled from 'styled-components';

const GeneralInformationStyle = styled.div`
    Input {
        color: var(--grey200);
    }

    @media screen and (max-width: 650px) {
            width: 320px;
            margin: auto;
      }
`;

export default GeneralInformationStyle;