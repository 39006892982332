import { Tab } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { Button, Text } from "components";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../../selectors";
import {
  getuser,
  updatemembership,
  updatemembershipcheck,
} from "../../../../../services/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Rules = ({ handleNextPage, handleBackPage }) => {
  const navigate = useNavigate();
  const user = useSelector(selectors.userProfile);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [check, setCheck] = useState(0);

  const handleNext = () => {
    if (selectedIndex === 2) {
      handleNextPage();
    } else {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  const updatememberships = async () => {
    console.log("user.user.id==>", user.user.id);
    if (check) {
      const values = {
        membershipstatus: "approved",
      };
      try {
        const userprofile = await updatemembershipcheck(user.user.id, values);
        if (userprofile.data.status) {
          handleNext();
          toast("MemberShip Completed Successfully");
        } else {
          handleNext();
          toast(userprofile.data.message);
        }
      } catch (err) {
        toast("Something went wrong");
        console.log(err);
      }
    } else {
      toast("Select the Check Box ");
    }
  };

  const renderContent = () => {
    return (
      <div id="container" className="flex my-5 h-[400px]">
        <div id="content" className="overflow-y-scroll pr-20">
          <Text
            fontType="p"
            fontSize="16px"
            className="mt-5"
            fontWeight={400}
            color="#1C1E19"
          >
            Oku House’s community strives to celebrate culture through all
            mediums and invites people who are interested in exploring new
            experiences and connecting with others. We require that our members
            adhere to Oku House Rules so that we may all continue sharing and
            enjoying the numerous stories Oku House and our partners have to
            tell.
            <br />
            <span className="italic">Last updated 15 February 2023</span>
          </Text>
          <div className="mt-5">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              1. Our Club Houses
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Oku House operates physical locations (which we call Club Houses
              and may sometimes refer to as “spaces”) exclusive to community
              members who own a Club House NFT. We commit to using the proceeds
              from the sale of Club House NFTs to build, outfit, and operate
              each of our spaces. Considering that proof of ownership of NFTs is
              immediately verifiable on our blockchain, we hold the right to
              verify access to our spaces, amend or waive payment of NFTs,
              determine the initial price and quantity of our Club House
              collections, issue new NFTs for any Club House collection, or
              amend the structure with which our Club House NFTs are sold and/or
              traded.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              2. Our Events
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Oku House also organizes and helps to operate certain events in
              partnership with creators. Each of these events is gated by an NFT
              collection and ownership of an event NFT grants access to that
              specific event. Oku House reserves the right to sell, trade,
              airdrop, or give these event NFTs in any form or fashion.
              <br />
              <br />
              We also reserve the right, in conjunction with any partner, to
              admit or deny entry to any individuals on a case-by-case basis to
              each event that we organize.
            </Text>
          </div>
          <div className="mt-5">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              3. Your profile details
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              By completing and submitting your profile, you agree to be bound
              by Our House rules. Our community team meets regularly to review
              profiles and confirm or deny the admittance of members at their
              sole discretion. You are free to withdraw or delete your community
              profile at any time. Should you wish to do so, please contact us
              at hi@oku.house.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              4. Members data and Oku database
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              It is crucial for us to have your current details plus a
              photograph of you in our database. By joining Oku House’s
              community, you agree that we can hold your personal details and
              photograph as well as your WalletID to verify authenticity and
              identify ownership.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              5. Interaction with our community
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Our community exists in many mediums, but interactions
              predominantly take place on Discord. Members are allowed to
              interact with one another so long as they do not violate the
              following criteria:
            </Text>
            <ul className="!flex !flex-col !list-decimal mt-2 pl-5">
              <li className="!text-darkSecurity !text-base !font-AcuminPro !m-0">
                Be respectful to one another. We are a diverse community and we
                celebrate everyone’s individual unique identity, ideas, and
                beliefs.
              </li>
              <li className="!text-darkSecurity !text-base !font-AcuminPro !m-0">
                No spam (unless the edible kind), inappropriate or illegal
                content, personal attacks, racism, sexism, offensive language,
                religious and/or political attacks.
              </li>
              <li className="!text-darkSecurity !text-base !font-AcuminPro !m-0">
                No blank, inappropriate, sexually explicit, offensive names or
                profile photos.
              </li>
              <li className="!text-darkSecurity !text-base !font-AcuminPro !m-0">
                No solicitation of any products or services.
              </li>
              <li className="!text-darkSecurity !text-base !font-AcuminPro !m-0">
                No conducting trades or sales via direct messages or outside of
                this server and our platform.
              </li>
            </ul>
          </div>
          <div className="mt-5">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              6. Your personal information
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              We will use the personal information you provide us in connection
              with your membership, in accordance with our Privacy Policy.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              7. Disciplinary procedure
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Conduct or behavior by a community member or guest that violates
              Our House rules or otherwise harms Oku House’s reputation and
              character may result in immediate removal and blacklist from Oku
              House’s community. This may include inappropriate, sexually
              explicit remarks, offensive comments, personal attacks, racism,
              sexism, religious and/or political attacks, violent behavior, or
              illegal content, products or substances. Members of Oku House’s
              community are always bound to Our House rules. Should Oku House
              determine that your conduct has violated any rules or crossed any
              boundaries, it is in our sole and absolute discretion to remove
              you from our community with immediate effect.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              8. Liability of Oku House
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Oku House will not be held liable for the loss of any property
              through our events, our spaces, or trading of our NFTs.
            </Text>
          </div>
          <div className="mt-7">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              9. Interpretation of the rules
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              If there is any disagreement, misunderstanding, or
              misinterpretation of Our Rules, our community team will be
              notified of the situation and should a resolution or decision be
              necessary, have full discretion on the outcome, which will be
              final and without appeal.
            </Text>
          </div>
          <div className="mt-5">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              10. Amendments to Our House rules
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              Our House rules may change from time to time given ever-changing
              circumstances of life. Amendments will be displayed on our
              website.
            </Text>
          </div>
          <div className="mt-7 mb-40">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              color="#000000"
              className="uppercase"
            >
              11. Contacting us
            </Text>
            <Text
              fontType="p"
              className="mt-2"
              fontSize="16px"
              fontWeight={400}
              color="#1C1E19"
            >
              If you have any questions at all, please contact us at
              hi@oku.house or shoot us a message in our Discord community.
            </Text>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex-1">
      <Tab.Group manual={true} selectedIndex={selectedIndex} as="div">
        <Tab.List as="div" className="hidden">
          <Tab>Tab 1</Tab>
          <Tab>Tab 2</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Text
              fontType="h2"
              fontSize="45px"
              fontWeight={600}
              color="#000000"
            >
              House Rules
            </Text>
            {renderContent()}
            <div className="flex flex-col lg:flex-row items-center justify-between gap-6 lg:pr-20">
              <div className="">
                <Text
                  fontType="h3"
                  fontSize="18px"
                  fontWeight={700}
                  color="#000000"
                  className="uppercase"
                >
                  You’re almost there!{" "}
                </Text>
                <button
                  onClick={() => setCheck((state) => !state)}
                  className="flex cursor-pointer items-center my-3 gap-4 group outline-none"
                >
                  <div
                    className={`w-6 h-6 rounded flex items-center justify-center border group-hover:border-okuGrowth ${
                      check
                        ? "bg-okuGrowth border-okuGrowth"
                        : "border-[#636363]"
                    }`}
                  >
                    {check ? <CheckIcon width={16} color="white" /> : undefined}
                  </div>
                  <Text
                    text="I agree with the oku house rules"
                    className="mt-1 uppercase"
                    color="#000000"
                    fontSize="16px"
                    fontWeight={500}
                  />
                </button>
                <Text
                  fontType="p"
                  fontSize="16px"
                  fontWeight={400}
                  color="#1C1E19"
                >
                  By submitting your form, you agree to the Oku House Rules,{" "}
                  <a
                    href="/legality"
                    target="_blank"
                    className="text-okuGrowth underline cursor-pointer"
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <span className="text-okuGrowth underline">
                    terms and conditions
                  </span>
                  .
                </Text>
              </div>
              <div className="flex justify-end items-center gap-3 w-full mt-12">
                <Button
                  onClick={() => handleBackPage()}
                  theme="primary-outline"
                >
                  Back
                </Button>
                <Button
                  onClick={() => updatememberships()}
                  theme="primary-outline"
                  className=" w-[154px]"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Tab.Panel>
          <Tab.Panel>
            <Text
              fontType="h3"
              className="uppercase"
              fontSize="24px"
              fontWeight={400}
              color="#000000"
            >
              congratulations! you finished!
            </Text>
            <Text
              fontType="h2"
              fontSize="45px"
              lineHeight="53px"
              className="mt-7"
              fontWeight={600}
              color="#000000"
            >
              Your profile is
              <br />
              currently in review!
            </Text>
            <Text
              fontType="p"
              className="mt-8 max-w-xl"
              fontSize="18px"
              lineHeight="22px"
              fontWeight={400}
              color="#000000"
            >
              Thanks for completing your membership profile! We’re filtering all
              the bots and spammers in our community so we’ll be reviewing each
              and every profile! Expect a profile verification email in your
              inbox!
            </Text>
            <Button
              onClick={() => navigate("/profile")}
              theme="primary-outline"
              className="mt-12"
            >
              View Profile
            </Button>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Rules;
