import React,{useState, useEffect} from 'react';
import {
	FAQ,
	Wallet
} from './Sections';
import { Layout } from 'components';
import WalletConnectionStyle from './style';
import { PageContruction, Header, Footer } from 'pages/LandingPageNew/Components';
import { Images,} from 'constant';
import metamask from '../../assets/images/logo/MetaMask_Fox.svg.png'
import walletconnect from '../../assets/images/logo/WalletConnect-Logo.png';
import formatic from '../../assets/images/logo/formatic.jpg';
import coinbase from '../../assets/images/logo/coinbase.png';

import Languages from 'constant/languages';
import {
  Text,
  WalletModal,
  Button,
  Input
} from 'components';

const WalletConnection = ({
	subscribe,
	status,
	message,
	visible,
	onClose
  }) => {
	const [success, setSuccess] = useState(false);
   

	return (
		<Layout>
			<Header />
			<WalletConnectionStyle>
				<div className='wrapper'>
					<div className='left-content'>
						<Wallet />
					</div>
					<div className='right-content'>
						<FAQ
							isRender={ true }
						/>
					</div>
				</div>
			</WalletConnectionStyle>
		</Layout>
	);
};

export default WalletConnection;