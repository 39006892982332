import React, { useState } from 'react';
import { Button, Input, Text } from 'components';
import useRegisterPage from './useRegisterPage';
import RegisterStyle from './style';
import { Colors } from 'constant';
import { useNavigate } from 'react-router';
import {signUp} from '../../services/auth/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const { onClickSubmit, onChangeText } = useRegisterPage();
  const navigate = useNavigate()
  const [successRegister, setSuccessRegister] = useState(false)

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [userDOB, setuserDOB] = useState('');

  const handleSubmit = async() => {
    const values = {
      email: email,
      phone: phone,
      password: password,
      userType: process.env.REACT_APP_USERTYPE,
      userDOB:userDOB
    }
    await signUp(values).then((sign) => {
      if(sign.data.status){
        setSuccessRegister(true);
      }
    }).catch((res) => {
      toast(res.response.data.message);
    })
    
  }

  return (
    <RegisterStyle>
      <div className='input-wrapper'>
        {
          successRegister ?
            <div>
              <Text text='Thank you for your registration!' color={ Colors.prussianBlue } className='my-5' fontWeight='800' />
              <Text text='We have sent a verification link to your email please check and verify to login.' color={ Colors.prussianBlue } className='my-5' fontWeight='800' />
              <Button
                label='Go to main page!'
                onClick={ onClickSubmit }
                width='100%'
              />
            </div>
            : 
            <>
              <Input
                label='Email'
                name='email'
                placeholder='john.doe@mail.com'
                className='mb-5'
                onChange={ (e) => setEmail(e.value) }
                textColor={ Colors.prussianBlue }
              />
              <Input
                label='Password'
                placeholder='Password'
                type='password'
                name='password'
                onChange={ (e) => setPassword(e.value) }
                className='mb-5'
                textColor={ Colors.prussianBlue }
              />
              <Input
                label='Phone Number'
                name='phone'
                placeholder='Phone Number'
                className='mb-5'
                onChange={ (e) => setPhone(e.value) }
                textColor={ Colors.prussianBlue }
              />
              <Input
                label='User Dob'
                name='dob'
                type='date'
                placeholder='User DOB'
                className='mb-5'
                onChange={ (e) => setuserDOB(e.value) }
                textColor={ Colors.prussianBlue }
              />
              <Button
                label='Submit'
                onClick={ () => handleSubmit() }
                width='100%'
              />
            </>
        }

      </div>
      {/* <ToastContainer /> */}
    </RegisterStyle>
  );
};

export default Register;
