import React, { useEffect, useState } from "react";
import { Button, Layout, Modal, Text, Tabs } from "components";
import NFTCollections from "components/NFTCollections";
import { Colors, Icons, Images } from "constant";
import icons from "constant/icons";
import { NFTCard } from "components/Card";
// import { Header } from 'components';
import { Header } from "pages/LandingPageNew/Components";
import { useDispatch, useSelector } from "react-redux";
import { BsStar, BsStarFill } from "react-icons/bs";
import selectors from "selectors";
import usdcABI from "../../contractsABI/USDC.json";
import usdtABI from "../../contractsABI/USDT.json";
import restaurantBuilder from "../../contractsABI/RestaurantBuilder.json";
import governances from "../../contractsABI/Governance.json";
// import {getRestaurantNFTs, PayNow, getnftstatus} from '../../services/Nft';
import {
  getRestaurantNFTs,
  PayNow,
  delistNft,
  getnftstatus,
  PutonSale,
  declineoffer,
  getnftbyid,
  getlastsold,
  executeNfts,
  getsaleshistory,
  Placeabid,
  Makeoffer,
  getbidhistory,
} from "../../services/Nft";

import { getAllusers, getuser } from "../../services/auth";

import { toast } from "react-toastify";
import { AddFavourites, RemoveFavourites } from "../../services/favourites";
import { createSignature } from "../../services/http";
import { SET_NFT, SET_PROFILE } from "../../stores/User/userActionTypes";
import axios from "axios";
// import { AbiCoder } from 'ethers/lib/utils';
import Loader from "react-js-loader";
import { useNavigate } from "react-router-dom";
import ModalStyles from "./styles";
import group27 from "../../assets/images/Group27.svg";
import group28 from "../../assets/images/Group28.svg";
import group29 from "../../assets/images/Group29.svg";
import loadiing from "../../assets/images/loading.gif";
import { addNetwork } from "../../stores/User/WalletAction";
import { connectFailed } from "../../stores/User/WalletAction";
import S3 from "react-aws-s3";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import loading from "../../assets/images/loading.gif";
import Web3 from "web3";

import Styles from "./stylestable";
import { PutonsaleModal } from "./putonsalemodal";
import { Selectbox } from "./putonsalemodal";
import { useParams } from "react-router-dom";
import templeteabi from "../../contractsABI/TempleteABI.json";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import moment from "moment";
import CdTimerComp from "./date-time-counter/CdTimerComp";
import useCountDown from "./date-time-counter/hooks/useCountDown";
import { useLocation } from "react-router-dom";

const tabsData = [{ title: "Offer history" }, { title: "Bid history" }];
////*** */ {showSignIn, setShowSignIn,setParticular}
const NFT = () => {
  //stripe payment
  const data = [
    { date: "10/10", price: 5 },
    { date: "2/10", price: 12 },
    { date: "30/09", price: 18 },
    { date: "25/09", price: 25 },
    { date: "20/09", price: 28 },
    { date: "15/09", price: 30 },
  ];

  const stripePromise = loadStripe(
    "pk_test_51LlQoyJJB6dbjlRdZsp94l6vWtRUK5L1vlCse22WBT1ncpbMlOtDRw02dwr5nwt6dTALUL9Vw7kOuzNUCSR6sRiY00CPQpdgM8"
  );

  const [clientSecret, setClientSecret] = useState("");
  const [paymentStatus, setPaymentstatus] = useState(false);
  const nftstate = useSelector(selectors.nft);
  const { id, collectionname } = useParams();
  const [nft, setNft] = useState("");
  const [metauri, setMetauri] = useState("");
  const { state } = useLocation();

  const collectionscheck = state ? state.collectionName : collectionname;
  const user = useSelector(selectors.userProfile);
  const userToken = user.token;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const paymentCall = async (amnt) => {
    console.log("amt===>paymentCall", amnt);
    // const amount_ = Number(amnt);
    const amount_ = 40;
    fetch(`${process.env.REACT_APP_BASE_URL}/create-payment-intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "content-type",
      },
      body: JSON.stringify({ items: [{ amount: amount_ }] }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setShowModalPurchaseWithCard(true);
      });
  };

  const paymentwithcrypto = () => {
    setShowModalPurchaseWithCrypto(true);
  };
  const getNFTstatus = async () => {
    if (wallet.address) {
      const res = await getnftstatus(nft._id, current_user.token);
      if (!res.data.status) {
        toast("session expired");
        dispatch({ type: SET_PROFILE, payload: "" });
        dispatch(connectFailed());
      } else {
        // toast("session exist")
        handlePurchaseModal();
      }
    } else {
      toast("Connect Your Wallet");
    }
  };
  const [instantsaleModal, setInstantsaleModal] = useState(false);
  const [showTimedmodal, setShowTimedModal] = useState(false);
  const [instantActive, setInstantActive] = useState(false);
  const [timedActive, setTimedActive] = useState(false);
  const [oneday, setOneday] = useState(false);
  const [twoday, setTwoday] = useState(false);
  const [threeday, setThreeday] = useState(false);
  const [daytime, setDay] = useState("");
  const [maticActive, setMaticActive] = useState(false);
  const [usdActive, setUsdActive] = useState(false);
  const [usdtActive, setUsdtActive] = useState(false);
  const [showModalPurchase, setShowModalPurchase] = useState(false);
  const [showModalPurchaseBuy, setShowModalPurchaseBuy] = useState(false);

  const [Bidprice, setBidprice] = useState("");
  const [salesHistory, setSalesHistory] = useState([]);
  const [oldSaleshistory, setOldSalesHistory] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [availableMatic, setAvailablebalance] = useState("");
  const [availableUsdc, setAvailableUsdc] = useState("");
  const [availableUsdt, setAvailableUsdt] = useState("");

  const [showModalPutonsale, setShowModalPutOnsale] = useState(false);

  const wallet = useSelector((state) => state.WalletConnect);
  const navigate = useNavigate();
  const walletaddress = useSelector(selectors.walletAddress);
  const dispatch = useDispatch();
  const current_user = useSelector(selectors.userProfile);
  const createdat = nft && nft.createdAt?.slice(0, 10);
  const [open, setOpen] = useState(false);
  const [added, setAdded] = useState(false);
  const [showModalPurchaseWithCard, setShowModalPurchaseWithCard] =
    useState(false);
  const [showModalPurchaseWithCrypto, setShowModalPurchaseWithCrypto] =
    useState(false);
  const [putonSalePrice, setPutonsalePrice] = useState("");
  const [showBidforsalemodal, setShowBidforsaleModal] = useState(false);
  const [showPlaceBidmodal, setShowPlaceBidModal] = useState(false);
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [cryptoActive, setCryptoActive] = useState(false);
  const [cardActive, setCardActive] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  const [metamaskStatus, setMetamaskStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(false);

  // formatting the created date basedon db createdat
  const slcyear = createdat;
  const createdyear = slcyear?.slice(0, 4);
  const slcdate = nft && nft.createdAt.toString();
  const createddate = slcdate.split("-").splice(2, 1).join().slice(0, 2);
  const slcmonth = createdat;
  const createdmonth = slcmonth.split("-").splice(1, 1).join();
  const created_date = createdmonth + "/" + createddate + "/" + createdyear;
  const [bidhistory, setBidHistory] = useState([]);
  const [maxbidder, setMaxbidder] = useState("");
  const [offerhistory, setOfferHistory] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [allusers, setAllusers] = useState([]);
  const [days, hours, minutes, seconds] = useCountDown(
    new Date(nft.PutonsaleTime).toLocaleString()
  );
  const [lastsoldNft, setLastnft] = useState("");
  const [nftowner, setNftowner] = useState("");
  const [offermethod, setOfferMethod] = useState("usdc");
  const [bidmethod, setBidMethod] = useState("usdc");
  // getting days in required format
  var date1 = new Date(created_date);

  var date2 = new Date();
  let currentdate =
    date2.getMonth() + 1 + "/" + date2.getDate() + "/" + date2.getFullYear();
  const date3 = new Date(currentdate);
  var Difference_In_Time = date3.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const [res_nfts, setResNfts] = useState([]);
  const [maticToUsd, setMatictousd] = useState("");

  const handleDatefilter = (value) => {
    if (value == "0") {
      let arr = [];
      const results =
        oldSaleshistory &&
        oldSaleshistory.map((item) => {
          const newarr = {
            date: item.SoldTime?.slice(0, 5),
            price: Number(item.NFTPrice),
          };
          arr.push(newarr);
        });
      setSalesHistory(arr);
    } else {
      const dd = moment().add(-value, "d");
      const filteredDates1 =
        oldSaleshistory &&
        oldSaleshistory.map((d) =>
          console.log("difference:", new Date(d.SoldTime) - new Date(dd._d) > 0)
        );

      const filteredDates =
        oldSaleshistory &&
        oldSaleshistory.filter(
          (d) => new Date(d.SoldTime) - new Date(dd._d) > 0
        );
      let arr = [];
      if (filteredDates.length > 0) {
        const results = filteredDates.map((item) => {
          const newarr = {
            date: item.SoldTime?.slice(0, 5),
            price: Number(item.NFTPrice),
          };
          arr.push(newarr);
        });
        setSalesHistory(arr);
      } else {
        setSalesHistory([]);
      }
    }
  };
  const getmatictoUsd = async () => {
    const matictousd = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
            "GET",
            "POST",
            "PUT",
            "PATCH",
            "DELETE",
            "OPTIONS",
          ],
        },
      }
    );
    setMatictousd(matictousd.data["matic-network"].usd);
  };

  const handlePurchaseModal = async () => {
    const { web3 } = wallet;
    let webs3;
    if (window.ethereum) {
      webs3 = new Web3(window.ethereum);
    } else if (window.web3) {
      webs3 = new Web3(window.web3.currentProvider);
    }
    try {
      const id = current_user.user.id;
      const res = await getuser(id);
      const useres = res.data.result;
      const { address } = wallet;
      await web3.eth.getAccounts().then(async (res) => {
        // if (address.toLowerCase() == useres.walletAddress.toLowerCase()) {
        setShowModalPurchase(true);
        // } else {
        //   toast("Connect with the registered wallet address");
        // }
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const handlegetnft = async () => {
    const res = await getnftbyid(id);
    if (res.data.status) {
      setNft(res.data.result);
      const cur_nft = res.data.result;
      const {
        web3,
        marketplace,
        restaurant,
        address,
        usdcInstance,
        usdtInstance,
      } = wallet;
      if (wallet.address) {
        var NftContracts = new web3.eth.Contract(
          templeteabi,
          cur_nft && cur_nft.contractAddress
        );
        try {
          if (cur_nft.TokenId) {
            const puonsale_ = await NftContracts.methods
              .uri(cur_nft.TokenId)
              .call();
            setMetauri(puonsale_);
          }
        } catch (err) {
          console.log("error:", err);
        }
      }
    }
  };

  const [payment_type, setPaymentType] = useState("");
  const [contract_address, setContractAddress] = useState(null);
  const [token_id, setTokenId] = useState(null);

  useEffect(() => {
    setAdded(false);
    getmatictoUsd();
    getnfts();
    handlegetnft();
    getBidHistory();
    GetSalesHistory();
    getAllUSers();
    if (id !== "" && !undefined) {
      getLastsoldnft();
    }
  }, [id, wallet]);

  useEffect(() => {
    getavailablebalance();
  }, [wallet]);
  const mintNFTs = async (id) => {
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    let paymentValue;
    if (maticActive) {
      paymentValue = 1;
    } else if (usdActive) {
      paymentValue = 2;
    } else {
      paymentValue = 3;
    }

    let price_;
    if (maticActive) {
      price_ = (Number(nft.NFTPrice) / maticToUsd).toFixed(2);
    } else if (usdActive) {
      price_ = nft.NFTPrice;
    } else {
      price_ = nft.NFTPrice;
    }
    if (current_user.token) {
      if (wallet.address) {
        if (window.ethereum && window.ethereum.networkVersion !== "137") {
          toast("Switch Polygon network");
        } else {
          if (
            maticActive
              ? availableMatic &&
                availableMatic >= Number(nft.NFTPrice) / maticToUsd
              : false
          ) {
            setOpen(true);
            setMetamaskStatus(true);
            setOpenLoader(true);

            const nftPrice = web3.utils.toWei(price_, "ether");
            // getting NFT address based on the name of the restaurant
            if (usdActive) {
              const usdcInstances = new web3.eth.Contract(
                usdcABI,
                process.env.REACT_APP_USDC
              );
              const usdc_pay1 = await usdcInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdc_pay = Number(usdc_pay1) / 1000000;

              if (Number(usdc_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdcInstances.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            } else if (usdtActive) {
              const usdtInstances = new web3.eth.Contract(
                usdtABI,
                process.env.REACT_APP_USDT
              );
              const usdt_pay1 = await usdtInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdt_pay = Number(usdt_pay1) / 1000000;

              if (Number(usdt_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdtInstance.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            }

            var restaurants = new web3.eth.Contract(
              restaurantBuilder,
              process.env.REACT_APP_RESTAURANT_BUILDER
            );
            const restaurantes = restaurant ? restaurant : restaurants;
            const getRestaurantAddress = await restaurantes.methods
              .GetEventAddress(nft.OwnedBy)
              .call();
            setMetamaskStatus(false);
            setContractStatus(true);
            setContractAddress(getRestaurantAddress);
            var date = Date.now();
            const tuple = await createSignature({
              _sellerAddress: address,
              _buyerAddress: address,
              _nftAddress: getRestaurantAddress,
              _stableCoinAddress: process.env.REACT_APP_USDC,
              amount: nftPrice,
              tokenId: 1,
              fee: nft.NFTRoyalty * 100,
              paymentType: paymentValue,
              nonce: date,
            });

            let jsonData = JSON.stringify({
              name: nft.NFTName,
              description: nft.NFTDescription,
              image: nft.NFTImage,
              attributes: [
                {
                  trait_type: "Benefits",
                  value: nft.benefits,
                  type: "property",
                },
                {
                  trait_type: "Tier",
                  value: nft.NFTCollections,
                  type: "property",
                },
                {
                  trait_type: "Collection Name",
                  value: nft.CollectionName,
                  type: "property",
                },
              ],
            });

            const nftSigner = [
              address,
              address,
              getRestaurantAddress,
              process.env.REACT_APP_USDC,
              nftPrice,
              1,
              nft.NFTRoyalty * 100,
              paymentValue,
              date,
              tuple.data.result,
            ];
            try {
              let JsonResuslt = await axios.post(
                "https://api.pinata.cloud/pinning/pinJSONToIPFS",
                jsonData,
                {
                  maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
                  headers: {
                    "Content-Type": "application/json",
                    pinata_api_key: "b59485d69cc440231a45",
                    pinata_secret_api_key:
                      "3ea46d088dbd804db43cd6108209f95762759e45a604773e18846b8e73405970",
                  },
                  body: jsonData,
                }
              );

              const ipfshash =
                "https://gateway.pinata.cloud/ipfs/" +
                JsonResuslt.data.IpfsHash;

              try {
                var marketplaces = new web3.eth.Contract(
                  governances,
                  process.env.REACT_APP_OKU_GOVERNANCE
                );
                const marketplaced = marketplace ? marketplace : marketplaces;
                const mint = await marketplaced.methods
                  .buyNFT(nft.NFTTier, ipfshash, nftSigner)
                  .send({ from: address, value: maticActive ? nftPrice : 0 });
                const token = mint.events.Minted.returnValues.tokenID;
                const transactionHash_ = mint.events.Minted.transactionHash;

                setTokenId(token);

                if (token) {
                  setContractStatus(false);
                  pay_nft(id, getRestaurantAddress, token, transactionHash_);
                } else {
                  toast("Something went wrong!");
                }
              } catch (err) {
                setOpenLoader(false);
                setOpen(false);
                setShowModalPurchase(false);
                setContractStatus(false);
                toast("Something went wrong!");
              }
            } catch (err) {
              toast("Buying NFT cancelled1.");
              setCompletionStatus(false);
              setOpenLoader(false);
              setOpen(false);
              setShowModalPurchase(false);
            }
          } else if (
            usdActive
              ? availableUsdc && availableUsdc >= Number(nft.NFTPrice)
              : false
          ) {
            setOpenLoader(true);
            setOpen(true);
            setMetamaskStatus(true);
            const nftPrice1 = Number(price_) * 1000000;
            const nftPrice = nftPrice1.toString();
            // getting NFT address based on the name of the restaurant
            if (usdActive) {
              const usdcInstances = new web3.eth.Contract(
                usdcABI,
                process.env.REACT_APP_USDC
              );
              const usdc_pay1 = await usdcInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdc_pay = Number(usdc_pay1) / 1000000;

              if (Number(usdc_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdcInstances.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            } else if (usdtActive) {
              const usdtInstances = new web3.eth.Contract(
                usdtABI,
                process.env.REACT_APP_USDT
              );
              const usdt_pay1 = await usdtInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdt_pay = Number(usdt_pay1) / 1000000;
              if (Number(usdt_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdtInstances.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            }
            var restaurants = new web3.eth.Contract(
              restaurantBuilder,
              process.env.REACT_APP_RESTAURANT_BUILDER
            );
            const restaurantes = restaurant ? restaurant : restaurants;
            const getRestaurantAddress = await restaurantes.methods
              .GetEventAddress(nft.OwnedBy)
              .call();

            setMetamaskStatus(false);
            setContractStatus(true);
            setContractAddress(getRestaurantAddress);
            var date = Date.now();
            const tuple = await createSignature({
              _sellerAddress: address,
              _buyerAddress: address,
              _nftAddress: getRestaurantAddress,
              _stableCoinAddress: process.env.REACT_APP_USDC,
              amount: nftPrice,
              tokenId: 1,
              fee: nft.NFTRoyalty * 100,
              paymentType: paymentValue,
              nonce: date,
            });
            var marketplaces = new web3.eth.Contract(
              governances,
              process.env.REACT_APP_OKU_GOVERNANCE
            );

            let jsonData = JSON.stringify({
              name: nft.NFTName,
              description: nft.NFTDescription,
              image: nft.NFTImage,
              attributes: [
                {
                  trait_type: "Benefits",
                  value: nft.benefits,
                  type: "property",
                },
                {
                  trait_type: "Tier",
                  value: nft.NFTCollections,
                  type: "property",
                },
                {
                  trait_type: "Collection Name",
                  value: nft.CollectionName,
                  type: "property",
                },
              ],
            });
            const nftSigner = [
              address,
              address,
              getRestaurantAddress,
              process.env.REACT_APP_USDC,
              nftPrice,
              1,
              nft.NFTRoyalty * 100,
              paymentValue,
              date,
              tuple.data.result,
            ];
            try {
              let JsonResuslt = await axios.post(
                "https://api.pinata.cloud/pinning/pinJSONToIPFS",
                jsonData,
                {
                  maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
                  headers: {
                    "Content-Type": "application/json",
                    pinata_api_key: "b59485d69cc440231a45",
                    pinata_secret_api_key:
                      "3ea46d088dbd804db43cd6108209f95762759e45a604773e18846b8e73405970",
                  },
                  body: jsonData,
                }
              );

              const ipfshash =
                "https://gateway.pinata.cloud/ipfs/" +
                JsonResuslt.data.IpfsHash;

              try {
                var marketplaces = new web3.eth.Contract(
                  governances,
                  process.env.REACT_APP_OKU_GOVERNANCE
                );
                const marketplaced = marketplace ? marketplace : marketplaces;
                const mint = await marketplaced.methods
                  .buyNFT(nft.NFTTier, ipfshash, nftSigner)
                  .send({ from: address, value: maticActive ? nftPrice : 0 });
                const token = mint.events.Minted.returnValues.tokenID;
                const transactionHash_ = mint.events.Minted.transactionHash;
                setTokenId(token);

                if (token) {
                  setContractStatus(false);
                  pay_nft(id, getRestaurantAddress, token, transactionHash_);
                } else {
                  toast("Something went wrong!");
                }
              } catch (err) {
                setOpenLoader(false);
                setOpen(false);
                setShowModalPurchase(false);
                setContractStatus(false);
                toast("Something went wrong!");
              }
            } catch (err) {
              toast("Buying NFT cancelled2.");
              setCompletionStatus(false);
              setOpenLoader(false);
              setOpen(false);
              setShowModalPurchase(false);
            }
          } else if (
            usdtActive
              ? availableUsdt && availableUsdt >= Number(nft.NFTPrice)
              : false
          ) {
            setOpenLoader(true);
            const nftPrice1 = Number(price_) * 1000000;
            const nftPrice = nftPrice1.toString();

            // getting NFT address based on the name of the restaurant
            if (usdActive) {
              const usdcInstances = new web3.eth.Contract(
                usdcABI,
                process.env.REACT_APP_USDC
              );
              const usdc_pay1 = await usdcInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdc_pay = Number(usdc_pay1) / 1000000;
              if (Number(usdc_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdcInstances.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            } else if (usdtActive) {
              const usdtInstances = new web3.eth.Contract(
                usdtABI,
                process.env.REACT_APP_USDT
              );
              const usdt_pay1 = await usdtInstances.methods
                .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
                .call();
              const usdt_pay = Number(usdt_pay1) / 1000000;
              if (Number(usdt_pay) < Number(price_)) {
                try {
                  const usdc_approve = await usdtInstances.methods
                    .approve(
                      process.env.REACT_APP_OKU_GOVERNANCE,
                      "9999999999999999999"
                    )
                    .send({ from: address });
                } catch (err) {
                  setOpenLoader(false);
                  setShowModalPurchase(false);
                  toast("User cancelled buying Nft ");
                }
              }
            }
            var restaurants = new web3.eth.Contract(
              restaurantBuilder,
              process.env.REACT_APP_RESTAURANT_BUILDER
            );
            const restaurantes = restaurant ? restaurant : restaurants;

            const getRestaurantAddress = await restaurantes.methods
              .GetEventAddress(nft.OwnedBy)
              .call();

            setMetamaskStatus(false);
            setContractStatus(true);
            setContractAddress(getRestaurantAddress);
            var date = Date.now();
            const tuple = await createSignature({
              _sellerAddress: address,
              _buyerAddress: address,
              _nftAddress: getRestaurantAddress,
              _stableCoinAddress: process.env.REACT_APP_USDC,
              amount: nftPrice,
              tokenId: 1,
              fee: nft.NFTRoyalty * 100,
              paymentType: paymentValue,
              nonce: date,
            });
            var marketplaces = new web3.eth.Contract(
              governances,
              process.env.REACT_APP_OKU_GOVERNANCE
            );
            const marketplaced = marketplace ? marketplace : marketplaces;

            let jsonData = JSON.stringify({
              name: nft.NFTName,
              description: nft.NFTDescription,
              image: nft.NFTImage,
              attributes: [
                {
                  trait_type: "Benefits",
                  value: nft.benefits,
                  type: "property",
                },
                {
                  trait_type: "Tier",
                  value: nft.NFTCollections,
                  type: "property",
                },
                {
                  trait_type: "Collection Name",
                  value: nft.CollectionName,
                  type: "property",
                },
              ],
            });
            const nftSigner = [
              address,
              address,
              getRestaurantAddress,
              process.env.REACT_APP_USDC,
              nftPrice,
              1,
              nft.NFTRoyalty * 100,
              paymentValue,
              date,
              tuple.data.result,
            ];
            try {
              let JsonResuslt = await axios.post(
                "https://api.pinata.cloud/pinning/pinJSONToIPFS",
                jsonData,
                {
                  maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
                  headers: {
                    "Content-Type": "application/json",
                    pinata_api_key: "b59485d69cc440231a45",
                    pinata_secret_api_key:
                      "3ea46d088dbd804db43cd6108209f95762759e45a604773e18846b8e73405970",
                  },
                  body: jsonData,
                }
              );

              const ipfshash =
                "https://gateway.pinata.cloud/ipfs/" +
                JsonResuslt.data.IpfsHash;

              try {
                var marketplaces = new web3.eth.Contract(
                  governances,
                  process.env.REACT_APP_OKU_GOVERNANCE
                );
                const marketplaced = marketplace ? marketplace : marketplaces;
                const mint = await marketplaced.methods
                  .buyNFT(nft.NFTTier, ipfshash, nftSigner)
                  .send({ from: address, value: maticActive ? nftPrice : 0 });
                const token = mint.events.Minted.returnValues.tokenID;
                const transactionHash_ = mint.events.Minted.transactionHash;
                setTokenId(token);

                if (token) {
                  setContractStatus(false);
                  pay_nft(id, getRestaurantAddress, token, transactionHash_);
                } else {
                  toast("Something went wrong!");
                }
              } catch (err) {
                setOpenLoader(false);
                setOpen(false);
                setShowModalPurchase(false);
                setContractStatus(false);
                toast("Something went wrong!");
              }
            } catch (err) {
              toast("Buying NFT cancelled3.");
              setCompletionStatus(false);
              setOpenLoader(false);
              setOpen(false);
              setShowModalPurchase(false);
            }
          } else {
            setShowModalPurchase(false);
            toast("You dont have enough balance to buy this nft");
          }
        }
      } else {
        setOpen(false);
        setShowModalPurchase(false);
        toast("Connet your wallet");
      }
    } else {
      navigate("/marketplace");
    }
  };

  // after payment
  const [loaderimage, setLoaderimage] = useState(false);
  const mintNFTsAfterPayment = async (id) => {
    const { web3, marketplace, restaurant, address } = wallet;
    setLoaderimage(true);
    setMetamaskStatus(true);
    if (current_user.token) {
      if (wallet.address) {
        if (window.ethereum && window.ethereum.networkVersion !== "137") {
          setOpenLoader(false);
          setOpen(false);
          setShowModalPurchase(false);
          toast("Switch to polygon nertwork.");
        } else {
          setLoaderimage(true);

          const nftPrice = web3.utils.toWei(nft.NFTPrice, "ether");
          //!!!
          // getting NFT address based on the name of the restaurant
          var restaurants = new web3.eth.Contract(
            restaurantBuilder,
            process.env.REACT_APP_RESTAURANT_BUILDER
          );
          const restaurantes = restaurant ? restaurant : restaurants;

          const getRestaurantAddress = await restaurantes.methods
            .GetEventAddress(nft.OwnedBy)
            .call();

          setMetamaskStatus(false);
          setContractStatus(true);
          setContractAddress(getRestaurantAddress);
          var date = Date.now();
          const tuple = await createSignature({
            _sellerAddress: address,
            _buyerAddress: address,
            _nftAddress: getRestaurantAddress,
            _stableCoinAddress: process.env.REACT_APP_USDT,
            amount: nftPrice,
            tokenId: 1,
            fee: nft.NFTRoyalty * 100,
            paymentType: 4,
            nonce: date,
          });
          // const LastMintid = await marketplace.methods
          //   .tokenID(getRestaurantAddress)
          //   .call();
          // var mintid = parseInt(LastMintid) + 2;
          // const filename = mintid + ".json";
          let jsonData = JSON.stringify({
            name: nft.NFTName,
            description: nft.NFTDescription,
            image: nft.NFTImage,
            attributes: [
              {
                trait_type: "Benefits",
                value: nft.benefits,
                type: "property",
              },
              {
                trait_type: "Tier",
                value: nft.NFTCollections,
                type: "property",
              },
              {
                trait_type: "Collection Name",
                value: nft.CollectionName,
                type: "property",
              },
            ],
          });
          const nftSigner = [
            address,
            address,
            getRestaurantAddress,
            process.env.REACT_APP_USDT,
            nftPrice,
            1,
            nft.NFTRoyalty * 100,
            4,
            date,
            tuple.data.result,
          ];

          try {
            let JsonResuslt = await axios.post(
              "https://api.pinata.cloud/pinning/pinJSONToIPFS",
              jsonData,
              {
                maxBodyLength: "Infinity", //this is needed to prevent axios from erroring out with large files
                headers: {
                  "Content-Type": "application/json",
                  pinata_api_key: "b59485d69cc440231a45",
                  pinata_secret_api_key:
                    "3ea46d088dbd804db43cd6108209f95762759e45a604773e18846b8e73405970",
                },
                body: jsonData,
              }
            );

            const ipfshash =
              "https://gateway.pinata.cloud/ipfs/" + JsonResuslt.data.IpfsHash;

            var marketplaces = new web3.eth.Contract(
              governances,
              process.env.REACT_APP_OKU_GOVERNANCE
            );
            const marketplaced = marketplace ? marketplace : marketplaces;
            const mint = await marketplaced.methods
              .buyNFT(nft.NFTTier, ipfshash, nftSigner)
              .send({ from: address, value: "0" });
            const token = mint.events.Minted.returnValues.tokenID;
            const transactionHash_ = mint.events.Minted.transactionHash;
            setTokenId(token);

            if (token) {
              setContractStatus(false);
              setLoaderimage(false);
              pay_nft(id, getRestaurantAddress, token, transactionHash_);
            } else {
              toast("Something went wrong!");
            }
          } catch (err) {
            setLoaderimage(false);
            toast("Buying NFT cancelled4.");
            setCompletionStatus(false);
            setOpenLoader(false);
            setOpen(false);
            setShowModalPurchase(false);
          }
        }
      } else {
        setOpen(false);
        setShowModalPurchase(false);
        toast("Connet your wallet");
      }
    } else {
      navigate("/marketplace");
    }
  };
  const pay_nft = async (id, getRestaurantAddress, token, transactionHash_) => {
    setCompletionStatus(true);
    let paymentmethod;
    let amount_;
    if (maticActive) {
      paymentmethod = "matic";
      amount_ = (Number(nft.NFTPrice) / maticToUsd).toFixed(2);
    } else if (usdActive) {
      paymentmethod = "usdc";
      amount_ = nft.NFTPrice;
    } else if (usdtActive) {
      paymentmethod = "usdt";
      amount_ = nft.NFTPrice;
    } else {
      paymentmethod = "card";
      amount_ = nft.NFTPrice;
    }

    const values = {
      walletAddress: wallet.address,
      TokenId: token,
      contractAddress: getRestaurantAddress,
      transactionHash: transactionHash_,
      paymentMethod: paymentmethod,
      paymentAmount: amount_,
    };
    const tokens = current_user.token;
    mintNFTs;
    const res = await PayNow(id, values, tokens);
    handlegetnft();
    setShowModalPurchaseWithCard(false);
    setShowModalPurchaseWithCrypto(false);
    setShowModalPurchase(false);
    setUsdActive(false);
    setUsdtActive(false);
    setMaticActive(false);
    // window.location.reload();
    setPaymentstatus(false);
    if (res.data.status) {
      setShowModalPurchase(false);
      setShowModalSuccess(true);
      nft.status = "sold";
      dispatch({
        type: SET_NFT,
        payload: nft,
      });
      getnfts();
      setShowModalPurchase(false);
      setCompletionStatus(false);
      setLoaderimage(false);
      setOpenLoader(false);
      setOpen(false);
      toast("Successfully paid to this nft");
    } else {
      toast("Token expired");
      dispatch({
        type: "SET_WALLET_ADDRESS",
        payload: "",
      });
      dispatch({
        type: "SET_PROFILE",
        payload: "",
      });
      setCompletionStatus(false);
      navigate("/marketplace");
      setOpen(false);
    }
  };

  const renderNFTCards = () => {
    return (
      res_nfts &&
      res_nfts
        .filter((item) => item.CollectionName == collectionscheck)
        .map((el, idx) => {
          return (
            <NFTCard
              wrapperClass="w-full hover:-translate-y-4 border border-solid border-platinumSolid h-[375px] hover:border-[2px] hover:border-abundance "
              item={el}
              restoName={`Restaurant ${idx}`}
              key={idx}
              maticToUsd={maticToUsd}
            />
          );
        })
    );
  };

  const NftRemovefromFavourite = async () => {
    if (userToken) {
      setAdded(true);
      let newnft = { ...nft };
      newnft.type = "NFT";
      newnft.userid = current_user.user.id;
      const id = current_user.user.id;
      delete newnft.updatedAt;
      delete newnft.createdAt;
      try {
        const removefav = await RemoveFavourites(
          id,
          newnft,
          current_user.token
        );
        if (removefav.data.status) {
          nft.Likes.filter((item) => item !== current_user.user.id);
          dispatch({
            type: SET_NFT,
            payload: nft,
          });
          setOpenLoader(false);
          navigate("/myprofile?favourite");
          setOpen(false);
          toast("NFT Removed from favourites");
        }
      } catch {
        console.log("remove error:", err);
      }
    } else {
      toast("Please Sign In to Continue");
    }
  };
  const NftAddtoFavourite = async () => {
    if (userToken) {
      setAdded(true);
      let newnft = { ...nft };
      newnft.type = "NFT";
      const id = current_user.user.id;
      delete newnft.updatedAt;
      delete newnft.createdAt;
      try {
        const addfav = await AddFavourites(id, newnft, current_user.token);
        if (addfav.data.status) {
          nft.Likes.push(current_user.user.id);
          dispatch({
            type: SET_NFT,
            payload: nft,
          });
          setOpenLoader(false);
          navigate("/myprofile?favourite");
          setOpen(false);
          toast("NFT Added to favourites");
        } else {
          dispatch({
            type: "SET_PROFILE",
            payload: "",
          });
          const { web3Modal } = wallet;
          web3Modal.clearCachedProvider();
          dispatch(connectFailed());
          navigate("/marketplace");
          toast("Session expired");
        }
      } catch (err) {
        console.log("update error:", err);
      }
    } else {
      toast("Please Sign In to Continue");
    }
  };

  const today_ = Date.now();
  const b_today = new Date(today_);
  const month = (b_today.getMonth() + 1).toString().padStart(2, "0");
  const day = b_today.getDate().toString().padStart(2, "0");
  const today_date = b_today.getFullYear() + "-" + month + "-" + day;
  const c = new Date(nft.NFTEndDate);

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const converted = convert(nft.NFTEndDate);

  console.log("today_date==>", today_date, "converted==>", converted);
  const date_comparison = today_date <= converted;
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  //from put on sale page

  const handleListMyNFT = () => {
    if (instantActive) {
      setShowModalPutOnsale(false);
      setInstantsaleModal(true);
    } else {
      setShowModalPutOnsale(false);
      setShowTimedModal(true);
    }
  };
  const [price, setPrice] = useState("");

  const BuyNFT = async (id) => {
    setShowModalPurchaseBuy(false);
    setMetamaskStatus(true);
    setOpenLoader(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    let coinaddress;
    if (maticActive) {
      coinaddress = process.env.REACT_APP_USDT;
    } else if (usdtActive) {
      coinaddress = process.env.REACT_APP_USDT;
    } else {
      coinaddress = process.env.REACT_APP_USDC;
    }

    let paymentValue;
    if (maticActive) {
      paymentValue = 1;
    } else if (usdActive) {
      paymentValue = 2;
    } else {
      paymentValue = 3;
    }

    let price_;
    let nftPrice;
    let nftPrice1;
    if (maticActive) {
      price_ = (Number(nft.NFTPrice) / maticToUsd).toFixed(2);
      nftPrice = web3.utils.toWei(price_, "ether");
    } else if (usdActive) {
      price_ = nft.NFTPrice;
      nftPrice1 = Number(price_) * 1000000;
      nftPrice = nftPrice1.toString();
      // nftPrice = web3.utils.toWei(price_, "ether")
    } else {
      price_ = nft.NFTPrice;
      nftPrice1 = Number(price_) * 1000000;
      nftPrice = nftPrice1.toString();
      // nftPrice = web3.utils.toWei(price_, "ether")
    }
    var date = Date.now();
    const tuple = await createSignature({
      _sellerAddress: nft.walletAddress,
      _buyerAddress: address,
      _nftAddress: nft && nft.contractAddress,
      _stableCoinAddress: coinaddress,
      amount: nftPrice,
      tokenId: nft.TokenId,
      fee: 10,
      paymentType: paymentValue,
      nonce: date,
    });
    const nftSigner = [
      nft.walletAddress,
      address,
      nft && nft.contractAddress,
      coinaddress,
      nftPrice,
      nft.TokenId,
      10,
      paymentValue,
      date,
      tuple.data.result,
    ];

    if (usdActive) {
      const usdcInstances = new web3.eth.Contract(
        usdcABI,
        process.env.REACT_APP_USDC
      );
      const usdc_pay1 = await usdcInstances.methods
        .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
        .call();
      const usdc_pay = Number(usdc_pay1) / 1000000;
      if (Number(usdc_pay) < Number(price_)) {
        try {
          const usdc_approve = await usdcInstances.methods
            .approve(
              process.env.REACT_APP_OKU_GOVERNANCE,
              "999999999999999999999999999999"
            )
            .send({ from: address });
        } catch (err) {
          setOpenLoader(false);
          setShowModalPurchaseBuy(false);
          toast("User cancelled buying Nft ");
        }
      }
    } else if (usdtActive) {
      const usdtInstances = new web3.eth.Contract(
        usdtABI,
        process.env.REACT_APP_USDT
      );
      const usdt_pay1 = await usdtInstances.methods
        .allowance(address, process.env.REACT_APP_OKU_GOVERNANCE)
        .call();
      const usdt_pay = Number(usdt_pay1) / 1000000;
      if (Number(usdt_pay) < Number(price_)) {
        try {
          const usdc_approve = await usdtInstances.methods
            .approve(
              process.env.REACT_APP_OKU_GOVERNANCE,
              "999999999999999999999999999999999"
            )
            .send({ from: address });
        } catch (err) {
          setOpenLoader(false);
          setShowModalPurchaseBuy(false);
          toast("User cancelled buying Nft ");
        }
      }
    }
    setContractStatus(true);
    setMetamaskStatus(false);
    try {
      var marketplaces = new web3.eth.Contract(
        governances,
        process.env.REACT_APP_OKU_GOVERNANCE
      );
      const marketplaced = marketplace ? marketplace : marketplaces;
      const buy_nft = await marketplaced.methods
        .executeOrder(nftSigner, nft.NFTTier)
        .send({ from: address, value: maticActive ? nftPrice : 0 });
      const transactionHash_ = buy_nft.transactionHash;
      if (buy_nft.status) {
        paynft(id, transactionHash_);
      } else {
        setOpenLoader(false);
        setShowModalPurchaseBuy(false);
      }
    } catch (err) {
      toast("failed to buy nft1");
      console.log("error", err);
      setOpenLoader(false);
    }
  };

  const BuyNFTAfterPayment = async (id) => {
    setLoaderimage(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    const nftPrice = nft.NFTPrice;
    var date = Date.now();
    const coinaddress = process.env.REACT_APP_USDC;
    const tuple = await createSignature({
      _sellerAddress: nft.walletAddress,
      _buyerAddress: address,
      _nftAddress: nft && nft.contractAddress,
      _stableCoinAddress: coinaddress,
      amount: nftPrice,
      tokenId: nft.TokenId,
      fee: 10,
      paymentType: 4,
      nonce: date,
    });

    const nftSigner = [
      nft.walletAddress,
      address,
      nft && nft.contractAddress,
      coinaddress,
      nftPrice,
      nft.TokenId,
      10,
      4,
      date,
      tuple.data.result,
    ];
    try {
      var marketplaces = new web3.eth.Contract(
        governances,
        process.env.REACT_APP_OKU_GOVERNANCE
      );
      const marketplaced = marketplace ? marketplace : marketplaces;
      const buy_nft = await marketplaced.methods
        .executeOrder(nftSigner, nft.NFTTier)
        .send({ from: address, value: 0 });
      const transactionHash_ = buy_nft.transactionHash;
      if (buy_nft.status) {
        paynft(id, transactionHash_);
      } else {
        setLoaderimage(false);
        setShowModalPurchaseBuy(false);
      }
    } catch (err) {
      toast("failed to buy nft");
      setOpenLoader(false);
    }
  };

  const paynft = async (id, transactionHash_) => {
    let paymentmethod;
    let amount_;
    if (maticActive) {
      paymentmethod = "matic";
      amount_ = (Number(nft.NFTPrice) / maticToUsd).toFixed(2);
    } else if (usdActive) {
      paymentmethod = "usdc";
      amount_ = nft.NFTPrice;
    } else if (usdtActive) {
      paymentmethod = "usdt";
      amount_ = nft.NFTPrice;
    } else {
      paymentmethod = "card";
      amount_ = nft.NFTPrice;
    }
    const values = {
      walletAddress: wallet.address,
      TokenId: nft.TokenId,
      from: nft.walletAddress,
      contractAddress: nft.contractAddress,
      transactionHash: transactionHash_,
      paymentMethod: paymentmethod,
      paymentAmount: amount_,
    };
    const tokens = current_user.token;
    const res = await executeNfts(id, values, tokens);
    if (res.data.status) {
      setOpenLoader(false);
      setLoaderimage(false);
      setShowModalPurchaseBuy(false);
      getnfts();
      handlegetnft();
      getBidHistory();
      GetSalesHistory();
      getAllUSers();
      getLastsoldnft();
      toast("Buying nft successfull");
    } else {
      toast("something went wrong");
    }
  };

  const putOnSaleContract = async () => {
    setInstantsaleModal(false);
    setShowTimedModal(false);
    setOpenLoader(true);
    setContractStatus(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try {
      const puonsale_ = await NftContracts.methods
        .approve(process.env.REACT_APP_OKU_GOVERNANCE, nft.TokenId, true)
        .send({ from: address });
      if (puonsale_.status) {
        handlePutonSale(puonsale_.transactionHash);
      } else {
        toast("failed");
      }
    } catch (err) {
      setOpenLoader(false);
      toast("Cancelled put on sale ");
    }
  };

  const handlePutonSale = async (transactionHash) => {
    setContractStatus(false);
    setCompletionStatus(true);

    let values;
    if (instantActive) {
      values = {
        type: "instantsale",
        Price: putonSalePrice,
        PutonsaleTime: "",
        transactionHash: transactionHash,
        from: wallet.address,
        paymentMethod: "dollar",
      };
    } else {
      values = {
        type: "timedsale",
        Price: putonSalePrice,
        transactionHash: transactionHash,
        PutonsaleTime: daytime,
        transactionHash: transactionHash,
        from: wallet.address,
        paymentMethod: "dollar",
      };
    }
    const res = await PutonSale(id, values, current_user.token);
    if (res.data.status) {
      handlegetnft();
      setOpenLoader(false);
      setShowTimedModal(false);
      setCompletionStatus(false);
      setShowModalPutOnsale(false);
      setInstantsaleModal(false);
      setShowBidforsaleModal(false);
      toast("Put on sale success");
    } else {
      toast("Put on sale failed");
    }
  };
  const handleDayOne = () => {
    const new_date = moment().add(1, "d");
    var day = new_date.format("YYYY-MM-DDTHH:mm:ss");
    setDay(day);
  };
  const handleDayTwo = () => {
    const new_date = moment().add(2, "d");
    var day = new_date.format("YYYY-MM-DDTHH:mm:ss");
    setDay(day);
  };
  const handleDayThree = () => {
    const new_date = moment().add(3, "d");
    var day = new_date.format("YYYY-MM-DDTHH:mm:ss");
    setDay(day);
  };

  const PlaceABid = async (hash_) => {
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    const values = {
      BidBy: current_user.user.id,
      type: "bid",
      NFTId: nft._id,
      NFTName: nft.NFTName,
      BidPrice: Bidprice && Bidprice,
      walletAddress: wallet.address,
      transactionHash: hash_,
      BidMethod: bidmethod,
    };
    const res = await Placeabid(values);
    if (res.data.status) {
      setOpenLoader(false);
      setCompletionStatus(false);
      setShowPlaceBidModal(false);
      getnfts();
      handlegetnft();
      getBidHistory();
      GetSalesHistory();
      getLastsoldnft();
      toast("You bidded for this nft");
      setBidprice("");
    } else {
      setOpenLoader(false);
      setCompletionStatus(false);
      toast("something went wrong");
    }
  };

  const MakeAnOffer = async (hash_) => {
    setContractStatus(false);
    setCompletionStatus(true);
    const price_1 = (Number(Bidprice) * maticToUsd).toFixed(2);
    let method_;
    let price_;
    if (offermethod == "matic") {
      method_ = "matic";
      price_ = (Number(Bidprice) / maticToUsd).toFixed(2);
    } else if (offermethod == "usdc") {
      method_ = "usdc";
      price_ = Number(Bidprice).toFixed(2);
    } else {
      method_ = "usdt";
      price_ = Number(Bidprice).toFixed(2);
    }
    const values = {
      OfferBy: current_user.user.id,
      type: "makeoffer",
      NFTId: nft._id,
      NFTName: nft.NFTName,
      BidPrice: price_,
      walletAddress: wallet.address,
      transactionHash: hash_,
      OfferMethod: method_,
    };
    const res = await Makeoffer(values);
    if (res.data.status) {
      setOpenLoader(false);
      setCompletionStatus(false);
      getBidHistory();
      handlegetnft();
      toast("Make Offer succes");
      setBidprice("");
      setShowMakeOfferModal(false);
    }
  };

  const handlePlaceBid = async () => {
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
      wrappedMatic,
    } = wallet;
    setShowPlaceBidModal(false);

    let price_ = Bidprice;
    const nftPrice1 = Number(price_) * 1000000;
    const nftPrice = nftPrice1.toString();
    // const nftPrice = web3.utils.toWei(price_, "ether");
    let ress;
    if (bidmethod == "usdc") {
      setMetamaskStatus(false);
      setContractStatus(true);
      if (availableUsdc >= Number(Bidprice)) {
        setOpenLoader(true);
        setMetamaskStatus(false);
        try {
          const usdcInstances = new web3.eth.Contract(
            usdcABI,
            process.env.REACT_APP_USDC
          );
          ress = await usdcInstances.methods
            .approve(process.env.REACT_APP_OKU_GOVERNANCE, nftPrice)
            .send({ from: address });
        } catch (err) {
          setContractStatus(false);
          setCompletionStatus(true);
          setOpenLoader(false);
          toast("Place bid failed");
        }
      } else {
        toast("You dont have enough USDC in Your wallet");
      }
    } else {
      if (availableUsdt >= Number(Bidprice)) {
        setOpenLoader(true);
        setMetamaskStatus(true);
        try {
          const usdtInstances = new web3.eth.Contract(
            usdtABI,
            process.env.REACT_APP_USDT
          );
          ress = await usdtInstances.methods
            .approve(process.env.REACT_APP_OKU_GOVERNANCE, nftPrice)
            .send({ from: address });
        } catch (err) {
          setContractStatus(false);
          setCompletionStatus(true);
          setOpenLoader(false);
          toast("Place bid failed");
        }
      } else {
        toast("You dont have enough USDT in Your wallet");
      }
    }
    if (ress.status) {
      setContractStatus(false);
      setCompletionStatus(true);
      PlaceABid(ress.transactionHash);
    } else {
      setContractStatus(false);
      setCompletionStatus(false);
      setOpenLoader(false);
      // toast("Place bid failed")
    }
  };

  const handleSettle = async (item) => {
    setOpenLoader(true);
    setMetamaskStatus(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
      wrappedMatic,
    } = wallet;
    const nftPrice1 = Number(item.BidPrice) * 1000000;
    const nftPrice = nftPrice1.toString();
    // const nftPrice = web3.utils.toWei(item.BidPrice, "ether");
    let coinaddress;
    let paymentid;
    if (item.BidMethod == "usdc") {
      coinaddress = process.env.REACT_APP_USDC;
      paymentid = 2;
    } else {
      coinaddress = process.env.REACT_APP_USDT;
      paymentid = 3;
    }
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try {
      setContractStatus(true);
      setMetamaskStatus(false);
      const approval_ = await NftContracts.methods
        .approve(process.env.REACT_APP_OKU_GOVERNANCE, nft.TokenId, true)
        .send({ from: address });
      if (approval_.status) {
        var date = Date.now();
        const tuple = await createSignature({
          _sellerAddress: address,
          _buyerAddress: item.walletAddress,
          _nftAddress: nft && nft.contractAddress,
          _stableCoinAddress: coinaddress,
          amount: nftPrice,
          tokenId: nft.TokenId,
          fee: 10,
          paymentType: paymentid,
          nonce: date,
        });

        const nftSigner = [
          address,
          item.walletAddress,
          nft && nft.contractAddress,
          coinaddress,
          nftPrice,
          nft.TokenId,
          10,
          paymentid,
          date,
          tuple.data.result,
        ];

        var marketplaces = new web3.eth.Contract(
          governances,
          process.env.REACT_APP_OKU_GOVERNANCE
        );
        const marketplaced = marketplace ? marketplace : marketplaces;

        const sell_nft = await marketplaced.methods
          .executeOrder(nftSigner, nft.NFTTier)
          .send({ from: address, value: 0 });

        if (sell_nft.status) {
          setContractStatus(false);
          setCompletionStatus(true);
          const values = {
            walletAddress: item.walletAddress,
            from: wallet.address,
            TokenId: nft.TokenId,
            contractAddress: nft.contractAddress,
            transactionHash: sell_nft.transactionHash,
            paymentMethod: item.BidMethod,
            paymentAmount: item.BidPrice,
          };
          const tokens = current_user.token;
          const res = await executeNfts(id, values, tokens);
          if (res.data.status) {
            getnfts();
            handlegetnft();
            getBidHistory();
            GetSalesHistory();
            getAllUSers();
            getLastsoldnft();
            setOpenLoader(false);
          }
        }
      }
    } catch (err) {
      console.log("error:", err);
      setOpenLoader(false);
      toast("user cancelled approval");
    }
  };

  const handlemakeOffer = async () => {
    setShowMakeOfferModal(false);
    setOpenLoader(true);
    setMetamaskStatus(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
      wrappedMatic,
    } = wallet;

    let price_ = (Number(Bidprice) / maticToUsd).toFixed(2);

    if (offermethod == "usdc") {
      const nftPrice1 = Number(Bidprice) * 1000000;
      const nftPrice = nftPrice1.toString();
      if (availableUsdc >= Number(Bidprice)) {
        setMetamaskStatus(false);
        setContractStatus(true);
        try {
          const usdcInstances = new web3.eth.Contract(
            usdcABI,
            process.env.REACT_APP_USDC
          );
          const ress1 = await usdcInstances.methods
            .approve(process.env.REACT_APP_OKU_GOVERNANCE, nftPrice)
            .send({ from: address });
          if (ress1.status) {
            MakeAnOffer(ress1.transactionHash);
          } else {
            toast("Make offer failed");
          }
        } catch (err) {
          toast("Approval cancelled");
          setMetamaskStatus(false);
          setContractStatus(false);
          setOpenLoader(false);
        }
      } else {
        setMetamaskStatus(false);
        setContractStatus(false);
        setOpenLoader(false);
        toast("You dont have enough USDC in Your wallet");
      }
    } else if (offermethod == "usdt") {
      if (availableUsdt >= Number(Bidprice)) {
        const nftPrice1 = Number(Bidprice) * 1000000;
        const nftPrice = nftPrice1.toString();
        setMetamaskStatus(false);
        setContractStatus(true);
        try {
          const usdtInstances = new web3.eth.Contract(
            usdtABI,
            process.env.REACT_APP_USDT
          );
          const ress1 = await usdtInstances.methods
            .approve(process.env.REACT_APP_OKU_GOVERNANCE, nftPrice)
            .send({ from: address });
          if (ress1.status) {
            MakeAnOffer(ress1.transactionHash);
          } else {
            toast("Make offer failed");
          }
        } catch (err) {
          toast("Approval cancelled");
          setMetamaskStatus(false);
          setContractStatus(false);
          setOpenLoader(false);
        }
      } else {
        setMetamaskStatus(false);
        setContractStatus(false);
        setOpenLoader(false);
        toast("You dont have enough USDT in Your wallet");
      }
    }
  };

  const handleAccept = async (item) => {
    setOpenLoader(true);
    setMetamaskStatus(true);

    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
      wrappedMatic,
    } = wallet;

    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try {
      setContractStatus(true);
      setMetamaskStatus(false);
      const acceptapprove = await NftContracts.methods
        .approve(process.env.REACT_APP_OKU_GOVERNANCE, nft.TokenId, true)
        .send({ from: address });

      if (acceptapprove.status) {
        let nftPrice;
        if (item.OfferMethod == "matic") {
          nftPrice = web3.utils.toWei(item.BidPrice, "ether");
        } else {
          const nftPrice1 = Number(item.BidPrice) * 1000000;
          nftPrice = nftPrice1.toString();
        }

        // const nftPrice = web3.utils.toWei(item.BidPrice, "ether")
        let sell_nft;
        if (item.OfferMethod == "matic") {
          const coinaddress = process.env.REACT_APP_WRAPPED_MATIC;
          var date = Date.now();
          const tuple = await createSignature({
            _sellerAddress: address,
            _buyerAddress: item.walletAddress,
            _nftAddress: nft && nft.contractAddress,
            _stableCoinAddress: coinaddress,
            amount: nftPrice,
            tokenId: nft.TokenId,
            fee: 10,
            paymentType: 4,
            nonce: date,
          });

          const nftSigner = [
            address,
            item.walletAddress,
            nft && nft.contractAddress,
            coinaddress,
            nftPrice,
            nft.TokenId,
            10,
            4,
            date,
            tuple.data.result,
          ];
          setContractStatus(true);
          setMetamaskStatus(false);
          var marketplaces = new web3.eth.Contract(
            governances,
            process.env.REACT_APP_OKU_GOVERNANCE
          );
          const marketplaced = marketplace ? marketplace : marketplaces;
          sell_nft = await marketplaced.methods
            .executeOrder(nftSigner, nft.NFTTier)
            .send({ from: address, value: 0 });
        } else if (item.OfferMethod == "usdc") {
          const coinaddress = process.env.REACT_APP_USDC;
          var date = Date.now();
          const tuple = await createSignature({
            _sellerAddress: address,
            _buyerAddress: item.walletAddress,
            _nftAddress: nft && nft.contractAddress,
            _stableCoinAddress: coinaddress,
            amount: nftPrice,
            tokenId: nft.TokenId,
            fee: 10,
            paymentType: 2,
            nonce: date,
          });

          const nftSigner = [
            address,
            item.walletAddress,
            nft && nft.contractAddress,
            coinaddress,
            nftPrice,
            nft.TokenId,
            10,
            2,
            date,
            tuple.data.result,
          ];
          setContractStatus(true);
          setMetamaskStatus(false);
          var marketplaces = new web3.eth.Contract(
            governances,
            process.env.REACT_APP_OKU_GOVERNANCE
          );
          const marketplaced = marketplace ? marketplace : marketplaces;
          sell_nft = await marketplaced.methods
            .executeOrder(nftSigner, nft.NFTTier)
            .send({ from: address, value: 0 });
        } else if (item.OfferMethod == "usdt") {
          const coinaddress = process.env.REACT_APP_USDT;
          var date = Date.now();
          const tuple = await createSignature({
            _sellerAddress: address,
            _buyerAddress: item.walletAddress,
            _nftAddress: nft && nft.contractAddress,
            _stableCoinAddress: coinaddress,
            amount: nftPrice,
            tokenId: nft.TokenId,
            fee: 10,
            paymentType: 3,
            nonce: date,
          });

          const nftSigner = [
            address,
            item.walletAddress,
            nft && nft.contractAddress,
            coinaddress,
            nftPrice,
            nft.TokenId,
            10,
            3,
            date,
            tuple.data.result,
          ];
          setContractStatus(true);
          setMetamaskStatus(false);
          var marketplaces = new web3.eth.Contract(
            governances,
            process.env.REACT_APP_OKU_GOVERNANCE
          );
          const marketplaced = marketplace ? marketplace : marketplaces;
          sell_nft = await marketplaced.methods
            .executeOrder(nftSigner, nft.NFTTier)
            .send({ from: address, value: 0 });
        }
        if (sell_nft.status) {
          setContractStatus(false);
          setCompletionStatus(true);
          const values = {
            walletAddress: item.walletAddress,
            from: wallet.address,
            TokenId: nft.TokenId,
            contractAddress: nft.contractAddress,
            transactionHash: sell_nft.transactionHash,
            paymentMethod: item.OfferMethod,
            paymentAmount: item.BidPrice,
          };
          const tokens = current_user.token;
          const res = await executeNfts(id, values, tokens);
          if (res.data.status) {
            toast("your nft was sold");
            getnfts();
            handlegetnft();
            getBidHistory();
            GetSalesHistory();
            getAllUSers();
            getLastsoldnft();
            setOpenLoader(false);
          } else {
            toast("Failed");
          }
        }
      } else {
        toast("Approval cancelled by user");
      }
    } catch (err) {
      toast("Approval failed");
      setOpenLoader(false);
    }
  };

  const handleDelist = async () => {
    setOpenLoader(true);
    setMetamaskStatus(true);
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
      wrappedMatic,
    } = wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try {
      setMetamaskStatus(false);
      setContractStatus(true);
      const putonsale_ = await NftContracts.methods
        .approve(process.env.REACT_APP_OKU_GOVERNANCE, nft.TokenId, false)
        .send({ from: address });
      setContractStatus(false);
      setCompletionStatus(true);
      const res = await delistNft(id);
      if (res.data.status) {
        setCompletionStatus(false);
        setOpenLoader(false);
        handlegetnft();
        toast("delist success");
      } else {
        toast("delist failed");
      }
    } catch (err) {
      setOpenLoader(false);
      setContractStatus(false);
      toast("Failed to connect with contract");
    }
  };
  const getAllUSers = async () => {
    const res = await getAllusers();
    if (res.data.status) {
      setAllusers(res.data.result);
    }
  };

  const getLastsoldnft = async () => {
    const res = await getlastsold(id);
    if (res.data.status) {
      if (res.data.status !== undefined) {
        setLastnft(res.data.result);
        setNftowner(res.data.owner);
      }
    } else {
      setLastnft("");
      setNftowner("");
    }
  };

  const getnfts = async () => {
    const res = await getRestaurantNFTs(nftstate && nftstate.OwnedBy);
    setResNfts(res.data.result);
    const new_data = res.data.result;
    const nft_new = new_data.filter((item) => item.NFTName == nftstate.NFTName);
    dispatch({
      type: "SET_NFT",
      payload: nft_new[0],
    });
  };

  const [showSignIn, setShowSignIn] = useState("INIT");
  const [particular, setParticular] = useState(false);

  const DeclineOffer = async (item) => {
    const res = await declineoffer(item._id);
    getBidHistory();
  };
  const getavailablebalance = async () => {
    const {
      web3,
      marketplace,
      restaurant,
      address,
      usdcInstance,
      usdtInstance,
    } = wallet;
    if (wallet.connected) {
      const tokenInst = await web3.eth.getBalance(address);
      const matic_balance = await web3.utils.fromWei(tokenInst, "ether");
      const bal = matic_balance;
      setAvailablebalance(bal.length > 3 ? bal.slice(0, 5) : bal);
      try {
        const usdcInstances = new web3.eth.Contract(
          usdcABI,
          process.env.REACT_APP_USDC
        );

        const usdbvalue = await usdcInstances.methods.balanceOf(address).call();
        const usdc_balance = Number(usdbvalue) / 1000000;
        setAvailableUsdc(usdc_balance);
      } catch (e) {
        console.log("errorsss", e);
      }
      const usdtInstances = new web3.eth.Contract(
        usdtABI,
        process.env.REACT_APP_USDT
      );
      const usdtb = await usdtInstances.methods.balanceOf(address).call();
      const usdt_balance = Number(usdtb) / 1000000;
      setAvailableUsdt(usdt_balance);
    }
  };

  const getBidHistory = async () => {
    const res = await getbidhistory(id);
    if (res.data.status) {
      const data_ = res.data.result;
      const bids = data_.filter(
        (item) =>
          item.type == "bid" &&
          item.status != "deleted" &&
          item.status != "inactive"
      );
      setBidHistory(bids);
      const maxbid = bids.map((item) => Number(item.BidPrice));
      if (maxbid.length > 0) {
        const max = Math.max(...maxbid);
        setMaxbidder(max);
      } else {
        setMaxbidder(0);
      }

      const offers = data_.filter((item) => item.type == "makeoffer");
      setOfferHistory(offers);
    } else {
      toast("something went wrong");
    }
  };

  const GetSalesHistory = async () => {
    const res = await getsaleshistory(id);
    const val = res.data.result;
    let arr = [];
    const results = val.map((item) => {
      if (item.type == "buy" || item.type == "transfer") {
        const newarr = {
          date: item.SoldTime?.slice(0, 5),
          price: Number(item.NFTPrice),
        };
        arr.push(newarr);
      }
    });
    const users = await getAllusers();
    setSalesHistory(arr);

    if (users.data.status) {
      setAllusers(users.data.result);
      const all_users = users.data.result;
      const filteredusers = all_users.filter((item) => item.userType == "user");
      const new_arr = [];
      val.map((item, index) => {
        filteredusers.map((i) => {
          if (item.walletAddress == i.walletAddress) {
            val[index].userName = i.userName;
            val[index].profilePicture = i.profilePicture;
            new_arr.push(val[index]);
          }
        });
      });
      let arr = new_arr.reverse();
      setSalesData(arr);
      setOldSalesHistory(new_arr);
    }
  };

  const renderModalPurchaseNFT = () => {
    //
    return (
      <Modal
        visible={showModalPurchase}
        onClose={() => {
          setShowModalPurchase(false);
          setPaymentstatus(false);
          setCardActive(false);
          setCryptoActive(false);
          setShowModalPurchaseWithCard(false);
          setShowModalPurchaseWithCrypto(false);
          handlegetnft();
        }}
        contentClassName="w-full xs:w-[540px] h-full"
      >
        {!paymentStatus &&
        !nft.PaymentStatus &&
        !showModalPurchaseWithCard &&
        !showModalPurchaseWithCrypto ? (
          <div className="">
            <Text
              text="Purchase NFT"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="500"
              fontSize="26px"
            />

            <div className="flex space-x-4 mb-1 mt-1">
              <div className="rounded-md overflow-hidden to-transparent w-[90px]">
                <img
                  src={nft.NFTImage}
                  alt="profile"
                  style={{ objectFit: "cover" }}
                  className="h-[90px] w-[90px]  rounded-md"
                />
              </div>
              <div>
                <Text
                  text={nft.NFTName}
                  color={Colors.darkBlue}
                  fontFamily="lora"
                  fontWeight="600"
                  fontSize="20px"
                  className=""
                />
                <Text
                  text={nft.NFTCollections}
                  color="black"
                  fontFamily="lora"
                  fontWeight="400"
                  fontSize="16px"
                />
                <div className="flex items-center space-x-2 ">
                  <Text text="Owned by" fontWeight="300" color="black" />
                  <Text text={nft.OwnedBy} fontWeight="700" color="black" />
                </div>
                <Text
                  text="View item on chain"
                  color={Colors.prussianBlue}
                  fontWeight="700"
                />
              </div>
            </div>

            <Text
              color={Colors.darkBlue}
              text="Summary"
              fontFamily="lora"
              fontWeight="700"
              fontSize="20px"
            />
            <div className="pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1">
              <div className="flex items-center justify-between mt-1">
                <Text text="NFT Price" color="black" fontWeight="300" />
                {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
              </div>
              <div className="flex justify-between">
                <div className="flex flex-start space-between mt-3 mb-1">
                  <div className="flex items-center">
                    {/* <img src={Icons.polygon} alt="currency" className="mr-1" /> */}
                    <Text
                      text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`}
                      color="black"
                      fontWeight="700"
                      fontSize="17px"
                    />
                  </div>
                  <div className="flex items-center ml-3">
                    <img
                      src={Images.USDollar}
                      alt="currency"
                      className="mr-1"
                    />
                    <Text
                      text={`${nft.NFTPrice}`}
                      color="black"
                      fontWeight="800"
                      fontSize="17px"
                    />
                  </div>
                  <div className="flex items-center ml-3">
                    <img src={Images.Usdt} alt="currency" className="mr-1" />
                    <Text
                      text={`${nft.NFTPrice}`}
                      color="black"
                      fontWeight="800"
                      fontSize="17px"
                    />
                  </div>
                </div>
                <div className="flex items-center flex-start space-between mt-1">
                  <div className="flex  ml-2">
                    <img src={Icons.polygon} alt="currency" className="mr-1" />
                    <Text
                      text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`}
                      color="black"
                      fontWeight="800"
                      fontSize="16px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <Text text="Additional Fee" color="black" fontWeight="300" />
                <div className="flex">
                  <img src={Icons.polygon} alt="currency" className="mr-1" />
                  <Text
                    text={(
                      ((Number(nft.NFTPrice) / maticToUsd).toFixed(2) *
                        (nft.NFTRoyalty + 1)) /
                      100
                    ).toFixed(2)}
                    color="black"
                    fontWeight="800"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between mt-2">
                <Text text="Total" color="black" fontWeight="300" />
                <div className="flex">
                  <img src={Icons.polygon} alt="currency" className="mr-1" />
                  <Text
                    text={`${(
                      Number(nft.NFTPrice) / maticToUsd +
                      ((Number(nft.NFTPrice) / maticToUsd) *
                        (nft.NFTRoyalty + 1)) /
                        100
                    ).toFixed(2)}`}
                    color="black"
                    fontWeight="800"
                    fontSize="16px"
                  />
                </div>
              </div>
            </div>

            <Text
              color="black"
              fontWeight="800"
              text="Payment method"
              fontSize="16px"
            />
            {/* <div className='rounded overflow-hidden shadow-lg p-px w-full bg-gradient-to-br from-white/40 to-transparent hover:bg-lemonChiffon'>
              <div className="bg-slate-800 rounded-md">
              </div>
            </div> */}
            <div
              style={{ minHeight: "80px", display: "flex", padding: "0 10%" }}
              className="mt-6"
            >
              <div
                style={{
                  backgroundColor: cryptoActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setCryptoActive(true);
                  setCardActive(false);
                }}
              >
                <img
                  src={cryptoActive ? Images.Cryptogreen : Images.Cryptoblack}
                  alt="currency"
                  style={{ height: "20px", width: "20px" }}
                />
                <h5 style={{ color: cryptoActive ? "white" : "black" }}>
                  Crypto
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: cardActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setCryptoActive(false);
                  setCardActive(true);
                }}
              >
                <img
                  src={cardActive ? Images.Cardgreen : Images.Cardblack}
                  alt="currency"
                  style={{ height: "20px", width: "20px" }}
                />
                <h5 style={{ color: cardActive ? "white" : "black" }}>Card</h5>
              </div>
            </div>

            <Button
              label="Pay Now"
              className="w-full mt-5"
              width="100%"
              onClick={() => {
                // mintNFTs(nft._id)
                if (cardActive) {
                  paymentCall(nft.NFTPrice);
                } else if (cryptoActive) {
                  paymentwithcrypto();
                } else {
                  toast("select payment method");
                }

                // renderModalPurchaseWithCard();
              }}
            />
          </div>
        ) : (
          <>
            {cardActive ||
            (nft.paymentStatus == "succeeded" &&
              nft.paidBy == current_user.user.id) ? (
              <div className="">
                <Text
                  text="Purchase NFT With Card"
                  color={Colors.prussianBlue}
                  fontFamily="lora"
                  fontWeight="600"
                  fontSize="24px"
                />

                <div className="flex space-x-4 mb-1 mt-1">
                  <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                    <img
                      src={nft.NFTImage}
                      alt="profile"
                      className="h-[80px] w-[80px]  rounded-md"
                    />
                  </div>
                  <div>
                    <Text
                      text={nft.NFTName}
                      color={Colors.darkBlue}
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="16px"
                      className="mb-1"
                    />
                    <Text
                      text={nft.NFTCollections}
                      color="black"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="16px"
                    />
                    <div className="flex items-center space-x-2 mb-1 mt-1">
                      <Text text="Owned by" fontWeight="300" color="black" />
                      <Text text={nft.OwnedBy} fontWeight="700" color="black" />
                    </div>
                    <Text
                      text="View item on chain"
                      color={Colors.prussianBlue}
                      fontWeight="700"
                    />
                  </div>
                </div>

                <Text
                  color={Colors.darkBlue}
                  text="Summary"
                  fontFamily="lora"
                  fontWeight="700"
                  fontSize="20px"
                />
                <div className="pb-5 ">
                  <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey">
                    <div className="flex items-center">
                      <img
                        src={nft.NFTImage}
                        alt="currency"
                        className="rounded mr-1 w-15 h-10"
                      />
                      <Text
                        text={`Purchasing NFT for US $ ${nft.NFTPrice}`}
                        fontSize="42"
                        color="black"
                        fontWeight="700"
                        className="ml-5"
                      />
                      {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                    </div>
                  </div>
                  {!paymentStatus && !nft.PaymentStatus ? (
                    <div className="mt-1">
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm
                          options={options}
                          stripe={stripePromise}
                          paymentStatus={paymentStatus}
                          setPaymentstatus={setPaymentstatus}
                          nft={nft}
                        />
                      </Elements>
                    </div>
                  ) : null}
                </div>
                {paymentStatus || nft.PaymentStatus ? (
                  <div>
                    {loaderimage ? (
                      <img
                        src={loading}
                        alt="loader"
                        style={{
                          width: "35px",
                          height: "35px",
                          marginLeft: "45%",
                        }}
                      ></img>
                    ) : null}
                    <Button
                      label="Complete Purchase"
                      disabled={
                        !paymentStatus && !nft.PaymentStatus ? true : false
                      }
                      className="w-full mt-1"
                      width="100%"
                      onClick={() => {
                        mintNFTsAfterPayment(nft._id);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {cryptoActive || nft.PaymentStatus != "succeeded" ? (
                  <div className="">
                    <Text
                      text="Purchase NFT With Crypto"
                      color={Colors.prussianBlue}
                      fontFamily="lora"
                      fontWeight="600"
                      fontSize="24px"
                    />

                    <div className="flex space-x-4 mb-1 mt-1">
                      <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                        <img
                          src={nft.NFTImage}
                          alt="profile"
                          className="h-[80px] w-[80px]  rounded-md"
                        />
                      </div>
                      <div>
                        <Text
                          text={nft.NFTName}
                          color={Colors.darkBlue}
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                          className="mb-1"
                        />
                        <Text
                          text={nft.NFTCollections}
                          color="black"
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                        />
                        <div className="flex items-center space-x-2 mb-1 mt-1">
                          <Text
                            text="Owned by"
                            fontWeight="300"
                            color="black"
                          />
                          <Text
                            text={nft.OwnedBy}
                            fontWeight="700"
                            color="black"
                          />
                        </div>
                        <Text
                          text="View item on chain"
                          color={Colors.prussianBlue}
                          fontWeight="700"
                        />
                      </div>
                    </div>

                    <Text
                      color={Colors.darkBlue}
                      text="Summary"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="20px"
                    />
                    <div className="pb-5 ">
                      <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey p-4">
                        <div className="flex items-center">
                          <img
                            src={nft.NFTImage}
                            alt="currency"
                            className="rounded mr-1 w-15 h-10"
                          />
                          {!usdActive && !usdtActive ? (
                            <Text
                              text={`Purchasing NFT for  ${
                                (Number(nft.NFTPrice) / maticToUsd).toFixed(2) +
                                " MATIC"
                              }`}
                              fontSize="42"
                              color="black"
                              fontWeight="700"
                              className="ml-5 rounded"
                            />
                          ) : (
                            <Text
                              text={`Purchasing NFT for  ${
                                usdActive
                                  ? nft.NFTPrice + " USDC"
                                  : nft.NFTPrice + " USDT"
                              }`}
                              fontSize="42"
                              color="black"
                              fontWeight="700"
                              className="ml-5 rounded"
                            />
                          )}
                          {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setMaticActive(true);
                        setUsdActive(false);
                        setUsdtActive(false);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: maticActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Icons.polygon}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(
                            2
                          )}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={maticActive ? "white" : "black"}
                        />
                      </div>

                      <Text
                        text="MATIC"
                        fontWeight="800"
                        color={maticActive ? "white" : "black"}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setMaticActive(false);
                        setUsdActive(true);
                        setUsdtActive(false);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: usdActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Images.USDollar}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${nft.NFTPrice}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={usdActive ? "white" : "black"}
                        />
                      </div>
                      <Text
                        text="USDC"
                        fontWeight="800"
                        color={usdActive ? "white" : "black"}
                      />
                    </div>

                    <div
                      onClick={() => {
                        setMaticActive(false);
                        setUsdActive(false);
                        setUsdtActive(true);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: usdtActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Images.Usdt}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${nft.NFTPrice}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={usdtActive ? "white" : "black"}
                        />
                      </div>
                      <Text
                        text="USDT"
                        fontWeight="800"
                        color={usdtActive ? "white" : "black"}
                      />
                    </div>

                    <Button
                      label="Pay Now"
                      disabled={
                        usdtActive || usdActive || maticActive ? false : true
                      }
                      className="w-full mt-1"
                      width="100%"
                      onClick={() => {
                        mintNFTs(nft._id);
                      }}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Text
                      text="Purchase NFT With Card"
                      color={Colors.prussianBlue}
                      fontFamily="lora"
                      fontWeight="600"
                      fontSize="24px"
                    />

                    <div className="flex space-x-4 mb-1 mt-1">
                      <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                        <img
                          src={nft.NFTImage}
                          alt="profile"
                          className="h-[80px] w-[80px]  rounded-md"
                        />
                      </div>
                      <div>
                        <Text
                          text={nft.NFTName}
                          color={Colors.darkBlue}
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                          className="mb-1"
                        />
                        <Text
                          text={nft.NFTCollections}
                          color="black"
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                        />
                        <div className="flex items-center space-x-2 mb-1 mt-1">
                          <Text
                            text="Owned by"
                            fontWeight="300"
                            color="black"
                          />
                          <Text
                            text={nft.OwnedBy}
                            fontWeight="700"
                            color="black"
                          />
                        </div>
                        <Text
                          text="View item on chain"
                          color={Colors.prussianBlue}
                          fontWeight="700"
                        />
                      </div>
                    </div>

                    <Text
                      color={Colors.darkBlue}
                      text="Summary"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="20px"
                    />
                    <div className="pb-5 ">
                      <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey">
                        <div className="flex items-center">
                          <img
                            src={nft.NFTImage}
                            alt="currency"
                            className="rounded mr-1 w-15 h-10"
                          />
                          <Text
                            text={`Purchasing NFT for US $ ${nft.NFTPrice}`}
                            fontSize="42"
                            color="black"
                            fontWeight="700"
                            className="ml-5"
                          />
                          {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                        </div>
                      </div>
                      {!paymentStatus && !nft.PaymentStatus ? (
                        <div className="mt-1">
                          <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                              options={options}
                              stripe={stripePromise}
                              paymentStatus={paymentStatus}
                              setPaymentstatus={setPaymentstatus}
                              nft={nft}
                            />
                          </Elements>
                        </div>
                      ) : null}
                    </div>
                    {paymentStatus || nft.PaymentStatus ? (
                      <div>
                        {loaderimage ? (
                          <img
                            src={loading}
                            alt="loader"
                            style={{
                              width: "35px",
                              height: "35px",
                              marginLeft: "45%",
                            }}
                          ></img>
                        ) : null}
                        <Button
                          label="Complete Purchase"
                          disabled={
                            !paymentStatus && !nft.PaymentStatus ? true : false
                          }
                          className="w-full mt-1"
                          width="100%"
                          onClick={() => {
                            mintNFTsAfterPayment(nft._id);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Modal>
    );
  };

  const renderSuccessPurchaseNFT = () => {
    //
    return (
      <Modal
        visible={showModalSuccess}
        onClose={() => setShowModalSuccess(false)}
        contentClassName="w-full xs:w-[540px]"
        hideCloseBtn={true}
      >
        <div className="flex flex-col items-center">
          <Text
            text="You Succesfully Bought"
            color={Colors.prussianBlue}
            fontFamily="lora"
            fontWeight="700"
            fontSize="32px"
          />
          <Text
            text={nft.NFTName}
            color={Colors.prussianBlue}
            fontFamily="lora"
            fontWeight="700"
            fontSize="32px"
          />

          <Text
            className="mt-5 mb-7"
            textAlign="center"
            color="black"
            text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Architecto, fugiat?"
          />

          <div className="rounded-md overflow-hidden shadow-lg p-px bg-gradient-to-br from-white/40 to-transparent w-[160px]">
            <img
              src={nft.NFTImage}
              alt="profile"
              className="h-[160px] w-[160px]  rounded-md"
            />
          </div>

          <Button
            label="View on My NFTs"
            className="w-full mt-10"
            width="100%"
            onClick={() => {
              setShowModalSuccess(false);
              navigate("/myprofile");
            }}
          />

          <div className="flex items-center space-x-2 mt-5">
            <Text
              text="You can check out your recent activity"
              fontWeight="300"
              color="black"
            />
            <Text
              text="here"
              style={{ cursor: "pointer" }}
              fontWeight="700"
              color="black"
              onClick={() => navigate("/my-wallet")}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <Layout>
      <Header
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
        particular={particular}
      />
      <div className="h-fit overflow-hidden">
        <div className="flex flex-col items-center ">
          <div className="w-full px-5 xl:w-defaultContent xl:px-0">
            <div className="xs:flex mt-[100px] xs:space-x-[30px]">
              <div className="w-full md:w-[350px]">
                <div className="rounded-md overflow-hidden shadow-lg p-px bg-gradient-to-br from-white/40 to-transparent md:w-[350px]">
                  <img
                    // src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`}
                    src={`${nft.NFTImage}`}
                    alt="profile"
                    className="h-full w-full object-cover md:h-[350px] md:w-[350px]  rounded-md"
                  />
                </div>
                <div className="mt-4 card_bg">
                  <div className="flex justify-between items-center bg-white/5 py-3 rounded-t border-solid border-b-[1px] border-b-white/10">
                    <Text
                      text={`Details`}
                      fontWeight="800"
                      fontSize="20px"
                      color={"black"}
                    />
                  </div>
                  <hr />
                  <div className="bg-white/5 rounded mt-2">
                    <Text
                      text="Edition"
                      color={"black"}
                      fontWeight="500"
                      fontSize="20px"
                    />
                    <div className="flex justify-between items-center">
                      {nft.status && nft.status == "sold" ? (
                        <>
                          <div className="flex items-center mt-3 mb-3">
                            <Text
                              text="Edition 1.1 - "
                              color={"black"}
                              fontWeight="400"
                              fontSize="14px"
                            />
                            <Text
                              text="Sold"
                              fontWeight="300"
                              fontSize="14px"
                              color={"black"}
                            />
                          </div>

                          <div>
                            <Text
                              text={new Date(nft?.updatedAt).toLocaleString()}
                              fontWeight="200"
                              color="black"
                              fontSize="14px"
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-2">
                        <Text
                          text="Edition 1 - "
                          color="black"
                          fontWeight="400"
                          fontSize="14px"
                        />
                        <Text
                          text="Created"
                          fontWeight="300"
                          fontSize="14px"
                          color="black"
                        />
                      </div>
                      <div>
                        <Text
                          text={new Date(nft?.createdAt).toLocaleString()}
                          fontWeight="200"
                          color="black"
                          fontSize="14px"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="pt-1 bg-white/5 rounded-b">
                    <div className="flex justify-between mt-5">
                      <Text text="Contract Address" color="black" />
                      <a
                        href={`https://mumbai.polygonscan.com/address/${nft.contractAddress}`}
                        target="_blank"
                        style={{ color: "blue" }}
                      >
                        {/* <Text  text={nft && nft.contractAddress.slice(0,5)+"..."+nft.contractAddress.slice(-3)} color={ Colors.okuGreen }/> */}
                      </a>
                    </div>
                    <div className="flex justify-between mt-3">
                      <Text text="Token id" color="black" />
                      <Text
                        text={nft.TokenId ? nft.TokenId : "--"}
                        color="black"
                      />
                    </div>
                    <div className="flex justify-between mt-3">
                      <Text text="Token Standard" color="black" />
                      <Text text={"ERC 1155"} color="black" />
                    </div>
                    <div className="flex justify-between mt-3">
                      <Text text="Meta Data" color="black" />
                      <a href={`${metauri ? metauri : ""}`} target="_blank">
                        <Text
                          color="black"
                          text={
                            metauri && nft.TokenId
                              ? metauri?.slice(0, 8) +
                                "..." +
                                metauri?.slice(-3)
                              : "--"
                          }
                        />
                      </a>
                    </div>
                    <div className="flex justify-between mt-3 h-[80px]">
                      {metauri && nft.TokenId ? (
                        <>
                          <div className="flex flex-col p-2 mr-2 justify-center items-center bg-black/5 rounded-md border-solid border-[1px] border-black/8 w-1/2 ">
                            <Text
                              text="Collection Name"
                              color="black"
                              fontWeight="800"
                            />
                            <Text
                              color="black"
                              text={
                                metauri && nft.CollectionName
                                  ? nft.CollectionName
                                  : "--"
                              }
                            />
                          </div>
                          <div className="flex flex-col p-2 mr-1 justify-center items-center bg-black/5 rounded-md border-solid border-[1px] border-black/8 w-1/2">
                            <Text text="Tier" color="black" fontWeight="800" />
                            <Text
                              color="black"
                              text={
                                metauri && nft.NFTCollections
                                  ? nft.NFTCollections
                                  : "--"
                              }
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="flex justify-between mt-3 h-[80px]">
                      {metauri && nft.TokenId ? (
                        <div className="flex flex-col p-2 mr-2 items-center justify-center bg-black/5 rounded-md border-solid border-[1px] border-black/8 w-1/2">
                          <Text
                            text="Benefits"
                            color="black"
                            fontWeight="700"
                          />
                          <Text
                            color="black"
                            text={metauri && nft.benefits ? nft.benefits : "--"}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="flex items-center justify-between mt-2 xs:mt-0">
                  <Text
                    text={nft.NFTName}
                    fontType="h4"
                    fontFamily="lora"
                    fontWeight="700"
                    fontSize="30px"
                    color="black"
                  />
                  {nft &&
                  nft.Likes.includes(
                    current_user.user && current_user.user.id
                  ) ? (
                    <button
                      className="flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px]  contact-btn"
                      disabled={added}
                      onClick={() => {
                        NftRemovefromFavourite();
                      }}
                    >
                      <BsStarFill size={18} color="#EF8440" />
                      <span>Added to Favourites</span>
                    </button>
                  ) : (
                    <button
                      className="flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px]  contact-btn"
                      disabled={added}
                      onClick={() => {
                        NftAddtoFavourite();
                      }}
                    >
                      <BsStar size={18} color="#EF8440" />
                      <span>Add to Favourites</span>
                    </button>
                  )}
                </div>
                <div className="flex items-center space-x-2 mb-1">
                  <Text text="Owned by" fontWeight="400" color="black" />
                  <Text text={nft.OwnedBy} fontWeight="700" color="#EF8440" />
                </div>
                <div className="flex items-center space-x-3 mb-2">
                  <Text
                    text={nft.NFTCollections ? nft.NFTCollections : "nft name"}
                    fontWeight="300"
                    color="black"
                  />
                  <span className="w-[5px] h-[5px] rounded-full bg-lightSilver" />
                  <Text
                    text={
                      Difference_In_Days != 0
                        ? `Created ${Difference_In_Days} days ago`
                        : "Created Today"
                    }
                    fontWeight="300"
                    className="opacity-50"
                    color="black"
                  />
                </div>
                <Text
                  text="Description"
                  fontWeight="800"
                  fontSize="20px"
                  color="black"
                />
                <Text
                  fontWeight="300"
                  className="mb-2"
                  color="black"
                  text={nft.NFTDescription}
                />
                <div>
                  <Text
                    text={`Sale ends ${
                      nft.NFTEndDate
                        ? new Date(nft.NFTEndDate).toLocaleDateString("en-GB", {
                            timeZone: "Singapore",
                          })
                        : "--"
                    } at ${new Date(nft.NFTEndDate).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: "Singapore",
                      }
                    )} GMT+8`}
                    color="#6BB692"
                    fontWeight="800"
                    fontSize="20px"
                    className="bg-white/5 py-2  rounded-t border-solid border-b-[1px] border-b-white/10"
                  />
                  <hr />
                  <div className="flex flex-col max-md:gap-3 md:flex-row justify-between items-center mb-2 bg-white/5 rouded-b mt-2">
                    <div>
                      {/* <Text text='Price' fontWeight='300' className='opacity-40' /> */}
                      <div className="mb-2">
                        <div className="flex space-x-2 items-center">
                          <img
                            src={Icons.polygon}
                            className=""
                            alt="currency"
                          />
                          <Text
                            text={(Number(nft.NFTPrice) / maticToUsd).toFixed(
                              2
                            )}
                            className="font-extrabold"
                            color="black"
                          />

                          <img
                            src={Icons.dollar}
                            className=""
                            style={{ width: "7%", height: "7%" }}
                            alt="currency"
                          />
                          <Text
                            text={Number(nft.NFTPrice).toFixed(2)}
                            color="black"
                          />
                          <img
                            src={Images.USDollar}
                            className=""
                            style={{ width: "7%", height: "7%" }}
                            alt="currency"
                          />
                          <Text
                            text={Number(nft.NFTPrice).toFixed(2)}
                            color="black"
                          />
                          <img
                            src={Images.Usdt}
                            className=""
                            style={{ width: "7%", height: "7%" }}
                            alt="currency"
                          />
                          <Text
                            text={Number(nft.NFTPrice).toFixed(2)}
                            color="black"
                          />
                        </div>
                      </div>
                      <div className="flex items-center space-x-1">
                        <span className="w-[5px] h-[5px] rounded-full bg-lightSilver" />
                        <Text text="Benefits:" fontWeight="300" color="black" />
                        <Text
                          text={nft.benefits}
                          fontWeight="300"
                          color="black"
                        />
                      </div>
                      <div className="flex items-center space-x-1">
                        <span className="w-[5px] h-[5px] rounded-full bg-lightSilver" />
                        <Text
                          text="Restaurant Royalty:"
                          fontWeight="300"
                          color="black"
                        />
                        <Text
                          text={nft.NFTRoyalty + "%"}
                          fontWeight="200"
                          color="black"
                        />
                      </div>
                      <div className="flex items-center space-x-1">
                        <span className="w-[5px] h-[5px] rounded-full bg-lightSilver" />
                        <Text
                          text="Platform fee:"
                          fontWeight="300"
                          color="black"
                        />
                        <Text text={1 + "%"} fontWeight="300" color="black" />
                      </div>
                    </div>
                    {nft.PutonsaleTime && !date_comparison ? (
                      <CdTimerComp targetdate={nft.PutonsaleTime} />
                    ) : null}

                    {!date_comparison ? (
                      <>
                        {nft.status &&
                        nft.status == "sold" &&
                        wallet.address == nft.walletAddress ? (
                          <Button
                            label="Put On Sale"
                            backgroundColor="#1c1e191a"
                            textColor="black"
                            onClick={() => {
                              setShowModalPutOnsale(true);
                            }}
                          />
                        ) : (
                          <>
                            {!nft.paidBy &&
                            nft.status != "onsale" &&
                            wallet.address == nft.walletAddress ? (
                              <Button
                                label="Put On Sale "
                                backgroundColor="#1c1e191a"
                                textColor="black"
                                onClick={() => {
                                  if (current_user.user && wallet.address) {
                                    handlePutonSale();
                                  } else if (
                                    !current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Please Sign In to Continue");
                                    navigate("/sign-in");
                                    // setShowSignIn("LOGIN")
                                    // setParticular(true)
                                  } else if (
                                    current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Connect your wallet");
                                  }
                                }}
                              />
                            ) : (
                              <>
                                {" "}
                                {(nft.status && nft.status == "sold") ||
                                nft.status == "onsale" ? null : (
                                  <Button
                                    label="Sale Ended"
                                    backgroundColor="lightgray"
                                    textColor="red"
                                    bghover="black"
                                    className="mr-2"
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {(nft.status && nft.status == "sold") ||
                        nft.status == "onsale" ? null : (
                          <>
                            {!nft.paidBy ? (
                              <Button
                                label="Buy Now"
                                backgroundColor="#1c1e191a"
                                textColor="black"
                                onClick={() => {
                                  if (current_user.user && wallet.address) {
                                    getNFTstatus();
                                  } else if (
                                    !current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Please Sign In to Continue");
                                    navigate("/sign-in");
                                    // setShowSignIn("LOGIN")
                                    // setParticular(true)
                                  } else if (
                                    current_user.user &&
                                    !wallet.address
                                  ) {
                                    getNFTstatus();
                                  }
                                }}
                              />
                            ) : (
                              <Button
                                label={`${
                                  current_user &&
                                  current_user.user.id == nft.paidBy
                                    ? "Claim NFT"
                                    : "Not Available"
                                }`}
                                backgroundColor="#1c1e191a"
                                textColor="black"
                                onClick={() => {
                                  if (
                                    current_user &&
                                    current_user.user.id == nft.paidBy
                                  ) {
                                    if (current_user.user && wallet.address) {
                                      setShowModalPurchase(true);
                                    } else if (
                                      !current_user.user &&
                                      !wallet.address
                                    ) {
                                      toast("Please Sign In to Continue");
                                      navigate("/sign-in");
                                      // setShowSignIn("LOGIN")
                                      // setParticular(true)
                                    } else if (
                                      current_user.user &&
                                      !wallet.address
                                    ) {
                                      toast("Connect your wallet");
                                    }
                                  }
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    )}

                    {/* {!date_comparison ? null : (
                      <> */}

                    {/* {nft.status &&
                        nft.status == "sold" &&
                        wallet.address == nft.walletAddress ? (
                          <Button
                            label="Put On Sale"
                            backgroundColor="#1c1e191a"
                            textColor="black"
                            onClick={() => {
                              setShowModalPutOnsale(true);
                            }}
                          />
                        ) : (
                          <>
                            {!nft.paidBy &&
                            nft.status != "onsale" &&
                            wallet.address == nft.walletAddress ? (
                              <Button
                                label="Put On Sale "
                                backgroundColor="#1c1e191a"
                                textColor="black"
                                onClick={() => {
                                  if (current_user.user && wallet.address) {
                                    handlePutonSale();
                                  } else if (
                                    !current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Please Sign In to Continue");
                                    navigate("/sign-in");
                                    // setShowSignIn("LOGIN")
                                    // setParticular(true)
                                  } else if (
                                    current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Connect your wallet");
                                  }
                                }}
                              />
                            ) : null}
                          </>
                        )} */}
                    {/* </>
                    )} */}

                    {wallet.address == nft.walletAddress &&
                    nft.status == "onsale" ? (
                      <Button
                        label="Delist"
                        backgroundColor="#1c1e191a"
                        textColor="black"
                        onClick={() => {
                          if (current_user.user && wallet.address) {
                            handleDelist();
                          } else if (!current_user.user && !wallet.address) {
                            toast("Please Sign In to Continue");
                            navigate("/sign-in");
                            // setShowSignIn("LOGIN")
                            // setParticular(true)
                          } else if (current_user.user && !wallet.address) {
                            toast("Connect your wallet");
                          }
                        }}
                      />
                    ) : (
                      <>
                        {nft.status == "onsale" && !nft.PutonsaleTime ? (
                          <Button
                            label={"Buy"}
                            backgroundColor="#1c1e191a"
                            textColor="black"
                            onClick={() => {
                              if (current_user.user && wallet.address) {
                                setShowModalPurchaseBuy(true);
                              } else if (
                                !current_user.user &&
                                !wallet.address
                              ) {
                                toast("Please Sign In to Continue");
                                navigate("/sign-in");
                                // setShowSignIn("LOGIN")
                                // setParticular(true)
                              } else if (current_user.user && !wallet.address) {
                                toast("Connect your wallet");
                              }
                            }}
                          />
                        ) : (
                          <>
                            {nft.status == "onsale" &&
                            nft.walletAddress != wallet.address &&
                            days + hours + minutes + seconds > 0 ? (
                              <Button
                                label="Place Bid"
                                backgroundColor="#1c1e191a"
                                textColor="black"
                                onClick={() => {
                                  if (current_user.user && wallet.address) {
                                    setShowPlaceBidModal(true);
                                  } else if (
                                    !current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Please Sign In to Continue");
                                    navigate("/sign-in");
                                    // setShowSignIn("LOGIN")
                                    // setParticular(true)
                                  } else if (
                                    current_user.user &&
                                    !wallet.address
                                  ) {
                                    toast("Connect your wallet");
                                  }
                                }}
                              />
                            ) : (
                              <>
                                {nft.walletAddress != wallet.address &&
                                nft.status == "sold" ? (
                                  <Button
                                    label="Make offer"
                                    backgroundColor="#1c1e191a"
                                    textColor="black"
                                    onClick={() => {
                                      if (current_user.user && wallet.address) {
                                        setShowMakeOfferModal(true);
                                      } else if (
                                        !current_user.user &&
                                        !wallet.address
                                      ) {
                                        toast("Please Sign In to Continue");
                                        navigate("/sign-in");
                                        // setShowSignIn("LOGIN")
                                        // setParticular(true)
                                      }
                                    }}
                                  />
                                ) : (
                                  // label={`${current_user && current_user.user.id == nft.paidBy ? "Claim NFT" : "Not Available"}`}
                                  <>
                                    {nft.status == "onsale" &&
                                    current_user.user.id == nft.paidBy ? (
                                      <Button
                                        label={`${
                                          current_user &&
                                          current_user.user.id == nft.paidBy
                                            ? "Claim NFT"
                                            : "Not Available"
                                        }`}
                                        backgroundColor="#1c1e191a"
                                        textColor="black"
                                        onClick={() => {
                                          if (
                                            current_user.user &&
                                            wallet.address
                                          ) {
                                            setShowModalPurchaseBuy(true);
                                          } else if (
                                            !current_user.user &&
                                            !wallet.address
                                          ) {
                                            toast("Please Sign In to Continue");
                                            navigate("/sign-in");
                                            // setShowSignIn("LOGIN")
                                            // setParticular(true)
                                          } else if (
                                            current_user.user &&
                                            !wallet.address
                                          ) {
                                            toast("Connect your wallet");
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="bg-red rounded mt-4 card_bg">
                  {/* <div className='flex  w-full'> */}
                  <div>
                    <div className="flex justify-between py-2 rounded-t border-solid border-b-[1px] border-b-black/10">
                      <Text
                        text="Sales History"
                        color="black"
                        fontWeight="800"
                        fontSize="20px"
                      />
                      {/* <Dropdown className="mr-2 rounded-t border-solid border-[1px] border-white/10"></Dropdown> */}
                      <Selectbox>
                        <select
                          onChange={(e) => handleDatefilter(e.target.value)}
                          style={{
                            color: "white",
                            opacity: "0.8",
                            fontSize: "16px",
                            appearance: "none",
                            fontWeight: "bold",
                          }}
                          className="selectbox  flex items-center justify-between w-fit space-x-2 py-[4px] px-[15px] color-white bg-white/5 rounded mr-5"
                        >
                          <option lable="All Dates" value="0">
                            All Dates
                          </option>
                          <option lable="3 days" value="3">
                            less than 3 days
                          </option>
                          <option lable="7 days" value="7">
                            less than 7 days
                          </option>
                          <option lable="14 days" value="14">
                            less than 14 days
                          </option>
                          <option lable="30 days" value="30">
                            less than 30 days
                          </option>
                        </select>
                      </Selectbox>
                    </div>
                    <LineChart
                      data={salesHistory && salesHistory}
                      width={700}
                      height={230}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 5,
                      }}
                    >
                      {/* <CartesianGrid strokeDasharray="5 5" /> */}
                      <XAxis dataKey="date" />
                      <YAxis dataKey="price" />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="price"
                        color="black"
                        fill="#2795e9"
                        // strokeDasharray="3 4 5 2"
                      />
                    </LineChart>
                  </div>
                  <div className="flex  w-full">
                    <div className=" w-full">
                      <div className="flex justify-between  w-full">
                        <Tabs
                          activeTabIndex={activeTabIndex}
                          setActiveTabIndex={setActiveTabIndex}
                          tabUnderlineWidth={tabUnderlineWidth}
                          setTabUnderlineWidth={setTabUnderlineWidth}
                          tabUnderlineLeft={tabUnderlineLeft}
                          setTabUnderlineLeft={setTabUnderlineLeft}
                          tabsData={tabsData}
                          wrapperClassName="mt-[3px] mb-1  w-full "
                          btnClassName="py-[8px] text-[15px] font-bold font-Lora  w-full"
                        />
                      </div>
                      {activeTabIndex == 1 ? (
                        <>
                          <div style={{ overflow: "auto" }}>
                            <div className="xs:h-[130px] mb-6 ">
                              {bidhistory.length > 0 ? (
                                bidhistory.map((item) => {
                                  return (
                                    <>
                                      {item.status != "deleted" ? (
                                        <div className="w-full px-5 py-3 flex items-center justify-between mr-15 border-solid border-b-[1px] border-b-white/10">
                                          <div className="ml-5">
                                            <Text
                                              fontSize="13px"
                                              text={
                                                item.walletAddress?.slice(
                                                  0,
                                                  3
                                                ) +
                                                "..." +
                                                item.walletAddress?.slice(-3)
                                              }
                                              color="black"
                                            />
                                            {days + hours + minutes + seconds <=
                                              0 &&
                                            nft.walletAddress ==
                                              wallet.address ? (
                                              <Text
                                                color="black"
                                                fontSize="13px"
                                                text={
                                                  item.BidPrice +
                                                  " " +
                                                  item.BidMethod
                                                }
                                              />
                                            ) : null}
                                          </div>
                                          {nft.walletAddress !=
                                          wallet.address ? (
                                            <Text
                                              color="black"
                                              fontSize="13px"
                                              text={
                                                item.BidPrice +
                                                " " +
                                                item.BidMethod
                                              }
                                            />
                                          ) : null}
                                          {nft.walletAddress ==
                                            wallet.address &&
                                          days + hours + minutes + seconds >
                                            0 ? (
                                            <Text
                                              color="black"
                                              fontSize="13px"
                                              text={
                                                item.BidPrice +
                                                " " +
                                                item.BidMethod
                                              }
                                            />
                                          ) : null}

                                          <Text
                                            fontSize="13px"
                                            text={new Date(
                                              item.createdAt
                                            ).toLocaleDateString()}
                                          />
                                          <a
                                            href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`}
                                            target="_blank"
                                          >
                                            <Text
                                              fontSize="13px"
                                              text={
                                                item.transactionHash
                                                  ? item.transactionHash?.slice(
                                                      0,
                                                      3
                                                    ) +
                                                    "..." +
                                                    item.transactionHash?.slice(
                                                      -3
                                                    )
                                                  : "--"
                                              }
                                              color="black"
                                            />
                                          </a>
                                          {/* && days+hours+minutes+seconds <= 0 */}
                                          {nft.walletAddress ==
                                            wallet.address &&
                                          nft.status != "sold" &&
                                          days + hours + minutes + seconds <=
                                            0 ? (
                                            <div className="flex">
                                              <Button
                                                backgroundColor="green"
                                                className="mr-1 mb-1 font-size-1 flex justify-center items-center"
                                                height="25px"
                                                width="80px"
                                                label="Settle"
                                                onClick={() =>
                                                  handleSettle(item)
                                                }
                                              />
                                              <Button
                                                backgroundColor="red"
                                                className="flex justify-center items-center"
                                                height="25px"
                                                width="80px"
                                                label="Reject"
                                                onClick={() =>
                                                  DeclineOffer(item)
                                                }
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                })
                              ) : (
                                <p className="text-center text-black mt-5">
                                  No Bid History
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      ) : null}
                      {activeTabIndex == 0 ? (
                        <div style={{ overflow: "auto" }}>
                          <div className="xs:h-[130px] mb-6">
                            {offerhistory.length > 0 ? (
                              offerhistory.map((item) => {
                                return (
                                  <>
                                    {item.status != "deleted" ? (
                                      <div className="w-full px-5 py-3 flex items-center justify-between mr-15 border-solid border-b-[1px] border-b-white/10">
                                        <div className={""}>
                                          <Text
                                            fontSize="13px"
                                            text={
                                              item.walletAddress?.slice(0, 3) +
                                              "..." +
                                              item.walletAddress?.slice(-3)
                                            }
                                            color="black"
                                          />
                                          {nft.walletAddress ==
                                            wallet.address &&
                                          nft.status == "sold" &&
                                          item.status != "inactive" ? (
                                            <Text
                                              fontSize="13px"
                                              color="black"
                                              text={
                                                item.BidPrice +
                                                " " +
                                                `${
                                                  item.OfferMethod
                                                    ? item.OfferMethod
                                                    : "-"
                                                }`
                                              }
                                            />
                                          ) : null}
                                        </div>
                                        {nft.walletAddress != wallet.address &&
                                        nft.status == "sold" &&
                                        item.status != "deleted" ? (
                                          <Text
                                            fontSize="13px"
                                            color="black"
                                            text={
                                              item.BidPrice +
                                              " " +
                                              `${
                                                item.OfferMethod
                                                  ? item.OfferMethod
                                                  : "-"
                                              }`
                                            }
                                          />
                                        ) : null}
                                        <Text
                                          fontSize="13px"
                                          text={new Date(
                                            item.createdAt
                                          ).toLocaleDateString()}
                                          className="mr-5"
                                          color="black"
                                        />
                                        <a
                                          href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`}
                                          target="_blank"
                                        >
                                          <Text
                                            fontSize="13px"
                                            text={
                                              item.transactionHash?.slice(
                                                0,
                                                3
                                              ) +
                                              "..." +
                                              item.transactionHash?.slice(-3)
                                            }
                                            color="black"
                                          />
                                        </a>

                                        {nft.walletAddress == wallet.address &&
                                        nft.status == "sold" &&
                                        item.status != "inactive" ? (
                                          <div className="flex ">
                                            <Button
                                              label="Accept"
                                              height="25px"
                                              width="80px"
                                              backgroundColor="green"
                                              className="mr-1 mb-1 font-size-1 flex justify-center items-center"
                                              onClick={() => handleAccept(item)}
                                            />
                                            <Button
                                              label="Decline"
                                              height="25px"
                                              width="80px"
                                              backgroundColor="red"
                                              className="flex justify-center items-center"
                                              onClick={() => DeclineOffer(item)}
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </>
                                );
                              })
                            ) : (
                              <p className="text-center text-black mt-5">
                                No Offer History
                              </p>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div className="pt-5 w-full bg-black/4 rounded mt-5 mb-5 tabledata card_bg">
              <Text
                text="Transaction History"
                color="black"
                className="pl-5"
                fontWeight="800"
                fontSize="20px"
              />
              <Styles>
                <table className="table mt-2">
                  <thead className="table-head">
                    <tr>
                      <th style={{ color: "black" }}>Activity</th>
                      <th style={{ color: "black" }}>Hash</th>
                      <th style={{ color: "black" }}>User</th>
                      <th style={{ color: "black" }}>Price</th>
                      <th style={{ color: "black" }}>From</th>
                      <th style={{ color: "black" }}>To</th>
                      <th style={{ color: "black" }}>Time</th>
                    </tr>
                  </thead>
                  {salesData.length > 0
                    ? salesData.map((item) => {
                        return (
                          <tbody className="table-body mt-3">
                            <tr className="table-row">
                              <td className="method" style={{ color: "black" }}>
                                {item.type == "buy" ? "mint" : item.type}
                              </td>
                              <td>
                                <a
                                  style={{ color: "blue" }}
                                  href={`https://mumbai.polygonscan.com/tx/${item.transactionHash}`}
                                  target="_blank"
                                >
                                  {item.transactionHash !== undefined
                                    ? item.transactionHash?.slice(0, 5) +
                                      "..." +
                                      item.transactionHash?.slice(-3)
                                    : "--"}
                                </a>
                              </td>
                              <td className="ml-5" style={{ color: "black" }}>
                                <div className="cusprofile ">
                                  <img
                                    src={
                                      item.profilePicture
                                        ? `${process.env.REACT_APP_S3_URL}${item.profilePicture}`
                                        : Images.Profile
                                    }
                                    alt="currency"
                                    className="mr-2 w-9 h-9 rounded-3xl"
                                  />
                                  <span style={{ color: "black" }}>
                                    {item.userName ? item.userName : ""}
                                  </span>
                                </div>
                              </td>
                              <td className="price" style={{ color: "black" }}>
                                {item.paymentMethod == "matic" ? (
                                  <img
                                    src={Icons.polygon}
                                    alt="currency"
                                    className="mr-1 "
                                  />
                                ) : null}
                                {item.paymentMethod == "usdc" ? (
                                  <img
                                    src={Images.USDollar}
                                    alt="currency"
                                    className="mr-1 "
                                  />
                                ) : null}
                                {item.paymentMethod == "usdt" ? (
                                  <img
                                    src={Images.Usdt}
                                    alt="currency"
                                    className="mr-1 "
                                  />
                                ) : null}
                                {item.paymentMethod == "dollar" ? (
                                  <img
                                    src={Icons.dollar}
                                    alt="currency"
                                    className="mr-1 w-5 h-5 "
                                  />
                                ) : null}
                                {item.paymentMethod == "card" ? (
                                  <img
                                    src={Icons.dollar}
                                    alt="currency"
                                    className="mr-1 w-5 h-5 "
                                  />
                                ) : null}
                                <span style={{ color: "black" }}>
                                  {Number(item.NFTPrice).toFixed(2)}
                                </span>
                              </td>
                              <td style={{ color: "black" }}>
                                {item.from
                                  ? item.from?.slice(0, 3) +
                                    "..." +
                                    item.from?.slice(-3)
                                  : "0x0...000"}
                              </td>
                              {item.type == "onsale" ||
                              item.type == "auction" ||
                              item.type == "bid" ||
                              item.type == "makeoffer" ? (
                                <td style={{ color: "black" }}>--</td>
                              ) : (
                                <td style={{ color: "black" }}>
                                  {item.walletAddress
                                    ? item.walletAddress?.slice(0, 3) +
                                      "..." +
                                      item.walletAddress?.slice(-3)
                                    : "--"}
                                </td>
                              )}
                              <td style={{ color: "black" }}>
                                {item.SoldTime}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    : "no results"}
                </table>
              </Styles>
            </div>
            <div className="mt-10">
              <div className="flex items-center space-x-3">
                <Text
                  text="Other NFTs from"
                  fontFamily=" lora"
                  fontWeight="700"
                  fontSize="32px"
                  color="black"
                />
                <Text
                  text={nft?.OwnedBy}
                  fontFamily="lora"
                  fontWeight="700"
                  fontSize="32px"
                  color="#EF8440"
                />
              </div>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-[10px] gap-y-[20px] mt-5 mb-10">
                {renderNFTCards()}
              </div>
            </div>
          </div>
        </div>
        {open ? (
          <Loader
            type="spinner-cub"
            bgColor={"black"}
            color={"black"}
            size={80}
          />
        ) : (
          ""
        )}
        {renderModalPurchaseNFT()}
        {renderSuccessPurchaseNFT()}
      </div>
      {openLoader && (
        <Modal visible={true} onClickClose={() => setOpenLoader(false)}>
          <ModalStyles>
            <Text
              text={"Wallet Action"}
              color="black"
              fontType={"p"}
              fontWeight={"700"}
              fontSize={"28px"}
              fontFamily={"Lora"}
              className="title"
            />

            {/* { list.map((item, i) => ( */}
            <div className="card">
              <div className="mr-5">
                <img src={group29} width={30} height={30} />
              </div>

              <div className="">
                <Text
                  text={"Request sent to wallet"}
                  color="black"
                  fontWeight={"700"}
                  fontType={"p"}
                  fontSize={"20px"}
                  fontFamily={"Lora"}
                />
              </div>
              <div className="ml-5">
                {metamaskStatus && (
                  <img src={loadiing} width={30} height={30} />
                )}
              </div>
            </div>
            <div className="card">
              <div className="mr-5">
                <img src={group28} width={30} height={30} />
              </div>
              <div className="">
                <Text
                  text={"Transaction Underway"}
                  color="black"
                  fontWeight={"700"}
                  fontType={"p"}
                  fontSize={"20px"}
                  fontFamily={"Lora"}
                />
              </div>
              <div className="ml-5">
                {contractStatus && (
                  <img src={loadiing} width={30} height={30} />
                )}
              </div>
            </div>
            <div className="card">
              <div className="mr-5">
                <img src={group27} width={30} height={30} />
              </div>
              <div className="">
                <Text
                  text={"Waiting for Confirmation"}
                  color="black"
                  fontWeight={"700"}
                  fontType={"h5"}
                  fontSize={"20px"}
                  fontFamily={"Lora"}
                />
              </div>
              <div className="ml-5">
                {completionStatus && (
                  <img src={loadiing} width={30} height={30} />
                )}
              </div>
            </div>
          </ModalStyles>
        </Modal>
      )}

      <Modal
        visible={showModalPutonsale}
        onClose={() => {
          setShowModalPutOnsale(false);
        }}
        contentClassName="w-full xs:w-[540px] h-full"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Put On Sale"
              color="black"
              fontFamily="lora"
              fontWeight="500"
              fontSize="26px"
            />

            <div className="flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md">
              <div className="rounded-md overflow-hidden to-transparent w-[100px]">
                <img
                  src={nft.NFTImage}
                  alt="profile"
                  className="h-[100px] w-[150px]  rounded-md"
                />
              </div>
              <div className="w-full">
                <Text
                  text={nft.NFTName}
                  color={Colors.darkBlue}
                  fontFamily="lora"
                  fontWeight="500"
                  fontSize="18px"
                  className="mb-2"
                />
                <div className="flex">
                  <img
                    src={
                      nftowner && nftowner.profilePicture
                        ? `${process.env.REACT_APP_S3_URL}${nftowner.profilePicture}`
                        : Images.Profile
                    }
                    alt="currency"
                    className="mr-1 w-6 h-6"
                  />
                  <Text
                    text={
                      "@" + nftowner == undefined ? "User" : nftowner?.userName
                    }
                    color="black"
                    fontFamily="lora"
                    fontWeight="500"
                    fontSize="16px"
                  />
                </div>
                <div className="flex items-center justify-between space-x-2 mr-5">
                  <Text text="Last Sold" fontWeight="300" color="black" />
                  <div className="flex">
                    {lastsoldNft.paymentMethod == "matic" ? (
                      <img
                        src={Icons.polygon}
                        alt="currency"
                        className="mr-1"
                      />
                    ) : null}
                    {lastsoldNft.paymentMethod == "usdt" ? (
                      <img src={Images.Usdt} alt="currency" className="mr-1" />
                    ) : null}
                    {lastsoldNft.paymentMethod == "usdc" ? (
                      <img
                        src={Images.USDollar}
                        alt="currency"
                        className="mr-1"
                      />
                    ) : null}
                    <Text
                      text={
                        lastsoldNft.NFTPrice +
                        " " +
                        (
                          lastsoldNft &&
                          lastsoldNft.paymentMethod &&
                          lastsoldNft.paymentMethod
                        ).toUpperCase()
                      }
                      fontWeight="700"
                      color="black"
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between  space-x-2 mr-5">
                  <Text text="Owned by" fontWeight="300" color="black" />
                  <Text text={nft.OwnedBy} fontWeight="700" color="black" />
                </div>
              </div>
            </div>

            <div className="pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1">
              <div className="flex items-center justify-between mt-5">
                <Text text="Set Reserve Price" color="black" fontWeight="700" />
                {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
              </div>
              <div className="flex justify-between">
                <div className="flex flex-start space-between mt-1">
                  <div className="flex items-center ml-2">
                    <Text
                      text={`Make sure  you have enough polygon to perform the network fee`}
                      color="black"
                      fontWeight="300"
                      fontSize="15px"
                    />
                  </div>
                </div>
              </div>

              <div className="inbutblock flex w-full xs:w-[300px]  items-center justify-between mt-2  border-solid border-[1px] border-netralGrey rounded-md h-10 ">
                <input
                  textColor="black"
                  className="inputbox"
                  placeholder="0"
                  onChange={(e) => setPutonsalePrice(e.target.value)}
                />
                <div className="flex mr-4 ">
                  <img
                    src={Icons.dollar}
                    alt="currency"
                    className="mr-1 w-5 h-5"
                  />
                  <Text
                    text="USD"
                    color="black"
                    fontWeight="800"
                    fontSize="16px"
                  />
                </div>
              </div>
            </div>

            <Text
              color="black"
              fontWeight="800"
              text="Select Listing options"
              fontSize="16px"
              className="mb-2"
            />
            <Text
              color="black"
              fontWeight="300"
              text="Timed auction allows you to set time range for collectors to bid on action"
              fontSize="16px"
            />
            <div
              style={{ minHeight: "80px", display: "flex", padding: "0 10%" }}
              className="mt-2"
            >
              <div
                style={{
                  backgroundColor: instantActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setInstantActive(true);
                  setTimedActive(false);
                }}
              >
                <img
                  src={
                    instantActive ? Images.instantgreen : Images.instantblack
                  }
                  alt="currency"
                />
                <h5
                  style={{ color: instantActive ? Colors.okuGreen : "black" }}
                >
                  Instant Sale
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: timedActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setInstantActive(false);
                  setTimedActive(true);
                }}
              >
                <img
                  src={timedActive ? Images.timedgreen : Images.timedblack}
                  alt="currency"
                />
                <h5 style={{ color: timedActive ? Colors.okuGreen : "black" }}>
                  Timed Auction
                </h5>
              </div>
            </div>
            <Text
              color="black"
              fontWeight="800"
              text="Auction Days"
              fontSize="18px"
            />
            <div
              style={{ minHeight: "40px", display: "flex", padding: "0 10%" }}
              className="mt-3"
            >
              <div
                style={{
                  backgroundColor: oneday ? "#141E30" : "white",
                  minHeight: "70%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  handleDayOne();
                  setOneday(true);
                  setTwoday(false);
                  setThreeday(false);
                }}
              >
                <h5 style={{ color: oneday ? Colors.okuGreen : "black" }}>
                  1 Day
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: twoday ? "#141E30" : "white",
                  minHeight: "70%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  handleDayTwo();
                  setOneday(false);
                  setTwoday(true);
                  setThreeday(false);
                }}
              >
                <h5 style={{ color: twoday ? Colors.okuGreen : "black" }}>
                  2 Days
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: threeday ? "#141E30" : "white",
                  minHeight: "70%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  handleDayThree();
                  setOneday(false);
                  setTwoday(false);
                  setThreeday(true);
                }}
              >
                <h5 style={{ color: threeday ? Colors.okuGreen : "black" }}>
                  3 Days
                </h5>
              </div>
            </div>

            <Button
              label="List my NFT"
              disabled={!putonSalePrice || putonSalePrice < 0}
              className="w-full mt-2"
              width="100%"
              onClick={() => handleListMyNFT()}
            />
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={instantsaleModal}
        onClose={() => {
          setInstantsaleModal(false);
        }}
        contentClassName="w-full xs:w-[500px] h-full xs:h-[400px]"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Fixed Price"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="800"
              fontSize="26px"
            />

            <div className="pb-5 border-solid  mb-1">
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-5">
                  <div className="flex items-center">
                    <Text
                      text={`Price`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  className="pricebox ml-2"
                  disabled={true}
                  placeholder="0"
                  defaultValue={
                    putonSalePrice &&
                    (Number(putonSalePrice) / maticToUsd).toFixed(2)
                  }
                />
                <img src={Icons.polygon} alt="currency" className="mr-1" />
                <Text
                  text={`Matic`}
                  color="black"
                  fontWeight="700"
                  fontSize="16px"
                  className="mr-5"
                />
              </div>
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-5">
                  <div className="flex items-center">
                    <Text
                      text={`USD`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  defaultValue={putonSalePrice && putonSalePrice}
                  disabled={true}
                  className="pricebox ml-2"
                  placeholder="$0"
                />
              </div>
            </div>
            <Button
              label="List for Sale"
              className="w-full mt-2"
              width="100%"
              onClick={() => putOnSaleContract()}
            />
          </div>
        </PutonsaleModal>
      </Modal>
      <Modal
        visible={showTimedmodal}
        onClose={() => {
          setShowTimedModal(false);
        }}
        contentClassName="w-full xs:w-[500px] h-full xs:h-[550px]"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Timed Auction"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="800"
              fontSize="26px"
            />

            <div className="pb-5  mb-1">
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Method`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <select className="pricebox">
                  <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Price`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  className="pricebox ml-2"
                  defaultValue={
                    putonSalePrice &&
                    (Number(putonSalePrice) / maticToUsd).toFixed(2)
                  }
                  placeholder="0"
                />
                <img src={Icons.polygon} alt="currency" className="mr-1" />
                <Text
                  text={`Matic`}
                  color="black"
                  fontWeight="700"
                  fontSize="16px"
                  className="mr-5"
                />
              </div>
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`USD`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  defaultValue={putonSalePrice && putonSalePrice}
                  className="pricebox ml-2"
                  placeholder="$0"
                />
              </div>

              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Date`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  id="meeting-time"
                  name="meeting-time"
                  type="datetime-local"
                  value={daytime ? daytime : ""}
                  className="pricebox ml-2"
                  onChange={(e) => setDay(e.target.value)}
                />
              </div>
            </div>
            <Button
              label="List for Sale"
              disabled={!daytime}
              className="w-full mt-2"
              width="100%"
              onClick={() => putOnSaleContract()}
            />
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={showBidforsalemodal}
        onClose={() => {
          setShowBidforsaleModal(false);
        }}
        contentClassName="w-full xs:w-[500px] h-full xs:h-[550px]"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Timed Auction"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="800"
              fontSize="26px"
            />

            <div className="pb-5  mb-1">
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Method`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <select className="pricebox">
                  <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Price`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  className="pricebox ml-2"
                  placeholder="0"
                />
                <img src={Icons.polygon} alt="currency" className="mr-1" />
                <Text
                  text={`Matic`}
                  color="black"
                  fontWeight="700"
                  fontSize="16px"
                  className="mr-5"
                />
              </div>
              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`USD`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  className="pricebox ml-2"
                  placeholder="$0"
                />
              </div>

              <div className="flex justify-start">
                <div className="flex flex-start space-between mt-2">
                  <div className="flex items-center">
                    <Text
                      text={`Date`}
                      color="black"
                      fontWeight="300"
                      fontSize="17px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  type={"date"}
                  textColor="black"
                  className="pricebox ml-2"
                />
              </div>
            </div>
            <Button
              label="List for Sale"
              className="w-full mt-2"
              width="100%"
            />
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={showPlaceBidmodal}
        onClose={() => {
          setShowPlaceBidModal(false);
        }}
        contentClassName="w-full xs:w-[500px] h-full xs:h-[550px]"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Place Bid"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="800"
              fontSize="26px"
            />

            <div className="pb-5  mb-1">
              <div className="flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md">
                <div className="rounded-md overflow-hidden to-transparent w-[250px]">
                  <img
                    src={`${nft.NFTImage}`}
                    alt="profile"
                    className="h-[120px] w-[250px]  rounded-md"
                  />
                </div>
                <div className="w-full">
                  <Text
                    text={nft.NFTName}
                    color={Colors.darkBlue}
                    fontFamily="lora"
                    fontWeight="500"
                    fontSize="18px"
                    className="mb-2"
                  />
                  <div className="flex">
                    <img
                      src={
                        nftowner && nftowner.profilePicture
                          ? `${process.env.REACT_APP_S3_URL}${nftowner.profilePicture}`
                          : Images.Profile
                      }
                      alt="currency"
                      className="mr-1 w-6 h-6"
                    />
                    <Text
                      text={nftowner == undefined ? "User" : nftowner?.userName}
                      color="black"
                      fontFamily="lora"
                      fontWeight="500"
                      fontSize="16px"
                    />
                  </div>
                  <div className="flex items-center justify-between space-x-2 mr-5 mt-2">
                    <div className="flex">
                      {lastsoldNft.paymentMethod == "matic" ? (
                        <img
                          src={Icons.polygon}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      {lastsoldNft.paymentMethod == "usdt" ? (
                        <img
                          src={Images.Usdt}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      {lastsoldNft.paymentMethod == "usdc" ? (
                        <img
                          src={Images.USDollar}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      <Text
                        text={
                          lastsoldNft.NFTPrice +
                          " " +
                          (
                            lastsoldNft &&
                            lastsoldNft.paymentMethod &&
                            lastsoldNft.paymentMethod
                          ).toUpperCase()
                        }
                        fontWeight="700"
                        color="black"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center ml-12  justify-between mt-3 w-full xs:w-[300px] border-solid border-[1px] border-netralGrey rounded-md h-12">
                <Text
                  text={`Available Balance`}
                  color="black"
                  fontWeight="300"
                  fontSize="17px"
                />
                <div className="flex">
                  {/* {bidmethod == 'matic' ? 
                  <>
                    <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                    <Text text={`${availableMatic && availableMatic} Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
                  </>
                    
                  : null} */}
                  {bidmethod == "usdc" ? (
                    <>
                      <img
                        src={Images.USDollar}
                        alt="currency"
                        className="mr-1"
                      />
                      <Text
                        text={`${availableUsdc && availableUsdc} USDC`}
                        color="black"
                        fontWeight="700"
                        fontSize="16px"
                        className="mr-5"
                      />
                    </>
                  ) : null}

                  {bidmethod == "usdt" ? (
                    <>
                      <img src={Images.Usdt} alt="currency" className="mr-1" />
                      <Text
                        text={`${availableUsdt && availableUsdt} USDT`}
                        color="black"
                        fontWeight="700"
                        fontSize="16px"
                        className="mr-5"
                      />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="inbutblock1 flex w-full xs:w-[400px]  items-center justify-between mt-2  border-solid border-[1px] border-netralGrey rounded-md h-10 ">
                <input
                  textColor="black"
                  className="inputbox"
                  value={Bidprice}
                  placeholder="0"
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setBidprice(e.target.value);
                    } else {
                      setBidprice("");
                    }
                  }}
                />
                <div className="flex mr-4 ">
                  {bidmethod == "matic" ? (
                    <img src={Icons.polygon} alt="currency" className="mr-1" />
                  ) : null}
                  {bidmethod == "usdc" ? (
                    <img
                      src={Images.USDollar}
                      alt="currency"
                      className="mr-1"
                    />
                  ) : null}
                  {bidmethod == "usdt" ? (
                    <img src={Images.Usdt} alt="currency" className="mr-1" />
                  ) : null}
                  <select
                    style={{ cursor: "pointer" }}
                    onChange={(e) => setBidMethod(e.target.value)}
                  >
                    <option label="USDC" value="usdc"></option>
                    <option label="USDT" value="usdt"></option>
                  </select>
                </div>
              </div>

              <div className="flex items-center  justify-between mt-3 w-full h-12">
                <Text
                  text={`Mininmum Bid Amount`}
                  color="black"
                  fontWeight="300"
                  fontSize="17px"
                />
                <div className="flex">
                  <img src={Images.USDollar} alt="currency" className="mr-1" />
                  <Text
                    text={`${nft.NFTPrice} USDC`}
                    color="black"
                    fontWeight="700"
                    fontSize="16px"
                    className="mr-5"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between mt-3 w-full  h-12">
                <Text
                  text={`Maximum bidder in the Auction`}
                  color="black"
                  fontWeight="300"
                  fontSize="17px"
                />
                <div className="flex">
                  <img src={Images.USDollar} alt="currency" className="mr-1" />

                  <Text
                    text={`${maxbidder} USDC`}
                    color="black"
                    fontWeight="700"
                    fontSize="16px"
                    className="mr-5"
                  />
                </div>
              </div>
            </div>
            <Button
              label="Place Bid"
              disabled={!Bidprice > 0}
              className="w-full mt-2"
              width="100%"
              onClick={() => handlePlaceBid()}
            />
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={showMakeOfferModal}
        onClose={() => {
          setShowMakeOfferModal(false);
        }}
        contentClassName="w-full xs:w-[500px] h-full xs:h-[500px]"
      >
        <PutonsaleModal>
          <div className="">
            <Text
              text="Make an Offer"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="800"
              fontSize="26px"
            />

            <div className="pb-4">
              <div className="flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md">
                <div className="rounded-md overflow-hidden to-transparent w-[250px]">
                  <img
                    src={nft.NFTImage}
                    alt="profile"
                    className="h-[120px] w-[250px]  rounded-md"
                  />
                </div>
                <div className="w-full">
                  <Text
                    text={nft.NFTName}
                    color={Colors.darkBlue}
                    fontFamily="lora"
                    fontWeight="500"
                    fontSize="18px"
                    className="mb-2 mt-2"
                  />

                  <div className="flex items-center justify-between space-x-2 mr-5 mt-5">
                    <Text text="Last Sold" fontWeight="700" color="black" />
                    <div className="flex">
                      {lastsoldNft.paymentMethod == "matic" ? (
                        <img
                          src={Icons.polygon}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      {lastsoldNft.paymentMethod == "usdt" ? (
                        <img
                          src={Images.Usdt}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      {lastsoldNft.paymentMethod == "usdc" ? (
                        <img
                          src={Images.USDollar}
                          alt="currency"
                          className="mr-1"
                        />
                      ) : null}
                      <Text
                        text={
                          lastsoldNft.NFTPrice +
                          " " +
                          (
                            lastsoldNft &&
                            lastsoldNft.paymentMethod &&
                            lastsoldNft.paymentMethod
                          ).toUpperCase()
                        }
                        fontWeight="700"
                        color="black"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Text
                className={"mt-4"}
                text="Once an offer is sent The owner will have 24 hours to accept. if the offer expires, the funds will be available in your marketplace balance."
                fontWeight="300"
                fontSize={"14px"}
                color="black"
              />
              <div className="flex items-center justify-start  mt-2 w-full  border-solid border-[1px] border-netralGrey rounded-md">
                <input
                  textColor="black"
                  className="pricebox ml-2"
                  value={Bidprice}
                  placeholder="0"
                  onChange={(e) => {
                    const re = /^\d*\.?\d*$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setBidprice(e.target.value);
                    } else {
                      setBidprice("");
                    }
                  }}
                />

                {offermethod == "usdc" ? (
                  <img src={Images.USDollar} alt="currency" className="mr-1" />
                ) : null}
                {offermethod == "usdt" ? (
                  <img src={Images.Usdt} alt="currency" className="mr-1" />
                ) : null}
                {/* <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/> */}
                <select
                  style={{ cursor: "pointer" }}
                  onChange={(e) => setOfferMethod(e.target.value)}
                >
                  <option label="USDC" value="usdc"></option>
                  <option label="USDT" value="usdt"></option>
                </select>
              </div>

              <div className="flex items-center  justify-between mt-3 w-full h-12">
                <Text
                  text={`Available Balance`}
                  color="black"
                  fontWeight="300"
                  fontSize="17px"
                />
                <div className="flex">
                  {offermethod == "usdc" ? (
                    <>
                      <img
                        src={Images.USDollar}
                        alt="currency"
                        className="mr-1"
                      />
                      <Text
                        text={`${availableUsdc && availableUsdc} USDC`}
                        color="black"
                        fontWeight="700"
                        fontSize="16px"
                        className="mr-5"
                      />
                    </>
                  ) : (
                    <>
                      <img src={Images.Usdt} alt="currency" className="mr-1" />
                      <Text
                        text={`${availableUsdt && availableUsdt} USDT`}
                        color="black"
                        fontWeight="700"
                        fontSize="16px"
                        className="mr-5"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <Button
              label="Make Offer"
              disabled={!Bidprice}
              className="w-full"
              width="100%"
              onClick={() => {
                handlemakeOffer();
              }}
            />
          </div>
        </PutonsaleModal>
      </Modal>
      <Modal
        visible={showModalPurchaseBuy}
        onClose={() => {
          setShowModalPurchaseBuy(false);
          cryptoActive(false);
          cardActive(false);
          setShowModalPurchaseWithCard(false);
          setShowModalPurchaseWithCrypto(false);
        }}
        contentClassName="w-full xs:w-[540px] h-full"
      >
        {!paymentStatus &&
        !nft.PaymentStatus &&
        !showModalPurchaseWithCard &&
        !showModalPurchaseWithCrypto ? (
          <div className="">
            <Text
              text="Purchase NFT"
              color={Colors.prussianBlue}
              fontFamily="lora"
              fontWeight="500"
              fontSize="26px"
            />

            <div className="flex space-x-4 mb-1 mt-1">
              <div className="rounded-md overflow-hidden to-transparent w-[90px]">
                <img
                  src={nft.NFTImage}
                  alt="profile"
                  className="h-[90px] w-[90px]  rounded-md"
                />
              </div>
              <div>
                <Text
                  text={nft.NFTName}
                  color={Colors.darkBlue}
                  fontFamily="lora"
                  fontWeight="500"
                  fontSize="20px"
                  className="mb-2"
                />
                <Text
                  text={nft.NFTCollections}
                  color="black"
                  fontFamily="lora"
                  fontWeight="500"
                  fontSize="20px"
                />
                <div className="flex items-center space-x-2 ">
                  <Text text="Owned by" fontWeight="300" color="black" />
                  <Text text={nft.OwnedBy} fontWeight="700" color="black" />
                </div>
                <Text
                  text="View item on chain"
                  color={Colors.prussianBlue}
                  fontWeight="700"
                />
              </div>
            </div>

            <Text
              color={Colors.darkBlue}
              text="Summary"
              fontFamily="lora"
              fontWeight="700"
              fontSize="20px"
            />
            <div className="pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1">
              <div className="flex items-center justify-between mt-1">
                <Text text="NFT Price" color="black" fontWeight="300" />
                {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
              </div>
              <div className="flex justify-between">
                <div className="flex flex-start space-between mt-5">
                  <div className="flex items-center ml-2">
                    {/* <img src={Icons.polygon} alt="currency" className="mr-1" /> */}
                    <Text
                      text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`}
                      color="black"
                      fontWeight="700"
                      fontSize="17px"
                    />
                  </div>
                  <div className="flex items-center ml-1">
                    <img
                      src={Images.USDollar}
                      alt="currency"
                      className="mr-1"
                    />
                    <Text
                      text={`${nft.NFTPrice}`}
                      color="black"
                      fontWeight="800"
                      fontSize="17px"
                    />
                  </div>
                  <div className="flex items-center ml-2">
                    <img src={Images.Usdt} alt="currency" className="mr-1" />
                    <Text
                      text={`${nft.NFTPrice}`}
                      color="black"
                      fontWeight="800"
                      fontSize="17px"
                    />
                  </div>
                </div>
                <div className="flex items-center flex-start space-between mt-1">
                  <div className="flex  ml-2">
                    <img src={Icons.polygon} alt="currency" className="mr-1" />
                    <Text
                      text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`}
                      color="black"
                      fontWeight="800"
                      fontSize="16px"
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-1 mb-1">
                <Text text="Additional Fee" color="black" fontWeight="300" />
                <div className="flex">
                  <img src={Icons.polygon} alt="currency" className="mr-1" />
                  <Text
                    text={(
                      ((Number(nft.NFTPrice) / maticToUsd).toFixed(2) *
                        (nft.NFTRoyalty + 1)) /
                      100
                    ).toFixed(2)}
                    color="black"
                    fontWeight="800"
                  />
                </div>
              </div>
              <div className="flex items-flex-end justify-between">
                <Text
                  text="Total"
                  color="black"
                  fontWeight="800"
                  fontSize="16px"
                />
                {/* <div style={{dipaly:"inline"}}> */}
                <img
                  src={Icons.polygon}
                  className="ml-25"
                  alt="currency"
                  style={{ marginLeft: "80%" }}
                />
                <Text
                  text={`${(
                    Number(nft.NFTPrice) / maticToUsd +
                    ((Number(nft.NFTPrice) / maticToUsd) *
                      (nft.NFTRoyalty + 1)) /
                      100
                  ).toFixed(2)}`}
                  color="black"
                  fontWeight="800"
                  fontSize="16px"
                />
                {/* </div> */}
              </div>
            </div>

            <Text
              color="black"
              fontWeight="800"
              text="Payment method"
              fontSize="16px"
            />
            {/* <div className='rounded overflow-hidden shadow-lg p-px w-full bg-gradient-to-br from-white/40 to-transparent hover:bg-lemonChiffon'>
              <div className="bg-slate-800 rounded-md">
              </div>
            </div> */}
            <div
              style={{ minHeight: "80px", display: "flex", padding: "0 10%" }}
              className="mt-6"
            >
              <div
                style={{
                  backgroundColor: cryptoActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setCryptoActive(true);
                  setCardActive(false);
                }}
              >
                <img
                  src={cryptoActive ? Images.Cryptogreen : Images.Cryptoblack}
                  alt="currency"
                  style={{ width: "20px", height: "20px" }}
                />
                <h5 style={{ color: cryptoActive ? "white" : "black" }}>
                  Crypto
                </h5>
              </div>
              <div
                style={{
                  backgroundColor: cardActive ? "#141E30" : "white",
                  minHeight: "100%",
                  width: "50%",
                  borderRadius: "1em",
                  margin: "0 5%",
                  border: "1px solid lightgray",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setCryptoActive(false);
                  setCardActive(true);
                }}
              >
                <img
                  src={cardActive ? Images.Cardgreen : Images.Cardblack}
                  alt="currency"
                  style={{ width: "20px", height: "20px" }}
                />
                <h5 style={{ color: cardActive ? "white" : "black" }}>Card</h5>
              </div>
            </div>

            <Button
              label="Pay Now"
              className="w-full mt-5"
              width="100%"
              onClick={() => {
                // mintNFTs(nft._id)
                if (cardActive) {
                  paymentCall(nft.NFTPrice);
                } else if (cryptoActive) {
                  paymentwithcrypto();
                } else {
                  toast("select payment method");
                }

                // renderModalPurchaseWithCard();
              }}
            />
          </div>
        ) : (
          <>
            {cardActive ||
            (nft.paymentStatus == "succeeded" &&
              nft.paidBy == current_user.user.id) ? (
              <div className="">
                <Text
                  text="Purchase NFT With Card"
                  color={Colors.prussianBlue}
                  fontFamily="lora"
                  fontWeight="600"
                  fontSize="24px"
                />

                <div className="flex space-x-4 mb-1 mt-1">
                  <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                    <img
                      src={nft.NFTImage}
                      alt="profile"
                      className="h-[80px] w-[80px]  rounded-md"
                    />
                  </div>
                  <div>
                    <Text
                      text={nft.NFTName}
                      color={Colors.darkBlue}
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="16px"
                      className="mb-1"
                    />
                    <Text
                      text={nft.NFTCollections}
                      color="black"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="16px"
                    />
                    <div className="flex items-center space-x-2 mb-1 mt-1">
                      <Text text="Owned by" fontWeight="300" color="black" />
                      <Text text={nft.OwnedBy} fontWeight="700" color="black" />
                    </div>
                    <Text
                      text="View item on chain"
                      color={Colors.prussianBlue}
                      fontWeight="700"
                    />
                  </div>
                </div>

                <Text
                  color={Colors.darkBlue}
                  text="Summary"
                  fontFamily="lora"
                  fontWeight="700"
                  fontSize="20px"
                />
                <div className="pb-5 ">
                  <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey">
                    <div className="flex items-center">
                      <img
                        src={nft.NFTImage}
                        alt="currency"
                        className="rounded mr-1 w-15 h-10"
                      />
                      <Text
                        text={`Purchasing NFT for US $ ${nft.NFTPrice}`}
                        fontSize="42"
                        color="black"
                        fontWeight="700"
                        className="ml-5"
                      />
                      {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                    </div>
                  </div>
                  {!paymentStatus && !nft.PaymentStatus ? (
                    <div className="mt-1">
                      <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm
                          options={options}
                          stripe={stripePromise}
                          paymentStatus={paymentStatus}
                          setPaymentstatus={setPaymentstatus}
                          nft={nft}
                        />
                      </Elements>
                    </div>
                  ) : null}
                </div>
                {paymentStatus || nft.PaymentStatus ? (
                  <div>
                    {loaderimage ? (
                      <img
                        src={loading}
                        alt="loader"
                        style={{
                          width: "35px",
                          height: "35px",
                          marginLeft: "45%",
                        }}
                      ></img>
                    ) : null}
                    <Button
                      label="Complete Purchase"
                      disabled={
                        !paymentStatus && !nft.PaymentStatus ? true : false
                      }
                      className="w-full mt-1"
                      width="100%"
                      onClick={() => {
                        BuyNFTAfterPayment(nft._id);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {cryptoActive || nft.PaymentStatus != "succeeded" ? (
                  <div className="">
                    <Text
                      text="Purchase NFT With Crypto"
                      color={Colors.prussianBlue}
                      fontFamily="lora"
                      fontWeight="600"
                      fontSize="24px"
                    />

                    <div className="flex space-x-4 mb-1 mt-1">
                      <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                        <img
                          src={nft.NFTImage}
                          alt="profile"
                          className="h-[80px] w-[80px]  rounded-md"
                        />
                      </div>
                      <div>
                        <Text
                          text={nft.NFTName}
                          color={Colors.darkBlue}
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                          className="mb-1"
                        />
                        <Text
                          text={nft.NFTCollections}
                          color="black"
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                        />
                        <div className="flex items-center space-x-2 mb-1 mt-1">
                          <Text
                            text="Owned by"
                            fontWeight="300"
                            color="black"
                          />
                          <Text
                            text={nft.OwnedBy}
                            fontWeight="700"
                            color="black"
                          />
                        </div>
                        <Text
                          text="View item on chain"
                          color={Colors.prussianBlue}
                          fontWeight="700"
                        />
                      </div>
                    </div>

                    <Text
                      color={Colors.darkBlue}
                      text="Summary"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="20px"
                    />
                    <div className="pb-5 ">
                      <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey p-4">
                        <div className="flex items-center">
                          <img
                            src={nft.NFTImage}
                            alt="currency"
                            className="rounded mr-1 w-15 h-10"
                          />
                          {!usdActive && !usdtActive ? (
                            <Text
                              text={`Purchasing NFT for  ${
                                (Number(nft.NFTPrice) / maticToUsd).toFixed(2) +
                                " MATIC"
                              }`}
                              fontSize="42"
                              color="black"
                              fontWeight="700"
                              className="ml-5 rounded"
                            />
                          ) : (
                            <Text
                              text={`Purchasing NFT for  ${
                                usdActive
                                  ? nft.NFTPrice + " USDC"
                                  : nft.NFTPrice + " USDT"
                              }`}
                              fontSize="42"
                              color="black"
                              fontWeight="700"
                              className="ml-5 rounded"
                            />
                          )}
                          {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setMaticActive(true);
                        setUsdActive(false);
                        setUsdtActive(false);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: maticActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Icons.polygon}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(
                            2
                          )}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={maticActive ? "white" : "black"}
                        />
                      </div>

                      <Text
                        text="MATIC"
                        fontWeight="800"
                        color={maticActive ? "white" : "black"}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setMaticActive(false);
                        setUsdActive(true);
                        setUsdtActive(false);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: usdActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Images.USDollar}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${nft.NFTPrice}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={usdActive ? "white" : "black"}
                        />
                      </div>
                      <Text
                        text="USDC"
                        fontWeight="800"
                        color={usdActive ? "white" : "black"}
                      />
                    </div>

                    <div
                      onClick={() => {
                        setMaticActive(false);
                        setUsdActive(false);
                        setUsdtActive(true);
                      }}
                      className="transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded "
                      style={{
                        marginBottom: "10px",
                        backgroundColor: usdtActive ? "#141E30" : "white",
                        borderRadius: "1em",
                        width: "60%",
                        margin: "auto",
                      }}
                    >
                      <div className="flex items-center">
                        <img
                          src={Images.Usdt}
                          alt="currency"
                          className="rounded mr-1 w-7 h-7"
                        />
                        <Text
                          text={`${nft.NFTPrice}`}
                          fontSize="42"
                          fontWeight="700"
                          className="ml-5"
                          color={usdtActive ? "white" : "black"}
                        />
                      </div>
                      <Text
                        text="USDT"
                        fontWeight="800"
                        color={usdtActive ? "white" : "black"}
                      />
                    </div>

                    <Button
                      label="Pay Now"
                      disabled={
                        usdtActive || usdActive || maticActive ? false : true
                      }
                      className="w-full mt-1"
                      width="100%"
                      onClick={() => {
                        if (maticActive) {
                          if (
                            availableMatic >=
                            (Number(nft.NFTPrice) / maticToUsd).toFixed(2)
                          ) {
                            BuyNFT(nft._id);
                          } else {
                            toast("You dont have enough MATIC in Your wallet");
                          }
                        } else if (usdActive) {
                          if (availableUsdc >= Number(nft.NFTPrice)) {
                            BuyNFT(nft._id);
                          } else {
                            toast("You dont have enough USDC in Your wallet");
                          }
                        } else if (usdtActive) {
                          if (availableUsdt >= Number(nft.NFTPrice)) {
                            BuyNFT(nft._id);
                          } else {
                            toast("You dont have enough USDT in Your wallet ");
                          }
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Text
                      text="Purchase NFT With Card"
                      color={Colors.prussianBlue}
                      fontFamily="lora"
                      fontWeight="600"
                      fontSize="24px"
                    />

                    <div className="flex space-x-4 mb-1 mt-1">
                      <div className="rounded-md overflow-hidden  to-transparent w-[80px]">
                        <img
                          src={nft.NFTImage}
                          alt="profile"
                          className="h-[80px] w-[80px]  rounded-md"
                        />
                      </div>
                      <div>
                        <Text
                          text={nft.NFTName}
                          color={Colors.darkBlue}
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                          className="mb-1"
                        />
                        <Text
                          text={nft.NFTCollections}
                          color="black"
                          fontFamily="lora"
                          fontWeight="700"
                          fontSize="16px"
                        />
                        <div className="flex items-center space-x-2 mb-1 mt-1">
                          <Text
                            text="Owned by"
                            fontWeight="300"
                            color="black"
                          />
                          <Text
                            text={nft.OwnedBy}
                            fontWeight="700"
                            color="black"
                          />
                        </div>
                        <Text
                          text="View item on chain"
                          color={Colors.prussianBlue}
                          fontWeight="700"
                        />
                      </div>
                    </div>

                    <Text
                      color={Colors.darkBlue}
                      text="Summary"
                      fontFamily="lora"
                      fontWeight="700"
                      fontSize="20px"
                    />
                    <div className="pb-5 ">
                      <div className="flex items-center  mt-1 border-[1px] border-b-netralGrey">
                        <div className="flex items-center">
                          <img
                            src={nft.NFTImage}
                            alt="currency"
                            className="rounded mr-1 w-15 h-10"
                          />
                          <Text
                            text={`Purchasing NFT for US $ ${nft.NFTPrice}`}
                            fontSize="42"
                            color="black"
                            fontWeight="700"
                            className="ml-5"
                          />
                          {/* <Text text={nft.NFTPrice} color='black' fontWeight='800' /> */}
                        </div>
                      </div>
                      {!paymentStatus && !nft.PaymentStatus ? (
                        <div className="mt-1">
                          <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm
                              options={options}
                              stripe={stripePromise}
                              paymentStatus={paymentStatus}
                              setPaymentstatus={setPaymentstatus}
                              nft={nft}
                            />
                          </Elements>
                        </div>
                      ) : null}
                    </div>
                    {paymentStatus || nft.PaymentStatus ? (
                      <div>
                        {loaderimage ? (
                          <img
                            src={loading}
                            alt="loader"
                            style={{
                              width: "35px",
                              height: "35px",
                              marginLeft: "45%",
                            }}
                          ></img>
                        ) : null}
                        <Button
                          label="Complete Purchase"
                          disabled={
                            !paymentStatus && !nft.PaymentStatus ? true : false
                          }
                          className="w-full mt-1"
                          width="100%"
                          onClick={() => {
                            BuyNFTAfterPayment(nft._id);
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {/* <div className=''>
            <Text text='Purchase NFT' color={Colors.prussianBlue} fontFamily='lora' fontWeight='500' fontSize='26px' />

            <div className='flex space-x-4 mb-1 mt-1'>
              <div className='rounded-md overflow-hidden to-transparent w-[90px]'>
                <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`} alt='profile' className='h-[90px] w-[90px]  rounded-md' />
              </div>
              <div>
                <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='500' fontSize='20px' className="mb-2" />
                <Text text={nft.NFTCollections} color='black' fontFamily='lora' fontWeight='500' fontSize='20px' />
                <div className='flex items-center space-x-2 '>
                  <Text text='Owned by' fontWeight='300' color='black' />
                  <Text text={nft.OwnedBy} fontWeight='700' color='black' />
                </div>
                <Text text='View item on chain' color={Colors.prussianBlue} fontWeight='700' />
              </div>
            </div>

            <Text color={Colors.darkBlue} text='Summary' fontFamily='lora' fontWeight='700' fontSize='20px' />
            <div className='pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1'>
              <div className='flex items-center justify-between mt-1'>
                <Text text='NFT Price' color='black' fontWeight='300' />
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-start space-between mt-5'>
                  <div className='flex items-center ml-2'>
                    <img src={Icons.polygon} alt='currency' className='mr-1' />
                    <Text text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`} color='black' fontWeight='700' fontSize='17px' />
                  </div>
                  <div className='flex items-center ml-1'>
                    <img src={Images.USDollar} alt='currency' className='mr-1' />
                    <Text text={`${nft.NFTPrice}`} color='black' fontWeight='800' fontSize='17px' />
                  </div>
                  <div className='flex items-center ml-2'>
                    <img src={Images.Usdt} alt='currency' className='mr-1' />
                    <Text text={`${nft.NFTPrice}`} color='black' fontWeight='800' fontSize='17px' />
                  </div>
                </div>
                <div className='flex items-center flex-start space-between mt-1'>
                  <div className='flex  ml-2'>
                    <img src={Icons.polygon} alt='currency' className='mr-1' />
                    <Text text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`} color='black' fontWeight='800' fontSize='16px' />
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between mt-1 mb-1'>
                <Text text='Additional Fee' color='black' fontWeight='300' />
                <div className='flex'>
                  <img src={Icons.polygon} alt='currency' className='mr-1' />
                  <Text text={(((Number(nft.NFTPrice) / maticToUsd).toFixed(2) * (nft.NFTRoyalty + 1)) / 100).toFixed(2)} color='black' fontWeight='800' />
                </div>
              </div>
              <div className='flex items-flex-end justify-between'>
                <Text text='Total' color='black' fontWeight='800' fontSize='16px' />
                <img src={Icons.polygon} className='ml-25' alt='currency' style={{ marginLeft: "80%" }} />
                <Text text={`${((Number(nft.NFTPrice) / maticToUsd) + (((Number(nft.NFTPrice) / maticToUsd) * (nft.NFTRoyalty + 1)) / 100)).toFixed(2)}`} color='black' fontWeight='800' fontSize='16px' />
              </div>

            </div>

            <Text
              color='black'
              fontWeight='800'
              text='Payment method'
              fontSize='16px'
            />
            
            <div style={{ minHeight: "80px", display: "flex", padding: "0 10%" }} className="mt-6">
              <div style={{ backgroundColor: cryptoActive ? "#141E30" : "white", minHeight: "100%", width: "50%", borderRadius: "1em", margin: "0 5%", border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                onClick={() => { setCryptoActive(true); setCardActive(false) }}
              >
                <img src={cryptoActive ? Images.Cryptogreen : Images.Cryptoblack} alt='currency' />
                <h5 style={{ color: cryptoActive ? "white" : "black" }}>Crypto</h5>
              </div>
              <div style={{ backgroundColor: cardActive ? "#141E30" : "white", minHeight: "100%", width: "50%", borderRadius: "1em", margin: "0 5%", border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                onClick={() => { setCryptoActive(false); setCardActive(true) }}
              >
                <img src={cardActive ? Images.Cardgreen : Images.Cardblack} alt='currency' />
                <h5 style={{ color: cardActive ? "white" : "black" }}>Card</h5>
              </div>
            </div>

            <Button label='Pay Now' className='w-full mt-5' width='100%' onClick={() => {
              // mintNFTs(nft._id)
              if (cardActive) {
                paymentCall(nft.NFTPrice)
              } else if (cryptoActive) {
                paymentwithcrypto()
              } else {
                toast("select payment method")
              }

              // renderModalPurchaseWithCard();

            }} />

          </div> */}
        {/* <div className=''>
          <Text text='Purchase NFT' color={Colors.prussianBlue} fontFamily='lora' fontWeight='600' fontSize='24px' />

          <div className='flex space-x-4 mb-1 mt-1'>
            <div className='rounded-md overflow-hidden  to-transparent w-[80px]'>
              <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`} alt='profile' className='h-[80px] w-[80px]  rounded-md' />
            </div>
            <div>
              <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='700' fontSize='16px' className="mb-1" />
              <Text text={nft.NFTCollections} color='black' fontFamily='lora' fontWeight='700' fontSize='16px' />
              <div className='flex items-center space-x-2 mb-1 mt-1'>
                <Text text='Owned by' fontWeight='300' color='black' />
                <Text text={nft.OwnedBy} fontWeight='700' color='black' />
              </div>
              <Text text='View item on chain' color={Colors.prussianBlue} fontWeight='700' />
            </div>
          </div>

          <Text color={Colors.darkBlue} text='Summary' fontFamily='lora' fontWeight='700' fontSize='20px' />
          <div className='pb-5 '>
            <div className='flex items-center  mt-1 border-[1px] border-b-netralGrey p-4'>
              <div className='flex items-center'>
                <img src={`${process.env.REACT_APP_S3_URL}${nft.NFTImage}`} alt='currency' className='rounded mr-1 w-15 h-10' />
                {!usdActive && !usdtActive ?
                  <Text text={`Purchasing NFT for  ${(Number(nft.NFTPrice) / maticToUsd).toFixed(2) + " MATIC"}`} fontSize="42" color='black' fontWeight='700' className="ml-5 rounded" />
                  :
                  <Text text={`Purchasing NFT for  ${usdActive ? nft.NFTPrice + " USDC" : nft.NFTPrice + " USDT"}`} fontSize="42" color='black' fontWeight='700' className="ml-5 rounded" />
                }
              </div>
            </div>

          </div>
          <div onClick={() => { setMaticActive(true); setUsdActive(false); setUsdtActive(false) }} className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded ' style={{ marginBottom: "4%", backgroundColor: maticActive ? "#141E30" : "white", borderRadius: "1em", width: "60%", margin: "auto" }}>
            <div className='flex items-center'>
              <img src={Icons.polygon} alt='currency' className='rounded mr-1 w-7 h-7' />
              <Text text={`${(Number(nft.NFTPrice) / maticToUsd).toFixed(2)}`} fontSize="42" fontWeight='700' className="ml-5" color={maticActive ? "white" : "black"} />
            </div>

            <Text text="MATIC" fontWeight='800' color={maticActive ? "white" : "black"} />
          </div>
          <div onClick={() => { setMaticActive(false); setUsdActive(true); setUsdtActive(false) }} className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded ' style={{ marginBottom: "4%", backgroundColor: usdActive ? "#141E30" : "white", borderRadius: "1em", width: "60%", margin: "auto" }}>
            <div className='flex items-center'>
              <img src={Images.USDollar} alt='currency' className='rounded mr-1 w-7 h-7' />
              <Text text={`${nft.NFTPrice}`} fontSize="42" fontWeight='700' className="ml-5" color={usdActive ? "white" : "black"} />
            </div>
            <Text text="USDC" fontWeight='800' color={usdActive ? "white" : "black"} />
          </div>

          <div onClick={() => { setMaticActive(false); setUsdActive(false); setUsdtActive(true) }} className='transactiontype  items-center flex p-3   justify-between mt-1 border-[1px] border-b-netralGrey  rounded ' style={{ marginBottom: "4%", backgroundColor: usdtActive ? "#141E30" : "white", borderRadius: "1em", width: "60%", margin: "auto" }}>
            <div className='flex items-center'>
              <img src={Images.Usdt} alt='currency' className='rounded mr-1 w-7 h-7' />
              <Text text={`${nft.NFTPrice}`} fontSize="42" fontWeight='700' className="ml-5" color={usdtActive ? "white" : "black"} />

            </div>
            <Text text="USDT" fontWeight='800' color={usdtActive ? "white" : "black"} />
          </div>


          <Button label='Pay Now' disabled={usdtActive || usdActive || maticActive ? false : true} className='w-full mt-1' width='100%' onClick={() => {
            if (maticActive) {
              if (availableMatic >= (Number(nft.NFTPrice) / maticToUsd).toFixed(2)) {
                BuyNFT(nft._id)
              } else {
                toast("You dont have enough MATIC in Your wallet")
              }
            } else if (usdActive) {
              if (availableUsdc >= Number(nft.NFTPrice)) {
                BuyNFT(nft._id)
              } else {
                toast("You dont have enough USDC in Your wallet")
              }
            } else if (availableUsdt) {
              if (availableMatic >= Number(nft.NFTPrice)) {
                BuyNFT(nft._id)
              } else {
                toast("You dont have enough USDT in Your wallet")
              }
            }
          }} />
        </div> */}
      </Modal>
    </Layout>
  );
};

export default NFT;
