import React, { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import {
  LandingPage,
  // Marketplace,
  // Marketplace,
  // LoginPage,
  WalletConnection,
  // Help,
  MyProfile,
  // MainPage,
  MyWallet,
  NFT,
  // Restaurant,
  Setting,
  // CollectionsGroup,
  ForgotPassword,
  // CreateNewPassword,
  Register,
  // Kyc,
  PutonSaleNFT,
  MarketplaceOld,
  CollectionsGroup,
  Restaurant,
  CreateNewPassword,
  // MarketplaceUnderConstruction,
  // MarketplaceOld
} from "pages";
import VerifyUser from "../../src/pages/VerifyUser";
import { Text } from "components";
import { Header } from "components";
import { Colors, Globalstyle } from "constant";

import { NavigationSetter } from "components/NavigationSetter";
import { RestoCard } from "components/Card";

import selectors from "selectors";
import { useDispatch, useSelector } from "react-redux";
import SessionTimeout from "../pages/session/SessionTimeout";
import Collections from "pages/Collections";
import ScrollToTop from "helpers/ScrollToTop";

const AppRouter = () => {
  const [showSignIn, setShowSignIn] = useState("INIT");
  const [particular, setParticular] = useState(false);
  const isAuthenticated = true;

  const user = useSelector(selectors.userProfile);
  const handleClick = () => {
    if (user != null) {
      dispatch({
        type: "SET_PROFILE",
        payload: "",
      });
      window.location.replace("/main-page");
    }
  };

  return (
    <BrowserRouter window={window}>
      {/* DO NOT DELETE THIS COMPONENT */}
      <NavigationSetter />
      {/* DO NOT DELETE ABOVE COMPONENT */}
      <ScrollToTop />
      <Globalstyle />
      {/* <Header showSignIn={showSignIn} setShowSignIn={setShowSignIn} particular={particular} /> */}
      <Routes>
        <Route path="/" element={<LandingPage.Main />} />
        <Route path="/oku-house" element={<LandingPage.House />} />
        <Route path="/become-a-member" element={<LandingPage.Community />} />
        <Route path="/philosophy" element={<LandingPage.Philosophy />} />
        <Route path="/our-house" element={<LandingPage.OurHouse />} />
        <Route path="/membership" element={<LandingPage.Membership />} />
        <Route path="/roadmap" element={<LandingPage.Roadmap />} />
        <Route path="/thank-you" element={<LandingPage.ThankYou />} />
        <Route path="/gallery" element={<LandingPage.Gallery />} />
        <Route path="/events" element={<LandingPage.Events />} />
        <Route path="/partnership" element={<LandingPage.Partnership />} />
        <Route path="/clubhouse" element={<LandingPage.ClubHouse />} />

        <Route path="/marketplace" element={<MarketplaceOld />} />
        <Route path="/collections/:id" element={<Collections />} />
        <Route path="/collectionsgroup/:id" element={<CollectionsGroup />} />
        <Route
          path="/collections/:id/:name/:group/tokens"
          element={<Restaurant />}
        />

        {/* <Route path="/marketplace" element={<LandingPage.Marketplace />} />
        <Route
          path="/collections/detail"
          element={<LandingPage.DetailMarketplace />}
        />
        <Route
          path="/all-collections"
          element={<LandingPage.AllCollections />}
        />
        <Route path="/collections" element={<LandingPage.Collection />} /> */}
        {/* <Route path="/artist" element={<LandingPage.Artist />} /> */}

        <Route path="/sign-in" element={<LandingPage.LoginPageNew />} />
        <Route path="/sign-up" element={<LandingPage.Register />} />
        <Route
          path="/verification"
          element={<LandingPage.Verify.Verification />}
        />
        <Route path="/verify/:id" element={<LandingPage.Verify.Verified />} />
        <Route path="/profile" element={<LandingPage.Profile />} />
        <Route path="/legality" element={<LandingPage.Legal />} />

        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/my-wallet" element={<MyWallet />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/verify/:id" element={<VerifyUser />} /> */}
        <Route path="/putonsale/:id" element={<PutonSaleNFT />} />
        <Route
          path="/nft/:id/:collectionname"
          element={
            <NFT
              showSignIn={showSignIn}
              setShowSignIn={setShowSignIn}
              setParticular={setParticular}
            />
          }
        />
        <Route path="/wallet" element={<WalletConnection />} />

        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/create-new-password/:id"
          element={<CreateNewPassword />}
        />

        {/* <Route
          path="/profile-info"
          element={<LandingPage.MyProfile.ProfileInfo />}
        />
        <Route
          path="/profile-wallet"
          element={<LandingPage.MyProfile.Wallet />}
        />
        <Route
          path="/profile-security"
          element={<LandingPage.MyProfile.Security />}
        />
        <Route
          path="/profile-invite-friends"
          element={<LandingPage.MyProfile.InviteFriends />}
        /> */}

        <Route
          path="*"
          element={
            <Text
              color={Colors.darkSecurity}
              className="flex items-center justify-center h-screen"
            >
              404 NOT FOUND
            </Text>
          }
        />

        {/* <Route path='/wallet' element={<WalletConnection />} />
        <Route path='/help' element={<Help />} />
        <Route path='/marketplace' element={<Marketplace />} />
        <Route path='/main-page' element={<MainPage />} />
        <Route path='/restaurant' element={<Restaurant />} />
        <Route path='/nft/:id/:collectionname' element={<NFT showSignIn={showSignIn} setShowSignIn={setShowSignIn} setParticular={setParticular} />} />
        <Route element={<LoginPage />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/my-wallet' element={<MyWallet />} />
        <Route path='*' element={<Text>404 NOT FOUND</Text>} />
        <Route path='/setting' element={<Setting />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/create-new-password/:id' element={<CreateNewPassword />} />
        <Route path='/register' element={<Register />} />
        <Route path="/verify/:id" element={<VerifyUser />} />
        <Route path="/kyc" element={<Kyc />} />
        <Route path='/putonsale/:id' element={<PutonSaleNFT />} />
        <Route path='/collections/:id' element={<Collections />} />
        <Route path='/collections/:id/:name/tokens' element={<Restaurant />} /> */}
      </Routes>
      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
    </BrowserRouter>
  );
};

export default AppRouter;
