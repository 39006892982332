import styled from 'styled-components';

const ForgotPasswordStepsStyle = styled.div`
  margin-top: 30px;

  .logo-cms {
    display: block;
      margin: 0 auto;
  }

  .forgotpassword-form {
    width: 450px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
  }
`;

export default ForgotPasswordStepsStyle;
