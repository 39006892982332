import { Button } from 'components';
import { Images } from 'constant';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
// import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router';

const AccessList = ({ text = '', showMembership = false }) => {
	const navigate = useNavigate();

	return (
		<div className='bg-darkSecurity relative overflow-hidden'>
			<img src={Images.VertorWhite} className='absolute -top-10 right-20 hidden md:block' />
			<img src={Images.VertorWhite} className='absolute -bottom-10 right-28' />
			<img src={Images.VertorWhite} className='absolute bottom-10 right-2' />
			<div className='md:container md:mx-auto py-20 md:py-[63px] px-6 xl:px-0'>
				<div className='flex flex-col md:flex-row md:items-center gap-10 lg:gap-[100px]'>
					<ScrollAnimation
						animateOnce
						duration={0.5}
						animateIn='fadeIn'
					>
						<h2 className='text-white text-[45px] font-bold leading-none'>Access list is<br />now open! </h2>
					</ScrollAnimation>
					<ScrollAnimation
						animateOnce
						duration={0.5}
						animateIn='fadeIn' className='flex flex-col text-white'>
						{
							text !== '' ?
								<p className='text-lg'>Be a member of our family, and create these memories with us.<br />To be the first to be updated, please join our access list!</p> :
								<p className='text-lg'>Join our family and begin creating memories and collecting experiences with us.<br />Stay up to date with our collection launches by joining our Access List!</p>
						}
						<div className='mt-10 md:mt-4 flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 w-fit'>
							{/* <Button theme='white' onClick={ () => window.open('https://8i76pet9rc6.typeform.com/to/VjruNm2I', '_blank') } >Register Now!</Button> */}
							<Button theme='white' onClick={ () => navigate('/sign-up') } >Register Now!</Button>
							{ showMembership && <Button theme='white' onClick={ () => navigate('/our-house') }>Explore Membership</Button> }
						</div>
					</ScrollAnimation>
				</div>
			</div>
		</div>
	);
};

export default AccessList;