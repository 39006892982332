import { NavigateFunction, NavigateOptions } from 'react-router-dom';

const History = {
	navigate: null,
	push: (page, options) => {
		if (History.navigate) {
			History.navigate(page, options);
		}
	},
};

export default History;