import { IReducer } from 'interface';
import { UserActionTypes, SET_PROFILE, SET_RESTAURANT } from './userActionTypes';


const initialState = { profile: {} };

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    default: return state;
  }
};

export default UserReducer;