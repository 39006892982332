// constants
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "./store";
// import marketplacecontract from "../contract/marketplace.json"
// import nftdata from "../contract/nft.json"
const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

export const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};


const getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          //infuraId: process.env.REACT_APP_INFURA_ID
          rpc: {
            56: "https://bsc-dataseed.binance.org",
            97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
            80001:'https://matic-mumbai.chainstacklabs.com'
          }
        }
      }
    }

    return providerOptions;
}

export const connectWallet = (walletname) => {
    return async(dispatch) => {
        dispatch(connectRequest());
        try {
            const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions() // required
            });
            var provider = ''
            
						if(walletname === "coinbasewallet"){
             var provider = await web3Modal.connectTo('coinbasewallet')
            }else if(walletname === "walletconnect" ){
             var provider =await web3Modal.connectTo("walletconnect")
            }else if(walletname === "fortmatic" ){
              var provider =await web3Modal.connectTo("fortmatic")
            }else if(walletname === "metamask"){
              const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions().walletconnect // required
              });
              var provider =await web3Modal.connect()
            }else{
              const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions().walletconnect // required
              });
              var provider = await web3Modal.connect()
            }
            
            const marketplaceAddress = process.env.REACT_APP_Marketplace;
           
            await subscribeProvider(provider);
            
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            const address = accounts[0];
            var marketplace =[]
              var marketplace = ""
             
          
            dispatch(
              connectSuccess({
                address,
                web3,
                provider,
                connected: true,
                marketplace,
                // NFT,
                web3Modal,
                walletname,
              })
            );
        } catch (e) {
            dispatch(connectFailed(e));
        }
    }
}

const subscribeProvider = async(provider) => {
    if (!provider.on) {
      return;
    }

    provider.on('connect', async(id) => {
      console.log(id);
    });

    provider.on("networkChanged", async (networkId) => {
      if(networkId !== '56') {
        store.dispatch(connectFailed('Please switch to Binance mainnet'));
      } else {
        store.dispatch(connectWallet());
      }
    });
}

export async function addNetwork(id) {
  let networkData;
  switch (id) {
      case 80001:
        networkData=JSON.stringify(networkData)
        networkData = [
          {
            chainId:'0x13881', //web3.utils.toHex('80001'),
            chainName: "Mumbai Testnet",
            rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ];
    
          break;   
    default:
      break;
  }
  if(Number(id)!=4)
    {
      return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: networkData,
        });
    }
    else
    {
      return window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: networkData,
        });
    }
}

(() => {
  if(window.ethereum) {
    window.ethereum.on('networkChanged', function(networkId){
      if(networkId !== '56') {
        store.dispatch(connectWallet());
      } else {
        store.dispatch(connectWallet());
      }
    });
  }
})();



