import React from 'react';
import { useEffect, useState } from 'react';

import { Text } from '..';
import { Images } from 'constant';

import ModalStyle from './style';

const Modal = ({
	children,
	visible,
	noPadding = false,
	width = '',
	height='',
	bgColor = 'var(--bg-modal)',
	modalTitle = '',
	hideCloseBtn = false,
	contentClassName,
	onClose,
	modalClassName = '',
	isCustom = false
}) => {
	const [modalOpen, setModalOpen] = useState(visible);

	const timeOut = () => setTimeout(() => { setModalOpen(visible); }, 300);

	useEffect(() => {
		if (visible) {
			setModalOpen(true);
			document.body.style.overflow = 'hidden';
		} else {
			timeOut();
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [visible]);

	const renderCloseBtn = () => {
		if (hideCloseBtn) return null;

		return (
			<div className='close-icon' onClick={ onClose }>
				<img src={ Images.Close } alt='' />
			</div>
		);
	};

	if (modalOpen) {
		return (
			<ModalStyle
				width={ width }
				height="400px"
				noPadding={ noPadding }
				bgColor={ bgColor }
				className={ modalClassName }
				isCustom={ isCustom }
			>
				<div className='modal-wrapper'>
					<div className={ `modal-backdrop ${visible ? 'backdrop-open-animation' : 'backdrop-close-animation'}` } onClick={ onClose } />

					<div id='modal-content' className={ `modal-content-container ${visible ? 'open-animation' : 'close-animation'}` }>
						<div className={ `modal-content ${contentClassName}` }>
							{ renderCloseBtn() }
							{ children }
						</div>
					</div>
				</div>
			</ModalStyle>
		);
	}

	return null;
};

export default Modal;