import React from 'react';
import { useNavigate } from 'react-router';
import { EyeOffIcon } from '@heroicons/react/solid';
import moment from 'moment';
import { CheckIcon } from '@heroicons/react/solid';
import { Icons } from 'constant';
import { Button, Input } from 'components';
import { Colors, Images } from 'constant';

import useRegisterPage from './useRegisterPage';
import RegisterStyle from './style';
import { Layout } from 'pages/LandingPageNew/Components';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const Register = () => {
	const navigate = useNavigate();
	const {
		payload,
		showPassword,
		check,
		check2,
		check3,
		setShowPassword,
		setCheck,
		setCheck2,
		setCheck3,
		onClickSubmit,
		onChangeText,
		onChangeText1,
		onChangeTextphone
	} = useRegisterPage();

	const disablePastDate = () => {
		const today = new Date();
		const dd = String(today.getDate() + 0).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0! 
		const yyyys = today.getFullYear();
		const yyyy = yyyys - 10;
		return yyyy + "-" + mm + "-" + dd;
	};

	return (
		<Layout>
			<RegisterStyle className='bg-newBeginning'>
				<div className='grid grid-cols-1- md:grid-cols-2 mx-auto bg-newBeginning'>
					<img alt='' src={Images.RegisterArt} className='w-full h-full' />
					<div className='max-w-[600px] w-full mx-auto flex flex-col items-start justify-center px-6 xl:px-0'>
						<h3 className='font-ExpressWay py-10 font-bold text-center md:text-left text-2xl md:text-[32px]'>{'Let\'s Sign You Up'}</h3>
						<Input
							label='FULL NAME'
							name='fullName'
							placeholder='John Doe'
							className='mb-[20px] w-full'
							onChange={({ value, name }) => onChangeText(value, name)}
							bgColor={Colors.white}
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							value={payload.fullName}
							textColor={Colors.darkSecurity}
						/>

						<Input
							label='EMAIL ADDRESS'
							name='email'
							placeholder='john.doe@gmail.com'
							className='mb-[20px] w-full'
							onChange={({ value, name }) => onChangeText(value, name)}
							bgColor={Colors.white}
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							value={payload.email}
							textColor={Colors.darkSecurity}
						/>

						{/* <PhoneInput
                  country={"in"}
                  value={payload.phone}
				//   className='mb-[20px] w-full'
                  placeholder="Mobile Number"
				  textColor={Colors.darkSecurity}
				  onChange={({ value, name }) => onChangeText(value, name)}
                /> */}

						<div
							className='mb-[20px] w-full'>
							<div className='mb-1' style={{ color: "#9d9d9d", fontWeight: "700", fontSize: "16px", fontFamily: "AcuminPro" }}> PHONE NUMBER (OPTIONAL) </div>
							<PhoneInput
								//   style={{zIndex:"1",padding:"5px 0",width:"100%"}}
								country={"sg"}
								inputStyle={{ color: 'black', width: "100%", height: "60px" }}
								dropdownStyle={{ color: 'black' }}
								enableSearch={true}
								disableSearchIcon={true}
								placeholder="Phone Number"
								name='phone'
								value={payload.phone}
								//   isValid={(inputNumber, country, countries) => {
								// 	return countries.some((country) => {
								// 	  return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
								// 	});
								//   }}
								onChange={(value) => onChangeTextphone(value, "phone")}
							/>

						</div>

						{/* <div className='mb-1' style={{ color: "#9d9d9d", fontWeight: "700", fontSize: "16px", fontFamily: "AcuminPro" }}>DATE OF BIRTH</div> */}
						<Input
							label='DATE OF BIRTH'
							name='dob'
							type='date'
							placeholder='john.doe@gmail.com'
							className='mb-[20px] w-full'
							// onChange={(e) => onChangeText1(e.target.value, "dob")}
							onChange={({ value, name }) => onChangeText1(value, name)}
							bgColor={Colors.white}
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							value={payload.dob ? payload.dob : "" }
							textColor={Colors.darkSecurity}
						/>
						{/* <input
							label='DATE OF BIRTH'
							name='dob'
							type='text'
							placeholder='DD/MM/YYYY'
							max={disablePastDate()}
							className='mb-[20px] w-full'
							onChange={(e) => onChangeText1(e.target.value, "dob")}
							onFocus={(e) => (e.target.type = "date")}
							onBlur={(e) => (e.target.type = "text")}
							bgColor={Colors.white}
							// value={payload.dob ?payload.dob :"" }
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							textColor={Colors.darkSecurity}
						/> */}

						<Input
							type={showPassword ? 'text' : 'password'}
							label='CREATE NEW PASSWORD'
							placeholder='enter your password'
							name='password'
							className='mb-[20px] w-full'
							bgColor={Colors.white}
							iconRight={
								<div className='h-[30px] flex justify-center items-center relative'>
									{
										!showPassword ?
											<EyeOffIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer ' onClick={() => setShowPassword(!showPassword)} />
											:
											<Icons.EyeIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer absolute right-3' onClick={() => setShowPassword(!showPassword)} />
									}
								</div>
							}
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							onChange={({ value, name }) => onChangeText(value, name)}
							value={payload.password}
							textColor={Colors.darkSecurity}
						/>
						<Input
							type={showPassword ? 'text' : 'password'}
							label='CONFIRM NEW PASSWORD'
							placeholder='confirm your password'
							name='confirmPassword'
							className='mb-[30px] w-full'
							bgColor={Colors.white}
							iconRight={
								<div className='h-[60px] flex justify-center items-center relative'>
									{
										!showPassword ?
											<EyeOffIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer ' onClick={() => setShowPassword(!showPassword)} />
											:
											<Icons.EyeIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer absolute right-3' onClick={() => setShowPassword(!showPassword)} />
									}
								</div>
							}
							border={`solid 1px ${Colors.platinumSolid}`}
							focusLabelColor={Colors.okuGrowth}
							borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
							onChange={({ value, name }) => onChangeText(value, name)}
							value={payload.confirmPassword}
							textColor={Colors.darkSecurity}
						/>


						<button onClick={() => setCheck(state => !state)} className='flex cursor-pointer items-start gap-5 group outline-none'>
							<div>
								<div className={`w-5 h-5 rounded flex items-center justify-center border group-hover:border-okuGrowth ${check ? 'bg-okuGrowth border-okuGrowth' : 'border-[#636363]'}`}>
									{
										check ? <CheckIcon width={16} color='white' /> : undefined
									}
								</div>
							</div>
							<p className='text-black leading-5 tracking-[0.01em] text-sm md:text-base font-AcuminPro font-medium text-left'>Would you like to receive updates on collections & products from Oku House?</p>
						</button>

						<button onClick={() => setCheck2(state => !state)} className='flex cursor-pointer items-start gap-5 my-[14px] group outline-none'>
							<div>
								<div className={`w-5 h-5 rounded flex items-center justify-center border group-hover:border-okuGrowth ${check2 ? 'bg-okuGrowth border-okuGrowth' : 'border-[#636363]'}`}>
									{
										check2 ? <CheckIcon width={16} color='white' /> : undefined
									}
								</div>
							</div>
							<p className='text-black leading-5 tracking-[0.01em] text-sm md:text-base font-AcuminPro font-medium text-left'>Would you like to receive newsletters ?</p>
						</button>
						<button onClick={() => setCheck3(state => !state)} className='mb-[30px] flex cursor-pointer items-start gap-5 group outline-none'>
							<div>
								<div className={`w-5 h-5 rounded flex items-center justify-center border group-hover:border-okuGrowth ${check3 ? 'bg-okuGrowth border-okuGrowth' : 'border-[#636363]'}`}>
									{
										check3 ? <CheckIcon width={16} color='white' /> : undefined
									}
								</div>
							</div>
							<p className='text-black leading-5 tracking-[0.01em] text-sm md:text-base font-AcuminPro font-medium text-left'>Click if you agree to the Oku House <a href='/legality' target='_blank' className='link'>privacy policy</a> and <span className='link cursor-text' onClick={(e) => e.preventDefault()}>terms and conditions</span>. You can change the way we contact you or unsubscribe at any time.**</p>
						</button>

						<Button
							label='Create my account'
							className='mb-5 w-full'
							onClick={onClickSubmit}
						/>
						<div className='w-full mb-10' onClick={() => navigate('/sign-in')}>
							<p className='text-center font-bold text-sm cursor-pointer text-abundance uppercase'>I already have an account</p>
						</div>
					</div>
				</div>
			</RegisterStyle>
		</Layout>
	);
};

export default Register;
