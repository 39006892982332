import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';
import { getInstagramData } from 'stores/LandingPage/landingPageAction';

const igReelsDev = [
	{
		media_url: 'https://video.cdninstagram.com/v/t50.2886-16/328276424_499751938908327_7795093638171109261_n.mp4?_nc_cat=101&vs=1837633446589187_2483030862&_nc_vs=HBksFQAYJEdNZ1prUk9uWU11UWhjWUJBSTJuR0I1ZHZDMXNia1lMQUFBRhUAAsgBABUAGCRHUFY5a1JOd2tCTE15YlVMQU9DNmdOaUdqMFY0YmtZTEFBQUYVAgLIAQAoABgAGwGIB3VzZV9vaWwBMRUAACaO2Z2S6KblPxUCKAJDMywXQBAp\u00252B\u00252Bdsi0QYEmRhc2hfYmFzZWxpbmVfMV92MREAdeoHAA\u00253D\u00253D&ccb=1-7&_nc_sid=59939d&efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjEyODAuZmVlZCJ9&_nc_ohc=lzFr1gk31HUAX-ewcVy&_nc_ht=video.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfALAR_zp_uF9C1vFh7uHQB4b9HvogW_UDNYRK-pawskyA&oe=63E54BFB&_nc_rid=30148f808c'
	},
	{
		media_url: 'https://video.cdninstagram.com/o1/v/t16/f1/m82/5A4F2EF081B8F2839768DE2BCAC76D90_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLjcyMC5jbGlwcyJ9&_nc_ht=video.cdninstagram.com&_nc_cat=100&vs=880532243366005_357407380&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC81QTRGMkVGMDgxQjhGMjgzOTc2OERFMkJDQUM3NkQ5MF92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVABgkR0pTOUZoUEpqSkxEeUx3RUFKdUYyNUx3R2JOcWJxX0VBQUFGFQICyAEAKAAYABsBiAd1c2Vfb2lsATEVAAAm\u00252FLHsyLz\u00252BgEAVAigCQzMsF0BFUeuFHrhSGBJkYXNoX2Jhc2VsaW5lXzFfdjERAHUAAA\u00253D\u00253D&ccb=9-4&oh=00_AfCmLj8_unFbCR09SkbhB9Eo9Zyf0Cw5P3NLFrGorjDK5w&oe=63E61F36&_nc_sid=ea0b6e&_nc_rid=79dd326993'
	},
];

const igFeedDev = [
	{
		media_url: 'https://scontent.cdninstagram.com/v/t51.2885-15/329769371_5965112203582918_2186610593381539695_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=5BvLTGUP2dgAX9352FN&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfD9pH7z86sG-4PQgfFN4zDOud6JI7ytc8OxkV1WizmNfg&oe=63EA86C2'
	},
	{
		media_url: 'https://scontent.cdninstagram.com/v/t51.2885-15/329246380_431960785752970_1264532271760268471_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=W1wIO46ys7AAX8nPhZb&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAk9PWmeffQBfQ_4G4elGZJHUuQmM6m-TieDsc3zxZvMw&oe=63EA0F8C'
	},
	{
		media_url: 'https://scontent.cdninstagram.com/v/t51.2885-15/327316255_3360156344232948_1197337978507472774_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=tJszqclIrMIAX9wK2ce&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfB5UVMhpfSdEjljGDdxxgKKtO3sJocmac49sQQ30L53qA&oe=63EA28E4'
	},
	{
		media_url: 'https://scontent.cdninstagram.com/v/t51.2885-15/328154427_713672953491626_8415004505329544132_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=TUl9omFEzfEAX-3sBgy&_nc_ht=scontent.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfAzJe5RLFJ5GIsOYO0X20SRJ1QuzKqgisujyYRTxQyJaQ&oe=63EA5070'
	}
];

const InstagramGallery = () => {

	const dispatch = useDispatch();
	const dataInstagram = useSelector(selectors.dataInstagram);
	const [igReels, setIgReels] = useState([])
	const [igFeed, setIgFeed] = useState([])
	// const igReels = process.env?.REACT_APP_STAGE === 'production' ? dataInstagram?.filter(item => item?.media_type === 'VIDEO') : igReelsDev;
	// const igFeed = process.env?.REACT_APP_STAGE === 'production' ? dataInstagram?.filter(item => item?.media_type !== 'VIDEO') : igFeedDev;

	useEffect(() => {
		if (process.env?.REACT_APP_STAGE === 'production') {
			setIgReels(dataInstagram?.filter(item => item?.media_type === 'VIDEO'))
			setIgFeed(dataInstagram?.filter(item => item?.media_type !== 'VIDEO'))
		} else {
			setIgReels(igReelsDev)
			setIgFeed(igFeedDev)
		}
	}, [dataInstagram])


	useEffect(() => {
		dispatch(getInstagramData());
	}, []);

	return (
		<div className='bg-newBeginning'>
			<div className='container mx-auto px-5 py-20'>
				<div className='grid grid-cols-2 md:grid-cols-4 gap-8' >
					<ScrollAnimation
						duration={0.5}
						animateOnce
						animateIn='fadeIn'
						className='w-full'
					>
						{
							igReels[0]?.media_url ? (
								<video autoPlay muted className='rounded-[5px] w-full h-full object-cover'>
									<source src={igReels[0]?.media_url} type='video/mp4' />
								</video>
							) : (
								<div className='rounded-[5px] w-full h-full object-cover flex items-center justify-center text-darkSecurity bg-slate-50'>
									<p className='text-darkSecurity'>Loading...</p>
								</div>
							)
						}
					</ScrollAnimation>
					<div className='grid grid-cols-1 gap-8'>
						<ScrollAnimation
							duration={0.5}
							animateOnce
							animateIn='fadeIn'
							delay={300}
						>
							<img src={igFeed[0]?.media_url} className='rounded-[5px]  w-full h-full object-cover' />
						</ScrollAnimation>
						<ScrollAnimation
							duration={0.5}
							animateOnce
							animateIn='fadeIn'
							delay={300}
						>
							<img src={igFeed[1]?.media_url} className='rounded-[5px]  w-full h-full object-cover' />
						</ScrollAnimation>
					</div>
					<ScrollAnimation
						duration={0.5}
						animateOnce
						animateIn='fadeIn'
						className='w-full order-5'
						delay={500}
					>
						{
							igReels[1]?.media_url ? (
								<video autoPlay muted className='rounded-[5px] w-full h-full object-cover'>
									<source src={igReels[1]?.media_url} type='video/mp4' />
								</video>
							) : (
								<div className='rounded-[5px] w-full h-full object-cover flex items-center justify-center text-darkSecurity bg-slate-50'>
									<p className='text-darkSecurity'>Loading...</p>
								</div>
							)
						}
					</ScrollAnimation>
					<div className='grid grid-cols-1 gap-8 md:order-10'>
						<ScrollAnimation
							duration={0.5}
							animateOnce
							animateIn='fadeIn'
							delay={500}
						>
							<img src={igFeed[2]?.media_url} className='rounded-[5px] h-full w-full object-cover' />
						</ScrollAnimation>
						<ScrollAnimation
							duration={0.5}
							animateOnce
							animateIn='fadeIn'
							delay={500}
						>
							<img src={igFeed[3]?.media_url} className='rounded-[5px] h-full w-full object-cover' />
						</ScrollAnimation>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InstagramGallery;