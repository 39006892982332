import { RootState } from 'stores';

const selectors = {
  userProfile: (state) => state.user.profile,
  userToken: (state) => state.user.profile.token,
  currentRestaurant: (state) => state.restaurant.restaurant,
  dataInstagram: (state) => state.landingPage.dataInstagram,
  nft: (state) => state.nft.nft,
  walletAddress: (state) => state.wallet,
  WalletConnect: (state) => state.wallet,
};

export default selectors;