import React from 'react';

const data = [
	{
		title: '1. Our Club Houses',
		desc: 'Oku House operates physical locations (which we call Club Houses and may sometimes refer to as “spaces”) exclusive to community members who own a Club House NFT. We commit to using the proceeds from the sale of Club House NFTs to build, outfit, and operate each of our spaces. Considering that proof of ownership of NFTs is immediately verifiable on our blockchain, we hold the right to verify access to our spaces, amend or waive payment of NFTs, determine the initial price and quantity of our Club House collections, issue new NFTs for any Club House collection, or amend the structure with which our Club House NFTs are sold and/or traded.',
		list: []
	},
	{
		title: '2. Our Events',
		desc: `${'Oku House also organizes and helps to operate certain events in partnership with creators. Each of these events is gated by an NFT collection and ownership of an event NFT grants access to that specific event. Oku House reserves the right to sell, trade, airdrop, or give these event NFTs in any form or fashion.'}\n${'We also reserve the right, in conjunction with any partner, to admit or deny entry to any individuals on a case-by-case basis to each event that we organize.'}`,
		list: []
	},
	{
		title: '3. Your profile details',
		desc: 'By completing and submitting your profile, you agree to be bound by Our House rules. Our community team meets regularly to review profiles and confirm or deny the admittance of members at their sole discretion. You are free to withdraw or delete your community profile at any time. Should you wish to do so, please contact us at ',
		list: []
	},
	{
		title: '4. Members data and Oku database',
		desc: 'It is crucial for us to have your current details plus a photograph of you in our database. By joining Oku House’s community, you agree that we can hold your personal details and photograph as well as your WalletID to verify authenticity and identify ownership.',
		list: []
	},
	{
		title: '5. Interaction with our community',
		desc: 'Our community exists in many mediums, but interactions predominantly take place on Discord. Members are allowed to interact with one another so long as they do not violate the following criteria:',
		list: [
			'Be respectful to one another. We are a diverse community and we celebrate everyone’s individual unique identity, ideas, and beliefs.',
			'No spam (unless the edible kind), inappropriate or illegal content, personal attacks, racism, sexism, offensive language, religious and/or political attacks.',
			'No blank, inappropriate, sexually explicit, offensive names or profile photos.',
			'No solicitation of any products or services.',
			'No conducting trades or sales via direct messages or outside of this server and our platform.'
		]
	},
	{
		title: '6. Your personal information',
		desc: 'We will use the personal information you provide us in connection with your membership, in accordance with our Privacy Policy.',
		list: []
	},
	{
		title: '7. Disciplinary procedure',
		desc: `${'Conduct or behavior by a community member or guest that violates Our House rules or otherwise harms Oku House’s reputation and character may result in immediate removal and blacklist from Oku House’s community. This may include inappropriate, sexually explicit remarks, offensive comments, personal attacks, racism, sexism, religious and/or political attacks, violent behavior, or illegal content, products or substances.'}\n${'Members of Oku House’s community are always bound to Our House rules. Should Oku House determine that your conduct has violated any rules or crossed any boundaries, it is in our sole and absolute discretion to remove you from our community with immediate effect.'}`,
		list: []
	},
	{
		title: '8. Liability of Soho House',
		desc: 'Oku House will not be held liable for the loss of any property through our events, our spaces, or trading of our NFTs.',
		list: []
	},
	{
		title: '9. Interpretation of the rules',
		desc: 'If there is any disagreement, misunderstanding, or misinterpretation of Our Rules, our community team will be notified of the situation and should a resolution or decision be necessary, have full discretion on the outcome, which will be final and without appeal.',
		list: []
	},
	{
		title: '10. Amendments to Our House rules',
		desc: 'Our House rules may change from time to time given ever-changing circumstances of life. Amendments will be displayed on our website.',
		list: []
	},
];

const HouseRules = () => {
	return (
		<div className='w-full'>
			<p className='font-ExpressWay text-black font-semibold text-[32px] leading-[38px] tracking-[0.01em] '>Oku House Rules</p>
			<p className='font-AcuminPro text-darkSecurity text-base leading-5 tracking-[0.01em] my-3'>Oku House’s community strives to celebrate culture through all mediums and invites people who are interested in exploring new experiences and connecting with others. We require that our members adhere to Oku House Rules so that we may all continue sharing and enjoying the numerous stories Oku House and our partners have to tell.</p>
			<p className='font-AcuminPro text-darkSecurity text-base leading-5 tracking-[0.01em] italic'>Last updated 15 February 2023</p>
			<ul role='list' className=''>
				{ data.map((item, i) => (
					<li key={ i }>
						<div className='flex items-center space-x-4'>
							<div className='flex-1 min-w-0'>
								<p className='font-ExpressWay text-black font-semibold text-2xl leading-[29px] tracking-[0.01em] whitespace-pre-line mb-3'>{ item?.title }</p>
								<p className='font-AcuminPro text-darkSecurity text-base leading-5 tracking-[0.01em] whitespace-pre-line mb-5'>{ item?.desc } { i === 2 ? <span className='text-okuGrowth underline'>hi@oku.house.</span> : null }</p>
								<ol className='list-decimal pl-5 mb-5' >
									{ item.list.map((items, i) => (
										<li key={ i } className='font-AcuminPro text-darkSecurity text-base leading-5 tracking-[0.01em] whitespace-pre-line -mt-3'>{ items }</li>
									)) }
								</ol>
							</div>
						</div>
					</li>
				)) }
				<div className='flex items-center space-x-4'>
					<div className='flex-1 min-w-0'>
						<p className='font-ExpressWay text-black font-semibold text-2xl leading-[29px] tracking-[0.01em] whitespace-pre-line mb-3'>11. Contacting us</p>
						<p className='font-AcuminPro text-darkSecurity text-base leading-5 tracking-[0.01em] whitespace-pre-line mb-5'>If you have any questions at all, please contact us at <span className=' text-okuGrowth underline'>hi@oku.house</span> or shoot us a message in our Discord community.</p>
					</div>
				</div>
			</ul>
		</div>
	);
};

export default React.memo(HouseRules);