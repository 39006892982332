import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { EyeOffIcon } from '@heroicons/react/solid';
import { Button, Input, Modal, Text } from 'components';
import { Colors } from 'constant';
import { Icons } from 'constant';
import { Layout, LoginPage } from 'pages/LandingPageNew/Components'
import axios from 'axios';

import LoginPageStyle from './style';
import useLoginPage from './useLoginPage';
import { useDispatch, useSelector } from 'react-redux';

import OtpInput from 'react-otp-input';

import { loginwithmail, getuser, validate, signIn } from '../../../../services/auth';

// import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

import { checkstatus } from '../../../../services/kyc';
import { SET_PROFILE } from '../../../../stores/User/userActionTypes';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginPageNew = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showModalInputCode, setShowModalInputCode] = useState(false)
	const [CurUserdata, setCurUserdata] = useState()
	const [CurUser, setCurUser] = useState()
	const [Usermembercheck, setUsermembercheck] = useState()

	const [otp, setOtp] = useState();

	const login = useGoogleLogin({

		onSuccess: async response => {
			try {
				const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
					headers: {
						"Authorization": `Bearer ${response.access_token}`
					}
				})

				otherfunction(res)
			} catch (err) {
				console.log(err)
			}
		}
	});

	const retriveChecks = async (id) => {
		const res = await checkstatus(id);
		if (res.data.status) {
			console.log(res.data.result)
		}
	}


	const otherfunction = async (res) => {

		const values = {
			email: res.data.email,
			email_verified: res.data.email_verified,
			name: res.data.name,
			picture: res.data.picture,
			sub: res.data.sub,
			password: res.data.name
		}
		try {
			await loginwithmail(values).then(async (req) => {

				if (req.data.response.success) {

					localStorage.setItem('token', req.data.response.token)
					localStorage.setItem('wallet', req.data.response.user.walletAddress)

					const user = req.data.response;
					setCurUser(user.user.id);
					setCurUserdata(user);
					const res1 = await getuser(user.user.id);
					if (res1.data.status) {
						const User = res1.data.result;
						if (User.CheckId && User.kyc_status != 'passed' && User.kyc_status != 'NotVerified') {
							retriveChecks(user.user.id);
						}

						setUsermembercheck(User)

						if (!User.tfauth) {
							dispatch({
								type: SET_PROFILE,
								payload: user
							});

							if (User.membershipstatus == "approved") {
								navigate(`/marketplace`);
							} else {
								navigate(`/membership`);
							}

						} else {
							setShowModalInputCode(true)
						}
					}

				}
			})
		} catch (err) {
			console.log("error", err)
		}
	}

	const handleChange = otp => {
		setOtp(otp);
	};

	const validateotp = async () => {

		if (otp != "") {
			const values = {
				userId: CurUser,
				token: otp
			};
			const res = await validate(values)

			if (res.data.validated) {

				toast("User validated Successfully")
				dispatch({
					type: SET_PROFILE,
					payload: CurUserdata
				});

				if (Usermembercheck.membershipstatus == "approved") {
					navigate(`/marketplace`);
				} else {
					navigate(`/membership`);
				}

				// navigate(`/marketplace`);
			} else {
				toast("Invalid OTP")
			}
		} else {
			toast("Enter the OTP")
		}
	}

	const handleChange1 = otp => {

		setOtp(otp);
	};

	const validateotps = async () => {
		if (otp != "") {
			const values = {
				userId: CurUsers,
				token: otp
			};
			const res = await validate(values)

			if (res.data.validated) {
				toast("User validated Successfully")
				dispatch({
					type: SET_PROFILE,
					payload: CurUserdatas
				});
				setShowModalInputCodes()

				if (Usermembershipcheks.membershipstatus == "approved") {
					navigate(`/marketplace`);
				} else {
					navigate(`/membership`);
				}

				// navigate(`/marketplace`);
			} else {
				toast("Invalid OTP")
			}
		} else {
			toast("Enter the OTP")
		}
	}


	const {
		payload,
		showPassword,
		handleDisabled,
		onChangeText,
		setShowPassword,
		onClickLogin,
		onClickForgotPassword,
		showModalInputCode1,
		setShowModalInputCodes,
		CurUserdatas,
		CurUsers,
		Usermembershipcheks
	} = useLoginPage();


	return (
		<Layout bgColor='bg-bgSignIn' hideFooter>
			<LoginPageStyle className='px-10 md:px-0 md:max-w-screen-xs mx-auto'>

				<Text
					fontType='h2'
					text='Sign in to your account'
					color={Colors.darkSecurity}
					fontFamily='Expressway'
					className='mb-[55px]'
				/>

				<Input
					label='EMAIL'
					name='email'
					placeholder='john.doe@mail.com'
					className='mb-[30px] w-full'
					onChange={({ value, name }) => onChangeText(value, name)}
					bgColor={Colors.white}
					border={`solid 1px ${Colors.platinumSolid}`}
					focusLabelColor={Colors.okuGrowth}
					borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
					value={payload.email}
					textColor={Colors.darkSecurity}
				/>
				<Input
					type={showPassword ? 'text' : 'password'}
					label='PASSW0RD'
					placeholder='Password'
					name='password'
					className='mb-[10px] w-full'
					bgColor={Colors.white}
					iconRight={
						<div className='h-[60px] flex justify-center items-center relative'>
							{
								!showPassword ?
									<EyeOffIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer ' onClick={() => setShowPassword(!showPassword)} />
									:
									<Icons.EyeIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer absolute right-3' onClick={() => setShowPassword(!showPassword)} />
							}
						</div>
					}
					border={`solid 1px ${Colors.platinumSolid}`}
					focusLabelColor={Colors.okuGrowth}
					borderFocus={`solid 1.5px ${Colors.okuGrowth}`}
					onChange={({ value, name }) => onChangeText(value, name)}
					value={payload.password}
					textColor={Colors.darkSecurity}
				/>
				{/* <p className='mb-[40px] ml-auto cursor-pointer text-okuGrowth font-bold font-AcuminPro text-sm md:text-base' onClick={onClickForgotPassword}></p> */}
				<p className='mb-[40px] ml-auto cursor-pointer text-okuGrowth font-bold font-AcuminPro text-sm md:text-base' onClick={()=>onClickForgotPassword()}>Forgot password?</p>
				<Button
					label='Sign In'
					className='w-full'
					onClick={onClickLogin}
					disabled={handleDisabled}
				/>
				<Button
					label={'Don\'t have an account?'}
					className='w-full my-5'
					theme='primary-outline'
					onClick={() => navigate('/sign-up')}
				/>

				<div className='flex items-center justify-center py-[12px] w-full md:bg-white rounded-full cursor-pointer'>
					<svg width='23' height='24' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
						<path fillRule='evenodd' clipRule='evenodd' d='M22.54 12.2614C22.54 11.4459 22.4668 10.6618 22.3309 9.90912H11.5V14.3575H17.6891C17.4225 15.795 16.6123 17.013 15.3943 17.8284V20.7139H19.1109C21.2855 18.7118 22.54 15.7637 22.54 12.2614Z' fill='#4285F4' />
						<path fillRule='evenodd' clipRule='evenodd' d='M11.5 23.4998C14.605 23.4998 17.2081 22.47 19.1109 20.7137L15.3943 17.8282C14.3645 18.5182 13.0472 18.9259 11.5 18.9259C8.50474 18.9259 5.96951 16.903 5.06519 14.1848H1.22314V17.1644C3.11542 20.9228 7.00451 23.4998 11.5 23.4998Z' fill='#34A853' />
						<path fillRule='evenodd' clipRule='evenodd' d='M5.06523 14.1851C4.83523 13.4951 4.70455 12.758 4.70455 12.0001C4.70455 11.2421 4.83523 10.5051 5.06523 9.81506V6.83551H1.22318C0.444318 8.38801 0 10.1444 0 12.0001C0 13.8557 0.444318 15.6121 1.22318 17.1646L5.06523 14.1851Z' fill='#FBBC05' />
						<path fillRule='evenodd' clipRule='evenodd' d='M11.5 5.07386C13.1884 5.07386 14.7043 5.65409 15.8961 6.79364L19.1945 3.49523C17.2029 1.63955 14.5997 0.5 11.5 0.5C7.00451 0.5 3.11542 3.07705 1.22314 6.83545L5.06519 9.815C5.96951 7.09682 8.50474 5.07386 11.5 5.07386Z' fill='#EA4335' />
					</svg>
					{/* <GoogleLogin onSuccess={credentialResponse => { console.log(credentialResponse);}} onError={() => {console.log('Login Failed')}}/> */}
					<p className='ml-[10px] text-darkGrey font-medium font-AcuminPro text-sm md:text-base' onClick={() => login()}>Sign In with Google</p>
				</div>

				<Modal
					visible={showModalInputCode}
					onClose={() => setShowModalInputCode(false)}
					contentClassName='w-full md:w-[540px]'
				>
					<div className='modal-input-container'>
						<Text
							fontType='h4'
							fontFamily='Lora'
							fontWeight='700'
							fontSize='24px'
							text='2FA Verification Code'
							textAlign='center'
							color='var(--blue)'
							className='mb-3 join-modal-title'
						/>

						<Text
							text='Verification code'
							color='var(--black200)'
							textAlign='center'
							className='mb-8'
						/>
						<OtpInput
							value={otp}
							onChange={handleChange}
							numInputs={6}
							isInputNum
							hasErrored={true}
							data-cy='123456'
							containerStyle='flex items-center gap-4 mt-5 ml-10'
							inputStyle={`!w-[47px] h-[60px] border rounded-md font-bold font-AcuminPro text-3xl text-center leading-[0px] ${otp?.length === 6 ? 'border-2 border-okuGrowth' : 'border-[#8D8D8D]'}`}
							focusStyle={`${otp?.length === 6 ? 'outline-none' : 'outline-abundance'}`}
						/>
					</div>
					<div className='btn-submit mx-auto flex justify-center mt-5'>

						<div>
							<Button
								className=''
								label='Submit'
								height={'40px'}
								width={'185px'}
								textColor={'white'}
								backgroundColor={'#003051'}
								onClick={() => { validateotp() }
								}
							/>
						</div>
					</div>
				</Modal>


				<Modal
					visible={showModalInputCode1}
					onClose={() => setShowModalInputCodes()}
					contentClassName='w-full md:w-[540px]'
				>
					<div className='modal-input-container'>
						<Text
							fontType='h4'
							fontFamily='Lora'
							fontWeight='700'
							fontSize='24px'
							text='2FA Verification Code'
							textAlign='center'
							color='var(--blue)'
							className='mb-3 join-modal-title'
						/>

						<Text
							text='Verification code'
							color='var(--black200)'
							textAlign='center'
							className='mb-8'
						/>
						<OtpInput
							value={otp}
							onChange={handleChange1}
							numInputs={6}
							isInputNum
							hasErrored={true}
							data-cy='123456'
							containerStyle='flex items-center gap-4 mt-5 ml-10'
							inputStyle={`!w-[47px] h-[60px] border rounded-md font-bold font-AcuminPro text-3xl text-center leading-[0px] ${otp?.length === 6 ? 'border-2 border-okuGrowth' : 'border-[#8D8D8D]'}`}
							focusStyle={`${otp?.length === 6 ? 'outline-none' : 'outline-abundance'}`}
						/>
					</div>
					<div className='btn-submit mx-auto flex justify-center mt-5'>

						<div>
							<Button
								className=''
								label='Submit'
								height={'40px'}
								width={'185px'}
								textColor={'white'}
								backgroundColor={'#003051'}
								onClick={() => { validateotps() }
								}
							/>
						</div>
					</div>
				</Modal>



			</LoginPageStyle>
		</Layout>
	);
};

export default LoginPageNew;