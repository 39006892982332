import React from 'react';

const TextArea = ({
	label = '',
	className = '',
	rows = 5,
	...restofProps
}) => {
	return (
		<div className='font-AcuminPro flex flex-col'>
			{
				label && (
					<span className='text-darkSecurity mb-2 text-sm uppercase'>{ label }</span>
				)
			}
			<textarea
				rows={ rows }
				className={ `${className && className} bg-white py-3 px-5 border border-platinumSolid rounded-lg focus:border-okuGrowth focus:outline-none text-darkSecurity placeholder:text-[#636363]` }
				{ ...restofProps }
			/>
		</div>
	);
};

export default TextArea;