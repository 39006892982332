import {
  MiscActionTypes,
  SET_LOADING,
} from './miscActionTpes';

// interface InitialStateProps {
//   isLoading: boolean
//   loadingMessage: string
// }

const initialState = {
  isLoading: false,
  loadingMessage: '',
};

const MiscReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        loadingMessage: action.loadingMessage
      };
    default: return state;
  }
};

export default MiscReducer;