import styled from 'styled-components';

const KYCStyle = styled.div`
    padding: 0 0px 0 30px;
    background: none;
    animation: slideToBottom 1s;

    .steps-content {
        min-height: 200px;
        margin: 30px 0 30px 0;
        padding-top: 40px;
        text-align: center;
        border-top: 1px solid #424949;
      }

    .steps-content-item {
        width: 50%;
        margin: auto;
      }
      
    .steps-action {
        margin-top: 24px;
        display: flex;
    }
    .stepper {
        display: flex;
        justify-content: space-between;
        color: white;
        text-align: center;
        padding: 0 60px 0 60px;
        margin: auto;
    }
    .stepper-active {
      color: #EF8440;
    }
    .stepper-label {
      cursor: pointer;
      :hover {
        color: lightgray;
      }
      color: black;
    }
    .stepborder {
      border-top: 2px dotted white;
      width: 100px;
    }

    .btn-kyc {
      height: 45px;
      width: 126px;
      display: block;
      margin-right: 0;
      margin-left: auto;
      border-radius:1.5em;
    }

    .btn-previous {
      height: 45px;
      width: 126px;
      display: block;
      margin-right: auto;
      margin-left: 0;
      border-radius:1.5em;
      background-color:transparent;
      border:1px solid black;
    }

    @media screen and (max-width: 1000px) {

      .steps-content-item {
        width: 80%;
        margin: auto;
      }
    }

    @media screen and (max-width: 650px) {
      padding: 0 0px 0 0px;
      margin: auto;

      .steps-content-item {
        width: 100%;
        margin: auto;
      }
      .steps-action {
        width: 50%;
        margin: auto;
        margin-top: 20px;
      }
    }

    @media screen and (max-width: 460px) {
      padding: 0 0px 0 0px;
      margin: auto;

      .stepper {
        padding: 10px;
      }
      .steps-action {
        width: 70%;
        margin: auto;
        margin-top: 20px;
      }
    }

`;

export default KYCStyle;