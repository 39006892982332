import React, {useState, useEffect} from 'react';
import { Button, Input, Text } from 'components';
import { useNavigate } from 'react-router';

import CreateNewPasswordStyle from './style';
import { Colors } from 'constant';
import { useParams } from 'react-router';
import { validation } from 'helpers';

import {getUserBytoken, verifyResetToken, resetPassword} from '../../services/auth/index';
import { toast } from 'react-toastify';
const CreateNewPassword = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [user, setUser] = useState('');
  const [newpassword, setNewpassword] = useState('');
  const [confirmpassword, seConfirmpassword] = useState('');
 
  const [passwordType, setPasswordType] = useState("password")
  const [Errormessage, setErrormessage] = useState('')
  const [UserId,setUserId] = useState('')

  const handleShowpass = () => {
    if(passwordType == 'password'){
      setPasswordType('text')
    }else{
      setPasswordType('password')
    }
  }
 
  const verifyToken =async() =>{
    try{
      const res = await verifyResetToken(id)
      const result =  res.data;
      if (!res.data.success){
        navigate('/sign-in')
      }else{
        setUserId(res.data?.data?._id)
      }
    }catch(err){
      console.log(err.response.data.message)
      navigate('/sign-in');
    }
  }
  const handleSubmit = async()=>{
    const values = {
      password:confirmpassword,
    }
    try{
      const verify_ = await resetPassword(UserId, values);
      if(verify_.data.success){
        toast(verify_.data.message)
        navigate('/sign-in')
      }else{
        toast(verify_.data.message)
      }
    }catch(err){
      setErrormessage(err.response.data.message)
      console.log(err.response.data.message)
    }
  }
  useEffect(() =>{

    verifyToken()
  },[])
  const passwordcheck = newpassword.length >= 8 && confirmpassword.length >= 8  && validation.passwordMatch(newpassword, confirmpassword);
  return (
    <CreateNewPasswordStyle>
      <div className='create-new-password'>
        <Text
          text={ 'Create New Password' }
          fontWeight={ '700' }
          fontSize={ '20px' }
          color={ Colors.prussianBlue }
        />
        <Text
          text={ 'Input your new password on the form below.' }
          fontWeight={ '300' }
          fontSize={ '16px' }
          className={ 'mb-8' }
          color={ Colors.prussianBlue }
        />
        <Input
          label='New Password'
          name='newpassword'
          type={passwordType}
          placeholder='new password'
          className='mb-5'
          textColor={ Colors.prussianBlue }
          onChange={(e) => setNewpassword(e.value)}
        />
        <Input
          label='Confirm New Password'
          name='confirmpassword'
          type={passwordType}
          placeholder='confirm new password'
          className='mb-5'
          textColor={ Colors.prussianBlue }
          onChange={(e) => seConfirmpassword(e.value)}
        />
        <Text text={Errormessage}
         fontWeight={ '400' }
         fontSize={ '20px' }
         color={ Colors.redSalsa }
         className="mt-3 ml-2"
        />
        <div className='flex items-center mb-5'>
         <Input
          name='checkbox'
          type='checkbox'
          placeholder='confirm new password'
          className='mb-5 w-6 h-6'
          textColor={ Colors.prussianBlue }
          onChange={() => handleShowpass()}
        />
         
        <Text text={"Show Password"}
         fontWeight={ '400' }
         fontSize={ '20px' }
         color={ Colors.prussianBlue }
         className="mt-3 ml-2"
        />
        </div>
        <Button
          label='Submit'
          width='100%'
          onClick={ () => handleSubmit()} 
          disabled = {!passwordcheck}

        />
      </div>
    </CreateNewPasswordStyle>
  );
};

export default React.memo(CreateNewPassword);
