import React from 'react';
import { Layout, Text } from 'components';
import { Colors } from 'constant';

const description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur urna ultricies nulla viverra in viverra auctor ante. Ornare faucibus luctus quis ante quam magnis ullamcorper. Odio hendrerit consequat lorem magna. Justo, tincidunt elit suspendisse consectetur morbi dolor, scelerisque integer. A nulla libero, ultricies facilisi tortor, egestas condimentum vel penatibus. Gravida vel sed sed lobortis fermentum. Nunc enim consectetur scelerisque cras neque, elit odio tortor integer. Varius cursus magnis malesuada mattis id. Eget scelerisque tortor pretium non varius.';

const data = [
	{
		id: 1,
		title: 'Lorem Ipsum',
		desc: `${description}\n\n${description}`
	},
	{
		id: 2,
		title: 'Lorem Ipsum',
		desc: `${description}\n\n${description}`
	},
	{
		id: 3,
		title: 'Lorem Ipsum',
		desc: `${description}\n\n${description}`
	},
	{
		id: 4,
		title: 'Lorem Ipsum',
		desc: `${description}\n\n${description}`
	}
];

const TermAndConditions = () => {
	return (
		<div className='w-full'>
			<ul role='list' className=''>
				{ data.map(item => (
					<li key={ item.id }>
						<div className='flex items-center space-x-4'>
							<div className='flex-1 min-w-0'>
								<Text
									text={ item?.title }
									fontSize={ '20px' }
									fontWeight={ '700' }
									className={ 'mb-3' }
									fontFamily='AcuminPro'
									color={ Colors.darkSecurity }
								/>
								<Text
									text={ item?.desc }
									fontSize={ '16px' }
									fontWeight={ '300' }
									fontFamily='AcuminPro'
									color={ Colors.darkSecurity }
									className={ 'mb-5 whitespace-pre-line' }
								/>
							</div>
						</div>
					</li>
				)) }
			</ul>
		</div>
	);
};

export default React.memo(TermAndConditions);