import React from 'react';
import ButtonStyle from './style';

const Button = ({
  children,
  label = 'Submit',
  theme = 'primary',
  type = 'button',
  width = 'fit',
  className = '',
  disabled = false,
  onClick,
  backgroundColor = '',
  textColor,
  height = '60px',
  bgHover,
  textColorHover,
  customBorder = false,
  borderRadius = '5px',
  customPadding
}) => {
  return (
    <ButtonStyle
      theme={theme}
      className={className}
      type={type}
      onClick={(e) => onClick && onClick(e)}
      width={width}
      disabled={disabled}
      backgroundColor={backgroundColor}
      textColor={textColor}
      height={height}
      bgHover={bgHover}
      textColorHover={textColorHover}
      customBorder={customBorder}
      borderRadius={borderRadius}
      customPadding={customPadding}
    >{children || label}</ButtonStyle>
  );
};

export default Button;