import React, { useState, useEffect } from "react";
import { Text, Dropdown, Input, Card } from "components";
import { NFTCard } from "components/Card";
import { Colors, Icons, Images } from "constant";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getNfts, getallNfts, getNftsbyid } from "../../services/Nft";
import selectors from "selectors";
import Loader from "react-js-loader";
import axios from "axios";

const nftsPerRow = 8;
const NFTCollections = ({ collectionName, collectionid, groupname }) => {
  const [Nfts, setNfts] = useState([]);
  const [Nfts1, setNfts1] = useState([]);
  const [atozformat, setAtoZformat] = useState("A - Z");
  const [oldNfts, setOldnfts] = useState([]);
  const [maticto, setMaticTo] = useState("");
  const [maticfrom, setMaticFrom] = useState("");

  const [newNfts, setNewnfts] = useState([]);
  const [restaurant_options, setRestaurant_options] = useState([]);
  const [open, setOpen] = useState(false);

  const [next, setNext] = useState(nftsPerRow);
  const handleMoreImage = () => {
    setNext(next + nftsPerRow);
  };

  const dispatch = useDispatch();
  const cur_restaurant = useSelector(selectors.currentRestaurant);

  const getdata = async () => {
    setOpen(true);

    const result = await getNftsbyid({
      id: cur_restaurant._id,
    });

    if (result && result.data.result) {
      setNfts(result.data.result);
      setNfts1(result.data.result);
      setNewnfts(result.data.result);
      setOldnfts(result.data.result);
      setOpen(false);
    }
  };

  const getalldata = async () => {
    const result = await getallNfts();
    if (result && result.data.result) {
      setNfts(result.data.result);
      setNewnfts(result.data.result);
      setNfts1(result.data.result);
      setOldnfts(result.data.result);
      const restaurants = result.data.result.map((item) => {
        return item.OwnedBy;
      });
      const set = new Set(restaurants);
      const newArr = Array.from(set);
      let list = [];
      newArr.map((item) => {
        if (item) {
          let obj = {};
          obj.label = item;
          obj.value = item;
          list.push(obj);
        }
      });
      setRestaurant_options(list);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("marketplace")) {
      getalldata();
    } else if (window.location.href.includes("myprofile")) {
      getdata();
    } else {
      getdata();
    }
  }, []);
  const [maticToUsd, setMatictousd] = useState("");
  const getmatictoUsd = async () => {
    const matictousd = await axios.get(
      "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
      {
        method: "GET",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Methods": "GET",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setMatictousd(matictousd.data["matic-network"].usd);
  };

  useEffect(() => {
    getmatictoUsd();
  }, []);
  const handleRestaurantFilter = (value) => {
    const filtered = oldNfts.filter((item) => item.OwnedBy == value);
    setNfts(filtered);
    setNfts1(filtered);
    setNewnfts(filtered);
  };

  const handlecollectionfilter = (value) => {
    const newnft = Nfts1;
    const filtered = newnft.filter((item) => item.NFTCollections == value);
    setNfts(filtered);
    setNewnfts(filtered);
  };

  const navigate = useNavigate();

  const converttimes = async (dateStr) => {
    const dateParts = dateStr.split("/");
    // The month in JavaScript's Date object is zero-based (0 = January)
    const month = parseInt(dateParts[0]) - 1;
    const day = parseInt(dateParts[1]);
    const year = parseInt(dateParts[2]);

    // Create a new Date object with the provided date
    const date = new Date(year, month, day);
    // Get the Unix timestamp by dividing the milliseconds by 1000
    const unixTimestamp = date.getTime();
    console.log("unixTimestamp==>", unixTimestamp);

    const current = new Date().getTime();
    console.log("current=>", current);

    if (Number(current) > Number(unixTimestamp)) {
      return true;
    } else {
      return false;
    }
  };

  const renderNFTCards = () => {
    return (
      Nfts &&
      Nfts.filter(
        (item) =>
          item.CollectionName == collectionName &&
          item.NFTCollectionid == collectionid &&
          item.NFTCollections == groupname &&
          converttimes(item.NFTStartDate)
      )
        .slice(0, next)
        ?.map((item, idx) => {
          return (
            <NFTCard
              item={item}
              wrapperClass="w-full hover:-translate-y-4 border border-solid border-platinumSolid h-[375px] hover:border-[2px] hover:border-abundance "
              restoName={`Restaurant ${idx}`}
              key={idx}
              maticToUsd={maticToUsd}
            />
          );
        })
    );
  };

  const handlenftatoz = () => {
    setAtoZformat(atozformat == "A - Z" ? "Z - A" : "A - Z");
    if (atozformat == "A - Z") {
      const NEWNFTS = newNfts.filter(
        (item) => item.NFTCollections == groupname
      );
      const nameArr = NEWNFTS.map((item) => item.NFTName.toLowerCase());
      const sortedArr = nameArr.sort();
      let arr = [];
      // pushing sorted items
      const newArr = sortedArr.map((item) =>
        newNfts.map((i) => (item == i.NFTName.toLowerCase() ? arr.push(i) : ""))
      );
      const newArray = [...new Set(arr)];
      setNfts(arr);
    } else {
      const NEWNFTS = newNfts.filter(
        (item) => item.NFTCollections == groupname
      );
      const nameArr = NEWNFTS.map((item) => item.NFTName.toLowerCase());
      const sortedArr = nameArr.sort();
      let arr = [];

      // pushing sorted items
      const newArr = sortedArr.map((item) =>
        newNfts.map((i) => (item == i.NFTName.toLowerCase() ? arr.push(i) : ""))
      );
      const newArray = [...new Set(arr)];
      setNfts(arr.reverse());
    }
  };
  const handleAtoZ = () => {
    setAtoZformat(atozformat == "A - Z" ? "Z - A" : "A - Z");
    if (atozformat == "A - Z") {
      const nameArr = newNfts.map((item) => item.OwnedBy);
      const sortedArr = nameArr.sort();
      let arr = [];

      // pushing sorted items
      const newArr = sortedArr.map((item) =>
        newNfts.map((i) => (item == i.OwnedBy ? arr.push(i) : ""))
      );
      const newArray = [...new Set(arr)];
      setNfts(newArray);
      setNewnfts(newArray);
    } else {
      const nameArr = newNfts.map((item) => item.OwnedBy);
      const sortedArr = nameArr.sort();
      let arr = [];

      // pushing sorted items
      const newArr = sortedArr.map((item) =>
        newNfts.map((i) => (item == i.OwnedBy ? arr.push(i) : ""))
      );
      const newArray = [...new Set(arr)];
      setNfts(newArray);
      setNewnfts(newArray);
    }
  };

  const handleCollectionsfilter = (value) => {
    const nameArr = newNfts.map((item) => item.NFTCollections);
    const sortedArr = nameArr.sort();
    let arr = [];
    // pushing sorted items
    if (value == "All") {
      setNfts(newNfts);
    } else {
      const newArr = newNfts.map((i) =>
        i.NFTCollections == value ? arr.push(i) : ""
      );
      setNfts(arr);
    }
  };

  const handleMaticfilter = () => {
    const maticfiltered = newNfts.filter(
      (item) => item.NFTPrice >= maticfrom && item.NFTPrice <= maticto
    );
    setNfts(maticfiltered);
  };
  return (
    <div className="mb-5 w-full px-5 xl:w-defaultContent xl:px-0">
      <div className="sm:flex justify-between items-center">
        <div className="flex items-center space-x-0 md:space-x-5 lg:space-x-5 lg:flex-row md:flex-row flex-col">
          {window.location.href.includes("marketplace") ? (
            <>
              <select
                style={{
                  color: "black",
                  opacity: "0.4",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="flex items-center justify-between space-x-2 py-[10px] px-[15px] md:w-auto lg:w-auto w-full color-black bg-black/5 rounded"
                onChange={(e) => handleRestaurantFilter(e.target.value)}
              >
                {restaurant_options.map((option) => (
                  <>
                    <option value="none" selected disabled hidden>
                      Select a Restaurant
                    </option>
                    <option
                      value={option.value}
                      color={Colors.darkSecurity}
                      style={{ color: "black", backgroundColor: "#1C263C" }}
                      className="bg-white/5 rounded"
                    >
                      {option.label}
                    </option>
                  </>
                ))}
              </select>

              <select
                style={{
                  color: "black",
                  opacity: "0.4",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                className="flex items-center justify-between lg:mt-0 md:mt-0 mt-2 space-x-2 py-[10px] px-[15px] md:w-auto lg:w-auto w-full color-black bg-black/5 rounded"
                onChange={(e) => handlecollectionfilter(e.target.value)}
              >
                {[
                  { value: "Gold", label: "Select collection" },
                  { value: "Gold", label: "Gold" },
                  { value: "Silver", label: "Silver" },
                  { value: "Platinum", label: "Platinum" },
                ].map((option) => (
                  <>
                    <option
                      value={option.value}
                      style={{
                        color: "white",
                        backgroundColor: "#1C263C",
                        border: "none",
                      }}
                      className="bg-white/5 rounded"
                    >
                      {option.label}
                    </option>
                  </>
                ))}
              </select>
            </>
          ) : (
            // <select
            //   style={{
            //     color: "black",
            //     opacity: "0.4",
            //     fontSize: "20px",
            //     fontWeight: "bold",
            //     border: "none",
            //   }}
            //   className="flex items-center justify-between items-center w-fit space-x-2 py-[10px] px-[15px] color-white bg-black/5 rounded"
            //   onChange={(e) => handleCollectionsfilter(e.target.value)}
            // >
            //   {[
            //     { value: "All", label: "Select a collection" },
            //     { value: "Gold", label: "Gold" },
            //     { value: "Silver", label: "Silver" },
            //     { value: "Platinum", label: "Platinum" },
            //   ].map((option) => (
            //     <>
            //       <option
            //         value={option.value}
            //         style={{
            //           color: "white",
            //           backgroundColor: "#1C263C",
            //           border: "none",
            //         }}
            //         className="bg-white/5 rounded"
            //       >
            //         {option.label}
            //       </option>
            //     </>
            //   ))}
            // </select>
            <></>
          )}

          <div
            className="flex items-center  w-fit space-x-2 py-[10px] px-[20px]  bg-black/5 rounded mt-2 sm:mt-0"
            onClick={() => handlenftatoz()}
          >
            <img src={Icons.AtoZ} alt="a-to-z" className="" />
            <Text
              style={{ width: "10%" }}
              fontWeight="700"
              text={atozformat}
              color="black"
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:px-0 sm:grid-cols-3 lg:grid-cols-4 gap-x-[10px] gap-y-[20px] mt-5">
        {renderNFTCards()}
      </div>
      <div className="flex justify-center items-center">
        {next < Nfts?.length && (
          <button
            className="mt-5 outline_button-rounded"
            onClick={handleMoreImage}
          >
            Load more
          </button>
        )}
      </div>
      {open ? (
        <Loader
          type="spinner-cub"
          bgColor={"black"}
          color={"black"}
          size={80}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default NFTCollections;
