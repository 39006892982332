import React, { useState, useEffect,useMemo } from 'react';

import {SecurityStyle, SubHeaderStyle,ForgotPasswordStyle} from './style';
import { Images, Colors,Icons } from "constant";
import { Text, Button, Modal, Input } from "components";
import { validation } from 'helpers';
import { useNavigate } from 'react-router';
import { EyeOffIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../selectors';
import { getuser, edit_user_profile, resetPassword } from '../../../../services/auth';
import S3 from "react-aws-s3";
import { ToastContainer, toast } from 'react-toastify';
import { connectFailed } from '../../../../stores/User/WalletAction';
import 'react-toastify/dist/ReactToastify.css';

const EditProfile = ({ menu="changepassword" }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectors.userProfile);
    const [image, setImage] = useState('');
    const [fileName, setFileName] = useState('');
  
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [newuser, setNewUser] = useState('');
    const [mode,setMode] = useState("FORGOT_PASSWORD");

    const [newpassword, setNewpassword] = useState('');
    const [confirmpassword, seConfirmpassword] = useState('');


    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState({ createPw: false, confirmPw: false });
    const [payload, setPayload] = useState({ createPassword: '', confirmPassword: '' });
  
    const validateResetPw = payload.createPassword.length > 4 && payload.confirmPassword.length > 4 && validation.passwordMatch(payload.createPassword, payload.confirmPassword);
  
    const onChangeText = (value, name) => {
      setPayload({ ...payload, [name]: value });
    };
  
    const handleChangeSubmit = async()=>{
      const values = {
        password:confirmpassword
      }
      try{
        const verify_ = await resetPassword(user.user.id, values);
        if(verify_.data.success){
          navigate('/setting')
          toast("Change password success")
          setMode("FORGOT_PASSWORD")
        }
      }catch(err){
        setErrormessage(err.response.data.message)
        console.log(err.response.data.message)
      }
    }

    const handleProfile = async (e) => {
      const files = e.target.files;
      let file = files[0];
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName: process.env.REACT_APP_DIRECTORYNAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      };
  
      const ReactS3Client = new S3(config);
      const newFileName = file.name + "_" + Date.now();
      ReactS3Client.uploadFile(file, newFileName).then((data) =>
        console.log(data)
      );
      const newImage = URL.createObjectURL(files[0]);
      setImage(newImage)
      setFileName(newFileName);
    }
    const getCurrentuser = async () => {
      const res = await getuser(user.user.id)
      if (res.data.status) {
        setNewUser(res.data.result);
      }
    }
    useEffect(() => {
      getCurrentuser();
    }, [])
    const handleSubmit = async () => {
        const tokens = user.token
        const values = {
          userName: name ? name : user.user.userName,
          phone: phone ? phone : newuser.Phone,
          email: email ? email : newuser.email,
          profilePicture: fileName ? fileName : newuser.profilePicture
    
        };
        const res = await edit_user_profile(user.user.id, values, tokens);
        if (res.data.status) {
          getCurrentuser();
          toast("Profile updated");
        } else {
          dispatch({
            type: 'SET_PROFILE',
            payload: ''
          });
          dispatch(connectFailed());
          toast("session expired");
        }
      }
  return (
    <SecurityStyle>
      <div className='context'>
        <div className='subtitle flex-1 min-w-0'>
          {
            mode == "FORGOT_PASSWORD" ? 

        <SubHeaderStyle>

        <div className="flex justify-center mt-2">
          {!image && !newuser.profilePicture ?
            <>
              <label for="file-upload" className="custom-file-upload">

                <img className="rounded-full max-w-full h-auto cursor-pointer" src={Images.Profile} width={100} height={100} />

              </label>
              <input id="file-upload" type="file" onChange={(e) => handleProfile(e)} />
            </>
            : null
          }
          {image || newuser.profilePicture ?
            <>
              <label for="profile-upload" className="custom-file-upload">

                <img className="rounded-full max-w-full h-auto cursor-pointer" src={image ? image : `${process.env.REACT_APP_S3_URL}${newuser.profilePicture}`} width={100} height={100} />

              </label>
              <input id="profile-upload" type="file" onChange={(e) => handleProfile(e)} />
            </>
            : null
          }
        </div>
        <div className="flex  mt-2 inputblock" >
          <label className="label">Name</label>
          <input type="text" className="inputbox rounded" defaultValue={newuser.userName} onChange={(e) => setName(e.target.value)} />
        </div>

        <div className="flex  mt-2 inputblock">
          <label className="label">Email</label>
          <input type="email" className="inputbox rounded" style={{ cursor: "no-drop" }} disabled={true} defaultValue={newuser && newuser.email} onChange={(e) => setEmail(e.target.value)} />
        </div>

        <div className="flex  mt-2 inputblock">
          <label className="label">Phone</label>
          <input type="text" className="inputbox rounded " defaultValue={newuser.Phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
 
        {/* <div className="flex justify-center mt-5">
          <Button label="Submit" onClick={() => handleSubmit()} />
        </div> */}
        <div className='flex justify-evenly mt-5'>
            <button className='outline_button-rounded mr-5' onClick={ () => setMode('CHANGE_PASSWORD') }>
                <span>Change Password</span>
            </button>
            <button className='filled_button-rounded' onClick={() => handleSubmit()}>
                <span>Save</span>
            </button>
		    </div>
        </SubHeaderStyle>
          : 
        <ForgotPasswordStyle
			isChangePassword={ mode !== 'FORGOT_PASSWORD' }
		>
			<div className='right-side w-[540px]'>
				<Text
					text={ menu === 'changepassword' ? 'Change Password' : 'Reset Password' }
					fontWeight= '700'
					fontType= 'h4'
					className= 'mb-2'
					textAlign= ''
					fontFamily='ExpressWay'
					fontSize='32px'
					color={ Colors.darkSecurity }
				/>
				<Input
					label='Create New Password'
          onChange={(e) => setNewpassword(e.value)}
					name='createPassword'
					placeholder='Password'
					className='mb-[30px]'
					bgColor={ Colors.white }
					border={ `solid 1px ${Colors.platinumSolid}` }
					focusLabelColor={ Colors.abundance }
					borderFocus={ `solid 1.5px ${Colors.abundance}` }
					textInputClassName='rounded'
					type={ showPassword.createPw ? 'text' : 'password' }
					iconRight={
						<div className='h-[60px] flex justify-center items-center relative'>
							{
								!showPassword.createPw ?
									<EyeOffIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer ' fill={ Colors.darkSecurity } onClick={ () => setShowPassword({ ...showPassword, createPw: !showPassword.createPw }) } />
									:
						 <Icons.EyeIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer absolute right-3' onClick={ () => setShowPassword({ ...showPassword, createPw: !showPassword.createPw }) } />
							}
						</div>
					 }
					 value={ payload.createPassword }
					 textColor={ Colors.darkSecurity }
				/>
				<Input
					type={ showPassword.confirmPw ? 'text' : 'password' }
					label='Confirm New Password'
          onChange={(e) => seConfirmpassword(e.value)}
					placeholder='Password'
					name='confirmPassword'
					className='mb-[30px]'
					border={ `solid 1px ${Colors.platinumSolid}` }
					focusLabelColor={ Colors.abundance }
					borderFocus={ `solid 1.5px ${Colors.abundance}` }
					bgColor={ Colors.white }
					textInputClassName='rounded'
					iconRight={
						<div className='h-[60px] flex justify-center items-center relative'>
							{
								!showPassword.confirmPw ?
									<EyeOffIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer ' fill={ Colors.darkSecurity } onClick={ () => setShowPassword({ ...showPassword, confirmPw: !showPassword.confirmPw }) } />
									:
						 <Icons.EyeIcon className='[&>path]:fill-darkSecurity opacity-50 eye-icon cursor-pointer absolute right-3' onClick={ () => setShowPassword({ ...showPassword, confirmPw: !showPassword.confirmPw }) }  />
							}
						</div>
					 }
					 value={ payload.confirmPassword }
					 textColor={ Colors.darkSecurity }
				/>

        <div className='flex justify-evenly mt-5'>
            <button className='outline_button-rounded mr-5' onClick={ () => setMode('FORGOT_PASSWORD') }>
                <span>Back</span>
            </button>
            <button className='filled_button-rounded' onClick={() => handleChangeSubmit()}>
                <span>Reset Password</span>
            </button>
		    </div>
			</div>
		</ForgotPasswordStyle>
}

        </div>
      </div>

    </SecurityStyle>
  );
};

export default EditProfile;