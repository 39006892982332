import React, { useEffect, useState } from 'react';

import { Verify } from 'components';
import { Images } from 'constant';
import { Layout } from 'pages/LandingPageNew/Components'
import { useNavigate, useParams } from 'react-router-dom';
import { Colors } from 'constant';
import axios from 'axios';
import { baseUrl } from 'config';
import { toast } from 'react-toastify';
import { Button, Text, Modal, Input } from 'components';
import {signIn} from "../../../../../services/auth"
import { useDispatch, useSelector } from 'react-redux';
import { SET_PROFILE } from '../../../../../stores/User/userActionTypes';


const Verified = () => {
	let params = useParams()
	const dispatch = useDispatch();
	const [user, setUser] = useState()
	const [showModalInputCode, setShowModalInputCode] = useState(false)
	const [password,setpassword]=useState("")
	const [email,setemail]=useState("")
	const navigate = useNavigate()
  const handleJwt = async() => {

    let headers = {};
    headers['Content-Type'] = 'application/json';
    headers['x-auth-token'] = params.id;
    headers = { ...headers };

    await axios.get(`${baseUrl}/auth/checkVerificationLink`,{headers}).then(async(sign) => {
      // console.log(sign)
      if(!sign.data.user){
        navigate('*')
      }else{
		await axios.put(`${baseUrl}/auth/verify/${sign.data.user._id}`)
		.then((signs) => {
			setUser(sign.data.user)
		})
      }
    }).catch((res) => {
      toast("something went wrong!");
    })
    
  }

  useEffect(()=>{
    handleJwt();
  },[])
	

	const handleSubmit = async() => {
		console.log('asd')
    await axios.put(`${baseUrl}/auth/verify/${user && user._id}`)
					.then((sign) => {
						if(sign.data.status){
							// toast("Your account verified successfully, you will be redirected to login.");
							console.log("user==>",user)
							setemail(user.email)
							setShowModalInputCode(true)
							// navigate('/membership')
						}else{
							toast("something went wrong!");
						}
					}).catch((res) => {
						toast("something went wrong!");
					})
  }

  const logedin = async()=>{
	console.log(password)
	var values = {
		email: email,
		password: password,
	}
	await signIn(values)
			.then(async (res) => {
    
				if (res.data.response.success) {
					localStorage.setItem('token',res.data.response.token)
					localStorage.setItem('wallet',res.data.response.user.walletAddress)
					const user = res.data.response;
					dispatch({
						type: SET_PROFILE,
						payload: user
					});
					navigate('/membership')
				}

         console.log(res)
			})
  }

  const renderModalInputCode = () => {
    return (
      
      <Modal
        visible={ showModalInputCode }
        onClose={ () => setShowModalInputCode(false) }
        contentClassName='w-full md:w-[540px]'

      >
        <div className='modal-input-container'>
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text='Input Password'
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='Enter your Password.'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
          <Input
            name='code'
            className='mb-8'
            autofocus
            placeholder='Password'
            onChange={({ value }) => setpassword(value)}
            value={password}
            textColor={ Colors.prussianBlue }
          />
        </div>
        <div className='btn-submit mx-auto flex justify-center'>
          <div>
            <Button
              className=''
              label='Submit'
              height={ '40px' }
              width={ '185px' }
              textColor={ 'white' }
              backgroundColor={ '#003051' }
            //   disabled={!validation.minLength(verificationCode,4)}
              onClick={ () => { logedin()} }
            />
          </div>
        </div>
      </Modal>
    );
  };

	return (
		<Layout bgColor='bg-white'>
			<div className='w-full m-auto md:px-20 container py-5 md:pt-20 min-h-[calc(100vh-250px)]'>
				<Verify
					handleSubmit={()=>handleSubmit()}
					Image={Images.Verify2}
					isCustomSubmit={true}
					Kicker={`congratulations ${user?.userName}`}
					Title='your account has been verified!'
					Subtitle='There’s just a few more steps to go through to complete your membership!'
					Btn='Let’s Begin!' />
			</div>
		{ renderModalInputCode() }
		</Layout>
	);
};

export default Verified;