import { Button, Text } from 'components';
import React from 'react';

const Finish = ({
	handleNextPage
}) => {
	return (
		<div className='flex-1'>
			<Text fontType='h3' className='uppercase' fontSize='24px' fontWeight={ 400 } color='#000000'>congratulations! you finished!</Text>
			<Text fontType='h2' fontSize='45px' lineHeight='53px' className='mt-7' fontWeight={ 600 } color='#000000'>Your profile is
				<br/>currently in review!</Text>
			<Text fontType='p' className='mt-8 max-w-xl' fontSize='18px' lineHeight='22px' fontWeight={ 400 } color='#000000'>Thanks for completing your membership profile! We’re filtering all the bots and spammers in our community so we’ll be reviewing each and every profile! Expect a profile verification email in your inbox!</Text>
			<Button onClick={ () => handleNextPage() } theme='primary-outline' className='mt-12'>View Profile</Button>

		</div>
	);
};

export default Finish;