import styled from 'styled-components';

const MyProfileStyle = styled.div`
    height: fit-content;
    overflow-x: hidden;

    .wrapper {
        width: 85%;
        margin: auto;
        margin-top: 20vh;
    }
`;
    
export default MyProfileStyle;