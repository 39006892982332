import React from 'react';

// import { Header } from 'components';

const Layout = ({ children }) => {
  return (
    <div style={{backgroundColor:"#fffbf9"}}>
      {/* <Header /> */}
      { children }
    </div>
  );
};

export default Layout;