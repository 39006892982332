import React, { useEffect, useState } from "react";
import WalletStyles from "./style";
import { Text, Button, Modal } from "../../../../components";
import metamask from "../../../../assets/images/logo/MetaMask_Fox.svg.png";
import walletconnect from "../../../../assets/images/logo/WalletConnect-Logo.png";
import formatic from "../../../../assets/images/logo/formatic.jpg";
import coinbase from "../../../../assets/images/logo/coinbase.png";
import { SET_WALLET_ADDRESS } from "../../../../stores/User/userActionTypes";
import { SET_PROFILE } from "../../../../stores/User/userActionTypes";
import selectors from "../../../../selectors";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  connectWallet,
  connectFailed,
} from "../../../../stores/User/WalletAction";
import "react-toastify/dist/ReactToastify.css";
import {
  update_user_wallet,
  getuser,
  listallWallets,
  updatethewallet,
} from "../../../../services/auth";
import Web3 from "web3";
import Web3Modal from "web3modal";
import { useNavigate } from "react-router-dom";
import { Colors, Icons, Images } from "constant";
import WalletConnectProvider from "@walletconnect/web3-provider";

const Wallet = () => {
  const navigate = useNavigate();
  const [connectionType, setConnectionType] = useState("");
  const dispatch = useDispatch();
  const [userdata, setUserdata] = useState("");
  const wallet = useSelector(selectors.WalletConnect);
  const [fortmatic, setfortmatic] = useState(false);
  const user = useSelector(selectors.userProfile);
  const [checkwallet, setcheckwallet] = useState("");
  const [walletconfirm, setwalletconfirm] = useState(false);
  const [refresh, setrefresh] = useState(false);

  const walletconnectaction = async () => {
    try {
      dispatch(connectWallet(connectionType));
    } catch (err) {
      console.log("error:", err);
    }
  };

  useEffect(() => {
    if (user.user) {
      userdatas();
      getUserAddress();
    } else {
      navigate("/marketplace");
    }
    if (wallet.address) {
      checkwalletlist();
    }
  }, [wallet, refresh]);

  const userdatas = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res1 = await getuser(user.user.id);
      if (res1.data.status) {
        const User = res1.data.result;
        if (User.membershipstatus !== "approved") {
          navigate(`/membership`);
        }
      } else {
        toast("Something Went wrong");
      }
    } else {
      navigate("/sign-in");
      toast("Login Your Account");
    }
  };

  const checkwalletlist = async () => {
    let id = user.user.id;
    if (id) {
      const res = await getuser(id);

      const checkwalletsaddress = res.data.result;
      console.log(checkwalletsaddress);
      if (checkwalletsaddress.walletAddress == "") {
        setcheckwallet(wallet.address);
        setwalletconfirm(true);
      } else {
        // if (checkwalletsaddress.walletAddress != wallet.address) {
        //   dispatch(connectFailed());
        //   toast("Connect with the registered wallet address");
        // } else {
        navigate("/marketplace");
        // }
      }
    }
  };

  const getUserAddress = async () => {
    let id = user.user.id;
    const res = await getuser(id);
    setUserdata(res.data.result);
    const checkwalletsaddress = res.data.result;
    if (checkwalletsaddress.walletAddress != "") {
      setfortmatic(false);
    } else {
      setfortmatic(true);
    }
  };
  const handleWalletConnect = async (value) => {
    if (!value) {
      await walletconnectaction(connectionType);
    } else {
      await walletconnectaction("fortmatic");
    }
  };

  const setwallets = async (value) => {
    dispatch(connectWallet(value));
  };

  const handleWalletDisConnect = async () => {
    const disconnect_ = await dispatch(connectFailed());
    const { web3Modal } = wallet;
    web3Modal.clearCachedProvider();
  };

  const list = [
    {
      id: 1,
      image: metamask,
      title: "Metamask",
      value: "metamask",
    },
    {
      id: 2,
      image: coinbase,
      title: "Coinbase Wallet",
      value: "coinbasewallet",
    },
    {
      id: 3,
      image: walletconnect,
      title: "Connect Wallet",
      value: "walletconnect",
    },
    {
      id: 4,
      image: formatic,
      title: "Formatic",
      value: "fortmatic",
    },
  ];

  const closemodal = async () => {
    dispatch(connectFailed());
    toast("Wallet Connection Failed ");
    setwalletconfirm(false);
  };

  const checkswallets = async () => {
    const res = await listallWallets();
    if (res.data.status) {
      const listallWallet = res.data.result;
      if (!listallWallet.includes(wallet.address)) {
        setaddressforemail();
      } else {
        dispatch(connectFailed());
        toast("This wallet address is already registered");
        setwalletconfirm(false);
      }
    }
  };

  const setaddressforemail = async () => {
    const values = {
      walletAddress: wallet.address ? wallet.address : "",
    };
    const updatewalletad = await updatethewallet(user.user.id, values);
    if (updatewalletad.data.status) {
      setwalletconfirm(false);
      toast("wallet address updated successfully");
      if (user.user.walletAddress == "") {
        localStorage.setItem("wallet", wallet.address);
        user.user.walletAddress = wallet.address;
        dispatch({
          type: SET_PROFILE,
          payload: user,
        });
      }
      setrefresh(true);
    } else {
      toast("updatefailed");
    }
  };

  return (
    <WalletStyles>
      <Text
        text={"Connect Wallet"}
        fontType={"h1"}
        fontWeight={"700"}
        fontSize={"48px"}
        color={"black"}
        fontFamily={"Lora"}
        className="title"
      />
      <Text
        text={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
        fontType={"p"}
        fontWeight={"300"}
        color={"black"}
        fontSize={"16px"}
      />
      {fortmatic
        ? list.slice(0, 3).map((item, i) => (
            <div className="card" key={item.id}>
              <div className="logo">
                <img src={item.image} width={40} height={40} />
              </div>
              <div className="menu">
                <Text
                  text={item.title}
                  fontWeight={"700"}
                  color={"black"}
                  fontType={"p"}
                  fontSize={"20px"}
                  fontFamily={"Lora"}
                />
              </div>
              <div className="arrow">
                {/* <img src={ Images.ArrowRight } width={ 8 } height={ 15 } /> */}
                <input
                  type="radio"
                  name="wallet"
                  onChange={() => {
                    setConnectionType(item.value);
                  }}
                ></input>
              </div>
            </div>
          ))
        : list.map((item, i) => (
            <div className="card" key={item.id}>
              <div className="logo">
                <img src={item.image} width={40} height={40} />
              </div>
              <div className="menu">
                <Text
                  text={item.title}
                  fontWeight={"700"}
                  color={"black"}
                  fontType={"p"}
                  fontSize={"20px"}
                  fontFamily={"Lora"}
                />
              </div>
              <div className="arrow">
                <input
                  type="radio"
                  name="wallet"
                  onChange={() => {
                    setConnectionType(item.value);
                  }}
                ></input>
              </div>
            </div>
          ))}

      {fortmatic ? (
        <div className="card">
          <div className="menu">
            <Text
              text={"Create Wallet Using Email"}
              fontWeight={"700"}
              color={"black"}
              fontType={"p"}
              fontSize={"20px"}
              fontFamily={"Lora"}
            />
          </div>

          <div className="menu1 flex items-center mr-0">
            <span style={{ fontSize: "20px", fontWeight: "700" }}>(</span>
            <img src={formatic} width={30} height={30} />
            <Text
              text={"Formatic "}
              fontWeight={"700"}
              color={"black"}
              fontType={"p"}
              fontSize={"20px"}
              fontFamily={"Lora"}
            />
            <span style={{ fontSize: "20px", fontWeight: "700" }}> )</span>
          </div>

          <div className="arrow">
            <input
              type="radio"
              name="wallet"
              onChange={() => {
                setConnectionType("fortmatic");
              }}
            ></input>
          </div>
        </div>
      ) : null}

      {fortmatic ? (
        <Button
          label="Register Wallet"
          className="connect-wallet"
          onClick={() => {
            handleWalletConnect();
          }}
        />
      ) : wallet.address ? (
        <Button
          label={`Disconnect(${
            wallet.address.slice(0, 3) + "..." + wallet.address.slice(-3)
          })`}
          className="connect-wallet"
          onClick={() => {
            handleWalletDisConnect();
          }}
        />
      ) : (
        <Button
          label="Connect Wallet"
          className="connect-wallet"
          onClick={() => {
            handleWalletConnect();
          }}
        />
      )}
      <Modal
        visible={walletconfirm}
        onClose={() => setwalletconfirm(false)}
        contentClassName="w-full md:w-[540px]"
      >
        <div className="">
          <Text
            text="Wallet Confirmation"
            className="mb-[30px]"
            color={Colors.prussianBlue}
            fontFamily="lora"
            fontWeight="700"
            fontSize="32px"
          />

          <div className="flex justify-between items-center mb-3">
            <div className=" ">
              <Text
                text="Do You Want To Continue With This Wallet Address"
                className="flex justify-between items-center mb-3"
                fontWeight="700"
                fontSize="24px"
                fontFamily="lora"
                color={Colors.darkBlue}
              />
              <Text
                className="flex justify-between items-center mb-3"
                fontWeight="400"
                fontSize="20px"
                fontFamily="lora"
                color={Colors.darkBlue}
              >
                {wallet.address}
              </Text>
            </div>
          </div>
          <div className="flex justify-center mb-3">
            <button
              className="mr-10 bg-transparent px-5 rounded border border-black"
              onClick={closemodal}
            >
              <Text fontWeight="400" color={Colors.darkBlue}>
                Cancel
              </Text>
            </button>
            <Button
              label="Confirm"
              onClick={checkswallets}
              width="fit-content"
            />
          </div>
        </div>
      </Modal>
    </WalletStyles>
  );
};

export default Wallet;
