import { SET_INSTAGRAM_DATA } from './landingPageActionTypes';

const initState = {
	isLoading: false,
	dataInstagram: []
};

const landingPageReducer = (state = initState, action) => {
	switch (action.type) {
		case SET_INSTAGRAM_DATA:
			return {
				...state,
				isLoading: false,
				dataInstagram: action.payload || []
			};

		default:
			return state;
	};
};

export default landingPageReducer;
