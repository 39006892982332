// import { Layout } from 'components';
import { Layout } from 'pages/LandingPageNew/Components';
import React, { useState } from 'react';

const Roadmap = () => {

	const [activeTab, setActiveTab] = useState('Singapore');

	return (
		<Layout bgColor='bg-white'>
			<div className='container mx-auto bg-newBeginning my-16 py-[30px] md:p-16'>
				<div className='text-center'	>
					<h3 className='text-base md:text-2xl font-ExpressWay'>Q1 2023 - Q4 2023</h3>
					<h2 className='text-[32px] md:text-5xl font-semibold font-ExpressWay'>Our Roadmap</h2>
				</div>

				<ul className='flex overflow-x-auto items-center justify-center gap-4 md:gap-16 mt-8 text-black font-AcuminPro'>
					<li onClick={() => setActiveTab('Singapore')} className={`border-b-2 border-transparent hover:border-okuGrowth cursor-pointer text-sm md:text-lg uppercase ${activeTab === 'Singapore' && 'border-okuGrowth font-bold'}`}>Singapore</li>
					<li onClick={() => setActiveTab('Jakarta')} className={`border-b-2 border-transparent hover:border-abundance cursor-pointer text-sm md:text-lg uppercase ${activeTab === 'Jakarta' && 'border-okuGrowth font-bold'}`}>Jakarta</li>
					<li onClick={() => setActiveTab('Bali')} className={`border-b-2 border-transparent hover:border-abundance cursor-pointer text-sm md:text-lg uppercase ${activeTab === 'Bali' && 'border-okuGrowth font-bold'}`}>Bali</li>
					<li onClick={() => setActiveTab('technology')} className={`border-b-2 border-transparent hover:border-black cursor-pointer text-sm md:text-lg uppercase ${activeTab === 'technology' && 'border-okuGrowth font-bold'}`}>technology</li>
				</ul>
				<div className={`mt-3 ${activeTab === 'Singapore' ? 'flex' : 'hidden'} grid grid-cols-1 divide-y divide-black px-6`}>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q1
							</div>
						</div>

						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore Club House Collection Launch!</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore Dining Partnership Events</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							{ /* <div className='w-full'>
								<h3 className='text-sm md:text-2xl'>Event collaboration with XX on July 16</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{ activeTab }</p>
							</div> */ }
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q2
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore Club House Connoisseur tier launch!</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							{ /* <div className='w-full'>
								<h3 className='text-sm md:text-2xl'>Event collaboration with XX on August 4</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase'>{ activeTab }</p>
							</div> */ }
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q3
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore Club House Aesthete tier launch!</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							{ /* <div className='w-full'>
								<h3 className='text-sm md:text-2xl'>Event collaboration with XX on August 4</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{ activeTab }</p>
							</div> */ }
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q4
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore & Jakarta Club House Opens!</h3>
								<p className='text-xs md:text-base text-okuGrowth mt-1 uppercase font-ExpressWay'>{activeTab + '& JAKARTA'}</p>
							</div>
						</div>
					</div>
				</div>
				<div className={`mt-3 ${activeTab === 'Jakarta' ? 'flex' : 'hidden'} grid grid-cols-1 divide-y divide-black px-6`}>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q1
							</div>
						</div>
						<div className='flex gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q1</h3>
							</div>
						</div>

					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q2
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Jakarta Club House Collection Launch</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							{ /* <div className='w-full'>
								<h3 className='text-sm md:text-2xl'>Event with Braud<br className='hidden md:inline'/>on July 6</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase'>{ activeTab }</p>
							</div> */ }
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q3
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className='w-full'>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Jakarta Club House Gold & Silver Launch</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
							{ /* <div className='w-full'>
								<h3 className='text-sm md:text-2xl'>Live DJ event with <br className='hidden md:inline'/>Bebop on Nov 25</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase font-ExpressWay'>{ activeTab }</p>
							</div> */ }
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q4
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div className=''>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Singapore & Jakarta Club House Opens!</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase font-ExpressWay'>{'SINGAPORE & ' + activeTab}</p>
							</div>
						</div>
					</div>
				</div>
				<div className={`mt-3 ${activeTab === 'Bali' ? 'flex' : 'hidden'} grid grid-cols-1 divide-y divide-black px-6`}>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q1
							</div>
						</div>
						<div className='flex gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q1</h3>
							</div>
						</div>

					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q2
							</div>
						</div>
						<div className='flex gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q2</h3>
							</div>
						</div>

					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q3
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Bali Club House Premiere Tier Launch</h3>
								<p className='text-xs md:text-base text-abundance mt-1 uppercase font-ExpressWay'>{activeTab}</p>
							</div>
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q4
							</div>
						</div>
						<div className='flex gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q4</h3>
							</div>
						</div>
					</div>
				</div>
				<div className={`mt-3 ${activeTab === 'technology' ? 'flex' : 'hidden'} grid grid-cols-1 divide-y divide-black px-6`}>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q1
							</div>
						</div>
						<div className='flex gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q1</h3>
							</div>
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q2
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Launch of <br className='hidden md:inline' />Community Voting</h3>
								<p className='text-xs md:text-base text-black mt-1 uppercase font-ExpressWay'>Oku House</p>
							</div>
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q3
							</div>
						</div>
						<div className='grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-16'>
							<div>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Membership Status and Loyalty Tracking Launch</h3>
								<p className='text-xs md:text-base text-black mt-1 uppercase font-ExpressWay'>Oku House</p>
							</div>
							<div>
								<h3 className='text-sm md:text-2xl font-AcuminPro font-medium'>Oku Passport <br className='hidden md:inline' />Launch</h3>
								<p className='text-xs md:text-base text-black mt-1 uppercase font-ExpressWay'>Oku House</p>
							</div>
						</div>
					</div>
					<div className='h-[192px] md:h-[175px] flex flex-col md:flex-row overflow-x-auto gap-4 md:gap-16 md:items-center items-start justify-center md:justify-start'>
						<div>
							<div className='flex items-center justify-center rounded-lg bg-black text-white font-semibold text-base md:text-[32px] w-10 md:w-20 h-10 md:h-20 font-ExpressWay'>
								Q4
							</div>
						</div>
						<div>
							<h3 className='text-sm md:text-2xl uppercase font-ExpressWay leading-4 md:leading-7 tracking-widest'>woops, seems like theres nothing planned for q4</h3>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Roadmap;