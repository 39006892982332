import { useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, Text } from 'components';

import { useNavigate } from 'react-router';
import { signIn, signUp, ListallUsers, listallWallets, getuser, sendtwofacode } from '../../../../services/auth';
import { checkstatus } from '../../../../services/kyc';
import { SET_PROFILE } from '../../../../stores/User/userActionTypes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpInput from 'react-otp-input';

const useLoginPage = () => {

    const navigate = useNavigate();
	const dispatch = useDispatch();

	const [showModalInputCode1, setShowModalInputCode1] = useState(false)
	const [CurUserdatas,setCurUserdata]= useState()
	const [CurUsers,setCurUser]= useState()
	const [Usermembershipcheks,setUsermembershipcheks]= useState()
	
	const [otps, setOtp] = useState();

	const [showPassword, setShowPassword] = useState(false);
	const [payload, setPayload] = useState({
		email: '',
		password: '',
	});

	const onChangeText = (value, name) => {
		setPayload({
			...payload,
			[name]: value
		});
	};

	const retriveChecks = async (id) => {
		const res = await checkstatus(id);
		if (res.data.status) {
			console.log(res.data.result)
		}
	}

	const onClickLogin = async() => {
		await signIn(payload)
			.then(async (res) => {
				
				if (res.data.response.success) {
					localStorage.setItem('token',res.data.response.token)
					localStorage.setItem('wallet',res.data.response.user.walletAddress)
					
					const user = res.data.response;
					setCurUser(user.user.id);
					setCurUserdata(user);
					const res1 = await getuser(user.user.id);
					if (res1.data.status) {
						const User = res1.data.result;
						if (User.CheckId && User.kyc_status != 'passed' && User.kyc_status != 'NotVerified') {
							retriveChecks(user.user.id);
						}
						setUsermembershipcheks(user)
						if (!User.tfauth) {
							dispatch({
								type: SET_PROFILE,
								payload: user
							});
						
							if(User.membershipstatus == "approved"){
								navigate(`/marketplace`);
							}else{
								navigate(`/membership`);
							}
								
						} else {
						
					        setShowModalInputCode1(true)
							
						}
					}

				}
			})
			.catch((err) => toast(err.response.data.message))
		
	};



	const handleSendCode = async (id) => {
		try {
			const res = await sendtwofacode(id);
			if (res.data.status) {
				
				toast('Verification code sent to your email')
			}
		} catch (err) {
			console.log("error->", err)
		}
	}


	const onClickForgotPassword = () => {
		navigate(`/forgot-password`);
	};

	const handleDisabled = useMemo(() => {
		if (!payload.email && !payload.password) return true;
		return false;
	}, [payload]);

	const handleChange1 = otp => {
		console.log("otp",otp)
		setOtp(otp);
	};

	

	const setShowModalInputCodes = async()=>{
		setShowModalInputCode1(false)
	}
	



	return {

		payload,
		showPassword,
		handleDisabled,
		onChangeText,
		setShowPassword,
		onClickLogin,
		onClickForgotPassword,
		showModalInputCode1,
		setShowModalInputCodes,
		handleChange1,
		CurUserdatas,
		CurUsers,
		Usermembershipcheks
	};

};

export default useLoginPage;