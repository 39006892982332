import React,{useEffect, useState} from 'react';
import { Button, Text, Modal } from 'components';
import { Icons, Images , Colors} from 'constant';
import { useNavigate,useLocation } from 'react-router';
import { SET_NFT } from '../../../stores/User/userActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import axios  from 'axios';
import PutonsaleModal from './putonsalemodal';
import moment from 'moment';
import {getAllusers} from '../../../services/auth';
import {getRestaurantNFTs, PayNow,delistNft, getnftstatus, PutonSale, declineoffer, getnftbyid,getlastsold,
  executeNfts,getsaleshistory, Placeabid,Makeoffer,getbidhistory} from '../../../services/Nft';
  import templeteabi from '../../../contractsABI/TempleteABI.json';
  import selectors from 'selectors';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalStyles from './styles';
import group27 from  '../../../assets/images/Group27.svg';
import group28 from  '../../../assets/images/Group28.svg';
import group29 from  '../../../assets/images/Group29.svg';
import loadiing from  '../../../assets/images/loading.gif';
const NFTCard = ({
  title = 'Lorem ipsum dolor sit amet',
  restoName = 'Restaurant Name',
  price = '1,2345',
  wrapperClass = 'w-full',
  onClick,
  item, 
  maticToUsd
}) => {
  // navigate will only use until integration

  const nftstate = item;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const wallet = useSelector((state) => state.WalletConnect);
  const current_user = useSelector(selectors.userProfile);
  const [instantsaleModal,setInstantsaleModal] = useState(false);
  const [showTimedmodal, setShowTimedModal] = useState(false);
  const [instantActive, setInstantActive] = useState(false);
  const [timedActive, setTimedActive] = useState(false);
  const [oneday, setOneday] = useState(false);
  const [twoday, setTwoday] = useState(false);
  const [threeday, setThreeday] = useState(false);
  const [daytime, setDay] = useState('');
  const [maticActive, setMaticActive] = useState(false);
  const [usdActive, setUsdActive] = useState(false);
  const [usdtActive, setUsdtActive] = useState(false);
  const [showModalPurchase,setShowModalPurchase] = useState(false);
  const [Bidprice, setBidprice] = useState('');
  const [salesHistory, setSalesHistory] = useState([]);
  const [salesData,setSalesData] = useState([]);

  const [availableMatic, setAvailablebalance] = useState('');
  const [availableUsdc, setAvailableUsdc] = useState('');
  const [availableUsdt, setAvailableUsdt] = useState('');
 const [putonSalePrice, setPutonsalePrice] = useState('');
  const [showBidforsalemodal, setShowBidforsaleModal] = useState(false);
  const [showPlaceBidmodal, setShowPlaceBidModal] = useState(false);
  const [showMakeOfferModal,setShowMakeOfferModal] = useState(false);
  const [cryptoActive, setCryptoActive] = useState(false);
  const [cardActive, setCardActive] = useState(false);
  const [openLoader,setOpenLoader] = useState(false);

  const [metamaskStatus, setMetamaskStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(false);
  const [lastsoldNft, ] = useState('');
  const [nftowner, setNftowner] = useState('');
  const [offermethod, setOfferMethod] = useState('usdc');
  const [bidmethod, setBidMethod] = useState('usdc')
  const [nft, setNft] = useState(item);

  const [showModalPutonsale, setShowModalPutOnsale] = useState(false);
  const handleNftClick= (item) => {
    dispatch({
      type: SET_NFT,
      payload:item,
    });
    window.scrollTo(0, 0);
    navigate(`/nft/${item._id}/${item.CollectionName}`,{state:{collectionName:item.CollectionName}});
  }
    
  const handleListMyNFT = () =>{
    if(instantActive){
        setShowModalPutOnsale(false);
        setInstantsaleModal(true);
    }else{
      setShowModalPutOnsale(false);
      setShowTimedModal(true);
    }
  }
  const handleDayOne = () =>{
    const new_date = moment().add(1, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }
  const handleDayTwo = () =>{
    const new_date = moment().add(2, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }
  const handleDayThree = () =>{
    const new_date = moment().add(3, "d")
    var day = new_date.format('YYYY-MM-DDTHH:mm:ss');
    setDay(day);
  }

  const putOnSaleContract = async() =>{
    setInstantsaleModal(false);
    setOpenLoader(true);
    setMetamaskStatus(true);
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance }=wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    setContractStatus(true);
    setMetamaskStatus(false);
    const puonsale_ = await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId, true).send({from:address});
    
    if(puonsale_.status){
      handlePutonSale();
    }else{
      setOpenLoader(false);
      setContractStatus(false)
      toast("put on sale cancelled")
    }
  
  }

  const handlePutonSale = async() =>{
    setContractStatus(false);
    setCompletionStatus(true);

    let values;
    if(instantActive){
      values={
        type:"instantsale",
        Price:putonSalePrice,
        PutonsaleTime:''
      }
    }else{
      values={
        type:"timedsale",
        Price: putonSalePrice,
        PutonsaleTime: daytime
      }
    }
    const res = await PutonSale(nft._id, values, current_user.token)
    if(res.data.status){
      handlegetnft();
      setOpenLoader(false);
      setShowTimedModal(false);
      setCompletionStatus(false)
      setShowModalPutOnsale(false);
      setInstantsaleModal(false);
      setShowBidforsaleModal(false);
      window.location.reload();
      toast("Put on sale success")
    }else{
      toast("Put on sale failed")
    }
  }


  const handlegetnft = async()=>{
    const res = await getnftbyid(item._id)
    if(res.data.status){
      setNft(res.data.result)
    }
  }

  const handleDelist = async() =>{
    setOpenLoader(true);
    setMetamaskStatus(true);
    const { web3,marketplace,restaurant,address,usdcInstance,usdtInstance,wrappedMatic}=wallet;
    var NftContracts = new web3.eth.Contract(
      templeteabi,
      nft && nft.contractAddress
    );
    try{
      setMetamaskStatus(false);
      setContractStatus(true);
      const putonsale_ = await NftContracts.methods.approve(process.env.REACT_APP_OKU_GOVERNANCE,nft.TokenId,false).send({from:address});
      setContractStatus(false);
      setCompletionStatus(true);
      const res = await delistNft(nft._id);
      if(res.data.status){
        setCompletionStatus(false);
        window.location.reload();
        setOpenLoader(false);
        toast("delist success")
      }else{
        toast("delist failed")
      }
    }catch(err){
      setOpenLoader(false);
      setMetamaskStatus(false);
      setContractStatus(false);
      toast("Delist nft cancelled")
    }
  }
  
  const renderBtnBuy = () => {
    if(item.status == 'sold'){
      return (
        <div className='flex flex-col justify-end'>
          { item.walletAddress && wallet.address == item.walletAddress ? 
           <Button
           width='100%'
           height='32px'
           bgHover='black'
           textColorHover='white'
           textColor='black'
           className='h-6'
           fontSize="10px"
           backgroundColor='lightgray'
           label={"Put on Sale"}
           onClick={()=> handleNftClick(item)}  
         />
         :
            <Button
            width='100%'
            height='32px'
            bgHover='black'
            textColorHover='white'
            textColor='black'
            className='h-6'
            fontSize="10px"
            backgroundColor='lightgray'
            label={"Make Offer"}
            onClick={() =>  handleNftClick(item)}
          />
        }
         
        </div>
      );
    }else if(item.status == 'onsale'){
      return (
        <div className='flex flex-col justify-end'>
          { item.walletAddress && wallet.address == item.walletAddress ? 
           <Button
           width='100%'
           height='32px'
           bgHover='black'
           textColorHover='white'
           textColor='black'
           className='h-6'
           fontSize="10px"
           backgroundColor='lightgray'
           label={"Delist"}
           onClick={()=>handleNftClick(item) }
         />
         :
            <Button
            width={item.PutonsaleTime ? '100%':'100%'}
            height='32px'
            bgHover='black'
            textColorHover='white'
            textColor='black'
            className='h-6'
            fontSize="6px"
            backgroundColor='lightgray'
            label={item.PutonsaleTime ? "Place Bid" : "Buy"}
            onClick={() =>  handleNftClick(item)}
          />
        }
         
        </div>
      );
    }else{
      return (
        <div className='flex flex-col justify-end'>
            <Button
            width='100%'
            height='32px'
            bgHover='black'
            textColorHover='white'
            textColor='black'
            className='h-6'
            fontSize="10px"
            backgroundColor='lightgray'
            label={"Buy"}
            onClick={ () => handleNftClick(item) }
          />         
        </div>
      );
    }
    
  };

  return (
    <div  style={{height:"100%",}} className={ `bg-layoutBg hover:bg-layoutBg/95 transition ease-in-out duration-500 rounded flex flex-col justify-between cursor-pointer ${wrapperClass}` }>
      <div className=' rounded'>
        {/* <img onClick={ () => handleNftClick(item) } className='w-full h-[170px]' src={ `${process.env.REACT_APP_S3_URL}${item.NFTImage}` } alt='Sunset in the mountains' style={{objectFit:"cover"}}/> */}
        <img onClick={ () => handleNftClick(item) } className='w-full h-[170px]' src={ `${item.NFTImage}` } alt='Sunset in the mountains' style={{objectFit:"cover"}}/>
        <div className='px-6 py-4'>
          <div onClick={ () => handleNftClick(item) } className='font-bold text-xl mb-3 text-dark font-Lora'>{item.NFTName.length > 20 ? item.NFTName.slice(0,20)+"..."  : item.NFTName}</div>
          <div className='flex space-x-2 mb-2.5'>
            <Text onClick={ () => handleNftClick(item) } text='Owned by' className='font-bold text-dark' fontSize='14px' color='black' />
            <Text onClick={ () => handleNftClick(item) } text={ item.OwnedBy?.length > 15 ? item.OwnedBy.slice(0,15)+'...'  : item.OwnedBy} fontSize='14px' className=' text-dark font-bold' color='darkgrey' />
          </div>
          <div className='flex w-full justify-between items-center mb-3 '>
            <div onClick={ () => handleNftClick(item) } className="w-full">
              <Text text='Price' className='opacity-40 mb-1.5' fontSize='14px' color="black" />
              <div className='flex justify-between'>
                <div className='flex justify-between '>
                  <img src={ Icons.polygon } className='mr-1' alt='currency' />

                  <Text text={(Number(item.NFTPrice)/maticToUsd).toFixed(2)} className='font-extrabold mr-2' color='black' />
                  <img src={ Icons.dollar } className='mr-1' style={{width:"20px", height:"20px"}} alt='currency' />
                  <Text text={item.NFTPrice} className='font-extrabold ' color='black' />
                </div>
                <div className='' style={{display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                
                  <img src={Icons.likes} alt="like"></img>
                  {item.Likes ?
                    <Text text={item.Likes.length} className='font-extrabold mr-1'  color='black'/>
                    :
                    <Text text={"0"} className='font-extrabold mr-1' color='black' />
                  }
                </div>  
              </div>
              
            </div>
            
          </div>
          { renderBtnBuy() }
        </div>
      </div>
      <Modal
        visible={ showModalPutonsale }
        onClose={ () =>{ setShowModalPutOnsale(false);} }
        contentClassName='w-full xs:w-[540px] h-full xs:h-[650px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Put On Sale' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='500' fontSize='26px' />

            <div className='flex space-x-4  border-solid border-[1px] border-b-netralGrey rounded-md'>
              <div className='rounded-md overflow-hidden to-transparent w-[100px]'>
                <img src={nft.NFTImage}alt='profile' className='h-[100px] w-[150px]  rounded-md' />
              </div>
              <div className='w-full'>
                <Text text={nft.NFTName} color={Colors.darkBlue} fontFamily='lora' fontWeight='500' fontSize='18px' className="mb-2" />
                <div className='flex'>
                    <img src={ current_user.user && current_user.user.profilePicture ? `${process.env.REACT_APP_S3_URL}${current_user.user && current_user.user.profilePicture}`: Images.Profile }  alt='currency'className='mr-1 w-6 h-6'  />
                    <Text text={"@" + current_user.user &&  current_user.user ?  current_user.user.userName : ""} color='black' fontFamily='lora' fontWeight='500' fontSize='16px' />
                </div>
                <div className='flex items-center justify-between space-x-2 mr-5'>
                  <Text text='Last Sold' fontWeight='300' color='black' />
                  <div className='flex'>
                    {nft.paymentMethod == "matic" ?
                    <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {nft.paymentMethod == "usdt" ?
                    <img src={ Images.Usdt }  alt='currency'className='mr-1'  />
                    :null
                    }
                    {nft.paymentMethod == "usdc" ?
                    <img src={ Images.USDollar }  alt='currency'className='mr-1'  />
                    :null
                    }
                    <Text text={nft.NFTPrice+" "+(nft && nft.paymentMethod && nft.paymentMethod)}  fontWeight='700' color='black' />
                  </div>
                </div>
                <div className='flex items-center justify-between  space-x-2 mr-5'>
                  <Text text='Owned by' fontWeight='300' color='black' />
                  <Text text={nft.OwnedBy}  fontWeight='700' color='black' />
                </div>
              </div>
            </div>

            <div className='pb-5 border-solid border-b-[1px] border-b-netralGrey mb-1'>
              <div className='flex items-center justify-between mt-5'>
                <Text text='Set Reserve Price' color='black' fontWeight='700' />
              </div>
              <div className='flex justify-between'>
                <div className='flex flex-start space-between mt-1'>
                  <div className='flex items-center ml-2'>
                    <Text text={`Make sure  you have enough polygon to perform the network fee`} color='black' fontWeight='300' fontSize='15px' />
                  </div>
                </div>
               
              </div>
             
              <div className='inbutblock flex w-full xs:w-[300px]  items-center justify-between mt-2  border-solid border-[1px] border-netralGrey rounded-md h-10 '>

                <input textColor="black" className='inputbox' placeholder='0' onChange={(e) => setPutonsalePrice(e.target.value)}/>
                <div className='flex mr-4 '>
                  <img src={ Icons.dollar }   alt='currency'className='mr-1 w-5 h-5'  />
                  <Text text='USD' color='black' fontWeight='800' fontSize='16px' />
                </div>
              </div>
              
            </div>

            <Text
              color='black'
              fontWeight='800'
              text='Select Listing options' 
              fontSize='16px'
              className='mb-2'
            />
             <Text
              color='black'
              fontWeight='300'
              text='Timed auction allows you to set time range for collectors to bid on action' 
              fontSize='16px'
            />
            <div style={{ minHeight:"80px", display:"flex", padding:"0 10%"}} className="mt-2">
              <div style={{backgroundColor: instantActive ? "#141E30" :"white", minHeight:"100%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{setInstantActive(true); setTimedActive(false)}}
              >
              <img src={instantActive ? Images.instantgreen : Images.instantblack  }  alt='currency'/>
                <h5 style={{color: instantActive? Colors.okuGreen :"black"}}>Instant Sale</h5>
              </div>
              <div style={{backgroundColor:timedActive ? "#141E30" :"white", minHeight:"100%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{setInstantActive(false); setTimedActive(true)}}
              >
              <img src={ timedActive ? Images.timedgreen : Images.timedblack }  alt='currency' />
                <h5 style={{color: timedActive? Colors.okuGreen :"black"}}>Timed Auction</h5>
              </div>
            </div>
            <Text
              color='black'
              fontWeight='800'
              text='Auction Days' 
              fontSize='18px'
            />
            <div style={{ minHeight:"40px", display:"flex", padding:"0 10%"}} className="mt-3">
              <div style={{backgroundColor: oneday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayOne();setOneday(true); setTwoday(false); setThreeday(false)}}
              >
                <h5 style={{color: oneday? Colors.okuGreen :"black"}}>1 Day</h5>
              </div>
              <div style={{backgroundColor:twoday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayTwo();setOneday(false); setTwoday(true); setThreeday(false)}}
              >
                <h5 style={{color: twoday? Colors.okuGreen :"black"}}>2 Days</h5>
              </div>
              <div style={{backgroundColor:threeday ? "#141E30" :"white", minHeight:"70%", width:"50%", borderRadius:"1em", margin:"0 5%", border:"1px solid lightgray", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}
                onClick={() =>{handleDayThree();setOneday(false); setTwoday(false); setThreeday(true)}}
              >
                <h5 style={{color: threeday? Colors.okuGreen :"black"}}>3 Days</h5>
              </div>
            </div>

            <Button label='List my NFT' disabled={!putonSalePrice || putonSalePrice < 0} className='w-full mt-2' width='100%' onClick={()=> handleListMyNFT()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      
      <Modal
        visible={ instantsaleModal }
        onClose={ () =>{ setInstantsaleModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[400px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Fixed Price' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5 border-solid  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-5'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' disabled={true} placeholder='0' defaultValue={putonSalePrice && (Number(putonSalePrice) /  maticToUsd).toFixed(2)}/>
                <img src={ Icons.polygon }   alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-5'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" defaultValue={putonSalePrice && putonSalePrice} disabled={true} className='pricebox ml-2' placeholder='$0' />
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%' onClick={() => putOnSaleContract()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>
      <Modal
        visible={ showTimedmodal }
        onClose={ () =>{ setShowTimedModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[550px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Timed Auction' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Method`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <select className='pricebox'>
                    <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' defaultValue={putonSalePrice && (Number(putonSalePrice) /  maticToUsd).toFixed(2)} placeholder='0'/>
                <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" defaultValue={putonSalePrice && putonSalePrice} className='pricebox ml-2' placeholder='$0' />
              </div>

              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Date`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input id="meeting-time" name="meeting-time"   type="datetime-local" value={daytime ? daytime : ''} className='pricebox ml-2' onChange={(e) => setDay(e.target.value)}/>
             
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%' onClick={() => putOnSaleContract()}/>
            
          </div>
        </PutonsaleModal>
      </Modal>

      <Modal
        visible={ showBidforsalemodal }
        onClose={ () =>{ setShowBidforsaleModal(false);} }
        contentClassName='w-full xs:w-[500px] h-full xs:h-[550px]'
      >
        <PutonsaleModal>
          <div className=''>
            <Text text='Timed Auction' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='800' fontSize='26px' />

            <div className='pb-5  mb-1'>
              
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Method`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <select className='pricebox'>
                    <option>Sell to Highest bidder</option>
                </select>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Price`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
              </div>
             
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' placeholder='0'/>
                <img src={ Icons.polygon }  alt='currency'className='mr-1'  />
                <Text text={`Matic`} color='black' fontWeight='700' fontSize='16px' className="mr-5"/>
              </div>
              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`USD`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input textColor="black" className='pricebox ml-2' placeholder='$0' />
              </div>

              <div className='flex justify-start'>
                <div className='flex flex-start space-between mt-2'>
                  <div className='flex items-center'>
                    <Text text={`Date`} color='black' fontWeight='300' fontSize='17px' />
                  </div>
                </div>
               
              </div>
              <div className='flex items-center justify-start mt-2 w-full border-solid border-[1px] border-netralGrey rounded-md'>
                <input type={"date"}  textColor="black" className='pricebox ml-2'  />
              </div>
              
            </div>
            <Button label='List for Sale' className='w-full mt-2' width='100%'/>
            
          </div>
        </PutonsaleModal>
      </Modal>
      {openLoader && 
      <Modal visible={true} onClickClose={() =>setOpenLoader(false)}>
        <ModalStyles>
            <Text
                text={ 'Wallet Action' }
                color='black'
                fontType={ 'p' }
                fontWeight={ '700' }
                fontSize={ '28px' }
                fontFamily={ 'Lora' }
                className='title'
            />
            
                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group29 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Request sent to wallet"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {metamaskStatus &&
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                        </div>
                    
                    
                </div>

                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group28 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Transaction Underway"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {contractStatus && 
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                        </div>
                    
                    
                </div>
                <div
                    className='card'  
                >
                    <div className='mr-5'>
                        <img src={ group27 } width={ 30 } height={ 30 } />
                    </div>
                    <div className=''>
                        <Text
                            text={"Waiting for Confirmation"}
                            color='black'
                            fontWeight={ '700' }
                            fontType={ 'h5' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='ml-5'>
                      {completionStatus && 
                        <img src={ loadiing } width={ 30 } height={ 30 } />
                      }
                      </div>
                </div>
        </ModalStyles>
      </Modal>
      }
    </div>
  );
};

export default NFTCard;
