import styled from "styled-components";
import { Images } from "constant";

const MarketplaceStyle = styled.div`
  height: fit-content;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  .input-search input {
    color: black;
  }

  .banner-page {
    background: url(${Images.BgMainPage});
  }
`;

export default MarketplaceStyle;
