import styled from 'styled-components';

const NFTCollectionStyle = styled.div`
.wrapper1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.item {
    margin: 20px 20px 0 0;
    width: 280px;
}

@media (max-width: 705px) { 
    .wrapper1 {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
    }
  }
`;

export default NFTCollectionStyle;