import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Images } from 'constant';
import { PageContruction, Header, Footer } from 'pages/LandingPageNew/Components';
import { handleOpenSocmed } from 'components/Socmed';

const list = [
	'Be the first to be notified of our first launch!',
	'Influence and vote on what amenities we should provide in our Club Houses',
	'Get discounts on food and beverages purchased in any Oku Club House'
];
const ClubHouse = () => {
	const navigate = useNavigate();

	const [usertokencheck, setusertokencheck] = useState(false);

	useEffect(() => {
		const token = localStorage.getItem("token")
		if (token) {
			setusertokencheck(true)
		} else {
			setusertokencheck(false)
		}
	}, [])
	return (
		<div className='bg-white'>
			<Header />
			<div className='h-[100px]' />
			<div className='w-full m-auto md:px-20 container pb-5 md:py-16'>
				<PageContruction
					Image={Images.ClubHouse}
					Title='Oops.. This page is still under construction'
					Subtitle={[
						'Just like this page, our Club Houses are currently under construction. Did you know that you get to help us shape how our Club Houses look?!'
					]}
					Heading='join our oku community to...'
					listString={list}
					TopBtn={`${usertokencheck ? '' : 'Create an account'}`}
					BottomBtn='We’re on Discord!'
					urlTop={() => navigate('/sign-up')}
					urlBottom={() => handleOpenSocmed('https://discord.gg/okuhouse')} />
			</div>
			<Footer />
		</div>
	);
};

export default ClubHouse;