import { Tab } from "@headlessui/react";
import { Button, Text } from "components";
import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../../selectors";
import { email } from "helpers/validation";
import {
  getuser,
  updatemembershipprofile,
  updatemembership,
} from "../../../../../services/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";

const tabs = [
  {
    name: "Your code",
  },
  {
    name: "Your details",
  },
  {
    name: "Select a location",
  },
  {
    name: "Your story",
  },
];

const locations = [
  "Oku House Singapore",
  "Oku House Jakarta",
  "Oku House Bali",
];

const discovers = [
  "Instagram",
  "Twitter",
  "Facebook",
  "Google Ads",
  "Referral",
  "Other",
];

const features = ["Art & Design", "Fashion", "Music", "Food", "Other"];

const Profile = ({ handleNextPage }) => {
  const navigate = useNavigate();

  const user = useSelector(selectors.userProfile);
  const getCurrentuser = async () => {
    if (user.user.id) {
      const res = await getuser(user.user.id);
      if (res.data.status) {
        const eve = res.data.result;

        setusername(eve.membershipname ? eve.membershipname : eve.userName);
        setuseremail(eve.email);
        setuserdob(eve.membershipdob ? eve.membershipdob : eve.userDOB);
        setuserinfo(eve.membershipaddressed ? eve.membershipaddressed : "");
        setuserlocation(eve.membershiplocation ? eve.membershiplocation : "");
        setSelectedLocation(
          eve.membershipclubhouselocation ? eve.membershipclubhouselocation : ""
        );
        setuserpatner(
          eve.membershippartnerclubhouse ? eve.membershippartnerclubhouse : ""
        );
        setreferral(eve.membershipreferral ? eve.membershipreferral : "");
        setother(eve.membershipother ? eve.membershipother : "");
        setSelectedDiscovers(
          eve.membershipdiscoverclubhouse ? eve.membershipdiscoverclubhouse : ""
        );
        setSelectedFeatures(
          eve.membershipexcitedclubhouse ? eve.membershipexcitedclubhouse : ""
        );
        setuserstory(eve.membershipstory ? eve.membershipstory : "");
        setreferralid(eve.referralid ? eve.referralid : "");
      }
    } else {
      navigate("/sign-in");
    }
  };

  useEffect(() => {
    if (user) {
      getCurrentuser();
    }
  }, [user]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState();
  const [selectedDiscovers, setSelectedDiscovers] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [username, setusername] = useState("");
  const [useremail, setuseremail] = useState("");
  const [userdob, setuserdob] = useState("");
  const [userinfo, setuserinfo] = useState("");
  const [userlocation, setuserlocation] = useState("");
  const [userpatner, setuserpatner] = useState("");
  const [referral, setreferral] = useState("");
  const [other, setother] = useState("");
  const [userstory, setuserstory] = useState("");
  const [referralid, setreferralid] = useState("");

  const handleNext = () => {
    if (selectedIndex === 3) {
      if (selectedDiscovers.length > 0 && selectedFeatures.length > 0) {
        if (
          selectedDiscovers.filter((e) => e.includes("Referral")).length > 0
        ) {
          if (referral) {
            if (
              selectedDiscovers.filter((e) => e.includes("Other")).length > 0
            ) {
              if (other) {
                setSelectedIndex(5);
              } else {
                toast("Please fill all the fields");
              }
            } else {
              setSelectedIndex(5);
            }
          } else {
            toast("Please fill all the fields");
          }
        } else {
          if (selectedDiscovers.filter((e) => e.includes("Other")).length > 0) {
            if (other) {
              setSelectedIndex(5);
            } else {
              toast("Please fill all the fields");
            }
          } else {
            setSelectedIndex(5);
          }
        }
      } else {
        toast("Fill All The Fields");
      }
    } else if (selectedIndex === 5) {
      savedatasandroute();
      // if (userstory) {
      // } else {
      // 	toast("Please fill all the fields")
      // }
    } else {
      if (selectedIndex === 1) {
        if (username && useremail && userdob && userinfo) {
          setSelectedIndex(selectedIndex + 1);
        } else {
          toast("Please Fill All The Fields");
        }
      } else if (selectedIndex === 0) {
        if (referralid) {
          referralverification(referralid);
        } else {
          // setSelectedIndex(selectedIndex + 1); // disable in prod
          toast("Please Fill All The Fields");
        }
      } else {
        if (userlocation && selectedLocation) {
          setSelectedIndex(selectedIndex + 1);
        } else {
          toast("Please Fill All The Fields");
        }
      }
    }
  };

  const handleBack = () => {
    if (selectedIndex > 0) {
      setSelectedIndex((prev) => prev - 1);
    }
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 0).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyys = today.getFullYear();
    const yyyy = yyyys - 10;
    return yyyy + "-" + mm + "-" + dd;
  };

  const savedatasandroute = async () => {
    const values = {
      membershipname: username,
      membershipdob: userdob,
      membershipaddressed: userinfo,
      membershiplocation: userlocation,
      membershipclubhouselocation: selectedLocation,
      membershipdiscoverclubhouse: selectedDiscovers,
      membershipexcitedclubhouse: selectedFeatures,
      membershippartnerclubhouse: userpatner,
      membershipother: other,
      membershipreferral: referral,
      membershipstory: userstory,
      refferalverfiystatus: "success",
      referralid: referralid,
    };
    const userprofile = await updatemembershipprofile(user.user.id, values);
    if (userprofile.data.status) {
      handleNextPage();
    }
  };

  const referralverification = async (referralids) => {
    // /sign-in
    const values = {
      refferalverfiystatus: "success",
      referralid: referralids,
    };
    const userprofile = await updatemembership(user.user.id, values);
    if (userprofile.data.status) {
      toast("Referral Id Verified Successfully");
      setSelectedIndex(selectedIndex + 1);
    } else {
      toast(userprofile.data.message);
    }
  };
  return (
    <div className="lg:flex-1">
      <Text fontType="h2" fontSize="45px" fontWeight={600} color="#000000">
        Your Profile
      </Text>

      <Tab.Group
        manual={true}
        selectedIndex={selectedIndex == 5 ? 3 : selectedIndex}
        as="div"
        className=""
      >
        <Tab.List
          as="div"
          className="flex overflow-x-auto items-center mt-8 gap-5 lg:gap-16"
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                onClick={(e) => {
                  e.preventDefault();
                }}
                key={index}
                className={({ selected }) =>
                  `focus:outline-none flex-shrink-0 cursor-default pb-2 border-b-2 ${
                    selected ? "border-okuGrowth" : "border-transparent"
                  }`
                }
              >
                <Text
                  fontType="h3"
                  className="uppercase"
                  fontSize="18px"
                  fontWeight={700}
                >
                  {tab.name}
                </Text>
              </Tab>
            );
          })}
        </Tab.List>
        <Tab.Panels as="div" className="h-full mt-10 ">
          <>
            <Tab.Panel>
              <p className="mt-4 text-[15px] leading-[18px] text-black font-AcuminPro tracking-wider">
                Oku House requires all members to sign up with an invite code.
                <br />
                Everyone who joins our platform is given 3 invites, so ask a
                friend or chat with our team on Discord.
              </p>
              <div className="mt-10">
                <Input
                  label="insert your INVITE code"
                  value={referralid}
                  onChange={(e) => setreferralid(e.target.value)}
                  placeholder="INSERT CODE HERE"
                  className="max-w-lg"
                />
              </div>

              <p className="mt-10 text-[15px] leading-[18px] text-abundance font-AcuminPro tracking-wider uppercase">
                Don’t have a code?
              </p>
              <p className="mt-3 text-[15px] leading-[18px] text-black font-AcuminPro tracking-wider">
                Chat with us on Discord to receive your invite code.
              </p>
              <Button
                onClick={() =>
                  window.open("https://discord.gg/okuhouse", "_blank")
                }
                theme="primary-outline"
                className="mt-3"
              >
                Join Discord
              </Button>
            </Tab.Panel>
            <Tab.Panel>
              <div className="grid grid-cols-2 gap-x-6 gap-y-8 lg:w-1/2">
                <Input
                  value={username}
                  onChange={(e) => setusername(e.target.value)}
                  placeholder="Jhon"
                  label="FIRST NAME*"
                />
                <Input
                  value={userinfo}
                  onChange={(e) => setuserinfo(e.target.value)}
                  placeholder="Doe"
                  label="LAST NAME*"
                />
                <div className="col-span-2">
                  <Input
                    value={useremail}
                    disable={true}
                    // onChange={(e)=>setuseremail(e.target.value)}
                    placeholder="michaelcheung@gmail.com"
                    type="email"
                    label="Email Address*"
                  />
                </div>
                <Input
                  value={userdob}
                  max={disablePastDate()}
                  onChange={(e) => setuserdob(e.target.value)}
                  placeholder="dd/mm/yyy"
                  type="date"
                  label="Date of birth*"
                />
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:grid-flow-col gap-5 lg:gap-x-14 lg:gap-y-8">
                <Input
                  value={userlocation}
                  onChange={(e) => setuserlocation(e.target.value)}
                  placeholder="City, Country"
                  label="Where are you located?*"
                />
                <Select
                  selected={selectedLocation}
                  setSelected={setSelectedLocation}
                  options={locations}
                  placeholder="Select your Oku Club House Location"
                  label="choose your clubhouse location*"
                />
                <Text fontType="p" fontSize="16px" fontWeight={400}>
                  Don’t worry if you don’t have a Oku Club House in your
                  neighborhood! You can still be part of our community and visit
                  any of our Oku Club Houses with your membership!
                </Text>
              </div>
            </Tab.Panel>
            {selectedIndex && selectedIndex == 5 ? (
              <>
                <Tab.Panel>
                  <div className="grid grid-cols-2 grid-rows-3 grid-flow-col gap-x-14 gap-y-8">
                    <div className="row-span-3">
                      <TextArea
                        onChange={(e) => {
                          setuserstory(e.target.value);
                        }}
                        value={userstory}
                        rows={10}
                        placeholder="Tell us your story / interests / hobbies! "
                        label="is there anything you want us to know about you? (Optional)"
                      />
                    </div>
                  </div>
                </Tab.Panel>
              </>
            ) : (
              <>
                <Tab.Panel>
                  <div className="flex flex-col lg:grid lg:grid-cols-2 lg:grid-rows-3 lg:grid-flow-col gap-5 lg:gap-x-14 gap-y-8">
                    <Select
                      selected={selectedDiscovers}
                      setSelected={setSelectedDiscovers}
                      isMultiple
                      options={discovers}
                      placeholder="Choose as many as you like"
                      label="How did you discover oku club house? *"
                    />
                    <Select
                      selected={selectedFeatures}
                      setSelected={setSelectedFeatures}
                      isMultiple
                      options={features}
                      placeholder="Choose as many as you like"
                      label="What are you most excited to see in oku club house? *"
                    />
                    <Input
                      value={userpatner}
                      onChange={(e) => {
                        setuserpatner(e.target.value);
                      }}
                      placeholder="Drop a name or IG handle"
                      label="Are there any partners you want oku club house to work with? (Optional)"
                    />
                    {selectedDiscovers.filter((e) => e.includes("Referral"))
                      .length > 0 && (
                      <Input
                        value={referral}
                        onChange={(e) => setreferral(e.target.value)}
                        placeholder="thom.eng@oku.house"
                        label="Who referred you?"
                      />
                    )}
                    {selectedDiscovers.filter((e) => e.includes("Other"))
                      .length > 0 && (
                      <Input
                        value={other}
                        onChange={(e) => setother(e.target.value)}
                        placeholder="Type answer here"
                        label="Elaborate “other”"
                      />
                    )}
                  </div>
                </Tab.Panel>
              </>
            )}
            <div className="flex justify-end gap-3 w-full mt-12 lg:pr-40">
              {selectedIndex !== 0 && (
                <Button
                  onClick={() => handleBack()}
                  disabled={selectedIndex === 0}
                  theme="primary-outline"
                >
                  Back
                </Button>
              )}
              <Button onClick={() => handleNext()} theme="primary-outline">
                {selectedIndex === 5 ? "Confirm" : "Continue"}
              </Button>
            </div>
          </>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Profile;
