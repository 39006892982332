import { IReducer } from 'interface';

export const SET_PROFILE = 'SET_PROFILE';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const SET_RESTAURANT = 'SET_RESTAURANT';
export const SET_NFT = 'SET_NFT';
export const SET_WALLET_ADDRESS ="SET_WALLET_ADDRESS";


// export interface SetProfile {
//   type: typeof SET_PROFILE
//   payload: IReducer.UserProfile
// }

// export type UserActionTypes = SetProfile