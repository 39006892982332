import React, { useState } from 'react';
import { Button, Input, Text } from 'components';
import { Images } from 'constant';

import InputEmail from './Sections/InputEmail';
import InputPhoneNumber from './Sections/InputPhoneNumber';
import ForgotPasswordStyle from './style';
import { Layout } from 'pages/LandingPageNew/Components';

import { forgotPasswordotp } from '../../services/auth';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = () => {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const [nextstep, setnextstep] = useState(false)

  const closemodal=()=>{
    setnextstep(false);
  }
  
  const next = async() => {

     const values = {
			email:email,
			};
     const forgetpasswors = await forgotPasswordotp(values)
     if(forgetpasswors.data.status){
      toast(forgetpasswors.data.message)
      setnextstep(true)
      // setStep(step + 1);
     }else{
      toast(forgetpasswors.data.message)
     }
    
  };

  const prev = () => {
    setStep(step - 1);
  };


  return (
    <Layout>
      <ForgotPasswordStyle>
        <div className='right-side'>
          <Text
            text={ 'Forgot Password' }
            fontWeight={ '700' }
            fontType={ 'h4' }
            className={ '' }
            textAlign={ 'center' }
          />
          {/* { step === 0 ? */}
            <InputEmail
              next={ next }
              email={email}
              setEmail={setEmail}
              nextstep={nextstep}
              closemodal={closemodal}
            />
            {/* :
            <InputPhoneNumber
              prev={ prev }
            />
          } */}
        </div>
      </ForgotPasswordStyle>
    </Layout>
  );
};

export default React.memo(ForgotPassword);
