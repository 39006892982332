import React, { useState, useEffect } from 'react';

import { ToggleBtn, Text } from 'components';

import AuthenticationForm from 'pages/Setting/Components/AuthenticationForm';
import SecurityStyle from './style';
import {sendtwofacode, getuser, disabletwofactorAuth} from '../../../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';
import { toast } from 'react-toastify';

const Security = () => {
  const [showForm, setShowForm] = useState(false)
  const current_user = useSelector(selectors.userProfile);

  const handleClickToggle = () =>{
    handleSendCode();
  }
 

  const [User, setUser] = useState('');

  const handlegetuser = async() =>{
    const res = await getuser(current_user.user.id);
    if(res.data.status){
      setUser(res.data.result);
    }
  }
 
  useEffect(() =>{
      handlegetuser()
  },[])
  const handleSendCode = async() =>{
    const res =  await sendtwofacode(current_user.user.id);

    if(res.data.status){
      setShowForm(!showForm);
    }
  }
  return (
    <SecurityStyle>
      <div className='context'>
        <div className='subtitle flex-1 min-w-0'>
          <Text
            text={ User.TwofactorAuth == 'enabled' ? 'Disable Google 2FA' : 'Enable Google 2FA' }
            fontSize={ '20px' }
            fontWeight={ '800' }
            color="black"
          />
          <Text
            text={ 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Est gravida nulla ut ultrices eu vitae tristique. Diam commodo aliquet pellentesque proin.' }
            fontSize={ '16px' }
            fontWeight={ '300' }
            fontType={ 'p' }
            color="black"
          />
        </div>
        <div className='inline-flex items-center'>
          <ToggleBtn
            handleClickToggle={  handleClickToggle }
            checked={User.TwofactorAuth}
          />
        </div>
      </div>
      { showForm === true ?
        <div className='content'>
          <div className='auth-form mt-8'>
            <AuthenticationForm />
          </div>
        </div>
        : null }
    </SecurityStyle>
  );
};

export default Security;