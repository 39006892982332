import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";

import icons from "constant/icons";
import { Icons, Images } from "constant";
import { Text } from "components";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../selectors";
import { connectFailed } from "../../stores/User/WalletAction";
import { getuser } from "../../services/auth";

const arr = [
  // {
  //   icons: Icons.myNft,
  //   title: 'My NFTs',
  //   className: 'filter-black',
  // },
  {
    icons: Icons.ProfileIco,
    title: "Profile",
    className: "filter-black",
  },
  // {
  //   icons: Icons.Member,
  //   title: 'Membership',
  //   className: 'filter-black',
  // },
  // {
  //   icons: Icons.Star,
  //   title: 'Favourite',
  //   className: 'filter-black',
  // },
  // {
  //   icons: Icons.transactionHistory,
  //   title: 'Transaction History',
  //   className: 'filter-black',
  // },
  // {
  //   icons: Icons.ProfileIco,
  //   title: 'Setting',
  //   className: 'filter-black',
  // },
  {
    icons: Icons.logout,
    title: "Logout",
  },
];

const ProfilePopup = ({ array }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.WalletConnect);
  const user = useSelector(selectors.userProfile);
  const [newuser, setNewUser] = useState("");

  const copyAddress = () => {
    var copyText = wallet.address;
    navigator.clipboard.writeText(copyText);
  };
  const getCurrentuser = async () => {
    const res = await getuser(user.user.id);
    if (res.data.status) {
      setNewUser(res.data.result);
    }
  };
  useEffect(() => {
    getCurrentuser();
  }, []);

  const renderMenu = () => {
    return arr.map((el, idx) => {
      return (
        <div className="px-px" key={idx}>
          <Menu.Item>
            {({ active }) => (
              <button
                className={
                  "h-[62px] pl-[20px] group flex w-full items-center rounded-md px-1 text-sm  border-solid border-[1px] border-white/10"
                }
                style={{
                  backgroundColor: "#ffffff",
                  color: "black !important",
                }}
                onClick={() => {
                  switch (el?.title) {
                    case "My NFTs":
                      navigate("/myprofile");
                      break;
                    case "Profile":
                      navigate("/profile");
                      break;
                    case "Membership":
                      navigate("/membership");
                      break;
                    case "Favourite":
                      navigate("/myprofile?favourite");
                      break;
                    case "Transaction History":
                      navigate("/myprofile?Transaction History");
                      break;
                    case "Setting":
                      navigate("/setting");
                      break;
                    case "Logout":
                      if (wallet.address) {
                        const { web3Modal } = wallet;
                        web3Modal.clearCachedProvider();
                        dispatch(connectFailed());
                      }
                      dispatch({
                        type: "SET_PROFILE",
                        payload: "",
                      });
                      localStorage.removeItem("token");
                      localStorage.clear();
                      //** */ navigate('/marketplace')
                      navigate("/");
                      break;
                    default:
                      break;
                  }
                }}
              >
                {active ? (
                  <img
                    src={el.icons}
                    className={`mr-3 ${el.className && el.className}`}
                    alt="bell"
                  />
                ) : (
                  <img
                    src={el.icons}
                    className={`mr-3 ${el.className && el.className}`}
                    alt="bell"
                  />
                )}
                <Text text={el.title} fontWeight="800" color="black" />
              </button>
            )}
          </Menu.Item>
        </div>
      );
    });
  };

  return (
    <div className="md:mr-10 lg:mr-10 xl:mr-[60px] md:ml-20 lg:ml-20">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md lg:mt-0 md:mt-0 mt-[5px] bg-opacity-20 text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            {newuser?.profilePicture != undefined && newuser?.profilePicture ? (
              <img
                src={
                  newuser?.profilePicture != undefined &&
                  newuser?.profilePicture
                    ? `${process.env.REACT_APP_S3_URL}${newuser.profilePicture}`
                    : Images.ProfilePicture
                }
                alt="profile"
                style={{ height: "4em", width: "4em", borderRadius: "2em" }}
              />
            ) : (
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="14" stroke="black" strokeWidth="2" />
                <path
                  d="M15.1577 12.4085C17.4539 12.4085 19.3154 10.5262 19.3154 8.20427C19.3154 5.88232 17.4539 4 15.1577 4C12.8615 4 11 5.88232 11 8.20427C11 10.5262 12.8615 12.4085 15.1577 12.4085Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
                <path
                  d="M4 23.827C4 17.8475 8.79379 13 14.707 13C20.6203 13 25.4141 17.8475 25.4141 23.827"
                  stroke="black"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white/10 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-px pb-px">
            <div className="px-px">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={
                      "h-full pl-[20px] text-black group flex w-full items-center rounded-md px-1 text-sm bg-white border-solid border-[1px] border-white/10"
                    }
                  >
                    {newuser?.profilePicture != undefined &&
                    newuser?.profilePicture ? (
                      <img
                        src={
                          newuser?.profilePicture &&
                          newuser?.profilePicture != undefined
                            ? `${process.env.REACT_APP_S3_URL}${newuser.profilePicture}`
                            : Images.ProfilePicture
                        }
                        alt="profile"
                        style={{ height: "4em", width: "4em" }}
                      />
                    ) : (
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="15"
                          cy="15"
                          r="14"
                          stroke="black"
                          strokeWidth="2"
                        />
                        <path
                          d="M15.1577 12.4085C17.4539 12.4085 19.3154 10.5262 19.3154 8.20427C19.3154 5.88232 17.4539 4 15.1577 4C12.8615 4 11 5.88232 11 8.20427C11 10.5262 12.8615 12.4085 15.1577 12.4085Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M4 23.827C4 17.8475 8.79379 13 14.707 13C20.6203 13 25.4141 17.8475 25.4141 23.827"
                          stroke="black"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                      </svg>
                    )}{" "}
                    <div className="ml-3 py-[20px]">
                      <Text
                        text={user && user.user.userName?.slice(0, 10)}
                        fontWeight="800"
                        color="black"
                        onClick={() => navigate("/myprofile")}
                      />
                      <div className="flex items-center space-x-2">
                        <Text
                          text={
                            wallet &&
                            `${wallet.address
                              .toString()
                              .slice(0, 5)}...${wallet.address
                              .toString()
                              .slice(-3)}`
                          }
                          fontWeight="300"
                          color="black"
                          fontSize="14px"
                          onClick={() => navigate("/myprofile")}
                        />
                        <img
                          src={icons.copy}
                          className="cursor-pointer"
                          alt="copy"
                          onClick={() => copyAddress()}
                        />
                      </div>
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
            {renderMenu()}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ProfilePopup;
