import React from 'react';

const CardList = ({
	list
}) => {
	return (
		<div className='overflow-x-auto flex lg:grid lg:grid-cols-3 gap-[20px] lg:gap-[30px] place-content-between px-6 md:mx-auto md:container h-full'>
			{
				list.map((list, index) => (
					<div key={index}>
						<div className='bg-white w-[85vw] md:w-[60vw] lg:w-full h-full p-[30px] flex flex-col space-y-2 md:space-y-3 items-center justify-center'>
							<img alt='' src={list.image} className='h-[290px] w-full' />
							<p className='pt-4 uppercase md:text-2xl text-base font-bold leading-[18px] md:leading-[28px] tracking-widest font-ExpressWay text-darkSecurity text-center'>{list.title}</p>
							<p className='text-darkSecurity text-sm md:text-lg font-AcuminPro leading-[19px] md:leading-6 text-center'>{list.subtitle}</p>
						</div>
					</div>
				))
			}
		</div>
	);
};

export default CardList;
