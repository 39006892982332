import { post , get , put } from "../http";
import axios from 'axios';

const baseUrl =  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "https://okunftbackend.click";
// "https://okunftbackend.click";
// "http://localhost:4000"
export const signUp = async (data) => {
  try {
    const result = await post(`auth/signup`, data);
    return result;

  } catch (error) {
    throw error;
  }
};


export const signIn = async (user) => {
  try {
    const data = await axios.post(`${baseUrl}/auth/login`, user);

    return data;
  } catch (error) {
    console.log("errr:", error)
    throw error;
  }
};


export const loginwithmail = async (user) => {
  try {
  
    const data = await axios.post(`${baseUrl}/auth/loginwithmail`, user);

    return data;
  } catch (error) {
    console.log("errr:", error)
    throw error;
  }
};



export const update_user_wallet = async (id,user) => {
  try {
    const data = await put(`auth/update_user_wallet/${id}`, user);
    return data;
  } catch (error) {
    console.log("errr:", error)
    throw error;
  }
};

export const mailchimpapi =async (user) => {
  try {
    const data = await post(`auth/mailchimpapi`, user);
    return data;
  } catch (error) {
    console.log("errr:", error)
    throw error;
  }
}


export const getuser = async (id) => {
  try {
    const data = await get(`auth/getuser/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const edit_user_profile = async (id, values, tokens) => {
  try {
    const data = await put(`auth/edit_user_profile/${id}`, values, tokens);
    return data;
  } catch (error) {
    throw error;
  }
};

export const updatethewallet = async(id,values)=>{

  console.log("wallets==>",values)
  try{
    const data = await put(`auth/update_user_wallet/${id}`, values);
    return data;
  }catch (error) {
    throw error;
  }
}

export const updatethemembershipwallet = async(id,values)=>{
  try{
    const data = await put(`auth/update_user_membershipwallet/${id}`, values);
    return data;
  }catch (error) {
    throw error;
  }
}

export const updatemembership = async(id,values)=>{
  try{
    const data = await put(`auth/update_user_updatemembership/${id}`, values);
    return data;
  }catch (error) {
    throw error;
  }
}

export const updatemembershipcheck = async(id,values)=>{
  try{
    const data = await put(`auth/update_user_updatemembershipcheck/${id}`, values);
    return data;
  }catch (error) {
    throw error;
  }
}


export const updatemembershipprofile = async(id,values)=>{
  console.log("values==>",values)
  try{
    const data = await put(`auth/update_user_membershipprofile/${id}`, values);
    return data;
  }catch (error) {
    throw error;
  }
}

export const createApplicant = async (value) => {
  try {
    const data = await post(`createApplicant`, value);
    return data;
  } catch (error) {
    
    throw error;
  }
};


export const forgotPassword = async (value) => {
  try {
    const data = await post(`auth/forgotPassword`, value);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const forgotPasswordotp = async (value) => {
  try {
    const data = await post(`auth/forgotPasswordotp`, value);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const userotpverification = async (value) => {
  try {
    const data = await post(`auth/userotpverification`, value);
    return data;
  } catch (error) {
    
    throw error;
  }
};




export const getUserBytoken = async (id) => {
  try {
    const data = await get(`auth/getUserBytoken/${id}`);
    return data;
  } catch (error) {
    
    throw error;
  }
};


export const verifyAccountToken = async (id) => {
  try {
    const data = await post(`auth/verifyAccountToken/${id}`);
    return data;
  } catch (error) {
    
    throw error;
  }
};


export const verifyResetToken = async (id) => {
  try {
    const data = await get(`auth/verifyResetToken/${id}`);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const resetPassword = async (id, values) => {
  console.log("id==>",id)
  // console.log("id==>",JSON.parse(id))
  try {
    const data = await post(`auth/resetPasswords/${id}`, values);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const resetPasswordprofile = async (id, values) => {

  try {
    const data = await post(`auth/resetPasswordprofile/${id}`, values);
    return data;
  } catch (error) {
    
    throw error;
  }
};

export const ListallUsers = async () => {
  try {
   const  data = await get(`auth/listallUsers`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const listallWallets = async () => {
  try {
   const  data = await get(`auth/listallWallets`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllusers = async () => {
  try {
   const  data = await get(`auth/getAllusers`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const sendtwofacode = async (id) => {
  try {
   const  data = await put(`auth/sendtwofacode/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const twofactorAuth = async (id, values) => {
  try {
   const  data = await put(`auth/twofactorAuth/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};


export const disabletwofactorAuth = async (id, values) => {
  try {
   const  data = await put(`auth/disabletwofactorAuth/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

export const verifytwofactorAuth = async (id, values) => {
  try {
   const  data = await put(`auth/verifytwofactorAuth/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};


export const updatetwofauth = async (id, values) => {
  console.log(id,values)
  try {
   const  data = await put(`auth/updatetwofauth/${id}`,values);
    return data;
  } catch (error) {
    throw error;
  }
};






export const register = async ( values) => {
  console.log("register==>",values)
  try {
   const  data = await post(`auth/register`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

export const verify = async ( values) => {
  try {
   const  data = await post(`auth/verify`,values);
    return data;
  } catch (error) {
    throw error;
  }
};

export const validate = async (values) => {
  try {
   const  data = await post(`auth/validate`, values);
    return data;
  } catch (error) {
    throw error;
  }
};
