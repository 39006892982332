import styled from "styled-components";

const Styles = styled.div`
	
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
    overflow:auto;
    max-height:300px

    .sales-history {
        border-bottom: 1px solid gray;
    }
    .table-body{
        color: rgb(255,255,255, 0.7);
        justify-content: center;
        text-align:center;
        padding-top:40px;
        // border-bottom: 1px solid white;
    }
    .table-row{
        
    }
    .table-head{
        color: rgb(255,255,255, 0.5);
        // height: 60px;
        border-bottom: 0.01px solid rgb(47,79,79, 0.5) ;
    }
    .table-row {
        // padding: 20px 0px;
        height: 60px;
        border-bottom: 0.1px solid rgb(47,79,79, 0.5);
    }
    .price{
        display:flex;
        align-items:center;
        justify-content: center;
        height:60px;
    }
    .cusprofile {
        display:flex;
        align-items:center;
        justify-content: flex-start;
        margin-left:30%;
        height:60px;
    }
    .method {
        padding-left: 50px !important;
        text-align: left;
        text-transform: capitalize;
    }
    
`;

export default Styles;

