import styled from 'styled-components';

const RegisterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .input-wrapper {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    min-width: 300px;
  }
`;

export default RegisterStyle;
