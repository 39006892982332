import { createSelector } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from 'stores';

const selectState = (state, key) => state[key];
const selectStore = createSelector([selectState], state => state);

const useTypedSelector = (key) => {
  const selector = useSelector;
  const result = selector(state => selectStore(state, key));
  return result
};

export default useTypedSelector;