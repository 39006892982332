import styled from 'styled-components';

const TabsStyle = styled.div`
  margin-top: 1vh;

  .activeTab {
    color: #FFFBD1;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
  }

  .unactiveTab {
    color: #fff;
    opacity: 0.6;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
  }

  ul {
    border-bottom: 1px solid #424949;
  }

  li {
    margin-right: 60px;
  }

  @media (max-width: 485px) { 
    li {
      margin-right: 20px;
    }
  }
`;

export default TabsStyle;