import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Layout, NFTCollections, Text, Tooltip, Share, Card } from "components";
import selectors from "selectors";
import { Colors, Icons, Images } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsStar, BsStarFill } from "react-icons/bs";
import { AddFavourites, RemoveFavourites } from "services/favourites";
import {
  getRestaurants,
  getNewCollections,
  getEventwithCollections,
  getcollectionnfts,
  NFTgroupcollection,
} from "services/restaurant";
import { SET_RESTAURANT } from "stores/User/userActionTypes";
// import { Header } from 'components';
import { Footer, Header } from "pages/LandingPageNew/Components";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const CollectionsGroup = () => {
  const { state } = useLocation();
  const { id } = useParams();

  const [states, setstates] = useState();
  useEffect(() => {
    // if(!state){
    //   getrefreshdata()
    // }else{
    getdatafromstate();
    // }
  }, []);

  const getdatafromstate = async () => {
    const res = await getcollectionnfts(
      state?.collectionId !== "" ? state.collectionId : id
    );
    if (res.data.result) {
      const array = res.data.result;
      const statedata = array.filter(
        (item) => item._id == state.collectionId || item._id == id
      );
      let newarray = [];
      await statedata.map(async (data) => {
        const unique = [
          ...new Set(data.collectionnfts.map((item) => item.NFTCollections)),
        ];
        let nftgroup = [];
        Promise.all(
          unique.map((item) => {
            const Group = data.collectionnfts.filter(
              (eve) => eve.NFTCollections == item
            );
            if (Group.length > 0) {
              nftgroup.push({
                Nftgroupname: Group[0].NFTCollections,
                NFTcount: Group[0].NFTCount,
                NFTgroupimg: Group[0].NFTImage,
                CollectionName: Group[0].CollectionName,
                NFTS: Group,
              });
            }
          })
        );
        newarray.push({ ...data, nftgroup });
      });
      setstates(newarray[0]);
    }
  };

  // const getrefreshdata=async()=>{
  //   //** */ const result = await getRestaurants();
  //   //** */ const results = await getNewCollections();
  //   const collectionresults = await getEventwithCollections();
  //     if (collectionresults && collectionresults.data.result) {
  //       const filterdata = collectionresults.data.result
  //       const filters = filterdata.filter((item)=> item._id == id)
  //       const stated = state ? state?.data : filters[0]
  //       setstates(stated)
  //     }
  // }

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const cur_restaurant = useSelector(selectors.currentRestaurant);
  const cur_user = useSelector(selectors.userProfile);
  const token = cur_user.token;

  const currentUrl = useMemo(() => {
    return location.pathname.split("/")[2];
  }, [location.pathname]);

  const tokens = useMemo(() => {
    return location.pathname.split("/")[3];
  }, [location.pathname]);

  const RestaurantRemovefromFavourite = async () => {
    if (token) {
      if (cur_restaurant) {
        cur_restaurant.type = "restaurant";
        cur_restaurant.userid = cur_user.user.id;
        const id = cur_user.user.id;
        try {
          const tokens = cur_user.token;
          const removefav = await RemoveFavourites(id, cur_restaurant, tokens);
          if (removefav.data.status) {
            cur_restaurant.Likes.filter((item) => item !== cur_user.user.id);
            dispatch({
              type: SET_RESTAURANT,
              payload: cur_restaurant,
            });
            toast("Removed from Favourites");
            navigate("/myprofile?favourite");
          }
        } catch (err) {
          console.log("update error:", err);
        }
      } else {
        navigate("/marketplace");
      }
    } else {
      toast("Please Sign In to Continue");
    }
  };

  const RestaurantAddtoFavourite = async () => {
    if (token) {
      if (cur_restaurant) {
        cur_restaurant.type = "restaurant";
        const id = cur_user.user.id;
        try {
          const tokens = cur_user.token;
          const addfav = await AddFavourites(id, cur_restaurant, tokens);
          cur_restaurant.Likes.push(cur_user.user.id);
          dispatch({
            type: SET_RESTAURANT,
            payload: cur_restaurant,
          });
          toast("Added to Favourites");
          navigate("/myprofile?favourite");
        } catch (err) {
          console.log("update error:", err);
        }
      } else {
        navigate("/marketplace");
      }
    } else {
      toast("Please Sign In to Continue");
    }
  };

  const renderCollectionContent = useCallback(() => {
    return (
      <div className="grid lg:px-0 md:px-5 lg:w-auto md:w-auto px-5 w-full grid-cols-1 sm:px-0  sm:grid-cols-3 lg:grid-cols-3 gap-x-[30px] gap-y-[30px] ">
        {states &&
          states?.nftgroup?.map((marketplace, idx) => {
            return (
              <Card.CollectionGroupCard
                wrapperClass="lg:w-[350px] md:w-[350px] w-full hover:-translate-y-4 border border-solid border-platinumSolid hover:border-abundance "
                title={marketplace.Nftgroupname}
                groupname={marketplace.Nftgroupname}
                srcImages={states?.NFTCollectionsimage}
                srcImagesLogo={marketplace.NFTgroupimg}
                key={idx}
                count={marketplace.NFTcount}
                collectionId={states ? states._id : id}
                nftPrice={marketplace.NFTPrice}
                description={states?.Description}
                benefits={states?.NFTTier}
                collectionName={states?.NFTCollections}
                OwnedBy={states?.EventName}
              />
            );
          })}
      </div>
    );
  }, [states]);

  const renderContent = () => {
    if (tokens === "tokens") {
      return <NFTCollections />;
    }
    return renderCollectionContent();
  };

  useEffect(() => {
    setTimeout(function () {
      setActive(false);
    }, 3000);
  }, [active]);

  const [showSignIn, setShowSignIn] = useState("INIT");
  const [particular, setParticular] = useState(false);

  return (
    <Layout>
      <Header
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
        particular={particular}
      />
      <div className="h-fit overflow-hidden mb-10">
        <div className="mt-[60px] lg:mt-[100px]">
          <img
            src={Images.BgCollections}
            alt="background-main-page absolute -z-10"
            className=""
            style={{ height: "250px", width: "100%" }}
          />
        </div>
        <div
          className="flex flex-col items-center animate-slideDown2 z-20"
          style={{ backgroundColor: "transparent", marginTop: "-10%" }}
        >
          <div className="w-full px-5 xl:w-defaultContent xl:px-0">
            <div className="mb-[40px] w-[200px] h-[200px] flex justify-center items-center bg-white border-solid border-[6px] border-lightGrey rounded">
              <img
                src={`${process.env.REACT_APP_S3_URL}${states?.NFTCollectionsimage}`}
                alt="profile"
                className=""
                style={{ height: "190px", width: "200px" }}
              />
            </div>
            <div className="flex flex-col-reverse xs:flex-row xs:items-center justify-between">
              <Text
                color={Colors.darkSecurity}
                text={states?.EventName}
                fontWeight="700"
                fontFamily="ExpressWay"
                fontSize="32px"
              />
              {/* <div
                className="flex items-center space-x-7 mb-2"
                style={{ cursor: "pointer" }}
              >
                <Tooltip
                  wrapperClassname="relative z-10"
                  top="-top-12"
                  title={
                    <Icons.ShareIcon
                      className={"[&>path]:fill-darkSecurity cursor-pointer"}
                    />
                  }
                >
                  <Share instaUrl={states?.Instagram} twitterUrl={states?.Twitter} discordUrl={states?.Discord} />
                </Tooltip>
				<Icons.LinkIcon className="h-5 w-5 [&>path]:fill-darkSecurity cursor-pointer" onClick={()=>{
					setActive(true)
					navigator.clipboard.writeText(states?.website)}}/>
					{active ? <div style={{color:"green"}}>Link Copied!</div> : null }
				
                <Icons.StarIcon className='h-5 w-5 [&>path]:fill-abundance cursor-pointer' />
                {cur_restaurant.Likes &&
                cur_restaurant.Likes.includes(
                  cur_user.user && cur_user.user.id
                ) ? (
                  <BsStarFill size={25} color="#EF8440"   onClick={() => RestaurantRemovefromFavourite()}/>
                ) : (
                  <BsStar
                    size={25}
                    color="#EF8440"
                    onClick={() => RestaurantAddtoFavourite()}
                  />
                )}
              </div> */}
            </div>

            <Text
              fontFamily="AcuminPro"
              color={Colors.darkSecurity}
              text={states?.Address}
              fontWeight="300"
            />
            <div className="flex items-center space-x-2">
              <Text
                onClick={() => navigate(`/collections/${currentUrl}`)}
                fontFamily="ExpressWay"
                color={tokens === "tokens" ? Colors.darkGrey : Colors.abundance}
                text="NFT Groups"
                className="font-bold mb-5 mt-5 cursor-pointer"
                fontWeight="700"
                fontSize="20px"
              />
              {tokens === "tokens" && (
                <>
                  <Icons.ArrowDropdown className="h-1.5 w-5 [&>path]:fill-abundance -rotate-90" />
                  <Text
                    fontFamily="ExpressWay"
                    color={Colors.abundance}
                    text="Tokens"
                    className="font-bold mb-5 mt-5"
                    fontWeight="700"
                    fontSize="20px"
                  />
                </>
              )}
            </div>
          </div>

          {renderContent()}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default CollectionsGroup;
