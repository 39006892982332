import { RadioGroup } from "@headlessui/react";
import { Button, Text } from "components";
import { Images } from "constant";
import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../../selectors";
import { getuser, updatethewallet } from "../../../../../services/auth";
import {
  connectWallet,
  connectFailed,
} from "../../../../../stores/User/WalletAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const wallets = [
  {
    name: "MetaMask",
    image: Images.Wallets.MetaMask,
    value: "metamask",
  },
  {
    name: "CoinBase",
    value: "coinbasewallet",
    image: Images.Wallets.CoinBase,
  },
  // {
  // 	name: 'Fortmatic',
  // 	value: "fortmatic",
  // 	image: Images.Wallets.Fortmatic
  // },
  {
    name: "WalletConnect",
    value: "walletconnect",
    image: Images.Wallets.WalletConnect,
  },
];

const Wallet = ({ handleNextPage, handleBackPage }) => {
  const dispatch = useDispatch();

  const user = useSelector(selectors.userProfile);
  const walletAddress = useSelector(selectors.WalletConnect);

  const [wallet, setWallet] = useState();
  const [walletname, setwalletname] = useState();

  const getCurrentuser = async () => {
    const res = await getuser(user.user.id);
    if (res.data.status) {
      const eve = res.data.result;
      setWallet(eve.walletAddress);
    }
  };

  const checkconnectedwallet = async () => {
    // if (
    //   user.user.walletAddress.toLowerCase() ==
    //   walletAddress.address.toLowerCase()
    // ) {
    setWallet(walletAddress.address);
    try {
      if (walletAddress.web3Modal.userOptions[0].name == "MetaMask") {
        setwalletname("metamask");
      }
    } catch (err) {
      console.log("error", err);
    }
    // } else {
    //   dispatch(connectFailed());
    //   toast("Connect with the registered wallet address");
    // }
  };

  useEffect(() => {
    if (user) {
      getCurrentuser();
    }
    if (walletAddress.address && user.user.walletAddress) {
      checkconnectedwallet();
    }
  }, [user, walletAddress]);

  const connectWallets = (value) => {
    try {
      setwalletname(value);
      dispatch(connectWallet(value));
    } catch (err) {
      console.log("error:", err);
    }
  };

  const updatemembershipwallets = async () => {
    const values = {
      walletAddress: wallet ? wallet : walletAddress.address,
    };
    try {
      const userprofile = await updatethewallet(user.user.id, values);
      if (userprofile.data.status) {
        handleNextPage();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="flex-1">
      <Text fontType="h2" fontSize="45px" fontWeight={600} color="#000000">
        Your Wallet
      </Text>

      {wallet ? (
        <>
          <div className="mt-8 pb-1 border-b-2 border-okuGrowth w-fit">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              className="uppercase"
              color="#000000"
            >
              Your Wallet Address
            </Text>
          </div>
          <br />
          <Text
            fontType="p"
            fontSize="16px"
            fontWeight={400}
            className="mt-3"
            color="#1C1E19"
          >
            {" "}
            {wallet}
          </Text>

          <Button
            onClick={() => handleNextPage()}
            theme="primary-outline"
            className="mt-12"
          >
            Confirm
          </Button>
        </>
      ) : (
        <>
          <div className="mt-8 pb-1 border-b-2 border-okuGrowth w-fit">
            <Text
              fontType="h3"
              fontSize="18px"
              fontWeight={700}
              className="uppercase"
              color="#000000"
            >
              connect your wallet
            </Text>
          </div>
          <Text
            fontType="p"
            fontSize="16px"
            fontWeight={400}
            className="mt-3"
            color="#1C1E19"
          >
            One step closer to collecting Oku exclusive pieces and experiencing
            once in a lifetime opportunities!
            <br />
            Connect the wallet you would like to use for Oku Club House!
          </Text>
          <RadioGroup
            value={wallet}
            onChange={setWallet}
            className="my-8 flex max-lg:flex-wrap items-center gap-8"
          >
            {wallets.map((wallet, index) => {
              return (
                <RadioGroup.Option
                  key={index}
                  value={wallet.name}
                  onClick={() => connectWallets(wallet.value)}
                >
                  {({ checked }) => (
                    <div
                      className={`w-36 h-36 bg-white rounded-lg border ${
                        checked ? "border-okuGrowth" : "border-platinumSolid"
                      } hover:border-okuGrowth  flex items-center justify-center p-7 cursor-pointer`}
                    >
                      <img src={wallet.image} />
                    </div>
                  )}
                </RadioGroup.Option>
              );
            })}
          </RadioGroup>
          <p className="text-[15px] leading-[18px] text-abundance font-AcuminPro tracking-wider uppercase">
            Don’t have a wallet?
          </p>
          <p className="text-[15px] leading-[18px] mt-1 font-AcuminPro tracking-wider text-black">
            Create one with Fortmatic!
            <br />
            We find this as the most intuitive for those who don’t <br />
            know where to start.
          </p>
          <div
            onClick={() => connectWallets("fortmatic")}
            className="w-36 h-36 bg-white rounded-lg border hover:border-okuGrowth mt-8 flex items-center justify-center p-7 cursor-pointer"
          >
            <img src={Images.Wallets.Fortmatic} />
          </div>
          <div className="flex justify-end gap-3 w-full max-lg:mt-5 lg:pr-40">
            <Button onClick={() => handleBackPage()} theme="primary-outline">
              Back
            </Button>
            <Button
              onClick={() => updatemembershipwallets()}
              theme="primary-outline"
            >
              Continue
            </Button>
          </div>
          {/* {walletAddress.address ? <><Text fontType='h3' fontSize='18px' fontWeight={700} className='uppercase mt-3 mb-4' color='#000000'>*Your Selected Wallet*</Text>
						<div className={`w-36 h-36 bg-white rounded-lg border ${'border-okuGrowth'} hover:border-okuGrowth  flex items-center justify-center p-7 cursor-pointer`}>
							<img src={walletname == "metamask" ? Images.Wallets.MetaMask : walletname == "coinbasewallet" ? Images.Wallets.CoinBase : walletname == "fortmatic" ? Images.Wallets.Fortmatic : walletname == "walletconnect" ? Images.Wallets.WalletConnect : ""} />
						</div>
						<Text fontType='h3' fontSize='18px' fontWeight={700} className='uppercase mt-3' color='#000000'>Wallet Address : {walletAddress.address}</Text>

						<Button onClick={() => updatemembershipwallets()} theme='primary-outline' className='mt-12'>Continue</Button>
					</>
						:
						<>
							<Text fontType='p' fontSize='16px' fontWeight={300} className='mt-10' fontStyle='italic' color='#1C1E19'>*If you don’t have a wallet, please create one on one of these platforms*</Text>
						</>} */}
        </>
      )}
    </div>
  );
};

export default Wallet;
