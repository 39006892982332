import React, { useEffect, useState, useMemo } from "react";
import { Layout, Text } from "components";
import NFTCollections from "components/NFTCollections";
import { Images } from "constant";
import icons from "constant/icons";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { AddFavourites } from "../../services/favourites";
import {
  getRestaurantsNftCount,
  getRestaurants,
  getcollectionnfts,
} from "../../services/restaurant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SET_RESTAURANT } from "../../stores/User/userActionTypes";
import Collections from "pages/Collections";
// import { Header } from 'components';
import { Footer, Header } from "pages/LandingPageNew/Components";

const restaurantPerRow = 8;

const Restaurant = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id, name, group } = useParams();

  const cur_restaurant = useSelector(selectors.currentRestaurant);
  const [states, setstates] = useState();
  const cur_user = useSelector(selectors.userProfile);
  const [RestaurantNfts, setRestaurantNfts] = useState("");

  const gettotalnfts = async () => {
    await getRestaurantsNftCount({
      restaurantName: cur_restaurant.restaurantName,
    }).then((res) => {
      setRestaurantNfts(res?.data.result);
    });
  };

  useEffect(() => {
    gettotalnfts();
    if (!state) {
      getrefreshdata();
    } else {
      setstates(state);
    }
  }, []);

  const getrefreshdata = async () => {
    const res = await getcollectionnfts(id);
    if (res.data.result) {
      const array = res.data.result;
      const statedata = array.filter((item) =>
        item._id == state?.collectionId
          ? state?.collectionId
          : "" || item._id == id
      );
      let newarray = [];
      await statedata.map(async (data) => {
        const unique = [
          ...new Set(data.collectionnfts.map((item) => item.NFTCollections)),
        ];
        let nftgroup = [];
        Promise.all(
          unique.map((item) => {
            const Group = data.collectionnfts.filter(
              (eve) => eve.NFTCollections == item
            );
            if (Group.length > 0) {
              nftgroup.push({
                Nftgroupname: Group[0].NFTCollections,
                NFTcount: Group[0].NFTCount,
                NFTgroupimg: Group[0].NFTImage,
                CollectionName: Group[0].CollectionName,
                NFTS: Group,
              });
            }
          })
        );
        newarray.push({ ...data, nftgroup });
      });
      const stated = {
        collectionId: newarray[0]._id,
        collectionImg: newarray[0].NFTCollectionsimage,
        collectionName: newarray[0].NFTCollections,
        groupname: group,
        OwnedBy: newarray[0].EventName,
      };
      setstates(stated);
    }
  };
  const RestaurantAddtoFavourite = async () => {
    if (cur_restaurant) {
      cur_restaurant.type = "restaurant";
      const id = cur_user.user.id;
      try {
        const tokens = cur_user.token;
        const addfav = await AddFavourites(id, cur_restaurant, tokens);
        cur_restaurant.Likes.push(cur_user.user.id);
        dispatch({
          type: SET_RESTAURANT,
          payload: cur_restaurant,
        });
        toast("Added to Favourites");
        window.location.reload();
      } catch (err) {
        console.log("update error:", err);
      }
    } else {
      navigate("/marketplace");
    }
  };
  const tokens = useMemo(() => {
    return location.pathname.split("/")[3];
  }, [location.pathname]);
  const [showSignIn, setShowSignIn] = useState("INIT");
  const [particular, setParticular] = useState(false);
  return (
    <Layout>
      <Header
        showSignIn={showSignIn}
        setShowSignIn={setShowSignIn}
        particular={particular}
      />
      <div className="h-fit overflow-hidden mb-10">
        <div className="mt-[60px] lg:mt-[100px]">
          <img
            src={Images.BgCollections}
            alt="background-main-page absolute -z-10"
            className=""
            style={{ height: "250px", width: "100%" }}
          />
        </div>

        <div className="flex flex-col items-center animate-slideDown2">
          <div
            className="w-full px-5 xl:w-defaultContent xl:px-0"
            style={{ zIndex: "5" }}
          >
            <div className="-mt-[10%] mb-[40px] w-[200px] h-[200px] flex justify-center items-center bg-white border-solid border-[6px] border-lightGrey rounded">
              <img
                src={`${process.env.REACT_APP_S3_URL}${states?.collectionImg}`}
                alt="profile"
                style={{ height: "190px", width: "200px" }}
              />
            </div>
            <div className="flex flex-col-reverse xs:flex-row xs:items-center justify-between">
              <Text
                className="!text-darkSecurity"
                text={states?.collectionName}
                fontType="h3"
                fontWeight="700"
                fontFamily="ExpressWay"
                fontSize="32px"
              />

              {/* <div className="flex items-center space-x-7 mb-2"> */}
              {/* <div className='flex items-center space-x-7 mb-2'>
								<Tooltip
									wrapperClassname='relative z-10'
									top='-top-12'
									title={ <Icons.ShareIcon
										className={ '[&>path]:fill-darkSecurity cursor-pointer' }
				 						/> }>
									<Share />
								</Tooltip>
								<Icons.LinkIcon className='h-5 w-5 [&>path]:fill-darkSecurity cursor-pointer' />
								<Icons.StarIcon className='h-5 w-5 [&>path]:fill-abundance cursor-pointer'   onClick={() => window.open(cur_restaurant.website, "_blank")}/>
							</div> */}

              {/* <div className='h-[50px] w-[50px] flex justify-center cursor-pointer items-center bg-white/5 rounded '>
                  <img alt='logo' src={Icons.link} className='h-5 w-5 filter-white' onClick={() => window.open(cur_restaurant.website, "_blank")} />
                </div> */}
              {/* <div className="w-0 border-solid border-[1px] border-white/20 h-[50px]" /> */}
              {/* {cur_restaurant.Likes && cur_restaurant.Likes.includes(cur_user.user && cur_user.user.id) ?
                  <div className='flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px] bg-white/5 rounded'>
                    <img alt='logo' src={Icons.Star} className='h-5 w-5 ' />
                    <Text text='Added to Favourite' fontWeight='800' color={ Colors.darkSecurity } />
                  </div>
                  :
                  <div className='flex justify-between space-x-3 items-center  h-[50px]  cursor-pointer px-[20px] bg-white/5 rounded' onClick={() => RestaurantAddtoFavourite()}>
                    <img alt='logo' src={Icons.Star} className='h-5 w-5 ' />
                    <Text text='Add to Favourite' fontWeight='800' color={ Colors.darkSecurity } />
                  </div>
                } */}
              {/* </div> */}
            </div>
            {/* <Text text={states?.OwnedBy} fontWeight='300' color="black"  fontSize='18px' fontFamily='AcuminPro' /> */}
            <h4 style={{ fontSize: "16px", color: "black" }}>
              OwnedBy{" "}
              <span style={{ fontWeight: "700" }}>{states?.OwnedBy}</span>
            </h4>
            <div className="flex items-center space-x-2 mt-3">
              {/* <Text text={`${states?.NFTCount} NFTs`} color={
									 tokens === 'tokens' ?
										Colors.darkGrey : Colors.abundance
								}
                className='font-bold mb-5 mt-5 cursor-pointer'
								fontWeight='700'
								fontSize='20px'  /> */}
            </div>
          </div>
          {/* {console.log("states==>NFTCollections",states)} */}

          <NFTCollections
            item={cur_restaurant.Collections}
            collectionName={states?.collectionName}
            collectionid={states?.collectionId}
            groupname={states?.groupname}
          />
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default Restaurant;
