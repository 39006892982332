import { Layout } from 'pages/LandingPageNew/Components';
import React, { useState, useMemo } from 'react';

// import { Layout } from 'components';
import { HouseRules, PrivacyPolicy, TermAndConditions } from './Pages';

const menu = [
	{
		title: 'House Rules',
		id: 1
	},
	{
		title: 'Privacy policy',
		id: 2
	},
	{
		title: `${'Terms and'}\n${'Conditions'}`,
		id: 3
	},
];

const Legal = () => {
	const [openTab, setOpenTab] = useState(1);

	const renderContent = useMemo(() => {
		const listView = [
			<HouseRules key='HouseRules' />,
			<PrivacyPolicy key='PrivacyPolicy' />,
		];

		return listView[openTab - 1];
	}, [openTab]);

	return (
		<Layout>
			<div className='max-w-screen-xl m-auto flex flex-col md:flex-row my-20 gap-5'>
				<div
					className='list-none'
					role='tablist'>
					{menu.map((item, i) => (
						<div
							key={i}
							onClick={e => {
								e.preventDefault();
								item.id !== 3 && setOpenTab(item.id);
							}}
						>
								<p className={` ${openTab === item.id ? 'text-okuGrowth font-bold' : 'text-veryDarkGray font-medium'} ${item.id !== 3 && 'cursor-pointer'} whitespace-pre-line text-2xl uppercase leading-[29px] tracking-[0.01em] font-AcuminPro mb-5 w-[30vh]`}>{item.title}</p>
						</div>
					))}
				</div>
				<div className='' id='link1'>
					<div>
						{renderContent}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default React.memo(Legal);