import React, { useState, useEffect } from 'react';
import {
  Balance, Button, Input, Layout, Modal, SelectMenu, Text
} from 'components';
import {
  Colors, Icons, Images
} from 'constant';
import { Menu } from '@headlessui/react';
import {gettransactionHistory} from '../../services/Nft';
import { useSelector } from 'react-redux';
import {AiFillClockCircle} from "react-icons/ai"
import axios from 'axios';

const MyWallet = ({ }) => {
  const wallet = useSelector(state => state.WalletConnect);

  const [showModalTopUp, setShowModalTopUp] = useState(false);
  const [showModalTransaction, setShowModalTransaction] = useState(false);
  const [data, setData] = useState([]);
  const [Transactions, setTransactions] = useState([]);
  const get_transaction = async() => {
    const res = await gettransactionHistory(wallet.address);
    const result = res.data.result;
    const filtered = result.filter((item) => item.paymentAmount)
    setData(filtered);
  }
  const getScandata = async() =>{
    const result1 = await axios.get("https://api-testnet.polygonscan.com/api", {
      params: {
        module: 'logs',
        action: 'getLogs',
        address: '0x9E2ee7daBcB182dc84c2455d2b2BBbCE01819fAD',
        topic0: "0x25b428dfde728ccfaddad7e29e4ac23c24ed7fd1a6e3e3f91894a9a073f5dfff",
        apikey: "TFWIFCYYUU3AFPRC1UU3GHZTRGXFEY9F8A"
      }
    });
    const resultArray1 = result1.data.result;
    const reversedList1 = resultArray1.map((e, i, a) => a[(a.length - 1) - i])
    
    const {web3, address} = wallet;
    const array_=[];
    await Promise.all(reversedList1.map(async (event, i) => {
      const transactionHash = event.transactionHash;
      var data1 = await web3.eth.abi.decodeLog(
        [
          { "indexed": false, "internalType": "address", "name": "accountt", "type": "address" },
          { "indexed": false, "internalType": "uint", "name": "value", "type": "uint" },
          { "indexed": false, "internalType": "uint", "name": "amount", "type": "uint" },
        ], event.data)
        if(address == data1.accountt){
          data1.transactionHash = event.transactionHash;
          array_.push(data1);
        }
    }))

    setTransactions(array_);
  }
  useEffect(()=>{
    get_transaction();
    getScandata()
  },[wallet])

  const renderHistory = () => {
    return data && data.map((el, idx) => (
        <div className='flex items-center justify-between border-solid border-b-[1px] border-b-white/10 mb-5' key={ idx }>
          <div className='pb-5 '>
            <Text text='Buy Nft' className='mb-3' fontWeight='800' color="black" />
            <div className='flex items-center space-x-2 mt-1'>
              <AiFillClockCircle size={18} color="black" className='opacity-50'/>
              <Text text={ el.SoldTime ? el.SoldTime : new Date(el?.updatedAt).toLocaleString()} fontWeight='300' color="black" className='opacity-50' fontSize='14px' />
            </div>
          </div>
          <div style={{display:"flex"}}>
            {el.paymentMethod =="card" ? <img src={ Icons.dollar } className='opacity-40 w-5 h-5' alt='bell' /> : null }
            {el.paymentMethod =="matic" ? <img src={ Icons.polygon } className='opacity-40' alt='bell' /> : null }
            {el.paymentMethod =="usdc" ? <img src={ Images.USDollar } className='opacity-40' alt='usdc' /> : null }
            {el.paymentMethod =="usdt" ? <img src={ Images.Usdt } className='opacity-40' alt='usdt' /> : null }

          <Text className="ml-2" text={el.paymentAmount} fontWeight='800' color={ Colors.okuGreen } />
          </div>
          <a href={`https://mumbai.polygonscan.com/tx/${el.transactionHash}`} target="_blank"><Text className="ml-2" text={"View transaction"} fontWeight='800' color={ Colors.okuGreen } /></a>
        </div>
      ));
  };

  const renderSelectCrypto = () => {
    return (
      <SelectMenu
        wrapperClassName=''
        menuBtnClassName='w-full'
        menuClassName='w-full'
        menuItemsClassName='w-full z-10'
        MenuButton={
          <div className='flex items-center justify-between p-5 border-solid border-[1px] border-black rounded'>
            <div className='flex space-x-2'>
              <img src={ Icons.solana } className='' alt='currency' />
              <Text text='Solana' color='black' className='font-extrabold' />
            </div>
            <img src={ Images.ArrowDown } className='filter-black' alt='' />
          </div>
        }
      >
        <div className='px-px z-10'>
          <Menu.Item>
            { ({ active }) => (
              <button
                className={ 'h-[62px] pl-[20px] text-white group flex w-full items-center space-x-3 rounded-md px-1 text-sm bg-white border-solid border-[1px] border-white/10' }
                onClick={ () => null }
              >
                <img src={ Icons.solana } className='' alt='currency' />
                <Text text='Solana' color='black' className='font-extrabold' />
              </button>
            ) }
          </Menu.Item>
        </div>
      </SelectMenu>
    );
  };

  const renderModalTopUp = () => {
    return (
      <Modal
        visible={ showModalTopUp }
        onClose={ () => setShowModalTopUp(false) }
        contentClassName='w-full md:w-[540px]'
      >
        <div className=''>
          <Text text='Top Up with Crypto' className='mb-3' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='700' fontSize='32px' />

          <Text
            color='black'
            fontWeight='300'
            className='mb-[30px]'
            text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia perspiciatis similique voluptatibus officia et culpa iure, labore nobis veritatis error.' />

          { renderSelectCrypto() }

          <Input
            label='Input Amount'
            name='input-amount'
            className='mb-10 mt-5'
            autofocus
            labelClassName='mb-[5px]'
            placeholder='Input Amount'
          />

          <Button label='Top Up Balance' className='w-full' width='100%' onClick={ () => {
            setShowModalTopUp(false);
            setShowModalTransaction(true);
          } } />
        </div>
      </Modal>
    );
  };

  const renderModalTransactionDetail = () => {
    return (
      <Modal
        visible={ showModalTransaction }
        onClose={ () => setShowModalTransaction(false) }
        contentClassName='w-full md:w-[540px]'
      >
        <div className=''>
          <Text text='Transaction Detail' className='mb-[30px]' color={ Colors.prussianBlue } fontFamily='lora' fontWeight='700' fontSize='32px' />

          <div className='flex justify-between items-center mb-3'>
            <div className=' '>
              <div className='flex items-center space-x-2 mb-1'>
                <img src={ Icons.clock } className='' alt='bell' />
                <Text text='20/07/2022 | 10.00 AM' fontWeight='300' color='black' className='' fontSize='14px' />
              </div>
              <Text text='Top Up Solana Crypto with Moonpay' className='' fontWeight='300' color='black' />
            </div>
            <Button label='Top Up Again' width='fit-content' />
          </div>

          <Text text='+1,235' fontWeight='700' fontSize='20px' className='mb-[30px]' fontFamily='lora' color={ Colors.prussianBlue } />

          <Text text='Summary' fontWeight='700' fontSize='24px' fontFamily='lora' color={ Colors.darkBlue } />

          <div className='flex items-center justify-between mt-5'>
            <Text text='NFT Name Goes Here' color='black' fontWeight='300' />
            <Text text='$1,237' color='black' fontWeight='800' />
          </div>
          <div className='flex items-center justify-between mt-5'>
            <Text text='NFT Name Goes Here' color='black' fontWeight='300' />
            <Text text='$1,237' color='black' fontWeight='800' />
          </div>

        </div>
      </Modal>
    );
  };

  return (
    <Layout>
      <div className='h-fit overflow-hidden'>
        <div className='flex flex-col items-center '>
          <div className='w-full px-5 xl:w-defaultContent xl:px-0'>
            <div className='md:flex mt-[140px] md:space-x-[30px]'>
              <div className='w-full mb-5 md:w-[350px] h-[300px] py-[30px] bg-white/5 rounded relative'>
                <Balance
                  hideHistoryBtn={ true }
                  wrapperClassName='px-[30px]'
                  btnClassName='rounded-b-sm'
                  onClickTopUp={ () => setShowModalTopUp(true) }
                />
                <hr className='border-t-white/10 absolute top-0 w-full h-[1px] mt-[95px]' />
              </div>
              <div className='flex-1'>
                <Text text='Balance History' fontType='h3' className='mb-[30px]' fontSize='32px' fontWeight='700' fontFamily='lora' color="black" />
                <div>
                  { renderHistory() }
                </div>
              </div>
            </div>
          </div>
        </div>
        { renderModalTopUp() }
        { renderModalTransactionDetail() }
      </div>
    </Layout>
  );
};

export default MyWallet;