import React,{useState, useEffect} from 'react';

import { NFTCard } from 'components/Card';

import NFTCollectionStyle from './style';
import {getallNfts} from '../../../../services/Nft';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';
import { result } from 'lodash';
import Loader from "react-js-loader";
import axios from 'axios';
const NFTCollection = () => {
  const nft  = {
    NFTDescription : 'Lorem ipsum dolor sit amet',
    OwnedBy : 'Restaurant Name',
    NFTPrice : '1,2345',
    wrapperClass : 'w-full',
    NFTImage:"collage-plaster-antique-sculpture-human-260nw-1951493971.webp_1662128924815"
  }

  const walletaddress = useSelector(state => state.WalletConnect);
  const [open, setOpen] = useState(true)

  const [moralis_nfts,seetMoralisnfts] = useState([]);
  const [mynfts, setMyNfts] = useState([]);
  const getnftid = async() =>{
    if(walletaddress.address){
    const options = {method: 'GET', headers: {Accept: 'application/json', 'x-api-key':process.env.REACT_APP_MORALIS_API_KEY}};
      fetch(`https://deep-index.moralis.io/api/v2/${walletaddress.address}/nft?chain=mumbai&format=decimal`, options)
      .then(response => response.json())
      .then(result => {
        const newarr = result.result;
        seetMoralisnfts(newarr);
        get_all_nfts(newarr);
      })
      .catch(err => console.error(err));
    }
  }
  const [maticToUsd, setMatictousd] = useState('');
  const getmatictoUsd = async() => {
    const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd", {method: "GET",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Methods": "GET",
      "Access-Control-Allow-Origin": "*",
    },})
    setMatictousd(matictousd.data['matic-network'].usd)
  }

  useEffect(() => {
    getmatictoUsd()
  },[])
  
  const get_all_nfts = async(newarr) => {
    if(moralis_nfts){
      const res = await getallNfts();
      const result1 = res.data.result;
      const arr1=[]
      const array = result1.map((item)=> {
        if(item.TokenId){
          newarr && newarr.map((value) => {if(value.token_id == Number(item.TokenId) && value.name == item.OwnedBy ){
            arr1.push(item)
          }})         
        }
      } );
      setMyNfts(arr1)
      setOpen(false);
    }
  }
  useEffect(() => {
    setOpen(true)
    getnftid();
  },[walletaddress])
  return (

    <NFTCollectionStyle>
      <div className='wrapper1'>
        {mynfts && mynfts.map((nft) => {
        return(
        
          <div className='item'>
            <NFTCard item={nft} maticToUsd={maticToUsd} wrapperClass='w-[260px] hover:-translate-y-4 border border-solid border-platinumSolid h-[375px] hover:border-[2px] hover:border-abundance'/>
            
          </div>
        )})}
        
      </div>
      {open ? 
        <Loader type="spinner-cub" bgColor={"black"}  color={'black'} size={80} />
        :""
      }
    </NFTCollectionStyle>
  );
};

export default NFTCollection;