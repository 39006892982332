import LogoCircle from "../assets/images/logo.svg";
import BurgerMenu from "../assets/images/burger-menu.svg";
import OGLogoYellow from "../assets/images/OG Logo Yellow.png";
import OGLogoBlue from "../assets/images/OG Logo Blue.png";
import ILDiscord from "../assets/images/socmed/discord.svg";
import ILInstagram from "../assets/images/socmed/instagram.svg";
import ILTwitter from "../assets/images/socmed/twitter.svg";
import ILLinkedin from "../assets/images/socmed/linkedin.svg";
import ILDiscordDark from "../assets/images/socmed/discord-dark.svg";
import ILInstagramDark from "../assets/images/socmed/instagram-dark.svg";
import ILTwitterDark from "../assets/images/socmed/twitter-dark.svg";
import ILLinkedinDark from "../assets/images/socmed/linkedin-dark.svg";
import ILFacebookDark from "../assets/images/socmed/facebook-dark.svg";
import ILCopyDark from "../assets/images/socmed/copy-dark.svg";
import ILTelegram from "../assets/images/socmed/telegram.svg";
import ILTelegramDark from "../assets/images/socmed/telegram-dark.svg";
import ILEmail from "../assets/images/socmed/email.svg";
import WhoWeAreImg from "../assets/images/who-we-are.svg";
import Close from "../assets/images/close.svg";
import ScrollAnimation from "../assets/images/scroll-animation.png";
import Badge from "../assets/images/badge.png";
import HeroBG from "../assets/images/LP-BG.jpg";
import HIWStep1 from "../assets/images/how-it-works/step-1.png";
import HIWStep2 from "../assets/images/how-it-works/step-2.png";
import HIWStep3 from "../assets/images/how-it-works/step-3.png";
import ArrowUp from "../assets/images/faq/arrow-up.svg";
import ArrowDown from "../assets/images/faq/arrow-down.svg";
import BtnMenu from "../assets/images/btn_menu.png";
import BtnCloseMenu from "../assets/images/btn_close_menu.png";
import Gold from "../assets/images/nft-partnerships/gold.jpg";
import Platinum from "../assets/images/nft-partnerships/platinum.jpg";
import Diamond from "../assets/images/nft-partnerships/diamond.jpg";
import CheckMark from "../assets/images/check-mark.svg";
import CheckRoundGreen from "../assets/images/icons/check_round_green.svg";
import TextLogo from "../assets/images/OG-Logo.svg";
import PokemonIllu from "assets/images/pokemon-logo.png";
import Phantom from "assets/images/logo/phantom.png";
import Slope from "assets/images/logo/slope.png";
import Solflare from "assets/images/logo/solflare.png";
import Sollet from "assets/images/logo/sollet.png";
import ArrowRight from "assets/images/ic/arrow-right.png";
import Rectangle from "../assets/images/Rectangle.png";
import Logo2 from "../assets/images/cards/logo2.png";
import IconSearch from "../assets/images/icons/search.svg";
import PinLoc from "../assets/images/icons/pin_loc.svg";
import Pencil from "../assets/images/icons/pencil.svg";
import Profile from "../assets/images/profile.png";
import ProfileIcon from "../assets/images/profile_icon.png";
import Setting from "../assets/images/setting.png";
import Share from "../assets/images/share.png";
import TransactionOut from "../assets/images/out.png";
import TransactionIn from "../assets/images/in.png";
import Restaurant from "../assets/images/logo/restaurant/image2.png";
import BgMainPage from "../assets/images/bgmainpage.png";
import ProfilePicture from "../assets/images/main-page/profilePicture.svg";
import Polygon from "../assets/images/cards/polygon.png";
import PaperPlane from "../assets/images/paperPlane.svg";
import Cryptoblack from "../assets/images/cards/Crypto black.svg";
import Cryptogreen from "../assets/images/cards/Crypto green.svg";
import Cardblack from "../assets/images/cards/Card black.svg";
import Cardgreen from "../assets/images/cards/Card green.svg";
import OkuHouseLogo from "../assets/images/logo/okuHouseLogo.png";
import FineDiningLogo from "../assets/images/logo/fine-dining.png";
import OdetteLogo from "../assets/images/logo/odette-logo.png";
import RonnyLogo from "../assets/images/logo/ronny-logo.png";
import SushiDiningLogo from "../assets/images/logo/logo_sushi_airways.png";
import FineDining from "../assets/images/cards/FineDining.png";
import Odette from "../assets/images/cards/Odette.png";
import RonnyGuillermo from "../assets/images/cards/RonnyGuillermo.png";
import SushiAirways from "../assets/images/cards/SushiAirways.png";
import BadgesLogo from "../assets/images/logo/badgesLogo.png";
import BagIcon from "../assets/images/bags_icon.png";
import BgCollections from "../assets/images/bgCollections.png";
import Rectangle2 from "../assets/images/Rectangle2.png";
import Rectangle3 from "../assets/images/Rectangle3.png";
import Rectangle4 from "../assets/images/Rectangle4.png";
import Rectangle5 from "../assets/images/Rectangle5.png";
import Rectangle6 from "../assets/images/Rectangle6.png";
import Rectangle7 from "../assets/images/Rectangle7.png";
import Rectangle8 from "../assets/images/Rectangle8.png";
import USDollar from "../assets/images/cards/Usdollar.svg";
import Usdt from "../assets/images/cards/Usdt.svg";
import Partnership from "../assets/images/how-it-works/partnership.svg";
import Offering from "../assets/images/how-it-works/offering.svg";
import Journey from "../assets/images/how-it-works/journey.svg";
import LpImage from "../assets/images/maskgroup.svg";
import FlowerLp from "../assets/images/flower.png";
import FlowerLp2 from "../assets/images/flower2.svg";
import OkuLogo from "../assets/images/logo/okusecondlogo.svg";
import OkuSubmark from "../assets/images/logo/okusubmark.svg";
import timedblack from "../assets/images/icons/Timed Auction Black.svg";
import timedgreen from "../assets/images/icons/Timed Auction Green.svg";
import instantblack from "../assets/images/icons/Instantsaleblack.svg";
import instantgreen from "../assets/images/icons/Instant sale Green.svg";
import TrashOrange from "../assets/images/icons/trash_orange.svg";

import HomeHero from "../assets/images/landing-page/hero.jpg";
import PartnerHero from "../assets/images/landing-page/partner-hero.png";
import PartnerProduct from "../assets/images/landing-page/partner-product.png";
import PartnerTier1 from "../assets/images/landing-page/partner-tier-1.png";
import PartnerTier2 from "../assets/images/landing-page/partner-tier-2.png";
import PartnerTier3 from "../assets/images/landing-page/partner-tier-3.png";
import HomeHit1 from "../assets/images/landing-page/hit-1.jpg";
import HomeHit2 from "../assets/images/landing-page/hit-2.jpg";
import HomeHit3 from "../assets/images/landing-page/hit-3.jpg";
import HomeGalery1 from "../assets/images/landing-page/home-1.jpg";
import HomeGalery2 from "../assets/images/landing-page/home-2.jpg";
import HomeGalery3 from "../assets/images/landing-page/home-3.jpg";
import HomeGalery4 from "../assets/images/landing-page/home-4.jpg";
import HomeGalery5 from "../assets/images/landing-page/home-5.jpg";
import HomeGalery6 from "../assets/images/landing-page/home-6.jpg";
import HeroMarketplace from "../assets/images/marketplace/hero_marketplace.png";
import HeroMarketplace2 from "../assets/images/marketplace/hero-marketplace.png";
import Marketplace1 from "../assets/images/marketplace/marketplace-1.png";
import Marketplace2 from "../assets/images/marketplace/marketplace-2.png";
import Marketplace3 from "../assets/images/marketplace/marketplace-3.png";
import Marketplace4 from "../assets/images/marketplace/marketplace-4.png";
import John from "../assets/images/marketplace/john.png";
import Singapore1 from "../assets/images/marketplace/singapore-1.png";
import Singapore2 from "../assets/images/marketplace/singapore-2.png";
import Singapore3 from "../assets/images/marketplace/singapore-3.png";
import Singapore4 from "../assets/images/marketplace/singapore-4.png";
import Singapore5 from "../assets/images/marketplace/singapore-5.png";
import Singapore6 from "../assets/images/marketplace/singapore-6.png";
import Singapore7 from "../assets/images/marketplace/singapore-7.png";
import Singapore8 from "../assets/images/marketplace/singapore-8.png";
import Collection_Tap1 from "../assets/images/marketplace/all_Collection.svg";
import Collection_Tap2 from "../assets/images/marketplace/events.svg";
import Collection_Tap3 from "../assets/images/marketplace/club-House.svg";
import Collection1 from "../assets/images/marketplace/coll-1.png";
import Collection4 from "../assets/images/marketplace/coll-4.png";
import Collection5 from "../assets/images/marketplace/coll-5.png";
import Collection6 from "../assets/images/marketplace/coll-6.png";
import Collection7 from "../assets/images/marketplace/coll-7.png";
import Collection8 from "../assets/images/marketplace/coll-8.png";
import Collection9 from "../assets/images/marketplace/coll-9.png";
import Collection11 from "../assets/images/marketplace/coll-11.png";
import Collection12 from "../assets/images/marketplace/coll-12.png";
import HeroCollection from "../assets/images/marketplace/hero-Collections.png";
import FOSingapore from "../assets/images/marketplace/foSingapore.png";
import Artist2 from "../assets/images/marketplace/artist-2.png";
import Artist3 from "../assets/images/marketplace/artist-3.png";
import Artist210 from "../assets/images/marketplace/artist2-10.png";
import Artist211 from "../assets/images/marketplace/artist2-11.png";
import Artist26 from "../assets/images/marketplace/artist2-6.png";
import Artist27 from "../assets/images/marketplace/artist2-7.png";
import Artist28 from "../assets/images/marketplace/artist2-8.png";
import Aesthete from "../assets/images/marketplace/aesthete.svg";
import Connoisseur from "../assets/images/marketplace/connoisseur.svg";
import Enthusiast from "../assets/images/marketplace/enthusiast.svg";
import LimitedEdition from "../assets/images/marketplace/limited_edition.svg";
import OnSale from "../assets/images/marketplace/on_sale.svg";
import Product3 from "../assets/images/landing-page/product-3.png";
import Product4 from "../assets/images/landing-page/product-4.png";
import MembershipHero from "../assets/images/landing-page/membership-hero.png";
import Tier1 from "../assets/images/landing-page/tier_1.png";
import Tier2 from "../assets/images/landing-page/tier_2.png";
import Tier3 from "../assets/images/landing-page/tier_3.png";
import ComingSoonMarketplace from "../assets/images/landing-page/comingsoon-marketplace.webp";
import Gallery from "../assets/images/landing-page/gallery.png";
import Events from "../assets/images/landing-page/events.png";
import OkuHouse from "../assets/images/landing-page/oku-house.png";
import OkuHouse1 from "../assets/images/landing-page/oku-house1.webp";
import OkuHouse2 from "../assets/images/landing-page/oku-house2.webp";
import OkuHouse3 from "../assets/images/landing-page/oku-house3.webp";
import FlowerOrange from "../assets/images/landing-page/flower-orange.png";
import BoxCircle from "../assets/images/landing-page/box-circle.png";
import BenefitBlockChain from "../assets/images/landing-page/buy-crypto.png";
import BenefitPeople from "../assets/images/landing-page/profile-circle.png";
import BenefitAccess from "../assets/images/landing-page/smart-home.png";
import BenefitPeoples from "../assets/images/landing-page/people.png";
import MainPageHero from "../assets/images/landing-page/mainpage-hero.webp";
import MainPageFlowers from "../assets/images/landing-page/flowerLP.png";
import ThankYou from "../assets/images/landing-page/thank-you.webp";

import Philosophy1 from "../assets/images/philosophy-1.jpg";
import Philosophy2 from "../assets/images/philosophy-2.jpg";
import Philosophy3 from "../assets/images/philosophy-3.jpg";
import Philosophy4 from "../assets/images/philosophy-4.jpg";
import Philosophy5 from "../assets/images/philosophy-5.jpg";
import PhilosophyVector from "../assets/images/philosophy-vector.svg";
import Verify1 from "../assets/images/verify-1.png";
import Verify2 from "../assets/images/verify-2.png";
import VertorWhite from "../assets/images/vektor-white.png";
import RegisterArt from "../assets/images/register-art.webp";
import TableTop from "../assets/images/table-top.png";
import TableBottom from "../assets/images/table-bottom.png";
import MembershipHeroXs from "../assets/images/landing-page/membership-xs.png";

import MetaMask from "../assets/images/wallet/metamask.png";
import CoinBase from "../assets/images/wallet/coinbase.png";
import CoinBaseLogo from "../assets/images/wallet/coinbase_logo.png";
import CoinBaseText from "../assets/images/wallet/coinbase_text.png";
import Fortmatic from "../assets/images/wallet/fortmatic.png";
import FortmaticLogo from "../assets/images/wallet/fortmatic_logo.png";
import FortmaticText from "../assets/images/wallet/fortmatic_text.png";
import WalletConnect from "../assets/images/wallet/walletconnect.png";
import WalletConnectLogo from "../assets/images/wallet/walletconnect_logo.png";
import WalletConnectText from "../assets/images/wallet/walletconnect_text.png";
import WalletIcon from "../assets/images/wallet.png";
import MetaMaskLogo from "../assets/images/wallet/metamask_logo.png";
import MetaMaskText from "../assets/images/wallet/metamask_text.svg";

import ClubHouse from "../assets/images/cloubhouse-uc.jpg";
import Collection from "../assets/images/collection_uc.jpg";

import ProfileMy from "../assets/images/icons/sidebarprofile/profile.svg";
import MyNtfs from "../assets/images/icons/sidebarprofile/my_ntfs.svg";
import Whistlist from "../assets/images/icons/sidebarprofile/whistlist.svg";
import Wallet from "../assets/images/icons/sidebarprofile/wallet.svg";
import Security from "../assets/images/icons/sidebarprofile/security.svg";
import InviteFriends from "../assets/images/icons/sidebarprofile/invite_friends.svg";
import SignOut from "../assets/images/icons/sidebarprofile/sign_out.svg";

const Wallets = {
  MetaMask,
  CoinBase,
  CoinBaseLogo,
  CoinBaseText,
  Fortmatic,
  FortmaticLogo,
  FortmaticText,
  WalletConnect,
  WalletConnectLogo,
  WalletConnectText,
  MetaMaskLogo,
  MetaMaskText,
};

const SidebarIcon = {
  ProfileMy,
  MyNtfs,
  Whistlist,
  Wallet,
  Security,
  SignOut,
  InviteFriends,
};

const Images = {
  SidebarIcon,
  Wallets,
  WalletIcon,
  USDollar,
  instantblack,
  instantgreen,
  timedgreen,
  timedblack,
  Usdt,
  TextLogo,
  LogoCircle,
  OGLogoYellow,
  Cardblack,
  OGLogoBlue,
  ILDiscord,
  ILInstagram,
  ILTwitter,
  ILLinkedin,
  ILDiscordDark,
  ILInstagramDark,
  ILTwitterDark,
  ILLinkedinDark,
  ILFacebookDark,
  ILCopyDark,
  WhoWeAreImg,
  Close,
  Cryptoblack,
  Cardgreen,
  Cryptogreen,
  ScrollAnimation,
  Badge,
  BagIcon,
  HeroBG,
  HIWStep1,
  HIWStep2,
  HIWStep3,
  ArrowUp,
  ArrowDown,
  BtnMenu,
  BtnCloseMenu,
  Gold,
  Platinum,
  Diamond,
  BurgerMenu,
  CheckMark,
  CheckRoundGreen,
  ILTelegram,
  ILTelegramDark,
  PokemonIllu: PokemonIllu,
  Rectangle,
  Logo2,
  IconSearch,
  Phantom,
  Slope,
  Solflare,
  Sollet,
  Profile,
  ProfileIcon,
  Setting,
  Share,
  TransactionOut,
  TransactionIn,
  Restaurant,
  ArrowRight,
  BgMainPage,
  PinLoc,
  Pencil,
  ProfilePicture,
  Polygon,
  PaperPlane,
  OkuHouseLogo,
  FineDiningLogo,
  OdetteLogo,
  RonnyLogo,
  SushiDiningLogo,
  FineDining,
  Odette,
  RonnyGuillermo,
  SushiAirways,
  BadgesLogo,
  BgCollections,
  Rectangle2,
  Rectangle3,
  Rectangle4,
  Rectangle5,
  Rectangle6,
  Rectangle7,
  Rectangle8,
  Partnership,
  Offering,
  Journey,
  LpImage,
  FlowerLp,
  FlowerLp2,
  OkuLogo,
  OkuSubmark,
  HomeHero,
  PartnerHero,
  PartnerProduct,
  PartnerTier1,
  PartnerTier2,
  PartnerTier3,
  HomeHit1,
  HomeHit2,
  HomeHit3,
  HomeGalery1,
  HomeGalery2,
  HomeGalery3,
  HomeGalery4,
  HomeGalery5,
  HomeGalery6,
  MembershipHero,
  Tier1,
  Tier2,
  Tier3,
  ComingSoonMarketplace,
  Gallery,
  Events,
  OkuHouse,
  OkuHouse1,
  OkuHouse2,
  OkuHouse3,
  FlowerOrange,
  BoxCircle,
  BenefitBlockChain,
  BenefitPeople,
  BenefitAccess,
  BenefitPeoples,
  MainPageHero,
  MainPageFlowers,
  ThankYou,
  TrashOrange,
  Philosophy1,
  Philosophy2,
  Philosophy3,
  Philosophy4,
  Philosophy5,
  PhilosophyVector,
  Verify1,
  Verify2,
  VertorWhite,
  RegisterArt,
  TableTop,
  TableBottom,
  ILEmail,
  MembershipHeroXs,
  HeroMarketplace,
  HeroMarketplace2,
  HeroMarketplace2,
  Marketplace1,
  Marketplace2,
  Marketplace3,
  Marketplace4,
  John,
  Product3,
  Product4,
  Singapore1,
  Singapore2,
  Singapore3,
  Singapore4,
  Singapore5,
  Singapore6,
  Singapore7,
  Singapore8,
  Collection_Tap1,
  Collection_Tap2,
  Collection_Tap3,
  Collection1,
  Collection4,
  Collection5,
  Collection6,
  Collection7,
  Collection8,
  Collection9,
  Collection11,
  Collection12,
  HeroCollection,
  FOSingapore,
  Artist2,
  Artist3,
  Artist210,
  Artist211,
  Artist26,
  Artist27,
  Artist28,
  Aesthete,
  Enthusiast,
  Connoisseur,
  LimitedEdition,
  OnSale,
  ClubHouse,
  Collection,
};

export default Images;
