import styled from 'styled-components';

const UploadDocumentsStyle = styled.div`

    @media screen and (max-width: 650px) {
            width: 320px;
            margin: auto;
      }
`;

export default UploadDocumentsStyle;