import React from 'react';

import ToggleBtnStyle from './style';

const ToggleBtn = ({ handleClickToggle, checked }) => {


  return (
    <ToggleBtnStyle>
      <label className="switch">
        <input type="checkbox" checked={checked ==true ? true : false} onChange={() =>{ handleClickToggle() }} />
        <span className="slider round"></span>
      </label>
    </ToggleBtnStyle>
  );
};

export default ToggleBtn;