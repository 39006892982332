import { Colors } from 'constant';
import styled from 'styled-components';

const LoginPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #fffbf9;

  .input-wrapper {
    padding: 20px;
    width: 100%;
    background-color: #fffbf9;
  }
`;

export default LoginPageStyle;
