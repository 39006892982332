import solana from '../assets/images/cards/logo_solana.svg';
import polygon from '../assets/images/polygon.svg';
import wallet from '../assets/images/main-page/wallet.svg';
import bell from '../assets/images/main-page/bell.svg';
import logout from '../assets/images/main-page/logout.svg';
import Star from '../assets/images/main-page/Star.svg';
import Member from '../assets/images/main-page/membership.svg';
import ProfileIco from '../assets/images/main-page/profile.svg';
import setting from '../assets/images/main-page/setting.svg';
import transactionHistory from '../assets/images/main-page/transactionHistory.svg';
import myNft from '../assets/images/main-page/myNft.svg';
import copy from '../assets/images/main-page/copy.svg';
import clock from '../assets/images/main-page/clock.svg';
import xClose from '../assets/images/main-page/xClose.svg';
import share from '../assets/images/main-page/share.svg';
import AtoZ from '../assets/images/main-page/AtoZ.svg';
import link from '../assets/images/main-page/link.svg';
import arrowDownSVG from '../assets/images/arrowDownSVG.svg';
import dollar from '../assets/images/cards/Dollar_sign.png'
import likes from '../assets/images/cards/like.svg'
import { ReactComponent as UserIcon } from '../assets/images/icons/userIcon.svg';
import { ReactComponent as EyeIcon } from '../assets/images/icons/eye.svg';
import { ReactComponent as LeftArrow } from '../assets/images/icons/leftArrow.svg';
import { ReactComponent as RightArrow } from '../assets/images/icons/rightArrow.svg';
import { ReactComponent as CopyIcon } from '../assets/images/main-page/copy.svg';
import { ReactComponent as ShareIcon } from '../assets/images/main-page/share.svg';
import { ReactComponent as StarIcon } from '../assets/images/main-page/Star.svg';
import { ReactComponent as LinkIcon } from '../assets/images/main-page/link.svg';
import { ReactComponent as WalletIcon } from '../assets/images/main-page/wallet.svg';
import { ReactComponent as BellIcon } from '../assets/images/main-page/bell.svg';
import { ReactComponent as SettingIcon } from '../assets/images/main-page/setting.svg';
import { ReactComponent as LineRange } from '../assets/images/icons/lineRange.svg';
import { ReactComponent as ArrowDropdown } from '../assets/images/arrowDownSVG.svg';
import { ReactComponent as ClockIcon } from '../assets/images/main-page/clock.svg';
import { ReactComponent as PolygonIcon } from '../assets/images/polygon.svg';
import { ReactComponent as CloseIcon } from '../assets/images/main-page/xClose.svg';
import { ReactComponent as SolanaIcon } from '../assets/images/cards/logo_solana.svg';
import { ReactComponent as Upload } from '../assets/images/icons/upload.svg';
import { ReactComponent as EditIcon } from '../assets/images/icons/edit.svg';
import { ReactComponent as TripleDotIcon } from '../assets/images/icons/tripledot.svg';
import { ReactComponent as XCloseIcon } from '../assets/images/main-page/xClose.svg';
import { ReactComponent as LocationIcon } from '../assets/images/icons/locationIcon.svg';
import { ReactComponent as AtoZIcon } from '../assets/images/main-page/AtoZ.svg';
import { ReactComponent as PaperPlane } from '../assets/images/paperPlane.svg';
import { ReactComponent as MyNftIcon } from '../assets/images/main-page/myNft.svg';
import { ReactComponent as TransactionIcon } from '../assets/images/main-page/transactionHistory.svg';
import { ReactComponent as LogoutIcon } from '../assets/images/main-page/logout.svg';
import { ReactComponent as Email } from '../assets/images/icons/ic/section/mail.svg';
import { ReactComponent as Submit } from '../assets/images/icons/ic/section/btn.svg';
import { ReactComponent as Asterisk } from '../assets/images/icons/asterisk.svg';
import { ReactComponent as Check } from '../assets/images/icons/check.svg';
import { ReactComponent as Eyes } from '../assets/images/icons/eyes.svg';
import { ReactComponent as Love } from '../assets/images/icons/love.svg';
import { ReactComponent as Share } from '../assets/images/icons/share.svg';

const icons = {
	Eyes,
	Love,
	Share,
	solana,
	likes,
	dollar,
	wallet,
	bell,
	logout,
	Star,
	Member,
	ProfileIco,
	setting,
	transactionHistory,
	myNft,
	copy,
	clock,
	xClose,
	share,
	AtoZ,
	link,
	arrowDownSVG,
	UserIcon,
	EyeIcon,
	LeftArrow,
	RightArrow,
	CopyIcon,
	ShareIcon,
	StarIcon,
	LinkIcon,
	WalletIcon,
	BellIcon,
	SettingIcon,
	LineRange,
	ArrowDropdown,
	ClockIcon,
	PolygonIcon,
	CloseIcon,
	SolanaIcon,
	polygon,
	Upload,
	EditIcon,
	TripleDotIcon,
	XCloseIcon,
	LocationIcon,
	AtoZIcon,
	PaperPlane,
	MyNftIcon,
	TransactionIcon,
	LogoutIcon,
	Email,
	Submit,
	Asterisk,
	Check
};

export default icons;