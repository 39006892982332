const menuList = [
	{
		label: 'About Oku',
		url: '/philosophy'
	},
	{
		label: 'Memberships',
		url: '/our-house'
	},
	// {
	// 	label: 'Roadmap',
	// 	url: '/roadmap'
	// },
	// {
	// 	label: 'Access List',
	// 	url: 'https://8i76pet9rc6.typeform.com/to/VjruNm2I'
	// },
	{
		label: 'Clubhouse',
		url: '/clubhouse'
	},
	{
		label: 'Our Events',
		url: '/events'
	},
	{
		label: 'Collections',
		url: '/marketplace'
	},
];

export default menuList;

