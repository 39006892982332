import React, { useRef, useState, useCallback } from "react";
import {
  RecordWebcam,
  useRecordWebcam,
  CAMERA_STATUS
} from "react-record-webcam";

import { Text } from "components";

import VideoRecorderStyle from "./style";
import Webcam from "react-webcam";
import S3 from "react-aws-s3";

const videoConstraints = {
  width: 720,
  height: 360,
  facingMode: "user"
};

const RecordView = ({ selfie, setSelfie, selfieurl, setSelfieurl }) => {
  const recordWebcam = useRecordWebcam();
  const [isCaptureEnable, setCaptureEnable] = useState(false);
  const webcamRef = useRef(null);
  const dataURLtoFile = (dataurl, filename) => {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setSelfie(imageSrc);
    if (imageSrc) {
      var file = dataURLtoFile(imageSrc, 'hello.png');
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName:process.env.REACT_APP_DIRECTORYNAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      };
  
      const ReactS3Client = new S3(config);
      const newFileName = file.name + "_" + Date.now()+".png";
      ReactS3Client.uploadFile(file, newFileName).then((data) =>
      setSelfieurl(data.location)
      );
      const newImage = URL.createObjectURL(file);
      setSelfieurl(newImage);
    }
  }, [webcamRef]);

  const handleselfieupload = async() =>{
    setSelfie(e.target.files[0])
    const newImage = URL.createObjectURL(e.target.files[0]);
    setSelfieurl(newImage);
  }
  return (
    <VideoRecorderStyle>
     
      <div className="mt-5 mb-5">
        {!selfie ?
          <>
          
            {isCaptureEnable || (
              <button className="bg-black/5 rounded-md w-full xs:w-[200px] h-10" onClick={() => setCaptureEnable(true)}><span className="text-black">Open Camera</span></button>
            )}
            {isCaptureEnable && (
              <>
                
                <div>
                  <Webcam
                    audio={false}
                    width={540}
                    height={360}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    videoConstraints={videoConstraints}
                  />
                </div>
                <div className="flex items-center justify-center mt-5">
                  <button className="bg-black/5 rounded-md w-full xs:w-[200px] h-10" onClick={() => setCaptureEnable(false)}><span className="text-black">Stop</span></button>
                  <button className="bg-black/5 rounded-md w-full xs:w-[200px] h-10" onClick={capture}><span className="text-black">Take Photo</span></button>
                </div>
              </>
            )}
          </>
          : null
        }
        {selfie && (
          <>
            <div>
              <img src={selfie} alt="Screenshot" />
            </div>
            <div className="mt-5">
              <button
                onClick={() => {
                  setSelfieurl(null);
                  setSelfie(null);
                  setCaptureEnable(true);
                }}
                className="bg-white/5 rounded-md w-full xs:w-[200px] h-10 "
              >
                Retake
              </button>
            </div>
          </>
        )}
      </div>
    </VideoRecorderStyle>
  );
};

export default RecordView