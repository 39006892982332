import styled from 'styled-components';

const TransactionHistoryStyle = styled.div`
    .wrapper1 {
        display: flex;
    }
    @media (max-width: 560px) { 
        .wrapper1 {
            display: flex;
            flex-direction: column;
        }
        .section1 {
            position: relative;
            bottom: 52px;
            left: 60px;
        }
      }
`;

export default TransactionHistoryStyle;