import styled from 'styled-components';

const AuthenticationFormStyle = styled.div`
  width: 450px;
  background: white;
  padding: 30px;
  border-radius: 5px;
  margin: auto;
  display: flex;

  .success {
    background-color: #27AE60 !important;
  }

  .btn-submit {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .btn-submit-list {
    height: 50px;
    width: 185px;
  }
  

  @media screen and (max-width: 720px) {

    .modal-content {
      padding: 20px;
    }
  }

  @media screen and (max-width: 540px) {
    
    .modal-content {
      height: 100vh;
    }

    .modal-input-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      flex: 1;
    }
  }

  @media screen and (max-width: 495px) {
    width: 325px;

    .btn-submit-list {
      height: 40px;
      width: 130px;
    }

    .join-our-team {

    }
  }
`;

export default AuthenticationFormStyle;