import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

const Tabs = ({
	activeTabIndex,
	setActiveTabIndex,
	tabUnderlineWidth,
	setTabUnderlineWidth,
	tabUnderlineLeft,
	setTabUnderlineLeft,
	tabsData,
	wrapperClassName = 'border-solid border-b-[1px] border-b-white/20 mb-[30px]',
	btnClassName = 'pt-2 pb-3 mr-10 font-extrabold text-xl',
	underlineStyle = 'h-1 bg-darkSecurity',
	hoverBtnClassName = '',
	activeColor = 'text-darkSecurity'
}) => {
	const navigate = useNavigate();

	const tabsRef = useRef([]);

	useEffect(() => {
		function setTabPosition() {
			const currentTab = tabsRef.current[activeTabIndex];

			setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
			setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
		}

		setTabPosition();
		window.addEventListener('resize', setTabPosition);

		return () => window.removeEventListener('resize', setTabPosition);
	}, [activeTabIndex]);

	return (
		<div className={ `relative  ${ wrapperClassName }` }>
			<div className='flex justify-center'>
				{ tabsData.map((tab, idx) => {
					return (
						<button
							key={ idx }
							ref={ el => (tabsRef.current[idx] = el) }
							className={ `${hoverBtnClassName} ${ activeTabIndex === idx ? activeColor : 'text-darkGrey' } transition-all duration-300 ${ btnClassName } ` }
							onClick={ () => {
								setActiveTabIndex(idx);
								tab.endpoint && navigate(tab.endpoint);
							} }>
							{ tab?.title }
						</button>
					);
				}) }
			</div>
			<span
				className={ `absolute bottom-0 block  transition-all duration-300 ${ underlineStyle }` }
				style={ {
					left: tabUnderlineLeft,
					width: tabUnderlineWidth
				} }
			/>
		</div>
	);
};

export default Tabs;