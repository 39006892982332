import React, { useState } from 'react';
import { message, Steps } from 'antd';

import { Button } from 'components';
import { Colors } from 'constant';

import GeneralInformation from './Components/GeneralInformation';
import LivenessVerification from './Components/Liveness Verification';
import UploadDocuments from './Components/UploadDocuments';
import KYCStyle from './style';
import { createApplicant, addDocument, addSelfie, creatCheck, checkstatus, EditApplicant } from '../../../../services/kyc';
import { getuser } from '../../../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../selectors';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Step } = Steps;

const KYC = () => {

  const [current, setCurrent] = useState(0);
  const [payload, setPayload] = useState({
    firstname: '',
    lastname: '',
    birthday: ''
  });
  const [selfie, setSelfie] = useState(null);
  const user = useSelector(selectors.userProfile);
  const [state, setState] = useState('');
  const [filestate, setFilestate] = useState('');
  const [selected, setSelected] = useState('Passport');
  const [selfieurl, setSelfieurl] = useState('');
  const [editkyc, setEditkyc] = useState(false);
  const next = () => {
    setCurrent(current + 1);
  };
  const [newuser, setNewUser] = useState('');

  const getCurrentuser = async () => {
    const res = await getuser(user.user.id)
    if (res.data.status) {
      const eve = res.data.result
      {
      setPayload({
        firstname:  eve.userFirstname ? eve.userFirstname : "",
        lastname: eve.userSecondname ? eve.userSecondname : "",
        birthday: eve.userDOB ? eve.userDOB : ""
      }) 
      }
      setNewUser(res.data.result);
    }

  }
  useEffect(() => {
    getCurrentuser();
  }, [])

  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: 'General Information',
      content: <GeneralInformation payload={payload} setPayload={setPayload} newuser={newuser} />,
    },
    {
      title: 'Uploading Document',
      content: <UploadDocuments DocumentUrl={newuser.DocumentUrl} setFilestate={setFilestate} filestate={filestate} state={state} setState={setState} selected={selected} setSelected={setSelected} newuser={newuser} />,
    },
    {
      title: 'Liveness Verification',
      content: <LivenessVerification selfie={selfie} setSelfie={setSelfie} newuser={newuser} selfieurl={selfieurl} setSelfieurl={setSelfieurl} />,
    },
  ];

  const id = user.user.id;
  const createanAppllicant = async () => {
    if(payload.firstname !="" && payload.lastname !="" && payload.birthday !=""){
    const values = {
      firstname: payload.firstname,
      lastname: payload.lastname,
      birthday: payload.birthday
    };
    const res = await createApplicant(id, values);
    if (res.data.status) {
      getCurrentuser();
      next();
    }
  }else{
    toast("Fill All Input")
  }
  }
  const editApplicant = async () => {
    const values = {
      firstname: payload.firstname ? payload.firstname : newuser.userFirstname,
      lastname: payload.lastname ? payload.lastname : newuser.userSecondname,
      birthday: payload.birthday ? payload.birthday : newuser.userDOB
    };
    const res = await EditApplicant(id, values);

    if (res.data.status) {
      toast("Editing general information success")
      getCurrentuser();
      next();
    } else {
      toast("something went wrong")
    }
  }

  const uploaddoc = async () => {
    
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token token=${process.env.REACT_APP_ONFIDO_API_TOKEN}`);
    var formdata = new FormData();
    formdata.append("type", state == "Passport" ? "passport" : "driving_licence");
    const fileinputm = filestate.files[0]
    formdata.append("file", fileinputm);
    formdata.append("applicant_id", newuser && newuser.OnfidoApplicantId);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://api.eu.onfido.com/v3.4/documents", requestOptions)
      .then(response => response.json())
      .then(async (result) => {
        const value = {
          documentimage: state,
          documenttype: state == "Passport" ? "passport" : "driving_licence",
          documentid: result.id,
        }
        const res1 = await addDocument(id, value);
        if (res1.data.status) {
          toast('document added')
          getCurrentuser()
          setCurrent(2);
        }
      })
      .catch(error => console.log('error', error));
  }
  const uploadSelfie = async () => {
    
    if (selfieurl) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Token token=${process.env.REACT_APP_ONFIDO_API_TOKEN}`);

      var formdata = new FormData();

      formdata.append("file", selfie);
      formdata.append("applicant_id", newuser && newuser.OnfidoApplicantId);
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch("https://api.eu.onfido.com/v3.4/live_photos", requestOptions)
        .then(response => response.json())
        .then(async (result) => {

          const value = {
            selfieurl: selfieurl,
            selfieid: result.id,
          }
          const res1 = await addSelfie(id, value);
          if (res1.data.status) {
            getCurrentuser();
            createcheck();
          }
        })
        .catch(error => console.log('error', error));
    } else {
      toast("upload a live photo")
    }
  }

  const createcheck = async () => {
    const res = await creatCheck(id);
    if (res.data.status) {
      toast('Your kyc has been created and waiting for verification');
      retriveChecks();
    } else {
      toast('You must provide a live photo to be able to proceed with this check')
    }

  }

  const retriveChecks = async () => {
    const res = await checkstatus(id);
    if (res.data.status) {
      getCurrentuser();
    }
  }
  if (newuser && newuser.kyc_status == 'in-progress' && !editkyc) {
    return (
      <div className='flex items-center justify-center'>
        <span style={{ color: 'black' }} >{"Your kyc is in progress press edit to edit the information"}</span>
        <Button width='100px' height='30px' label='Edit' className="ml-3" onClick={() => setEditkyc(true)}></Button>
      </div>
    )
  } else {
    return (
      <KYCStyle>
        <>
          {newuser && newuser.kyc_status != 'passed' ?
            <>
              <Steps current={current} className='stepper'>
                {steps?.map(item => (
                  <Step key={item.title} title={item.title} className={steps[current].title === item.title ? 'stepper-active' : 'stepper-label'} />
                ))}
              </Steps>
              <div className='steps-content'>
                <div className='steps-content-item'>
                  {steps[current].content}
                  <div className='steps-action'>
                    {current > 0 && (
                      <Button
                        className='btn-previous mr-[20px]'
                        label='Previous'
                        textColor={'black'}
                        backgroundColor={Colors.darkGrey}
                        onClick={() => prev()}
                      />
                    )}
                    {current < steps.length - 1 && (
                      <Button
                        className='btn-kyc rounded-full'
                        label='Continue'
                        textColor={'white'}
                        backgroundColor={Colors.darkBlue} onClick={() => {
                          if (current == 0) {
                            if (payload.firstname || payload.lastname || payload.birthday) {
                              if (editkyc) {
                                editApplicant();
                              } else {
                                createanAppllicant();
                              }
                            } else {
                              next();
                            }
                          } else if (current == 1) {
                            if (filestate) {
                              uploaddoc();
                            } else {
                              next();
                            }
                            // next();
                          }
                        }}
                      />
                    )}
                    {current === steps.length - 1 && (
                      <Button
                        className='btn-kyc rounded-full'
                        label='Submit'
                        textColor={Colors.darkBlue}
                        backgroundColor="lightgray"
                        onClick={() => uploadSelfie()}
                      />

                    )}
                  </div>
                </div>

              </div>

            </>
            :
            <div className='flex items-center justify-center'>
              <span style={{ color: 'black' }} >{"Your kyc is Verified"}</span>
            </div>
          }

        </>
      </KYCStyle>
    );
  }
};

export default KYC;