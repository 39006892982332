import React from 'react';

import { Text } from 'components';

import RecordView from './Components/VideoRecorder';

const LivenessVerification = ({selfie, setSelfie,setSelfieurl,selfieurl}) => {

	return (
		<>
			<Text
				text={ 'Please make sure your face is within the circle' }
				color={ 'black' }
				fontWeight={ '700' }
				textAlign={ 'center' }
				fontSize={ '20px' }
				className='title1 mb-2'
			/>
			<RecordView selfie={selfie} setSelfie={setSelfie} selfieurl={selfieurl} setSelfieurl={setSelfieurl} />
		</>
	);
};

export default LivenessVerification;