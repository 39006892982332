import "./datetime.css";
import React,{useState} from 'react';
import DateTimeDisplay from "./DateTimeDisplay";
import useCountDown from "./hooks/useCountDown";
import clock from './timer.png';
const ExpiredNotice = () => {
  return (
    <div className="expired-notice ">
      <span className="text-black">Auction Ended</span>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <div
        rel="noopener noreferrer"
        className="countdown-link items-center"
      >
        <img  src={clock} className="w-8 h-8 mr-2"/>
        <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
        <p className="text-black" style={{fontWeight:"bold"}}>d</p>{" "}<p className="text-black mx-1 mb-0" style={{fontWeight:"bold"}}>:{" "}</p>
        <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
        <p className="text-black" style={{fontWeight:"bold"}}>h</p>{" "}<p className="text-black mx-1 mb-0" style={{fontWeight:"bold"}}>:{" "}</p>
        <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
        <p className="text-black" style={{fontWeight:"bold"}}>m</p>{" "}<p className="text-black mx-1 mb-0" style={{fontWeight:"bold"}}>:{" "}</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
        <p className="text-black" style={{fontWeight:"bold"}}>s</p>{" "}<p className="text-black mx-1 mb-0" style={{fontWeight:"bold"}}>{" "}</p>
      </div>
    </div>
  );
};
const CountDownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);


  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDownTimer;
