import styled from 'styled-components';

const VideoRecorderStyle = styled.div`
  animation: slideToBottom 1s;
  button {
    color: lemonChiffon;
    margin: 0 10px 0 10px;
  }
  p {
    color: white;
  }
  .info {
    background: rgba(113, 254, 127, 0.1);
    border-radius: 5px;
    align-items: center;
  }

  @media screen and (max-width: 650px) {
    padding: 20px;
    margin: auto;
  }
`;

export default VideoRecorderStyle;