import { Layout } from 'pages/LandingPageNew/Components';
import React from 'react';
import Hero from './sections/Hero';
import HowItsWork from './sections/HowItsWork';
import InstagramGalleryGallery from './sections/InstagramGalleryGallery';

const Main = () => {
	return (
		// <Layout showAccessList={true}>
		<Layout>
			<Hero />
			<HowItsWork />
			<InstagramGalleryGallery />
		</Layout>
	);
};

export default Main;