import React, { useEffect } from "react";

import Header from "../Header";
import Footer from "../Footer";
import AccessList from "../AccessList";
import { HeaderMarketplace } from "components";

const Layout = ({
  children,
  hideFooter = false,
  showAccessList = false,
  textAccessList = "",
  btnMembership = false,
  bgColor = "bg-newBeginning",
  isMarketplace = false,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scroll({ top: 2 });
  }, []);

  return (
    <div className={bgColor}>
      {isMarketplace ? <HeaderMarketplace /> : <Header />}
      <div className="h-[60px] md:h-[70px]" />
      {children}
      {showAccessList && (
        <AccessList text={textAccessList} showMembership={btnMembership} />
      )}
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Layout;
