import React from "react";
import { useNavigate } from "react-router";

import { Text, Socmed } from "components";
import { Images, Colors, MenuList } from "constant";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-darkSecurity relative z-10">
      <div className="w-full md:container lg:mx-auto md:mx-0 px-5 lg:px-6 py-8 grid grid-cols-1 md:grid-cols-3 ">
        <div className="flex flex-col md:justify-between items-start xl:mr-auto mt-[30px] md:mt-0 h-full order-last md:order-first">
          <img
            src={Images.OkuSubmark}
            alt=""
            className="h-[25px] md:h-[56px]"
          />
          <Text
            text="© 2022 Oku House | All right reserved"
            fontFamily="Expressway"
            contentClassName="tracking-normal-[1px] text-[14px]"
            className="mt-3 md:mt-0"
            fontSize="14px"
            lineHeight="25px"
            color={Colors.lightGrey}
          />
        </div>

        <div className="md:flex xl:mx-auto md:col-span-2 justify-between w-full">
          <div className="flex md:justify-around">
            <div className="xl:mr-20 md:mr-5 mr-0">
              <Text
                text="quick links"
                fontFamily="Expressway"
                contentClassName="tracking-normal-[1px]"
                className="mb-2 uppercase"
                color={Colors.lightGrey}
              />
              <div className="grid grid-cols-3 w-fit">
                <div className="grid col-span-2 grid-rows-3 gap-y-3 lg:gap-y-1 md:gap-y-0 grid-flow-col gap-x-8 md:gap-x-5 xl:gap-x-10">
                  {MenuList.map((menu, index) => (
                    <Text
                      key={index}
                      text={menu.label}
                      fontFamily="AcuminPro"
                      className="cursor-pointer"
                      color={Colors.newBegining}
                      lineHeight="25px"
                      onClick={() =>
                        menu.label === "Access List"
                          ? window.open(menu.url, "_blank")
                          : navigate(menu.url)
                      }
                    />
                  ))}
                  <Text
                    text={"Partnership"}
                    fontFamily="AcuminPro"
                    className="cursor-pointer"
                    color={Colors.newBegining}
                    lineHeight="25px"
                    onClick={() => navigate("/partnership")}
                  />
                  {/* <Text
									text={'Blog'}
									fontFamily='AcuminPro'
									className='cursor-pointer'
									lineHeight='25px'
									color={Colors.newBegining}
								onClick={ () => navigate('/partnership') }
								/> */}
                </div>
                <div className="px-4 md:px-5 xl:px-10">
                  <Text
                    text={"FAQ"}
                    fontFamily="AcuminPro"
                    className="cursor-pointer md:px-5 lg:px-0 px-0"
                    lineHeight="25px"
                    color={Colors.newBegining}
                  />
                  <p className="flex lg:flex-row md:flex-col flex-row md:px-5 lg:px-0 px-0 font-AcuminPro text-newBeginning text-xs md:text-sm lg:text-base whitespace-nowrap">
                    <div className="">
                      <a
                        onClick={() => navigate("/sign-in")}
                        className="cursor-pointer"
                      >
                        Sign In /
                      </a>
                    </div>
                    <div className="md:pl-0 lg:pl-0 pl-1">
                      <a
                        onClick={() => navigate("/sign-up")}
                        className="cursor-pointer"
                      >
                        Sign Up
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-fit mt-[30px] md:mt-0">
            <Text
              text="Keep up with us!"
              fontFamily="Expressway"
              contentClassName="tracking-normal-[1px]"
              className="mb-4 uppercase"
              color={Colors.lightGrey}
            />
            <Socmed />
            <Text
              text="Terms and Conditions | Privacy Policy"
              fontFamily="Expressway"
              contentClassName="tracking-normal-[1px]"
              className="mt-4 cursor-pointer"
              color={Colors.lightGrey}
              onClick={() => navigate("/legality")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
