import { IReducer } from 'interface';
import { UserActionTypes, SET_RESTAURANT, SET_NFT } from './userActionTypes';

// interface UserState {
//   profile: IReducer.UserProfile
// }

const initialState = { restaurant: {} };

const RestaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RESTAURANT:
      return {
        ...state,
        restaurant: action.payload
    };
    case SET_NFT:
      return {
        ...state,
        nft: action.payload
    };

    default: return state;
  }
};

export default RestaurantReducer;