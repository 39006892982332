import styled from 'styled-components';

export const SecurityStyle = styled.div`
    padding: 0 15%;

    .context {
        display: flex;
    }
    .content {
        width: 100%;
        border-top: 1px solid #424949;
        margin: 30px 0 0px 0;
    }

    @media screen and (max-width: 650px) {
        padding: 20px;
      }

`;



export const SubHeaderStyle = styled.div`
   .inputbox{
      width:80%;
      height:60px;
      padding:0 1em;
      border:1px solid black;
   }
   input[type="file"] {
      display: none;
   }
   .inputblock{
      flex-direction:column;
      align-items: center;
      justify-content: center;
   }
   .label{
      margin:0 10%;
      align-self: flex-start;
   }
   .profil {
      margin-right: 30px;
   }
   @media (max-width: 715px) { 
      .profil {
         width: 90px;
         height: 90px;
         margin-right: 15px;
      }
    }

    @media (max-width: 480px) { 
      .settingmenu {
         position: relative;
         margin-top: -30px;
      }
    }
`;


export const ForgotPasswordStyle = styled.div`
  display: flex;
  height: ${props => props.isChangePassword ? '100%' : '100vh'};
  
  .title {
    color: #FFFBD1;
    padding: 0 80px 0 80px;
    position: relative;
    top: 60%;
  }

  .right-side {
    margin: auto;
  }

  .slogan {
    padding: 0 80px 0 80px;
    color: white;
    position: relative;
    top: 60%;
  }

  .btn-back {
    border: 1px solid #1C1E19;
    border-radius: 100px;
  }

  .logo-cms {
    display: block;
      margin: 0 auto;
  }

  .login-form {
    width: 540px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
  }
`;
