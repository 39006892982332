import { persistStore, persistReducer } from 'redux-persist';
import thunk, { ThunkDispatch } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
// import errorHandlerMiddleware from 'middlewares/errorHandlerMiddleware';

// import type { ActionTypes } from './actionTypes';
import reducers from './combineReducers';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk,
    // errorHandlerMiddleware
  ],
  // eslint-disable-next-line no-undef
  devTools: process.env.NODE_ENV !== 'production'
});

const persistor = persistStore(store);

// export type AppDispatch = ThunkDispatch<any, any, AppActions>;
// export type AppActions = ActionTypes;
// export type RootState = ReturnType<typeof reducers>;

export { store, persistor };