import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";

import { Button, Text } from "components";
import { Colors, Images, MenuList } from "constant";

import { HeaderStyle, MenuItemStyle } from "./style";

const Header = () => {
  const menuList = [
    {
      label: "About Oku",
      url: "/",
    },
    {
      label: "Memberships",
      url: "/memberships",
    },
    {
      label: "Clubhouse",
      url: "/",
    },
    {
      label: "Our Events",
      url: "/events",
    },
    {
      label: "Collections",
      url: "/all-collections",
    },
  ];
  const navigate = useNavigate();
  const pathname = useLocation()?.pathname;

  const [showSidebar, setShowSidebar] = useState(false);

  const onClickBurgerMenu = () => setShowSidebar(!showSidebar);

  const renderMenuList = () =>
    menuList.map((menu, index) => (
      <MenuItemStyle
        key={index}
        className="menu-item"
        isPageFocus={pathname === menu.url}
        onClick={() =>
          menu.label === "Access List"
            ? window.open(menu.url, "_blank")
            : navigate(menu.url)
        }
      >
        <Text
          text={menu.label}
          fontWeight="500"
          color={Colors.darkSecurity}
          className="label uppercase"
        />
      </MenuItemStyle>
    ));

  const renderIconsMyProfile = () => (
    <img
      src={Images.ProfileIcon}
      alt=""
      className="w-5 h-5 lg:h-[30px] md:h-[30px] lg:w-[30px] md:w-[30px]"
    />
  );

  const renderIconsCart = () => (
    <img
      src={Images.BagIcon}
      alt=""
      className="w-4 h-5 lg:h-[30px] md:h-[30px] lg:w-[24px] md:w-[24px]"
    />
  );

  const renderBurgerMenu = () => (
    <div className="flex gap-x-4">
      <div className="relative md:hidden lg:hidden">{renderIconsCart()}</div>
      <div className="burger block lg:hidden " onClick={onClickBurgerMenu}>
        <div className="burger-lines" />
      </div>
    </div>
  );

  const renderAfterLoginMenuSidebar = () => (
    <div className="flex flex-col pt-8">
      <div className="profile-menu-sidebar flex items-center gap-x-3">
        <div className="">{renderIconsMyProfile()}</div>
        <MenuItemStyle>
          <Text
            text="MY PROFILE"
            fontWeight="500"
            color={Colors.darkSecurity}
            className="label uppercase"
          />
        </MenuItemStyle>
      </div>
      <div className="profile-menu-sidebar flex items-center gap-x-3 border-b border-darkGrey/20 pb-3">
        <MenuItemStyle>
          <Text
            text="WALLET"
            fontWeight="500"
            color={Colors.darkSecurity}
            className="label uppercase"
          />
        </MenuItemStyle>
      </div>
    </div>
  );

  return (
    <HeaderStyle showSidebar={showSidebar}>
      <div className="fixed overlay w-full h-screen flex justify-center items-center bg-black/80 z-[799]"></div>
      <div className="fixed flex items-center w-full h-[60px] md:h-[70px] bg-newBeginning z-[999]">
        <div className="max-w-[1276px] w-full px-5 mx-auto flex justify-between items-center">
          <div className="flex items-center justify-between w-full lg:w-fit">
            <img
              onClick={() => navigate("/")}
              src={Images.OkuHouseLogo}
              className="w-[90px] md:w-[150px]  cursor-pointer"
              alt=""
            />
            {renderBurgerMenu()}
          </div>

          <div className="h-fit list-container hidden lg:flex">
            {renderMenuList()}
          </div>

          <div className="space-x-5 items-center hidden lg:flex">
            {renderIconsCart()}
            {renderIconsMyProfile()}
          </div>
        </div>
      </div>

      <div className="sidebar h-screen">
        {renderAfterLoginMenuSidebar()}
        {renderMenuList()}
      </div>
    </HeaderStyle>
  );
};

export default Header;
