import React from 'react';
import { Text } from 'components';
import { Images, Icons } from 'constant';

import TransactionHistoryStyle from './style'
// import { Icons } from 'react-toastify';

const TransactionCard = ({ image, title, restoName, time, purchase, img, status, matic }) => {

  return (
    <TransactionHistoryStyle>
      <div className="rounded overflow-hidden shadow-lg p-px w-full mb-8" >
        <div className="rounded-md">
          <div className="px-6 py-6">
            <div className="wrapper1 space-x-4">
              <div className="flex-shrink-0">
                <img className="rounded w-[60px] h-[60px]" src={ image } />
              </div>
              <div className="section1 flex-1 min-w-0">
                <Text text={ title } className='font-bold' fontSize='20px' color='black' fontFamily='Lora' />
                <div className='flex mt-3 space-x-2'>
                  <Text text='Owned by' className='font-bold' fontSize='16px' color='gray' fontWeight='300' />
                  <Text text={ restoName } fontSize='16px' className='font-bold' color='gray' />
                </div>
              </div>
              <div className="section2 inline-flex text-base font-semibold text-gray-900 dark:text-white">
                <div className="flex-1 min-w-0 justify-end">
                  <div className='flex space-x-2'>
                    <img className="w-[15px] h-[15px]" src={ status === "transfer" ? Images.TransactionOut : Images.TransactionIn } />
                    <Text text={ time } fontSize='16px' color='gray' fontWeight='300' />
                  </div>
                  <div className='flex mt-3 space-x-2 ' style={{alignItems:'center'}}>
                    <Text text='Total Purchase' fontSize='16px' color='gray' fontWeight='300' />
                    <Text text={ purchase } fontSize='16px' className='font-bold' color='black' />
                    <img className="w-[15px] h-[15px]" src={Icons.polygon } alt="polygon" />
                    <Text text={matic } fontSize='16px' className='font-bold' color='black' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransactionHistoryStyle>
  );
};

export default TransactionCard;