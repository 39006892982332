import React, { useState, useEffect } from 'react';

import { NFTCard, RestaurantCard } from 'components/Card';
import { Text ,Card} from 'components';
import {getrestaurants} from '../../../../services/favourites';
import {getRestaurants} from '../../../../services/restaurant';
import {getallNfts} from '../../../../services/Nft';
import FavouriteStyle from './style';
import { useDispatch, useSelector } from 'react-redux';
import selectors from 'selectors';
import Loader from "react-js-loader";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Favourite = () => {
  const [openTab, setOpenTab] = useState(1)
  const [open, setOpen] = useState(true)
  const navigate= useNavigate();
  const dispatch = useDispatch();
  const current_user = useSelector(selectors.userProfile);
  const [fav_restaurants, setFavouriteRestaurants] = useState([]);
  const [fav_nfts, setFavouriteRNfts] = useState([]);

  const getFavouriterestaurants = async() =>{
    const res = await getrestaurants(current_user.user.id)
    const result_ = res.data.result;
    const restaurant_ = result_.filter((item) => item.type == "restaurant")
    const result1 = await getRestaurants()
    const newres_ = result1.data.result;
    const arr=[]
    const fixeddata = restaurant_.map((item) => {
      newres_.map((i) => {
        if(i.restaurantName == item.restaurantName){
        arr.push(i);
      }})
    })
    setFavouriteRestaurants(arr);
    const nfts_ = result_.filter((item) => item.type == "NFT");
    const resultnft = await getallNfts();
    const new_nfts = resultnft.data.result;
    const nftarr= []
    nfts_.map((item)=>{
      new_nfts.map((i) =>{
        if(i.NFTName == item.NFTName){
          nftarr.push(i);
        }
      } )
    })

    setFavouriteRNfts(nftarr);
    setOpen(false);

  }

  const [maticToUsd, setMatictousd] = useState('');

  const getmatictoUsd = async() => {
    const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",{headers: {"Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods':['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'],
    }})
    setMatictousd(matictousd.data['matic-network'].usd)
  }
  useEffect(()=> {
    setOpen(true);
    getFavouriterestaurants()
    getmatictoUsd();
  },[])
  const handleNestaurantNavigate = (item)=>{
    dispatch({
      type: "SET_RESTAURANT",
      payload:item,

    });
    navigate('/restaurant')
  }
  const menu = [
    { title: "Restaurant", id: 1 },
    { title: "NFTs", id: 2 },
  ]

  const restaurantList = [
    { id: 1, restoName: 'Restaurant 1', total: "1,000" },
    { id: 2, restoName: 'Restaurant 2', total: "1,200" },
  ]

  return (
    <FavouriteStyle>
      <div className='flex flex-wrap'>
        <div className='w-full'>
          <div
            className='flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row'
            role='tablist'
          >
            { menu.map((item, i) => (
              <div key={ item.id } className={ openTab === item.id ? 'bg-slate-800 rounded p-4 mr-5 flex' : 'p-4 -mb-px mr-2 last:mr-0 flex' }>
                <a
                  onClick={ e => {
                    e.preventDefault();
                    setOpenTab(item.id);
                  } }
                  data-toggle='tab'
                  href={ `link${item.id}` }
                  role='tablist'
                >
                  <Text
                    text={ item.title }
                    fontWeight={ '800' }
                    color={ openTab == item.id ? '#FFFBD1' : '#B2BABB' }
                    fontSize={ '16px' }
                    fontFamily={ 'Nunito' }
                  />
                </a>
              </div>
            )) }
          </div>
          <div className='relative flex flex-col min-w-0 break-words w-full mb-6'>
            <div className='mt-5 flex-auto'>
              <div className='tab-content tab-space'>
                <div className={ openTab === 1 ? 'block' : 'hidden' } id='link1'>
                  <div className='wrapper1'>
                    { fav_restaurants && fav_restaurants.map((item, i) => (
                      <div className='item' key={ item.id } >
                        			<Card.MarketplaceCard
                                  wrapperClass='w-full hover:-translate-y-4 border border-solid border-platinumSolid hover:border-[2px] hover:border-abundance '
                                  title={ item.restaurantName }
                                  address={item.City}
                                  count={item.totalNfts}
                                  srcImages={ `${process.env.REACT_APP_S3_URL}${item.restaurantProfile}`}
                                  srcImagesLogo={ item.imgLogo }
                                  key={ i }
                                  onClick={ () => {
                                    dispatch({
                                      type: "SET_RESTAURANT",
                                      payload:item,
                                    });
                                    navigate(`/collections/${item._id}`,{state:{img:item.img,id:item._id,data:item}})

                                  }
                                  }
                                />
                      </div>
                    )) }
                  </div>
                </div>
                <div className={ openTab === 2 ? 'block' : 'hidden' } id='link1'>
                <div className='wrapper1'>
                  {fav_nfts && fav_nfts.map((item) => (
                    <div className='item'  key={ item.id }>
                      <NFTCard item={item} maticToUsd={maticToUsd} wrapperClass='w-full hover:-translate-y-4 border border-solid border-platinumSolid h-[375px] hover:border-[2px] hover:border-abundance '/>
                    </div>
                  ))}
                </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open ? 
        <Loader type="spinner-cub" bgColor={"black"}  color={'black'} size={80} />
        :""
      }
    </FavouriteStyle>
  );
};

export default Favourite;