import styled from 'styled-components';

const KycStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  

  .input-wrapper {
    margin-top:10%;
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    min-width: 300px;
  }
`;

export default KycStyle;
