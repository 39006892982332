import {get,put,post} from '../http';


export const Contactmail = async(values) => {
    try{
        const result = await post(`favourites/Contactmails/`, values)
        return result;
    }catch(error){
        throw error;
    }
}

export const AddFavourites = async(id,values, tokens) => {
    try{
        const result = await put(`favourites/FavouriteController/${id}`, values, tokens)
        return result;
    }catch(error){
        throw error;
    }
}

export const RemoveFavourites = async(id,values, tokens) => {
    try{
        const result = await put(`favourites/RemoveFavourite/${id}`, values, tokens)
        return result;
    }catch(error){
        throw error;
    }
}

export const getrestaurants = async(id) => {
    try{
        const result = await get(`favourites/getrestaurants/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}

export const getTrendingnfts = async(id) => {
    try{
        const result = await get(`favourites/trending_nfts`)
        return result;
    }catch(error){
        throw error;
    }
}
