import styled from 'styled-components';

const JoinOurTeamStyle = styled.div`
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 60px;
    perspective: 1000px;
    border-radius: 5px;
    margin-top: 40px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: rotateX(${props => props.success ? '180deg' : '0deg'});
    transition: transform 0.6s;
    transform-style: preserve-3d;
    border-radius: 5px;
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    color: black;
    width: 100%;
    border-radius: 5px;
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateX(180deg);
    border-radius: 5px;
  }

  .success {
    background-color: #27AE60 !important;
  }

  @media screen and (max-width: 720px) {
    .flip-card {
      height: 48px;
    }

    .modal-content {
      padding: 20px;
    }
  }

  @media screen and (max-width: 540px) {
    .flip-card {
      margin-top: auto;
    }
    
    .modal-content {
      height: 100vh;
    }

    .modal-input-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      flex: 1;
    }
  }
`;

export default JoinOurTeamStyle;