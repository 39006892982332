import React from 'react';

import { Header, Footer, PageContruction } from 'pages/LandingPageNew/Components';
import { Images } from 'constant';

const list = [
	'Be the first to be notified of our first launch!',
	'Get prioritized access to our Oku Events!',
	'Get discounts on purchasing any of our collection pieces!'
];
const Gallery = () => {
	return (
		<div className='bg-white'>
			<Header />
			<div className='h-[100px]' />
			<div className='w-full m-auto md:px-20 container pb-5 md:py-16'>
				<PageContruction
					Image={Images.Gallery}
					Title={'Oops.. This page is still \n under construction'}
					Subtitle={[
						'Our Oku family is still working on compiling pieces for our first collection! We’re very excited for you to see what we have in store for you!',
					]}
					Heading='JOIN OUR ACCESS LIST TO...'
					listString={list}
					// TopBtn='Join Access List'
					// urlTop={ () => window.open('https://8i76pet9rc6.typeform.com/to/VjruNm2I', '_blank') }
					BottomBtn='We’re on Discord!'
					urlBottom={() => window.open('https://discord.gg/okuhouse', '_blank')} />
			</div>
			<Footer />
		</div>
	);
};

export default Gallery;