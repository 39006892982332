import style from 'styled-components';
import '../../animation.scss';

const RestaurantPartnerStyle = style.div`
  .restaurant-page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #FBF6F3;
    z-index: 99;
    overflow: hidden;
    padding-bottom: 120px;
    margin-top: -60px;
    animation: slideToTop 5s;
  }

  .rectangle1 {
    background-color: #FBF6F3;
    height: 15vh;
    transform: rotate(-4deg);
    width: 120vw;
    margin-top: -70px;
    margin-left: -50px;
  }
  
  .content-wrapper {
    display: flex;
  }

  .description {
    flex: 1px;
    margin-right: 30px;
    margin-top: 30px;
    width: 50%;
    height: fit-content;
    animation: slideToRight 5s;
  }

  .restaurant-partner-title {
    position: relative;
    display: flex;
    align-items: center;
  }

  .line {
    position: absolute;
    right: -30px;
    width: 160px;
    height: 1px;
    background: linear-gradient(90deg, #FFFBD1 0%, #1B2B3F 100%);
  }

  .flex-1 {
    position: relative;
  }

  @media screen and (max-width: 900px) {
    .restaurant-page-container {
      overflow: scroll;
    }

    .header-backdrop {
      height: 100px;
    }

    .content-wrapper {
      display: block;
    }

    .line {
      display: none;
    }

    .description {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
    }

    .form-container {
      margin: auto;
      width: 100%;
      max-width: unset;
      margin-bottom: 30px;
    }

    .mb-30 {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-height: 890px) {
    .restaurant-page-container {
      // padding-top: 80px;
    }
  }

  @media screen and (max-height: 750px) {
    .restaurant-page-container {
      // padding-top: 100px;
      overflow: scroll;
    }
  }
  
  @media screen and (max-width: 720px) {
    .restaurant-page-container {
      // padding-top: 100px;
    }
  }
  
  @media screen and (max-width: 500px) {
    .content-wrapper {
      padding: 0px 20px;
      margin-top: 0px;
    }
  }
`;

export default RestaurantPartnerStyle;