import {get,put,post} from '../http';

export const getNfts = async(values) => {
    try{
        const result = await post('nft/getNFTs', values)
        return result;
    }catch(error){
        throw error;
    }
}


export const getNftsbyid = async(values) => {
    try{
        const result = await post('nft/getNftsbyid', values)
        return result;
    }catch(error){
        throw error;
    }
}

export const getallNfts = async() => {
    try{
        const result = await get('nft/getallNFTs')
        return result;
    }catch(error){
        throw error;
    }
}

export const getRestaurantNFTs= async(name)=> {
    try{
        const result = await get(`nft/getRestaurantNFTs/${name}`)
        return result;
    }catch(error){
        throw error;
    }
}


export const getLatestNFTs= async()=> {
    try{
        const result = await get(`nft/getLatestNFTs`)
        return result;
    }catch(error){
        throw error;
    }
}


export const PayNow= async(id, values, tokens)=> {
    try{
        const result = await put(`nft/updatenfts/${id}`, values, tokens)
        return result;
    }catch(error){
        throw error;
    }
}


export const executeNfts= async(id, values, tokens)=> {
    try{
        const result = await put(`nft/executeNfts/${id}`, values, tokens)
        return result;
    }catch(error){
        throw error;
    }
}

export const gettransactionHistory= async(id)=> {
    try{
        const result = await get(`nft/gettransactionHistory/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}

export const updatePayment = async(id,values) => {
    try{
        const result = await put(`nft/updatePayment/${id}`, values)
        return result;
    }catch(error){
        throw error;
    }
}

export const getnftstatus = async(id, tokens) => {
    try{
        const result = await get(`nft/getnftstatus/${id}`, tokens)
        return result;
    }catch(error){
        throw error;
    }
}


export const PutonSale = async(id,values, tokens) => {
    try{
        const result = await put(`nft/putonsale/${id}`, values, tokens)
        return result;
    }catch(error){
        throw error;
    }
}

export const getnftbyid = async(id) => {
    try{
        const result = await get(`nft/getnftbyid/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}

export const Placeabid = async(values) => {
    try{
        const result = await post(`nft/placeabid`, values)
        return result;
    }catch(error){
        throw error;
    }
}

export const Makeoffer = async(values) => {
    try{
        const result = await post(`nft/makeoffer`, values)
        return result;
    }catch(error){
        throw error;
    }
}

export const getbidhistory = async(id) => {
    try{
        const result = await get(`nft/getbidhistory/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}
export const getsaleshistory = async(id) => {
    try{
        const result = await get(`nft/getsaleshistory/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}

export const delistNft = async(id) => {
    try{
        const result = await put(`nft/delist/${id}`)
        return result;
    }catch(error){
        throw error;
    }
}

export const getlastsold = async(id) => {
    try{
        const result = await get(`nft/getlastsold/${id}`);
        return result;
    }catch(error){
        throw error;
    }
}

export const declineoffer = async(id) => {
    try{
        const result = await put(`nft/declineoffer/${id}`);
        return result;
    }catch(error){
        throw error;
    }
}